import React from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { emptyColleague } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IColleague } from 'types/payloadTypes';

interface ChatColleagueListProps {
  colleagueArray: Array<IColleague>;
  onSelect: (selectColleague: IColleague) => void;
  scrollbarRef: React.RefObject<any>;
}

function ChatColleagueList({ colleagueArray, onSelect, scrollbarRef }: ChatColleagueListProps) {
  return (
    <ChatColleagueContainer ref={scrollbarRef}>
      {colleagueArray.map((colleague) => {
        const { departmentName, hospitalName, colleagueName, colleaguePhoto } = colleague;
        return (
          <ColleagueProfile onClick={() => onSelect(colleague)}>
            <ProfileImg>
              <Image alt="colleague-profile" src={colleaguePhoto || emptyColleague} />
            </ProfileImg>
            <ColleagueSection>
              <ColleagueName>{colleagueName}</ColleagueName>
              <Hospital>{departmentName ? `${hospitalName} / ${departmentName}` : hospitalName}</Hospital>
            </ColleagueSection>
          </ColleagueProfile>
        );
      })}
    </ChatColleagueContainer>
  );
}

export default ChatColleagueList;

const color = {
  colleagueName: '#424242',
  hospitalName: '#777777',
};

const ChatColleagueContainer = styled.div`
  overflow-y: auto;
  padding: 0 24px;
`;

const ColleagueProfile = styled.div`
  display: flex;
  margin: 40px 0;
  align-items: center;
  cursor: pointer;
`;

const ProfileImg = styled.div`
  ${flexCenter};
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const ColleagueName = styled.div`
  font-family: NotoSansCJKkr;
  font-size: 13px;
  color: ${color.colleagueName};
  margin-bottom: 8px;
`;

const Hospital = styled.div`
  font-family: NotoSansCJKkr;
  font-size: 13px;
  color: ${color.hospitalName};
`;

const ColleagueSection = styled.section`
  ${flexColumn};
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
`;
