import React from 'react';

import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { forbiddenColor } from 'styles/global/color';
import { forbiddenIllust } from 'assets/images';
import { flexColumn } from 'styles/global/mixins';

function Forbidden() {
  return (
    <BackBround>
      <ForbiddenContainer>
        <ForbiddenImage alt="ForbiddenCover" src={forbiddenIllust} />
        <ErrorTitle>잘못된 접근입니다</ErrorTitle>
        <ErrorMessage>해당 페이지는 로그인 이후 서비스 이용이 가능합니다</ErrorMessage>
        <RedirectToSigninBtn to="/signin">로그인하기</RedirectToSigninBtn>
      </ForbiddenContainer>
    </BackBround>
  );
}

export default Forbidden;

const BackBround = styled.div`
  background-color: ${forbiddenColor.background};
`;

const ForbiddenContainer = styled.div`
  ${flexColumn};
  font-family: NotoSansRegular;
  padding: 124px 286px;
  align-items: center;
  background-color: white;
  width: 1089px;
  height: 100vh;
  margin: 0 auto;
`;

const ForbiddenImage = styled.img`
  width: 408px;
  height: 408px;
`;

const ErrorTitle = styled.h1`
  font-size: 28px;
  color: ${forbiddenColor.titleText};
  text-align: center;
  margin-top: 16px;
`;

const ErrorMessage = styled.h2`
  font-size: 15px;
  line-height: 1.47;
  color: ${forbiddenColor.messageText};
  text-align: center;
  margin-top: 12px;
`;

const RedirectToSigninBtn = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
  padding: 8px 22px;
  width: 516px;
  height: 40px;
  background-color: ${forbiddenColor.btnBackground};
  border-radius: 3px;
  border: 1px solid ${forbiddenColor.btnBorder};
  color: ${forbiddenColor.btnText};
  margin-top: 42px;
`;
