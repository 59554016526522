import React from 'react';

import styled from '@emotion/styled';

import { Button } from 'components/atoms';

import { modalColors } from 'styles/global/color';
import { cooperationCoverIllust } from 'assets/images';

interface ConfirmRequestProps {
  onSelect: (selectedTab: string) => void;
  onConfirm: () => void;
}

function ConfirmRequest({ onSelect, onConfirm }: ConfirmRequestProps) {
  return (
    <>
      <ModalTitle>협진 요청을 완료하시겠습니까?</ModalTitle>
      <ModalExplanation>협진 요청 사항을 다시 확인하시려면 이전을 선택해 주세요</ModalExplanation>
      <ConfirmIconWrap>
        <ConfirmCooperationIcon src={cooperationCoverIllust} />
      </ConfirmIconWrap>
      <BtnWrap>
        <Button color="gray" size="mid" onClick={() => onSelect('SelectedDoctorListTab')}>
          이전
        </Button>
        <Button color="lightBlue" size="mid" onClick={() => onConfirm()}>
          요청 완료
        </Button>
      </BtnWrap>
    </>
  );
}

export default ConfirmRequest;

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 18px;
  color: ${modalColors.normalText};
  font-weight: 500;
  margin-top: 15px;
`;

const ModalText = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
`;

const ModalExplanation = styled(ModalText)`
  color: ${modalColors.normalText};
  margin-top: 9px;
  line-height: 30px;
`;

const ConfirmIconWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0;
`;

const ConfirmCooperationIcon = styled.img`
  width: 229px;
  height: 195px;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 49px;
`;
