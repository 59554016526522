import React from 'react';
import styled from '@emotion/styled';

import { BasicBtn, EndCallIcon } from 'styles/button';
import { flexCenter } from 'styles/global/mixins';

interface EndCallButtonProps {
  onCallEnd: () => void;
}

function EndCallButton({ onCallEnd }: EndCallButtonProps) {
  return (
    <BtnWrapper onClick={() => onCallEnd()}>
      <EndCallIcon />
    </BtnWrapper>
  );
}

export default EndCallButton;

const BtnWrapper = styled(BasicBtn)`
  ${flexCenter};
  margin: 0 12px;
  border-radius: 21px;
  background-color: var(--end-call);
  height: 36px;
  width: 50px;
  padding: 4px;
`;
