import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';

import { useForm } from 'hooks';

import { signUpColors } from 'styles/global/color';
import { NormalInput } from 'styles/textinput';
import { NextBtn } from 'styles/button';
import { showBtn, hideBtn, alertIcon } from 'assets/images';

const userInfo = {
  firstName: '',
  lastName: '',
  eMail: '',
  pw: '',
  repw: '',
  mobileNo: '',
};

const pwVisibleStatus = {
  pwVisibility: false,
  rePwVisibility: false,
};

function UserInfo() {
  const [form, onFormChange] = useForm(userInfo);

  const [formFullfilled, setFormFullfilled] = useState(false);
  const [pwVisibility, setPwVisibility] = useState(pwVisibleStatus);
  const [coincidePw, setCoincidePw] = useState(true);
  const [pwValidation, setPwValidation] = useState(true);
  // eslint-disable-next-line
  const [receivedAuthNo, setVerificationNo] = useState(null);

  const formCheck = () => {
    if (!Object.values(form).includes('') && coincidePw) {
      setFormFullfilled(true);
    } else {
      setFormFullfilled(false);
    }
  };

  const handlePwValidation = () => {
    const special = /\w\d/;
    if (!form.pw) {
      setPwValidation(true);
    } else if (!special.test(form.pw) || form.pw.length < 9) {
      setPwValidation(false);
    } else {
      setPwValidation(true);
    }
  };

  const handlePwCheck = () => {
    if (!form.pw || !form.repw) {
      setCoincidePw(true);
    }
    if (form.pw !== form.repw) {
      setCoincidePw(false);
    } else {
      setCoincidePw(true);
    }
  };

  // const sendAuthNo = () => {
  //   dispatch(getVerificationCode({ mobileNo: form.mobileNo }));
  // };

  const toggleVisibility = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const target = e.target as HTMLButtonElement;
    setPwVisibility({
      ...pwVisibility,
      [target.name]: !pwVisibility[target.name],
    });
  };

  useEffect(() => {
    formCheck();
    handlePwCheck();
    // setVerificationNo(verificationNo);
    // eslint-disable-next-line
  }, [form]);

  return (
    <UserInfoContainer>
      <Label htmlFor="familyNameInput">
        이름
        <InputWrapper>
          <UserInfoInput
            id="familyNameInput"
            name="firstName"
            placeholder="성을 입력해주세요"
            type="text"
            onChange={(e) => onFormChange(e)}
          />
          <UserInfoInputForMargin
            id="familyNameInput"
            name="lastName"
            placeholder="이름을 입력해주세요"
            type="text"
            onChange={(e) => onFormChange(e)}
          />
        </InputWrapper>
      </Label>
      <Label htmlFor="emailInput">
        이메일 주소
        <UserInfoInput
          id="emailInput"
          name="eMail"
          placeholder="이메일 주소를 입력해주세요"
          type="text"
          value={form.eMail}
          onChange={(e) => onFormChange(e)}
        />
      </Label>
      <Label htmlFor="passwordInput">
        비밀번호
        <PwInputWrapper>
          <PwInput
            id="passwordInput"
            name="pw"
            placeholder="비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다"
            type={pwVisibility.pwVisibility ? 'text' : 'password'}
            value={form.pw}
            onChange={(e) => onFormChange(e)}
            onKeyUp={handlePwValidation}
          />
          <VisibilityBtn active={pwVisibility.pwVisibility} name="pwVisibility" onClick={(e) => toggleVisibility(e)} />
        </PwInputWrapper>
        {!pwValidation && (
          <PwAlertWrapper>
            <AlertIcon src={alertIcon} />
            <PwAlert>비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다</PwAlert>
          </PwAlertWrapper>
        )}
      </Label>
      <Label htmlFor="rePasswordInput">
        비밀번호 확인
        <PwInputWrapper>
          <RePwInput
            active={coincidePw}
            id="rePasswordInput"
            name="repw"
            placeholder="비밀번호를 한번 더 입력해주세요"
            type={pwVisibility.rePwVisibility ? 'text' : 'password'}
            value={form.repw}
            onChange={(e) => onFormChange(e)}
            onKeyUp={() => handlePwCheck()}
          />
          <VisibilityBtn
            active={pwVisibility.rePwVisibility}
            name="rePwVisibility"
            onClick={(e) => toggleVisibility(e)}
          />
        </PwInputWrapper>
      </Label>
      {!coincidePw && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>비밀번호가 일치하지 않습니다</PwAlert>
        </PwAlertWrapper>
      )}
      <Label htmlFor="mobileInput">
        핸드폰 번호
        <InputWrapper>
          <UserInfoInput
            id="mobileInput"
            name="mobileNo"
            placeholder="핸드폰 번호를 입력해주세요"
            onChange={(e) => onFormChange(e)}
          />
          {/* <SendAuthNo onClick={sendAuthNo}>인증번호 발송</SendAuthNo> */}
        </InputWrapper>
      </Label>
      <Label htmlFor="authenticationNo">
        인증코드
        <InputWrapper>
          <MobileAuth
            id="authenticationNo"
            name="authenticationNo"
            placeholder="인증코드를 입력해주세요"
            value={form.authenticationNo}
            onChange={(e) => onFormChange(e)}
          />
          {receivedAuthNo}
          {/* <GetAuth>인증하기</GetAuth> */}
        </InputWrapper>
      </Label>
      <NextBtn active={formFullfilled === true}>다음</NextBtn>
    </UserInfoContainer>
  );
}

export default UserInfo;

interface VisibilityBtnProps {
  active: boolean;
}

const UserInfoContainer = styled.div`
  margin-top: 88px;
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 22px;
    color: ${signUpColors.useTermsContent};
    &:focus-within {
      color: ${signUpColors.focus};
    }
  }
`;

const Label = styled.label`
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 22px;
    color: ${signUpColors.useTermsContent};
    &:focus-within {
      color: ${signUpColors.focus};
    }
  }
`;

const PwAlertWrapper = styled.div`
  display: flex;
  background-color: ${signUpColors.pwAlertBackground};
  align-items: center;
  margin-top: -23px;
  margin-bottom: 30px;
`;

const AlertIcon = styled.img`
  width: 19px;
  height: 19px;
  margin: 0 3px;
`;

const PwAlert = styled.div`
  color: ${signUpColors.pwAlertText};
  padding-left: 5px;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
  display: flex;
  align-items: center;
`;

const UserInfoInput = styled(NormalInput)`
  height: 40px;
  width: 100%;
  &::placeholder {
    font-size: 14px;
    color: ${signUpColors.useTermsTitle};
  }
  &:focus {
    border: solid 1px ${signUpColors.focus};
  }
`;

const UserInfoInputForMargin = styled(UserInfoInput)`
  margin-left: 10px;
`;

const PwInputWrapper = styled.div`
  position: relative;
`;

const PwInput = styled(UserInfoInput)`
  height: 40px;
  &::placeholder {
    font-size: 14px;
  }
  &:focus {
    border: solid 1px ${signUpColors.focus};
  }
`;

interface RePwInputProps {
  active: boolean;
}

const RePwInput = styled(PwInput)<RePwInputProps>`
  border: ${(props) =>
    props.active ? `1px solid ${signUpColors.photoUploaderBorder}}` : `1px solid ${signUpColors.pwAlertText}`};
`;

const VisibilityBtn = styled.button<VisibilityBtnProps>`
  position: absolute;
  top: 5px;
  right: 20px;
  height: 30px;
  width: 30px;
  border: none;
  background-color: transparent;
  background-image: ${(props) => (props.active ? `url(${showBtn})` : `url(${hideBtn})`)};
  background-size: 30px;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;
// const SendAuthNo = styled(SignUpBtn)`
//   margin-top: 8px;
//   margin-left: 16px;
//   border: solid 1px ${signUpColors.authBtnBorder};
//   border-radius: 5px;
//   width: 132px;
//   height: 40px;
//   color: ${signUpColors.authBtnText};
//   background-color: ${signUpColors.authBtnBackground};
//   font-size: 14px;
//   font-weight: 500;
//   text-align: center;
// `;

const MobileAuth = styled(UserInfoInput)``;
// const GetAuth = styled(SendAuthNo)`
//   margin-top: 9px;
// `;
