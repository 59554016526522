import axiosClient from './axiosClient';

export const changeDoctorCareer = ({
  payload: { educationList, careerList },
  doctorId,
}: {
  payload: { educationList: string[]; careerList: string[] };
  doctorId: number;
}) =>
  axiosClient.post(`user/doctor/${doctorId}/experience/`, {
    education: educationList,
    career: careerList,
  });

export const getUserInfo = () => axiosClient.get('user/user_info/');

export const audioRecording = (payload: FormData) => axiosClient.put('user/audio_recording/', { payload });

export const changePassword = ({ currentPassword, password }: { currentPassword: string; password: string }) =>
  axiosClient.patch('user/change_password/', {
    old_password: currentPassword,
    new_password: password,
  });

export const login = ({ username, password, fcmToken }: { username: string; password: string; fcmToken: string }) =>
  axiosClient.post('user/login/', {
    username,
    password,
    fcm_token: fcmToken,
  });

export const phoneVerificationCode = ({ phoneNumber }: { phoneNumber: string }) =>
  axiosClient.post('user/kakao_verification_code/', {
    phone: phoneNumber,
  });

export const departmentList = () => axiosClient.get('user/department/');

export const findPassword = ({ username, phoneNumber }: { username: string; phoneNumber: string }) =>
  axiosClient.post('user/kakao_find_password/', {
    username,
    phone: `82-${phoneNumber.slice(1)}`,
  });

export const findUsername = ({
  firstName,
  lastName,
  phoneNumber,
}: {
  firstName: string;
  lastName: string;
  phoneNumber: string;
}) =>
  axiosClient.post('user/find_username/', {
    first_name: firstName,
    last_name: lastName,
    phone: `82-${phoneNumber.slice(1)}`,
  });

export const verityPhone = ({ email, code, phoneNumber }: { email: string; code: string; phoneNumber: string }) =>
  axiosClient.post('user/verify_code/', {
    username: email,
    phone: phoneNumber,
    verification_code: code,
  });

export const getSpecialtyList = (id: number) => axiosClient.get(`user/department/${id}/specialty_list/`);

export const updateSpecialty = (specialty: string[]) =>
  axiosClient.patch('user/change_specialty/', {
    specialty,
  });

export const logout = () => axiosClient.post('user/logout/');

export const getPolicy = (policyNumber: number) => axiosClient.get(`user/policy/${policyNumber}`);

export const getFcmToken = ({ userId, fcmToken }: { userId: number; fcmToken: string }) =>
  axiosClient.patch(`user/account/${userId}/`, {
    fcm_token: fcmToken,
  });

export const passwordGracePeriod = (doctorId: number) => axiosClient.patch(`user/doctor/${doctorId}/grace_period/`);
