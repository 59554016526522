import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Checkbox } from 'components/molecules';

import { getPolicy } from 'redux/modules/authentication';

import { recordingColor } from 'styles/global/color';
import { recordingStartCoverIllust } from 'assets/images';
import { flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';
import { IRootState } from 'types/payloadTypes';

interface RecordingIntroProps {
  onStart: () => void;
  onPolicyOpen: () => void;
}

function RecordingIntro({ onStart, onPolicyOpen }: RecordingIntroProps) {
  const dispatch = useDispatch();

  const [agreed, setAgreed] = useState(false);

  const { title, script } = useSelector((state: IRootState) => state.authentication.policy);

  const toggleAgree = () => {
    setAgreed(!agreed);
  };

  useEffect(() => {
    dispatch(getPolicy(2));
    // eslint-disable-next-line
  }, []);

  return (
    <RecordingIntroContainer>
      <RecordingTitle>보이닥 의료 음성데이터 수집</RecordingTitle>
      <RecordingAdvise>
        보이닥은 의료환경 개선을 위해 의료진의 음성 데이터를 수집하고 있습니다 음성 녹음 후 서비스 이용이 가능합니다
      </RecordingAdvise>
      <RecordingImage alt="recordingCover" src={recordingStartCoverIllust} />
      <PolicySection>
        <PolicyTitle>{title}</PolicyTitle>
        <PolicyScript>{script}</PolicyScript>
        <PrivacyPolicy onClick={() => onPolicyOpen()}>퍼즐에이아이 개인정보 처리방침</PrivacyPolicy>
        <CheckboxWrap active={agreed}>
          <Checkbox checked={agreed} onCheck={toggleAgree} />
          <AgreeText active={agreed}>약관에 동의합니다</AgreeText>
        </CheckboxWrap>
      </PolicySection>
      <RecordingBtn active={agreed} disabled={!agreed} onClick={() => onStart()}>
        음성녹음 시작하기
      </RecordingBtn>
    </RecordingIntroContainer>
  );
}

export default RecordingIntro;

const CheckboxWrap = styled.div<IActive>`
  display: flex;
  margin-top: 23px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(props) =>
    props.active ? recordingColor.agreeActiveBackground : recordingColor.agreeInactiveBackground};
  padding: 4px 7px;
`;

const AgreeText = styled.span<IActive>`
  color: ${(props) => (props.active ? recordingColor.agreeActiveText : recordingColor.agreeInactiveText)};
  font-size: 13px;
  text-align: left;
  line-height: 1.46;
`;

const PrivacyPolicy = styled(BasicBtn)`
  background-color: ${recordingColor.privacyBackground};
  border: 1px solid ${recordingColor.privacyBorder};
  color: ${recordingColor.privacyText};
  margin-top: 14px;
  line-height: 1.46;
  border-radius: 3px;
  padding: 3px 7px;
`;

const PolicySection = styled.section`
  margin-top: 12px;
  min-width: 300px;
`;

const PolicyTitle = styled.h3`
  font-family: NotoSansMedium;
  font-size: 15px;
  line-height: 1.47;
  color: ${recordingColor.policyTitle};
  margin-bottom: 20px;
  font-weight: 500;
  min-width: 185px;
  text-align: left;
`;

const PolicyScript = styled.h4`
  font-size: 12px;
  color: ${recordingColor.policyScript};
  line-height: 1.5;
  text-align: left;
  white-space: pre-line;
  margin: 0;
  padding: 0;
`;

const RecordingIntroContainer = styled.div`
  ${flexColumn};
  padding: 70px 190px;
  align-items: center;
  background-color: white;
`;

const RecordingImage = styled.img`
  width: 408px;
  height: 408px;
`;

const RecordingTitle = styled.h1`
  font-size: 28px;
  color: ${recordingColor.titleText};
  text-align: center;
  min-width: 328px;
  line-height: 1.46;
`;

const RecordingAdvise = styled.h2`
  font-size: 15px;
  line-height: 1.47;
  color: ${recordingColor.adviseText};
  text-align: center;
  margin-top: 12px;
  min-width: 328px;
`;

const RecordingBtn = styled(BasicBtn)<IActive>`
  width: 100%;
  min-width: 300px;
  padding: 8px 0;
  border-radius: 3px;
  border: 1px solid ${(props) => (props.active ? recordingColor.recordBtnBorder : recordingColor.privacyBorder)};
  margin-top: 42px;
  line-height: 1.5;
  align-items: center;
  background-color: ${(props) =>
    props.active ? recordingColor.agreeActiveBackground : recordingColor.agreeInactiveBackground};
  color: ${(props) => (props.active ? recordingColor.agreeActiveText : recordingColor.agreeInactiveText)};
`;
