import React from 'react';

import Routes from 'Routes';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import 'firebase/messaging';
import firebase from 'firebase/app';

import { ModalManager } from 'components/organisms';

import rootReducer from 'redux/modules';
import rootSaga from 'redux/modules/sagas';
import { getUserInfo } from 'redux/modules/authentication';
import { getTodayAppointment } from 'redux/modules/appointment';
import { composeWithDevTools } from 'redux-devtools-extension';
import { getNewMessageNotification } from 'redux/modules/chatting';

import { firebaseConfig } from 'hooks/useFirestore';

import 'index.css';
import { GlobalStyles } from 'styles/global/globalStyle';
import 'styles/global/colors.css';

import { IMessage } from 'types/payloadTypes';

const isMobile = () => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

const initializeFirebase = () => {
  if (isMobile()) {
    return;
  }

  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      firebase.initializeApp(firebaseConfig);

      const messaging = firebase.messaging();
      messaging
        .getToken({
          vapidKey: 'BJQLO2GAfSygOrRRcVkYEdOyw4XlwUsa2neDSIoOB1rlaAteMVGh5Mlb6aUnTyKlYapzDUWCxHNuonUryiUsd14',
        })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem('fcmToken', currentToken);
          }
        })
        .catch((e) => {
          console.error(e);
        });

      messaging.onMessage((payload: IMessage) => {
        if (payload.notification.title === '새로운 메세지') {
          store.dispatch(getNewMessageNotification());
          return;
        }
        if (payload.notification.title) {
          store.dispatch(getTodayAppointment());
        }
      });
      return;
    }

    Notification.requestPermission();
  });
};

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

const loadUser = () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return;
    store.dispatch(getUserInfo());
  } catch (e) {
    console.error(e);
  }
};

sagaMiddleware.run(rootSaga);
loadUser();
initializeFirebase();

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <GlobalStyles />
      <Routes />
      <ModalManager />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
