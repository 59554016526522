import { IModal } from 'types/payloadTypes';

const SHOW_MODAL = 'modal/SHOW_MODAL';
const HIDE_MODAL = 'modal/HIDE_MODAL';
const SHOW_SUCCESS_MODAL = 'modal/SHOW_SUCCESS_MODAL';
const SHOW_FAIL_MODAL = 'modal/SHOW_FAIL_MODAL';

export const showModal = (payload) => ({
  type: SHOW_MODAL,
  payload,
});

export const hideModal = (payload) => ({
  type: HIDE_MODAL,
  payload,
});

export const showSuccessModal = (payload) => ({
  type: SHOW_SUCCESS_MODAL,
  payload,
});

export const showFailModal = (payload) => ({
  type: SHOW_FAIL_MODAL,
  payload,
});

const initialState = {
  modalList: [],
  modalInfo: {
    modalTilte: '',
    modalExplanation: '네트워크 오류가 발생하였습니다.',
  },
};

interface IModalAction {
  type: string;
  payload: IModal;
}

const modalReducer = (state = initialState, action: IModalAction) => {
  switch (action.type) {
    case SHOW_MODAL: {
      const modal = action.payload;
      return {
        ...state,
        modalList: [...state.modalList, modal],
      };
    }
    case HIDE_MODAL: {
      const modal = action.payload;
      return {
        ...state,
        modalList: modal,
      };
    }
    case SHOW_SUCCESS_MODAL: {
      const { modalTitle, modalExplanation } = action.payload.modalInfo;
      return {
        ...state,
        modalList: [
          ...state.modalList,
          {
            modalType: 'ConfirmModal',
            modalProps: {
              modalTitle,
              modalExplanation,
              confirmBtnText: '확인',
            },
          },
        ],
      };
    }
    case SHOW_FAIL_MODAL: {
      const { modalTitle, modalExplanation } = action.payload.modalInfo;
      return {
        ...state,
        modalList: [
          ...state.modalList,
          {
            modalType: 'ConfirmModal',
            modalProps: {
              modalTitle,
              modalExplanation,
              confirmBtnText: '확인',
            },
          },
        ],
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default modalReducer;
