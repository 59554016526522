import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import AgoraRTC, { IAgoraRTCClient } from 'agora-rtc-sdk-ng';

import DoctorScreen from './DoctorScreen';
import PatientScreen from './PatientScreen';
// eslint-disable-next-line import/no-cycle
import UtilityButtons from './UtilityButtons';
import { MediaPlayer } from 'components/atoms';

import { postRecordStart, postResourceId, patchStartTreatment } from 'redux/modules/appointment';

import { rtmClient } from 'utils';
import { useAgora } from 'hooks';

import { flexColumn } from 'styles/global/mixins';

import { IDoctor, IRootState } from 'types/payloadTypes';

interface CallingProps {
  appointmentId: number;
  departmentName: string;
  doctorId: number;
  doctorName: string;
  // eslint-disable-next-line react/no-unused-prop-types
  patientId: number;
  onInvite: () => void;
  roomToken: string;
  subDoctorArray: Array<IDoctor>;
}

export type VideoSource = 'camera' | 'screen';

const agoraClient: IAgoraRTCClient = AgoraRTC.createClient({
  codec: 'vp8',
  mode: 'rtc',
});

AgoraRTC.setLogLevel(4);

function Calling({
  appointmentId,
  departmentName,
  doctorId,
  doctorName,
  onInvite,
  roomToken,
  subDoctorArray,
}: CallingProps) {
  const dispatch = useDispatch();

  const {
    localAudioTrack,
    localScreenTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    shareScreen,
    stopScreenShare,
    remoteUsers,
    screenError,
  } = useAgora(agoraClient);

  const startTreatmentRef = useRef(false);
  // eslint-disable-next-line new-cap
  const rtmRef = useRef(new rtmClient());

  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [videoSource, setVideoSource] = useState<VideoSource>('camera');

  const { agoraToken, uid } = useSelector((state: IRootState) => state.appointment.appointmentDetail.appointment);
  const { resourceId } = useSelector((state: IRootState) => state.appointment.appointmentDetail);

  const checkPatient = () => {
    if (!remoteUsers.length) return;

    let patient = remoteUsers?.find((user) => user.uid === 9999);

    if (!patient) {
      [patient] = remoteUsers;
    }

    if (!startTreatmentRef.current) {
      dispatch(patchStartTreatment({ appointmentId }));
      dispatch(postResourceId(`appointment_${appointmentId}`));

      startTreatmentRef.current = true;
    }

    return patient;
  };

  const getRemoteTracks = () => {
    if (remoteUsers.length === 1) return [];
    return remoteUsers.filter((user) => user.uid !== 9999 && user.uid !== doctorId);
  };

  const toggleAudio = () => {
    setAudioMuted(!audioMuted);
    localAudioTrack.setMuted(!localAudioTrack.muted);
  };

  const toggleVideo = () => {
    setVideoMuted(!videoMuted);
    localVideoTrack.setMuted(!localVideoTrack.muted);
  };

  const toggleVideoSource = () => {
    if (videoSource === 'camera') {
      shareScreen();
      setVideoSource('screen');
      rtmRef.current?.sendChannelMessage(`${JSON.stringify({ isSharing: true })}`, `appointment_${appointmentId}`);
      return;
    }

    alert('화면공유가 취소되었습니다.');
    stopScreenShare();
    setVideoSource('camera');
    rtmRef.current?.sendChannelMessage(`${JSON.stringify({ isSharing: false })}`, `appointment_${appointmentId}`);
  };

  useEffect(() => {
    if (!remoteUsers.length) return;
    if (!startTreatmentRef.current) {
      dispatch(patchStartTreatment({ appointmentId }));
      startTreatmentRef.current = true;
    }
  }, [remoteUsers]);

  useEffect(() => {
    const joinRTC = async () => {
      await join(`appointment_${appointmentId}`, roomToken, doctorId);
    };

    const joinRTM = async () => {
      // eslint-disable-next-line new-cap
      const rtm = new rtmClient();

      rtm.init();
      await rtm.login(uid, agoraToken);
      await rtm.joinChannel(`appointment_${appointmentId}`);
      rtmRef.current = rtm;
    };
    if (!appointmentId || !roomToken || !agoraToken || !uid) return;
    if (!joinState) {
      joinRTC();
      joinRTM();
    }
  }, [roomToken, appointmentId, agoraToken]);

  useEffect(() => {
    if (!resourceId) return;

    dispatch(
      postRecordStart({
        cname: `appointment_${appointmentId}`,
        resourceId,
      }),
    );
  }, [resourceId]);

  useEffect(() => {
    if (!localScreenTrack) return;

    localScreenTrack.on('track-ended', () => {
      alert('화면공유가 취소되었습니다.');
      setVideoSource('camera');
      stopScreenShare();
      rtmRef.current?.sendChannelMessage(`${JSON.stringify({ isSharing: true })}`, `appointment_${appointmentId}`);
    });

    // eslint-disable-next-line
  }, [localScreenTrack]);

  useEffect(() => {
    if (screenError) {
      alert('화면공유가 취소되었습니다.');
      setVideoSource('camera');
      rtmRef.current?.sendChannelMessage(`${JSON.stringify({ isSharing: false })}`, `appointment_${appointmentId}`);
    }
  }, [screenError]);

  return (
    <>
      {videoSource === 'screen' && (
        <ScreenWrap>
          <MediaPlayer audioTrack={undefined} id="screen" videoTrack={localScreenTrack} />
        </ScreenWrap>
      )}
      <VideoWrap>
        <PatientScreen patient={checkPatient()} onInvite={onInvite} />
        <DoctorScreen
          audioMuted={audioMuted}
          departmentName={departmentName}
          doctorName={doctorName}
          localAudioTrack={localAudioTrack}
          localVideoTrack={localVideoTrack}
          remoteDoctors={getRemoteTracks()}
          subDoctors={subDoctorArray}
          videoMuted={videoMuted}
        />
        <UtilityButtons
          audioMuted={audioMuted}
          leave={leave}
          videoMuted={videoMuted}
          videoSource={videoSource}
          onAudioMute={toggleAudio}
          onShare={toggleVideoSource}
          onVideoMute={toggleVideo}
        />
      </VideoWrap>
    </>
  );
}

export default Calling;

const VideoWrap = styled.div`
  ${flexColumn};
  width: 100%;
  height: calc(100vh - 105px);
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
`;

const ScreenWrap = styled.div`
  top: 80px;
  width: 68%;
  height: calc(100vh - 120px);
  left: 29.8vw;
  z-index: 5;
  position: absolute;
  > video {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }
`;
