import React, { Fragment } from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { modalColors } from 'styles/global/color';
import { CancelBtn } from 'styles/button';
import { flexColumn } from 'styles/global/mixins';

import { ICooperationDoctor } from 'types/payloadTypes';

interface DoctorListProps {
  doctorArray: Array<ICooperationDoctor>;
  handleDepartmentSelect: (id: number, selectedTab: string) => void;
  handleDoctorSelect: (doctor: ICooperationDoctor, selectedTab: string) => void;
}

function DoctorList({ doctorArray, handleDepartmentSelect, handleDoctorSelect }: DoctorListProps) {
  return (
    <>
      <ModalTitle>의사 선택</ModalTitle>
      <ModalExplanation>해당 환자를 진료할 의사를 선택해주세요</ModalExplanation>
      <DoctorTab>
        <DoctorSection>
          {doctorArray.map((doctor) => {
            const { id, fullName, department, hospital, specialties, idPhoto } = doctor;
            return (
              <Fragment key={`Doctor-${id}`}>
                <DoctorProfile onClick={() => handleDoctorSelect({ ...doctor }, 'SelectedDoctorListTab')}>
                  <ProfileImg>
                    <Image alt="doctor-profile" src={idPhoto} />
                  </ProfileImg>
                  <DoctorInfo>
                    <DoctorName>{fullName}</DoctorName>
                    <Hospital>{`${department} 전문의 ${hospital}`}</Hospital>
                    <SpecialtyWrap>
                      {specialties.map((specialty, index) => (
                        <Specialty key={`specialty-${index}`}>{specialty}</Specialty>
                      ))}
                    </SpecialtyWrap>
                  </DoctorInfo>
                </DoctorProfile>
              </Fragment>
            );
          })}
        </DoctorSection>
        <BtnWrap>
          <BackBtn type="button" onClick={() => handleDepartmentSelect(0, 'DepartmentListTab')}>
            이전
          </BackBtn>
        </BtnWrap>
      </DoctorTab>
    </>
  );
}

export default DoctorList;

const colors = {
  sub: '#777777',
  text: '#717171',
  h3Text: '#a3a3a3',
  border: '#eeeeee',
  specialtyborder: '#e0e0e0',
  scrollbarBackground: '#909090',
  scrollbarShadow: '#3e3e3e',
  backBtnActiveText: '#a3a3a3',
  backBtnActiveBackground: '#d8d8d8',
};

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 18px;
  color: ${modalColors.normalText};
  font-weight: 500;
  margin-top: 32px;
`;

const ModalText = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
`;

const ModalExplanation = styled(ModalText)`
  color: ${modalColors.normalText};
  margin-top: 9px;
  line-height: 30px;
`;

const DoctorTab = styled.div`
  margin-top: 48px;
`;

const DoctorSection = styled.div`
  height: 600px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    background-color: ${colors.specialtyborder};
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${colors.scrollbarBackground};
    -webkit-box-shadow: 0 0 1px ${colors.scrollbarShadow};
  }
  div:last-of-type {
    border-bottom: none;
  }
`;

const DoctorProfile = styled.div`
  display: flex;
  padding: 24px 0;
  border-bottom: 1px solid ${colors.border};
  cursor: pointer;
`;

const ProfileImg = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 50%;
`;

const DoctorName = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: ${colors.text};
`;

const DoctorInfo = styled.div`
  ${flexColumn};
  margin-left: 16px;
  justify-content: space-between;
`;

const Hospital = styled.div`
  font-size: 13px;
  color: ${colors.sub};
  margin: 5px 0;
  span {
    color: ${colors.h3Text};
  }
`;

const SpecialtyWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 310px;
`;

const Specialty = styled.div`
  border: 1px solid ${colors.specialtyborder};
  background-color: ${colors.border};
  color: ${colors.sub};
  font-size: 13px;
  line-height: 19px;
  padding: 1px 15px;
  width: max-content;
  margin-left: 5px;
  margin-bottom: 5px;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 49px;
`;

const BackBtn = styled(CancelBtn)`
  width: 174px;
  padding: 8px 0;
  font-size: 16px;
  line-height: 24px;
  :hover {
    color: ${colors.backBtnActiveText};
    background-color: ${colors.backBtnActiveBackground};
    border: 1px solid ${colors.backBtnActiveBackground};
  }
`;
