import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import { useSelector } from 'react-redux';

import {
  ApprovalModal,
  AutoLogoutInfoModal,
  BasicModal,
  CancelModal,
  ChangePasswordRequestModal,
  ChangeAppointmentModal,
  ConfirmModal,
  CooperationModal,
  FindInfoModal,
  InputModal,
  Mypage,
  SymptomsImageModal,
  SelectDeptModal,
} from 'components/modals';

import { useModal } from 'hooks';

import { IModalType, IRootState } from 'types/payloadTypes';

const modalRoot = document.getElementById('modal');

const MODAL_TYPES = {
  ApprovalModal: 'ApprovalModal',
  AutoLogoutInfoModal: 'AutoLogoutInfoModal',
  BasicModal: 'BasicModal',
  CancelModal: 'CancelModal',
  ChangeAppointmentModal: 'ChangeAppointmentModal',
  ChangePasswordRequestModal: 'ChangePasswordRequestModal',
  ConfirmModal: 'ConfirmModal',
  CooperationModal: 'CooperationModal',
  FindInfoModal: 'FindInfoModal',
  InputModal: 'InputModal',
  Mypage: 'Mypage',
  SymptomsImageModal: 'SymptomsImageModal',
  SelectDeptModal: 'SelectDeptModal',
} as const;

const MODAL_COMPONENTS = {
  [MODAL_TYPES.ApprovalModal]: ApprovalModal,
  [MODAL_TYPES.AutoLogoutInfoModal]: AutoLogoutInfoModal,
  [MODAL_TYPES.BasicModal]: BasicModal,
  [MODAL_TYPES.CancelModal]: CancelModal,
  [MODAL_TYPES.ChangePasswordRequestModal]: ChangePasswordRequestModal,
  [MODAL_TYPES.ChangeAppointmentModal]: ChangeAppointmentModal,
  [MODAL_TYPES.ConfirmModal]: ConfirmModal,
  [MODAL_TYPES.CooperationModal]: CooperationModal,
  [MODAL_TYPES.FindInfoModal]: FindInfoModal,
  [MODAL_TYPES.InputModal]: InputModal,
  [MODAL_TYPES.Mypage]: Mypage,
  [MODAL_TYPES.SymptomsImageModal]: SymptomsImageModal,
  [MODAL_TYPES.SelectDeptModal]: SelectDeptModal,
};

const ModalManager = () => {
  const { closeModal } = useModal();

  const elRef = useRef(null);

  if (!elRef.current) {
    elRef.current = document.createElement('div');
    elRef.current.className = 'modalInner';
  }

  const { modalList } = useSelector((state: IRootState) => state.modal);

  const renderComponent = () => {
    if (!modalList) return;

    const modalComponent = modalList.map((modal: IModalType, index) => {
      const { modalProps, modalType } = modal;

      const { onConfirm, ...restProps } = modalProps;

      const Component = MODAL_COMPONENTS[modalType];

      const handleClose = () => {
        closeModal(modal);
      };

      const handleConfirm = async (...args) => {
        if (typeof onConfirm === 'function') {
          await onConfirm(...args);
        }
        handleClose();
      };

      return <Component {...restProps} key={index} onClose={handleClose} onConfirm={handleConfirm} />;
    });

    return modalComponent;
  };

  useEffect(() => {
    if (modalList.length === 0) return;
    modalRoot.appendChild(elRef.current);
    return () => {
      modalRoot.removeChild(elRef.current);
    };
    // eslint-disable-next-line
  }, [modalList]);

  return createPortal(renderComponent(), elRef.current);
};

export default ModalManager;
