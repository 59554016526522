import { useRef, useState } from 'react';

type SocketState = 'open' | 'closed';

const useSTT = () => {
  const socketRef = useRef(null);
  const streamRef = useRef(null);
  const contextRef = useRef(null);

  const [reset, setReset] = useState(false);
  const [socketState, setSocketState] = useState<SocketState>('closed');
  const [text, setText] = useState('');

  const convertFloat32ToInt16 = (buffer) => {
    let l = buffer.length;
    const buf = new Int16Array(l);
    while (l--) {
      buf[l] = Math.min(1, buffer[l]) * 0x7fff;
    }
    return buf;
  };

  const connect = () => {
    const ws = new WebSocket(process.env.REACT_APP_STT_SOCKET_ADDRESS);
    socketRef.current = ws;
    ws.onopen = async () => {
      ws.send(
        JSON.stringify({
          config: {
            session_id: process.env.REACT_APP_STT_SESSION_ID,
            user_id: process.env.REACT_APP_STT_USER_ID,
          },
        }),
      );
      setSocketState('open');
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            sampleRate: 16000,
          },
        });
        streamRef.current = stream;

        const audioContext = new AudioContext({ sampleRate: 16000 });
        contextRef.current = audioContext;
        const source = audioContext.createMediaStreamSource(stream);
        const processor = audioContext.createScriptProcessor(2048, 1, 1);

        source.connect(processor);
        processor.connect(audioContext.destination);
        processor.addEventListener('audioprocess', (event) => {
          processAudio(event);
        });
      } catch (e) {
        console.error('error: ', e);
      }
    };

    ws.onmessage = (event) => {
      const parsed = JSON.parse(event.data);
      const replaced = parsed.partial.replaceAll('$00$', '\n');
      const { status } = parsed;
      setText(replaced);
      if (status === 'reset') setReset(true);
      setReset(false);
    };

    ws.onclose = (event) => {
      if (event.wasClean) {
        setText('');
        setSocketState('closed');
      } else {
        alert('음성인식기 활성화 실패');
      }
    };

    ws.onerror = (error) => {
      console.error('error: ', error);
    };
  };

  const processAudio = (event) => {
    const left = event.inputBuffer.getChannelData(0);
    const buffer = convertFloat32ToInt16(left);
    socketRef.current.send(buffer);
  };

  const stop = () => {
    if (contextRef.current && contextRef.current.state !== 'closed') {
      contextRef.current.close();
    }
    const tracks = streamRef.current.getTracks();
    tracks.forEach((track) => {
      track.stop();
    });
    socketRef.current.close();
  };

  // 연결을 시작한다.

  return { connect, socketState, reset, stop, text };
};

export default useSTT;
