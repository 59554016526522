import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

interface CallIconProps {
  muted?: boolean;
  active?: boolean;
  category: 'audio' | 'video' | 'sharing';
}

function CallIcon({ muted, active, category }: CallIconProps) {
  return <Icon active={active} category={category} muted={muted} />;
}

export default CallIcon;

interface IconProps {
  muted?: boolean;
  active?: boolean;
  category: 'audio' | 'video' | 'sharing';
}

const iconStyled = {
  activeStyle: {
    audio: css`
      width: 12.7px;
      height: 20.6px;
      background-image: url(/icons/ic-audio-active.svg);
    `,
    video: css`
      width: 22.8px;
      height: 13.2px;
      background-image: url(/icons/ic-video-active.svg);
    `,
  },
  mutedStyle: {
    audio: css`
      width: 12px;
      height: 19.5px;
      background-image: url(/icons/ic-audio-muted.svg);
    `,
    video: css`
      width: 20.4px;
      height: 15.7px;
      background-image: url(/icons/ic-video-muted.svg);
    `,
  },

  sharing: css`
    width: 20.2px;
    height: 15.7px;
    background-image: url(/icons/ic-share-screen.svg);
  `,
};

const { mutedStyle, activeStyle, sharing } = iconStyled;

const Icon = styled.div<IconProps>`
  ${({ category }) => category === 'sharing' && sharing}
  ${({ muted, category }) => muted && mutedStyle[category]}
  ${({ active, category }) => active && activeStyle[category]}

  background-repeat: no-repeat;
  background-position: center center;
`;
