import React, { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { IAgoraRTCRemoteUser, ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';

import DoctorScreen from './DoctorScreen';
import PatientScreen from './PatientScreen';
import UtilityButtons from './UtilityButtons';

import { patchStartTreatment } from 'redux/modules/appointment';

import { flexColumn } from 'styles/global/mixins';

import { IDoctor } from 'types/payloadTypes';

interface CallingProps {
  appointmentId: number;
  departmentName: string;
  doctorId: number;
  doctorName: string;
  onInvite: () => void;
  roomToken: string;
  subDoctorArray: Array<IDoctor>;
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  localScreenTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  leave: Function;
  join: Function;
  onStart: () => void;
  onStop: () => void;
  remoteUsers: IAgoraRTCRemoteUser[];
}

export type VideoSource = 'camera' | 'screen';

function Calling({
  appointmentId,
  departmentName,
  doctorId,
  doctorName,
  onInvite,
  roomToken,
  subDoctorArray,
  localAudioTrack,
  localVideoTrack,
  localScreenTrack,
  joinState,
  leave,
  join,
  onStart,
  onStop,
  remoteUsers,
}: CallingProps) {
  const dispatch = useDispatch();

  const startTreatmentRef = useRef(false);

  const [audioMuted, setAudioMuted] = useState(false);
  const [videoMuted, setVideoMuted] = useState(false);
  const [videoSource, setVideoSource] = useState<VideoSource>('camera');

  const checkPatient = () => {
    const patient = remoteUsers?.find((user) => user.uid === 9999);
    if (!patient) return;

    if (!startTreatmentRef.current) {
      dispatch(patchStartTreatment({ appointmentId }));
      startTreatmentRef.current = true;
    }
    return patient;
  };

  const getScreenTrack = () => {
    if (videoSource === 'camera') return;
    return localScreenTrack;
  };

  const getRemoteTracks = () => remoteUsers.filter((user) => user.uid !== 9999 && user.uid !== doctorId);
  const toggleAudio = () => {
    setAudioMuted(!audioMuted);
    localAudioTrack.setMuted(!audioMuted);
  };

  const toggleVideo = () => {
    setVideoMuted(!videoMuted);
    localVideoTrack.setMuted(!videoMuted);
  };

  const toggleVideoSource = () => {
    if (videoSource === 'camera') {
      onStart();
      setVideoSource('screen');
      return;
    }

    onStop();
    setVideoSource('camera');
  };

  useEffect(() => {
    if (!remoteUsers.length) return;
    if (!startTreatmentRef.current) {
      dispatch(patchStartTreatment({ appointmentId }));
      startTreatmentRef.current = true;
    }
  }, [remoteUsers]);

  useEffect(() => {
    if (!appointmentId || !roomToken) return;
    if (!joinState) {
      join(`appointment_${appointmentId}`, roomToken, doctorId);
    }
  }, [appointmentId, roomToken]);

  // useEffect(() => {
  //   if (!localAudioTrack) return;
  // }, [localAudioTrack]);
  return (
    <VideoWrap>
      <PatientScreen patient={checkPatient()} screenTrack={getScreenTrack()} onInvite={onInvite} />
      <DoctorScreen
        departmentName={departmentName}
        doctorName={doctorName}
        localAudioTrack={localAudioTrack}
        localVideoTrack={localVideoTrack}
        remoteDoctors={getRemoteTracks()}
        subDoctors={subDoctorArray}
      />
      <UtilityButtons
        audioMuted={audioMuted}
        leave={leave}
        videoMuted={videoMuted}
        videoSource={videoSource}
        onAudioMute={toggleAudio}
        onShare={toggleVideoSource}
        onVideoMute={toggleVideo}
      />
    </VideoWrap>
  );
}

export default Calling;

const VideoWrap = styled.div`
  ${flexColumn};
  width: 100%;
  height: calc(100vh - 40px);
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
`;
