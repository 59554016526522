/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios';
import moment from 'moment';

import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { startLoading, finishLoading } from 'redux/modules/loading';
import { createActionTypes } from 'redux/lib/createSaga';
import * as appointmentAPI from 'redux/lib/api/appointment';

import { arraySnakeToCamel, snakeToCamel } from 'utils';

import { IAppointmentState, IRootState } from 'types/payloadTypes';
import { showFailModal, showSuccessModal } from './modal';

const RESET_INITIALIZE_STATE = 'appontment/RESET_INITIALIZE_SATET';

const INITIALIZE_STATE = 'appontment/INITIALIZE_STATE';

const [GET_PATIENT_QUESTIONNAIRE, GET_PATIENT_QUESTIONNAIRE_SUCCESS] = createActionTypes(
  'appointment/GET_PATIENT_QUESTIONNAIRE',
);

const [GET_PATIENT_DIAGNOSIS, GET_PATIENT_DIAGNOSIS_SUCCESS] = createActionTypes('appointment/GET_PATIENT_DIAGNOSIS');

const PATCH_DOCTOR_ENTERED = 'appointment/PATCH_DOCTOR_ENTERED';

const [GET_DIAGNOSIS, GET_DIAGNOSIS_SUCCESS, GET_DIAGNOSIS_FAIL] = createActionTypes('appointment/GET_DIAGNOSIS');

const [GET_PRESCRIPTION_INFO, GET_PRESCRIPTION_INFO_SUCCESS] = createActionTypes('appointment/GET_PRESCRIPTION_INFO');

const [POST_PRESCRIPTION] = createActionTypes('appointment/POST_PRESCRIPTION');

const [GET_DISTRICT_LIST, GET_DISTRICT_LIST_SUCCESS] = createActionTypes('appointment/GET_DISTRICT_LIST');

const [PATCH_INVITE_PATIENT, PATCH_INVITE_PATIENT_SUCCESS] = createActionTypes('appointment/PATCH_INVITE_PATIENT');

const [PATCH_FINISH_TREATMENT, PATCH_FINISH_TREATMENT_SUCCESS] = createActionTypes(
  'appointment/PATCH_FINISH_TREATMENT',
);

const [GET_AGORA_TOKEN, GET_AGORA_TOKEN_SUCCESS] = createActionTypes('appointment/GET_AGORA_TOKEN');

const [POST_AGORA_RTC_TOKEN, POST_AGORA_RTC_TOKEN_SUCCESS] = createActionTypes('appointment/POST_AGORA_RTC_TOKEN');

const PATCH_APPOINTMENT_DURATION = 'appointment/PATCH_APPOINTMENT_DURATION';
const GET_APPOINTMENT_DETAIL = 'appointment/GET_APPOINTMENT_DETAIL';
const GET_APPOINTMENT_LIST = 'appointment/GET_APPOINTMENT_LIST';
const GET_APPOINTMENT_SUCCESS = 'appointment/GET_APPOINTMENT_SUCCESS';
const GET_APPOINTMENT_DETAIL_SUCCESS = 'appointment/GET_APPOINTMENT_DETAIL_SUCCESS';
const CALL_PATIENT = 'appointment/CALL_PATIENT';
const POST_MEDICAL_RECORD = 'appointment/POST_MEDICAL_RECORD';
const POST_MEDICAL_RECORD_SUCCESS = 'appointment/POST_MEDICAL_RECORD_SUCCESS';
const GET_PREVIOUS_PAGE = 'appointment/GET_PREVIOUS_PAGE';
const GET_NEXT_PAGE = 'appointment/GET_NEXT_PAGE';
const GET_TARGET_PAGE = 'appointment/GET_TARGET_PAGE';
const GET_DOCTOR_LIST = 'appointment/GET_DOCTOR_LIST';
const GET_DOCTOR_LIST_SUCCESS = 'appointment/GET_DOCTOR_LIST_SUCCESS';
const GET_DEPARTMENT_LIST = 'appointment/GET_DEPARTMENT_LIST';
const GET_DEPARTMENT_LIST_SUCCESS = 'appointment/GET_DEPARTMENT_LIST_SUCCESS';
const PATCH_CANCEL_APPOINTMENT = 'appointment/PATCH_CANCEL_APPOINTMENT';
const GET_MEDICAL_RECORD_SUCCESS = 'appointment/GET_MEDICAL_RECORD_SUCCESS';
const GET_DISEASE_LIST = 'appointment/GET_DISEASE_LIST';
const GET_DISEASE_LIST_SUCCESS = 'appointment/GET_DISEASE_LIST_SUCCESS';
const PATCH_PATIENT_NO_SHOW = 'appointment/PATCH_PATIENT_NO_SHOW';

const PATCH_MEDICAL_RECORD = 'appointment/PATCH_MEDICAL_RECORD';
const PASTE_PREVIOUS_DIAGNOSIS = 'appointment/PASTE_PREVIOUS_DIAGNOSIS';
const PATCH_START_TREATMENT = 'appointment/PATCH_START_TREATMENT';

const GET_WAITING_APPOINTMENT = 'appointment/GET_WAITING_APPOINTMENT';
const GET_WAITING_APPOINTMENT_SUCCESS = 'appointment/GET_WAITING_APPOINTMENT_SUCCESS';
const GET_NEXT_WAITING_PAGE = 'appointment/GET_NEXT_WAITING_PAGE';
const GET_PREVIOUS_WAITING_PAGE = 'appointment/GET_PREVIOUS_WAITING_PAGE';
const GET_COMPLETE_APPOINTMENT = 'appointment/GET_COMPLETE_APPOINTMENT';
const GET_COMPLETE_APPOINTMENT_SUCCESS = 'appointment/GET_COMPLETE_APPOINTMENT_SUCCESS';
const GET_NEXT_COMPLETE_PAGE = 'appointment/GET_NEXT_COMPLETE_PAGE';
const GET_PREVIOUS_COMPLETE_PAGE = 'appointment/GET_PREVIOUS_COMPLETE_PAGE';

const POST_WEBRTC_OPTION = 'appointment/POST_WEBRTC_OPTION';
const SET_CURRENT_APPOINTMENT = 'appointment/SET_CURRENT_APPOINTMENT';
const [GET_COOPERATION_DOCTOR_LIST, GET_COOPERATION_DOCTOR_LIST_SUCCESS] = createActionTypes(
  'appointment/GET_COOPERATION_DOCTOR_LIST',
);

const [REMOVE_DOCTOR, REMOVE_DOCTOR_SUCCESS] = createActionTypes('appointment/REMOVE_DOCTOR');

const [PATCH_COOPERATION_DOCTOR_REQUEST, PATCH_COOPERATION_DOCTOR_REQUEST_SUCCESS] = createActionTypes(
  'appointment/PATCH_COOPERATION_DOCTOR_REQUEST',
);

const [POST_RESOURCE_ID, POST_RESOURCE_ID_SUCCESS] = createActionTypes('appointment/POST_RESOURCE_ID');

const [POST_RECORD_START, POST_RECORD_START_SUCCESS] = createActionTypes('appointment/POST_RECORD_START');

const [POST_RECORD_STOP, POST_RECORD_STOP_SUCCESS] = createActionTypes('appointment/POST_RECORD_STOP');

const initialState: IAppointmentState = {
  appointmentList: {
    count: 0,
    appointmentArray: [],
    next: '',
    previous: '',
    waitingCount: 0,
    completeCount: 0,
  },
  completeList: {
    count: 0,
    appointmentArray: [],
    next: '',
    previous: '',
    waitingCount: 0,
    completeCount: 0,
  },
  waitingList: {
    count: 0,
    appointmentArray: [],
    next: '',
    previous: '',
    waitingCount: 0,
    completeCount: 0,
  },
  appointmentDetail: {
    appointment: {
      id: 0,
      uid: '',
      doctor: 0,
      doctorId: 0,
      patient: 0,
      diagnosis: '',
      appointmentDate: '',
      status: 0,
      symptomDesc: '',
      doctorName: '',
      patientName: '',
      hospitalName: '',
      departmentName: '',
      dateOfBirth: '',
      patientGender: '',
      doctorPhoto: '',
      requestStatus: 0,
      imagePath: [],
      opinion: '',
      hasPatientEntered: false,
      hasDoctorRequested: false,
      filePath: [],
      useEnglish: false,
      isEmergency: false,
      district: '',
      patientPhone: '',
      isFirstTime: true,
      patientDistrict: '',
      startedAt: '',
      finishedAt: '',
      duration: '',
      agoraToken: '',
      subDoctors: [],
      isCooperation: false,
      guardian: '',
    },
    diagnosis: [],
    roomToken: '',
    resourceId: '',
    sid: '',
    questionnaire: {
      id: 0,
      height: 0,
      weight: 0,
      smoking: false,
      drinking: 0,
      medication: '',
      updatedAt: '',
      patient: 0,
      pastHistory: [],
      familyHistory: [],
    },
  },
  medicalRecord: {
    id: 0,
    subjective: '',
    objective: '',
    assessment: '',
    plan: '',
    opinion: '',
    prescription: '',
    date: '',
    department: '',
    doctorName: '',
    duration: '',
    startedAt: '',
    finishedAt: '',
  },
  medicalRecordSaved: false,
  cooperationRequestSuccess: false,
  removeDoctorSuccess: false,
  invitePatientSuccess: false,
  finishTreatmentSuccess: false,
  doctorList: [],
  cooperationDoctorList: [],
  departmentList: [],
  diseaseList: [],
  approveRequestList: {
    count: 0,
    next: '',
    previous: '',
    results: [],
  },
  prescriptionInfo: {
    id: 0,
    doctorName: '',
    doctorPhone: '',
    licenseNumber: 0,
    hospitalName: '',
    patientName: '',
    patientPhone: '',
  },
  districtList: [{ id: 0, name: '', phone: '', email: '' }],
};

interface IAppointmentAction {
  type: string;
  payload: IAppointmentState;
}

export const postAgoraRTCToken = (payload) => ({
  type: POST_AGORA_RTC_TOKEN,
  payload,
});

const postAgoraRTCTokenSuccess = (payload) => ({
  type: POST_AGORA_RTC_TOKEN_SUCCESS,
  payload,
});

const getAgoraTokenSuccess = (payload) => ({
  type: GET_AGORA_TOKEN_SUCCESS,
  payload,
});

export const getAgoraToken = () => ({
  type: GET_AGORA_TOKEN,
});

const patchFinishTreatmentSucces = () => ({
  type: PATCH_FINISH_TREATMENT_SUCCESS,
});

const patchInvitePatientSuccess = () => ({
  type: PATCH_INVITE_PATIENT_SUCCESS,
});

export const setCurrentAppointment = (payload) => ({
  type: SET_CURRENT_APPOINTMENT,
  payload,
});

export const removeDoctor = (payload) => ({
  type: REMOVE_DOCTOR,
  payload,
});

export const getPreviousCompletePage = () => ({
  type: GET_PREVIOUS_COMPLETE_PAGE,
});

export const getNextCompletePage = () => ({
  type: GET_NEXT_COMPLETE_PAGE,
});

export const getCompletePage = () => ({
  type: GET_COMPLETE_APPOINTMENT,
});

const getCompleteAppointmentSuccess = (payload) => ({
  type: GET_COMPLETE_APPOINTMENT_SUCCESS,
  payload,
});

export const getPreviousWaitingPage = () => ({
  type: GET_PREVIOUS_WAITING_PAGE,
});

export const getNextWaitingPage = () => ({
  type: GET_NEXT_WAITING_PAGE,
});

export const initializeAppointmentState = () => ({
  type: INITIALIZE_STATE, // saga 에서 call 안함. fetch가 아님
});

export const patchCooperationDoctorRequest = (payload) => ({
  type: PATCH_COOPERATION_DOCTOR_REQUEST,
  payload,
});

export const getCooperationDoctorList = (payload) => ({
  type: GET_COOPERATION_DOCTOR_LIST,
  payload,
});

const getCooperationDoctorListSuccess = (payload) => ({
  type: GET_COOPERATION_DOCTOR_LIST_SUCCESS,
  payload,
});

// const getCooperationSelcetedDoctorListSuccess = (payload) => ({
//   type: GET_COOPERATION_SELECTED_DOCTOR_LIST_SUCCESS,
//   payload,
// });

export const resetInitializeAppointmentState = () => ({
  type: RESET_INITIALIZE_STATE, // saga 에서 call 안함. fetch가 아님
});

export const patchAppointmentDuration = (payload) => ({
  type: PATCH_APPOINTMENT_DURATION,
  payload,
});

export const postWebRTCOption = (payload) => ({
  type: POST_WEBRTC_OPTION,
  payload,
});

const pastePreviousDiagnosis = (payload) => ({
  type: PASTE_PREVIOUS_DIAGNOSIS,
  payload,
});

const getPatientQuestionnaireSuccess = (payload) => ({
  type: GET_PATIENT_QUESTIONNAIRE_SUCCESS,
  payload,
});

export const getPatientQuestionnaire = (payload) => ({
  type: GET_PATIENT_QUESTIONNAIRE,
  payload,
});

const getPatientDiagnosisSuccess = (payload) => ({
  type: GET_PATIENT_DIAGNOSIS_SUCCESS,
  payload,
});

export const getPatientDiagnosis = (payload) => ({
  type: GET_PATIENT_DIAGNOSIS,
  payload,
});

export const getDistrictList = () => ({
  type: GET_DISTRICT_LIST,
});

const getDistrictListSuccess = (payload) => ({
  type: GET_DISTRICT_LIST_SUCCESS,
  payload,
});

const getWaitingAppointmentSuccess = (payload) => ({
  type: GET_WAITING_APPOINTMENT_SUCCESS,
  payload,
});

export const postPrescription = (payload) => ({
  type: POST_PRESCRIPTION,
  payload,
});

export const getPrescriptionInfo = (payload) => ({
  type: GET_PRESCRIPTION_INFO,
  payload,
});

const getPrescriptionInfoSuccess = (payload) => ({
  type: GET_PRESCRIPTION_INFO_SUCCESS,
  payload,
});

export const getTodayAppointment = () => ({
  type: GET_WAITING_APPOINTMENT,
});

export const patchStartTreatment = (payload) => ({
  type: PATCH_START_TREATMENT,
  payload,
});

export const getDiagnosis = (payload) => ({
  type: GET_DIAGNOSIS,
  payload,
});

const getDiagnosisSuccess = (payload) => ({
  type: GET_DIAGNOSIS_SUCCESS,
  payload,
});

const getDiagnosisFail = (payload) => ({
  type: GET_DIAGNOSIS_FAIL,
  payload,
});

export const patchDoctorEntered = (payload) => ({
  type: PATCH_DOCTOR_ENTERED,
  payload,
});

export const patchMedicalRecord = (payload) => ({
  type: PATCH_MEDICAL_RECORD,
  payload,
});

export const patchInvitePatient = (payload) => ({
  type: PATCH_INVITE_PATIENT,
  payload,
});

export const getTargetPage = (payload) => ({
  type: GET_TARGET_PAGE,
  payload,
});

export const getMedicalRecordSuccess = (payload) => ({
  type: GET_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const patchPatientNoShow = () => ({
  type: PATCH_PATIENT_NO_SHOW,
});

export const patchFinishTreatment = (payload) => ({
  type: PATCH_FINISH_TREATMENT,
  payload,
});

export const callPatient = (payload) => ({
  type: CALL_PATIENT,
  payload,
});

export const patchCancelAppointment = (payload) => ({
  type: PATCH_CANCEL_APPOINTMENT,
  payload,
});

export const getDepartmentList = () => ({
  type: GET_DEPARTMENT_LIST,
});

const getDepartmentListSuccess = (payload) => ({
  type: GET_DEPARTMENT_LIST_SUCCESS,
  payload,
});

export const getDoctorList = (payload) => ({
  type: GET_DOCTOR_LIST,
  payload,
});

const getDoctorListSuccess = (payload) => ({
  type: GET_DOCTOR_LIST_SUCCESS,
  payload,
});

export const getAppointmentPreviousPage = () => ({
  type: GET_PREVIOUS_PAGE,
});

export const getAppointmentNextPage = () => ({
  type: GET_NEXT_PAGE,
});

export const postMedicalRecordSuccess = (payload: boolean) => ({
  type: POST_MEDICAL_RECORD_SUCCESS,
  payload,
});

export const postMedicalRecord = (payload) => ({
  type: POST_MEDICAL_RECORD,
  payload,
});

export const getAppointmentList = (payload) => ({
  type: GET_APPOINTMENT_LIST,
  payload,
});

export const getAppointmentDetail = (payload) => ({
  type: GET_APPOINTMENT_DETAIL,
  payload,
});

const getAppointmentSuccess = (payload) => ({
  type: GET_APPOINTMENT_SUCCESS,
  payload,
});

const getAppointmentDetailSuccess = (payload) => ({
  type: GET_APPOINTMENT_DETAIL_SUCCESS,
  payload,
});

export const getDiseaseList = () => ({
  type: GET_DISEASE_LIST,
});

const getDiseaseListSuccess = (payload) => ({
  type: GET_DISEASE_LIST_SUCCESS,
  payload,
});

export const postResourceId = (payload) => ({
  type: POST_RESOURCE_ID,
  payload,
});

export const postResourceIdSuccess = (payload) => ({
  type: POST_RESOURCE_ID_SUCCESS,
  payload,
});

export const postRecordStart = (payload) => ({
  type: POST_RECORD_START,
  payload,
});

export const postRecordStartSuccess = (payload) => ({
  type: POST_RECORD_START_SUCCESS,
  payload,
});

export const postRecordStop = (payload) => ({
  type: POST_RECORD_STOP,
  payload,
});

export const postRecordStopSuccess = () => ({
  type: POST_RECORD_STOP_SUCCESS,
});

const appointmentReducer = (state = initialState, action: IAppointmentAction) => {
  switch (action.type) {
    case POST_AGORA_RTC_TOKEN_SUCCESS: {
      const roomToken = action.payload;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          roomToken,
        },
      };
    }

    case GET_AGORA_TOKEN_SUCCESS: {
      const { agoraToken, uid } = action.payload.appointmentDetail.appointment;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          appointment: {
            ...state.appointmentDetail.appointment,
            agoraToken,
            uid,
          },
        },
      };
    }

    case PATCH_FINISH_TREATMENT_SUCCESS: {
      return {
        ...state,
        finishTreatmentSuccess: true,
      };
    }
    case PATCH_INVITE_PATIENT_SUCCESS: {
      return {
        ...state,
        invitePatientSuccess: true,
      };
    }

    case SET_CURRENT_APPOINTMENT: {
      const { appointment } = action.payload.appointmentDetail;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          appointment,
        },
      };
    }

    case INITIALIZE_STATE: {
      return {
        ...state,
        finishTreatmentSuccess: false,
        invitePatientSuccess: false,
        medicalRecordSaved: false,
        cooperationRequestSuccess: false,
        removeDoctorSuccess: false,
      };
    }

    case PATCH_COOPERATION_DOCTOR_REQUEST_SUCCESS: {
      return {
        ...state,
        cooperationRequestSuccess: true,
      };
    }

    case REMOVE_DOCTOR_SUCCESS: {
      return {
        ...state,
        removeDoctorSuccess: true,
      };
    }

    case GET_COOPERATION_DOCTOR_LIST_SUCCESS: {
      const { cooperationDoctorList } = action.payload;
      return {
        ...state,
        cooperationDoctorList,
      };
    }

    case RESET_INITIALIZE_STATE: {
      return {
        ...state,
        initialState,
      };
    }

    case PASTE_PREVIOUS_DIAGNOSIS: {
      const { subjective, objective, assessment, plan, opinion } = action.payload.medicalRecord;

      return {
        ...state,
        medicalRecord: {
          ...state.medicalRecord,
          subjective,
          objective,
          assessment,
          plan,
          opinion,
        },
      };
    }

    case GET_PATIENT_QUESTIONNAIRE_SUCCESS: {
      const { questionnaire } = action.payload.appointmentDetail;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          questionnaire,
        },
      };
    }
    case GET_PATIENT_DIAGNOSIS_SUCCESS: {
      const { diagnosis } = action.payload.appointmentDetail;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          diagnosis,
        },
      };
    }
    case GET_DISTRICT_LIST_SUCCESS: {
      const { districtList } = action.payload;

      return {
        ...state,
        districtList,
      };
    }

    case GET_PRESCRIPTION_INFO_SUCCESS: {
      const { prescriptionInfo } = action.payload;

      return {
        ...state,
        prescriptionInfo,
      };
    }

    case GET_WAITING_APPOINTMENT_SUCCESS: {
      const waitingList = action.payload;

      return {
        ...state,
        waitingList,
      };
    }

    case GET_COMPLETE_APPOINTMENT_SUCCESS: {
      const completeList = action.payload;

      return {
        ...state,
        completeList,
      };
    }

    case GET_DIAGNOSIS_SUCCESS: {
      const medicalRecord = action.payload;

      return {
        ...state,
        medicalRecord,
      };
    }

    case GET_DIAGNOSIS_FAIL: {
      const medicalRecord = action.payload;

      return {
        ...state,
        medicalRecord,
      };
    }

    case GET_MEDICAL_RECORD_SUCCESS: {
      const { id, subjective, objective, assessment, plan, opinion } = action.payload.medicalRecord;

      return {
        ...state,
        medicalRecord: {
          ...state.medicalRecord,
          id,
          subjective,
          objective,
          assessment,
          plan,
          opinion,
        },
      };
    }

    case GET_DOCTOR_LIST_SUCCESS: {
      const { doctorList } = action.payload;
      return {
        ...state,
        doctorList,
      };
    }

    case GET_DEPARTMENT_LIST_SUCCESS: {
      const { departmentList } = action.payload;
      return {
        ...state,
        departmentList,
      };
    }
    case POST_MEDICAL_RECORD_SUCCESS: {
      const medicalRecordSaved = action.payload;
      return {
        ...state,
        medicalRecordSaved,
      };
    }

    case GET_APPOINTMENT_SUCCESS: {
      const appointmentList = action.payload;
      return {
        ...state,
        appointmentList,
      };
    }

    case GET_APPOINTMENT_DETAIL_SUCCESS: {
      const { appointment } = action.payload.appointmentDetail;
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          appointment,
        },
      };
    }

    case GET_DISEASE_LIST_SUCCESS: {
      const { diseaseList } = action.payload;
      return {
        ...state,
        diseaseList,
      };
    }

    case POST_RESOURCE_ID_SUCCESS: {
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          resourceId: action.payload,
        },
      };
    }

    case POST_RECORD_START_SUCCESS: {
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          sid: action.payload,
        },
      };
    }

    case POST_RECORD_STOP: {
      return {
        ...state,
        appointmentDetail: {
          ...state.appointmentDetail,
          resourceId: '',
          sid: '',
        },
      };
    }

    default: {
      return state;
    }
  }
};

function* fetchDiagnosis({ type, payload }) {
  try {
    const { data } = yield call(appointmentAPI.retrieveDiagnosis, payload);

    const medicalRecord = snakeToCamel(data);

    yield put(getDiagnosisSuccess(medicalRecord));
  } catch (e) {
    const {
      response: { status, data },
    } = e;
    if (status === 409) {
      yield put(getDiagnosisFail(snakeToCamel(data)));
    }
  }
}

function* fetchPatientQuestionnaire({ type, payload }) {
  try {
    const { data } = yield call(appointmentAPI.patientQuestionnaire, payload);

    const questionnaire = snakeToCamel(data);

    yield put(getPatientQuestionnaireSuccess({ appointmentDetail: { questionnaire } }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPatientDiagnosis({ type, payload }) {
  try {
    const { data } = yield call(appointmentAPI.patientDiagnosis, payload);

    const diagnosis = arraySnakeToCamel(data);

    yield put(
      getPatientDiagnosisSuccess({
        appointmentDetail: { diagnosis },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchDistrictList() {
  try {
    const {
      data: { district_list },
    } = yield call(appointmentAPI.getDistrictList);

    const districtList = arraySnakeToCamel(district_list);

    yield put(getDistrictListSuccess({ districtList }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchWriteprescription({ type, payload }) {
  try {
    const today = moment().format('YYYY-MM-DD');

    const { appointmentId, assessment, prescription } = payload;
    const { doctorName, doctorPhone, hospitalName, patientName } = yield select(
      (state: IRootState) => state.appointment.prescriptionInfo,
    );

    const prescriptionData = {
      assessment,
      doctor_name: doctorName,
      doctor_phone_num: doctorPhone,
      hospital_name: hospitalName,
      patient_name: patientName,
      prescription_date: today,
      ...prescription,
    };

    yield call(appointmentAPI.writePrescription, { appointmentId, prescriptionData });
  } catch (e) {
    console.error(e);
  }
}

function* fetchPrescriptionInfo({ type, payload }) {
  try {
    const { data } = yield call(appointmentAPI.getPrescriptionInfo, payload);

    const prescriptionInfo = snakeToCamel(data);

    yield put(getPrescriptionInfoSuccess({ prescriptionInfo }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchDoctorEntered({ type, payload }) {
  try {
    yield call(appointmentAPI.doctorEnterTreatmentRoom, payload);
  } catch (e) {
    console.error(e);
  }
}

function* fetchDepartmentList() {
  try {
    const { data } = yield call(appointmentAPI.getDepartmentList);

    const departmentList = arraySnakeToCamel(data);

    yield put(getDepartmentListSuccess({ departmentList }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchDoctorList({ type, payload }) {
  try {
    const {
      data: { doctor_list },
    } = yield call(appointmentAPI.getDoctorList, payload);

    const doctorList = arraySnakeToCamel(doctor_list);

    yield put(getDoctorListSuccess({ doctorList }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchNextPage() {
  try {
    const nextPageUrl = yield select((state) => state.appointment.appointmentList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.appointmentNextPage, nextPageUrl);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPreviousPage() {
  try {
    const prevPageUrl = yield select((state) => state.appointment.appointmentList.previous);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.appointmentPrevPage, prevPageUrl);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* saveMedicalRecord({ type, payload }) {
  try {
    const { id } = yield select((state: IRootState) => state.appointment.appointmentDetail.appointment);
    const diagnosisId = yield select((state: IRootState) => state.appointment.medicalRecord.id);
    let response: AxiosResponse;

    if (diagnosisId) {
      response = yield call(appointmentAPI.patchMedicalRecord, {
        diagnosisId,
        appointmentId: id,
        ...payload.medicalRecord,
      });
    } else {
      const doctorId = yield select((state) => state.authentication.userInfo.doctorId);
      response = yield call(appointmentAPI.postMedicalRecord, {
        appoitnemntId: id,
        doctorId,
        ...payload.medicalRecord,
      });
    }

    const { data } = response;

    const updatedRecord = snakeToCamel(data);

    yield all([
      yield put(postMedicalRecordSuccess(true)),
      yield put(getMedicalRecordSuccess({ medicalRecord: updatedRecord })),
    ]);
  } catch (e) {
    console.error(e);
  }
}

function* fetchCallPatient({ type, payload }) {
  try {
    const { appointmentId } = payload;
    yield localStorage.setItem('appointmentId', appointmentId);

    yield call(appointmentAPI.callPatient, { type: CALL_PATIENT, ...payload });
  } catch (e) {
    console.error(e);
  }
}

function* fetchAppointmentDetail({ type, payload }) {
  try {
    const { data } = yield call(appointmentAPI.appointmentDetail, payload);

    const appointment = snakeToCamel(data);

    yield put(
      getAppointmentDetailSuccess({
        appointmentDetail: { appointment },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchAppointmentTargetPage({ type, payload }) {
  try {
    const { page, filter } = payload;

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.appointmentTargetPage, { ...filter, page });

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAppointmentList({ type, payload }) {
  yield put(startLoading(type));
  try {
    payload.patient_name = payload.patientName;
    const queryList = Object.keys(payload);
    const queryParams = queryList.map((query) => (query ? `${query}=${payload[query]}&` : ''));

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.appointmentList, queryParams);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
  yield put(finishLoading(type));
}

function* fetchPatchCancelAppointment({ type, payload }) {
  try {
    const { status } = yield call(appointmentAPI.cancelAppointment, payload);

    if (status === 201) {
      yield put(
        showSuccessModal({
          modalInfo: {
            modalTitle: '취소 요청 완료',
            modalExplanation: '취소 요청이 성공적으로 완료되었습니다.',
          },
        }),
      );
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '취소 요청 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchPatchFinishTreatment({ type, payload }) {
  try {
    yield call(appointmentAPI.patchFinishTreatment, payload);

    yield put(patchFinishTreatmentSucces());
  } catch (e) {
    console.error(e);
  }
}

function* fetchDiseaseList() {
  try {
    const { data } = yield call(appointmentAPI.diseaseList);

    const diseaseList = arraySnakeToCamel(data);

    yield put(getDiseaseListSuccess({ diseaseList }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPatchPatientNoShow() {
  try {
    const appointmentId = yield select((state: IRootState) => state.appointment.appointmentDetail.appointment.id);

    yield call(appointmentAPI.patientNoShow, appointmentId);
  } catch (e) {
    console.error(e);
  }
}

function* fetchInvitePatient({ type, payload }) {
  try {
    const { appointmentId } = payload;
    yield localStorage.setItem('appointmentId', appointmentId);

    yield call(appointmentAPI.invitePatient, payload);

    yield all([yield put(patchInvitePatientSuccess()), yield call(fetchWaitingAppointmentList)]);
  } catch (e) {
    console.error(e);
  }
}

function* fetchUpdateStartedAt({ type, payload }) {
  try {
    yield call(appointmentAPI.patchStartTreatment, payload);
  } catch (e) {
    console.error(e);
  }
}

function* patchMedicalRecordLocally({ type, payload }) {
  const { medicalRecord } = payload;

  yield put(pastePreviousDiagnosis({ medicalRecord }));
}

function* fetchWaitingAppointmentList() {
  yield put(startLoading(''));
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.waitingAppointmentList);

    const appointmentArray = arraySnakeToCamel(results);

    yield all([
      yield put(
        getWaitingAppointmentSuccess({
          count,
          next,
          previous,
          appointmentArray,
        }),
      ),
      yield call(fetchCompleteAppointmentList),
    ]);
  } catch (e) {
    console.error(e);
  }
  yield put(finishLoading(''));
}

function* fetchWebRTCOption({ type, payload }) {
  try {
    yield call(appointmentAPI.postWebRTCOption, payload);
  } catch (e) {
    console.error(e);
  }
}

function* fetchUpdateAppointmentDuration({ type, payload }) {
  try {
    yield call(appointmentAPI.patchAppointmentDuration, payload);
  } catch (e) {
    console.error(e);
  }
}

function* fetchCooperationDoctorList({ type, payload }) {
  try {
    const { departmentId } = payload;

    let response: AxiosResponse;

    if (departmentId) {
      response = yield call(appointmentAPI.cooperationDoctorList, payload);
    } else {
      response = yield call(appointmentAPI.cooperationDoctorList, payload);
    }

    if (departmentId) {
      const cooperationDoctorList = arraySnakeToCamel(response.data);
      yield put(getCooperationDoctorListSuccess({ cooperationDoctorList }));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchremoveDoctor({ type, payload }) {
  try {
    const { status } = yield call(appointmentAPI.deleteCooperationDoctor, payload);
    if (status === 200) {
      yield put(
        showSuccessModal({
          modalInfo: {
            modalTitle: '협진 취소 완료',
            modalExplanation: '협진 취소가 성공적으로 완료되었습니다.',
          },
        }),
      );
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '협진 취소 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchCooperationDoctorRequest({ type, payload }) {
  yield put(startLoading(''));
  try {
    const { status } = yield call(appointmentAPI.requestCooperation, payload);
    if (status === 200) {
      yield put(
        showSuccessModal({
          modalInfo: {
            modalTitle: '협진 요청 완료',
            modalExplanation: '협진 요청이 성공적으로 완료되었습니다.',
          },
        }),
      );
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '협진 요청 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
  yield put(finishLoading(''));
}

function* fetchNextWaitingPage() {
  try {
    const nextPageUrl = yield select((state) => state.appointment.waitingList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.waitingNextPage, nextPageUrl);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getWaitingAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPreviousWaitingPage() {
  try {
    const prevPageURL = yield select((state) => state.appointment.waitingList.previous);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.waitingPrevPage, prevPageURL);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getWaitingAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchCompleteAppointmentList() {
  yield put(startLoading(''));
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.completeAppointmentList);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(
      getCompleteAppointmentSuccess({
        count,
        next,
        previous,
        appointmentArray,
      }),
    );
  } catch (e) {
    console.error(e);
  }
  yield put(finishLoading(''));
}

function* fetchNextCompletePage() {
  try {
    const nextPageUrl = yield select((state) => state.appointment.completeList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.completeNextPage, nextPageUrl);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getCompleteAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchPreviousCompletePage() {
  try {
    const prevPageURL = yield select((state) => state.appointment.completeList.previous);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.completePrevPage, prevPageURL);

    const appointmentArray = arraySnakeToCamel(results);

    yield put(getCompleteAppointmentSuccess({ count, next, previous, appointmentArray }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchAgoraToken({ type, payload }) {
  try {
    const {
      data: { uid, agora_token: agoraToken },
    } = yield call(appointmentAPI.getAgoraToken);

    yield put(
      getAgoraTokenSuccess({
        appointmentDetail: { appointment: { agoraToken, uid } },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchAgoraRTCToken({ type, payload }) {
  try {
    const {
      data: { rtc_token: roomToken },
    } = yield call(appointmentAPI.postAgoraRTCToken, payload);

    yield put(postAgoraRTCTokenSuccess(roomToken));
  } catch (e) {
    console.error(e);
  }
}

function* fetchResourceId({ type, payload }) {
  try {
    const {
      data: { resource_id: resourceId },
    } = yield call(appointmentAPI.postResourceId, payload);

    yield put(postResourceIdSuccess(resourceId));
  } catch (e) {
    console.error(e);
  }
}

function* fetchRecordStart({ type, payload }) {
  try {
    const {
      data: { sid },
    } = yield call(appointmentAPI.postRecordStart, payload);

    yield put(postRecordStartSuccess(sid));
  } catch (e) {
    console.error(e);
  }
}

function* fetchRecordStop({ type, payload }) {
  try {
    yield call(appointmentAPI.postRecordStop, payload);
    yield put(postRecordStopSuccess());
  } catch (e) {
    console.error(e);
  }
}

export const appointmentSagas = [
  takeLatest(REMOVE_DOCTOR, fetchremoveDoctor),
  takeLatest(PATCH_COOPERATION_DOCTOR_REQUEST, fetchCooperationDoctorRequest),
  takeLatest(GET_COOPERATION_DOCTOR_LIST, fetchCooperationDoctorList),
  takeLatest(GET_APPOINTMENT_LIST, fetchAppointmentList),
  takeLatest(GET_APPOINTMENT_DETAIL, fetchAppointmentDetail),
  takeLatest(CALL_PATIENT, fetchCallPatient),
  takeLatest(POST_MEDICAL_RECORD, saveMedicalRecord),
  takeLatest(GET_NEXT_PAGE, fetchNextPage),
  takeLatest(GET_PREVIOUS_PAGE, fetchPreviousPage),
  takeLatest(GET_DEPARTMENT_LIST, fetchDepartmentList),
  takeLatest(GET_DOCTOR_LIST, fetchDoctorList),
  takeLatest(PATCH_CANCEL_APPOINTMENT, fetchPatchCancelAppointment),
  takeLatest(PATCH_FINISH_TREATMENT, fetchPatchFinishTreatment),
  takeLatest(GET_DISEASE_LIST, fetchDiseaseList),
  takeLatest(GET_TARGET_PAGE, fetchAppointmentTargetPage),
  takeLatest(PATCH_PATIENT_NO_SHOW, fetchPatchPatientNoShow),
  takeLatest(PATCH_INVITE_PATIENT, fetchInvitePatient),
  takeLatest(PATCH_MEDICAL_RECORD, patchMedicalRecordLocally),
  takeLatest(PATCH_DOCTOR_ENTERED, fetchDoctorEntered),
  takeLatest(GET_DIAGNOSIS, fetchDiagnosis),
  takeLatest(PATCH_START_TREATMENT, fetchUpdateStartedAt),
  takeLatest(GET_WAITING_APPOINTMENT, fetchWaitingAppointmentList),
  takeLatest(GET_PRESCRIPTION_INFO, fetchPrescriptionInfo),
  takeLatest(POST_PRESCRIPTION, fetchWriteprescription),
  takeLatest(GET_DISTRICT_LIST, fetchDistrictList),
  takeLatest(GET_PATIENT_DIAGNOSIS, fetchPatientDiagnosis),
  takeLatest(GET_PATIENT_QUESTIONNAIRE, fetchPatientQuestionnaire),
  takeLatest(POST_WEBRTC_OPTION, fetchWebRTCOption),
  takeLatest(PATCH_APPOINTMENT_DURATION, fetchUpdateAppointmentDuration),
  takeLatest(GET_NEXT_WAITING_PAGE, fetchNextWaitingPage),
  takeLatest(GET_PREVIOUS_WAITING_PAGE, fetchPreviousWaitingPage),
  takeLatest(GET_COMPLETE_APPOINTMENT, fetchCompleteAppointmentList),
  takeLatest(GET_NEXT_COMPLETE_PAGE, fetchNextCompletePage),
  takeLatest(GET_PREVIOUS_COMPLETE_PAGE, fetchPreviousCompletePage),
  takeLatest(GET_AGORA_TOKEN, fetchAgoraToken),
  takeLatest(POST_AGORA_RTC_TOKEN, fetchAgoraRTCToken),
  takeLatest(POST_RESOURCE_ID, fetchResourceId),
  takeLatest(POST_RECORD_START, fetchRecordStart),
  takeLatest(POST_RECORD_STOP, fetchRecordStop),
];

export default appointmentReducer;
