import styled from '@emotion/styled';
import React from 'react';

interface ImageProps {
  src: string;
  alt: string;
}

function Image({ src, alt }: ImageProps) {
  return <Img alt={alt} src={src} />;
}

export default Image;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
