import React, { useState } from 'react';

import styled from '@emotion/styled';

import { flexCenter, flexColumn } from 'styles/global/mixins';
import { resetFilterBtn } from 'assets/images';

import { IDoctor } from 'types/payloadTypes';
import { IActive } from 'types/styleTypes';
import { BasicBtn } from 'styles/button';

interface MedicalDoctorProps {
  subDoctorArray: Array<IDoctor>;
  doctorName: string;
  doctorPhoto: string;
  hospitalName: string;
  departmentName: string;
  onRemove?: (subDoctorId: number) => void;
  showDelete: boolean;
}

function MedicalDoctor({
  subDoctorArray,
  doctorName,
  doctorPhoto,
  hospitalName,
  departmentName,
  onRemove,
  showDelete,
}: MedicalDoctorProps) {
  const [isHovering, setIsHovering] = useState({
    onHover: false,
    doctorId: 0,
  });

  return (
    <DoctorSection>
      <DoctorProfile>
        <DoctorPhoto alt="doctor-profile" src={doctorPhoto}></DoctorPhoto>
        <DoctorInfo>
          <SubDoctorName>{`${doctorName} (나)`}</SubDoctorName>
          <Hospital>{`${departmentName} 전문의 ${hospitalName}`}</Hospital>
        </DoctorInfo>
      </DoctorProfile>
      {subDoctorArray.map((doctor, index) => {
        const { doctorId, fullName, departmentName: docterDepartmentName, hospital, photo } = doctor;
        return (
          <SubDoctorProfile key={`doctor-${index}`}>
            <SubDoctorWrap>
              <DoctorPhoto alt="doctor-profile" src={photo}></DoctorPhoto>
              <DoctorInfo>
                <DoctorInfoWrap>
                  <SubDoctorName>{fullName}</SubDoctorName>
                  {showDelete && (
                    <DeleteBtnWrap
                      active={isHovering.onHover && isHovering.doctorId === doctorId}
                      onClick={() => onRemove(doctorId)}
                      onMouseEnter={() => setIsHovering({ onHover: true, doctorId })}
                      onMouseLeave={() => setIsHovering({ onHover: false, doctorId: 0 })}
                    >
                      {isHovering && isHovering.doctorId === doctorId ? (
                        '협진 삭제'
                      ) : (
                        <DeleteIcon src={resetFilterBtn} />
                      )}
                    </DeleteBtnWrap>
                  )}
                </DoctorInfoWrap>
                <Hospital>{`${docterDepartmentName} 전문의 ${hospital}`}</Hospital>
              </DoctorInfo>
            </SubDoctorWrap>
          </SubDoctorProfile>
        );
      })}
    </DoctorSection>
  );
}

export default MedicalDoctor;

const colors = {
  sub: '#777777',
  text: '#717171',
  h3Text: '#a3a3a3',
  border: '#eeeeee',
  specialtyborder: '#e0e0e0',
  scrollbarBackground: '#909090',
  scrollbarShadow: '#3e3e3e',
  backBtnActiveText: '#a3a3a3',
  backBtnActiveBackground: '#d8d8d8',
  confirmActiveBackground: '#d4f3f7',
  confirmActiveText: '#399ba7',
};

const DoctorSection = styled.div``;

const DoctorPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const DoctorProfile = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const SubDoctorProfile = styled(DoctorProfile)`
  justify-content: space-between;
`;

const SubDoctorWrap = styled.div`
  display: flex;
  align-items: center;
`;

const SubDoctorName = styled.div`
  font-size: 15px;
  color: ${colors.text};
  text-align: start;
`;

const DoctorInfo = styled.div`
  ${flexColumn};
  margin-left: 16px;
  justify-content: space-around;
  line-height: initial;
`;

const Hospital = styled.div`
  margin-top: 5px;
  font-size: 13px;
  color: ${colors.sub};
`;

const DeleteBtnWrap = styled(BasicBtn)<IActive>`
  ${flexCenter};
  background-color: ${colors.confirmActiveBackground};
  color: ${colors.confirmActiveText};
  font-size: 13px;
  border-radius: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-left: 8px;
  width: 18px;
  height: 18px;
  animation: ${(props) => (props.active ? 'leftToRigth 0.4s both' : '')};
  @keyframes leftToRigth {
    0% {
      width: 18px;
      height: 18px;
      border-radius: 10px;
    }
    100% {
      height: 18px;
      width: 63px;
      border-radius: 8px;
    }
  }
`;

const DoctorInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

const DeleteIcon = styled.img`
  width: 15px;
  height: 15px;
`;
