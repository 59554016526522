import React, { useState } from 'react';

import styled from '@emotion/styled';
import moment from 'moment';

import { Button, Image } from 'components/atoms';
import { Calendar, TimePicker } from 'components/molecules';

import { device } from 'styles/global/device';
import { inActiveCloseBtn } from 'assets/images';

interface ChangeAppointmentModalProps {
  onConfirm?: (selectedTime: string, date: string) => void;
  onClose?: () => void;
}

function ChangeAppointmentModal({ onConfirm, onClose }: ChangeAppointmentModalProps) {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedTime, setSelctedTime] = useState({
    dayPeriod: 'AM',
    hour: '',
    minute: '',
  });

  const { dayPeriod, hour, minute } = selectedTime;

  const handleDayPeriodChange = (option: string) => {
    setSelctedTime({
      ...selectedTime,
      dayPeriod: option,
    });
  };

  const handleHourChange = (hourOption: string) => {
    setSelctedTime({
      ...selectedTime,
      hour: hourOption,
    });
  };

  const handleMinuteChange = (minuteOption: string) => {
    setSelctedTime({
      ...selectedTime,
      minute: minuteOption,
    });
  };

  const handleTimeChange = () => {
    let time = '';

    if (dayPeriod === 'PM' && Number(hour) < 10) {
      time = `${Number(hour.slice(1)) + 12}:${minute}`;
    } else if (dayPeriod === 'PM' && Number(hour) === 12) {
      time = `${hour}:${minute}`;
    } else if (dayPeriod === 'PM') {
      time = `${Number(hour) + 12}:${minute}`;
    } else if (dayPeriod === 'AM' && Number(hour) === 12) {
      time = `00:${minute}`;
    } else {
      time = `${hour}:${minute}`;
    }
    onConfirm(time, date);
  };

  const handleDateChange = (value: string) => {
    setDate(value);
  };

  return (
    <ChangeModalContainer>
      <CloseBtnWrap onClick={() => onClose()}>
        <Image alt="close-button" src={inActiveCloseBtn} />
      </CloseBtnWrap>
      <CalendarWrap>
        <Calendar handleDateChange={handleDateChange} parent="ChangeAppointmentModal"></Calendar>
      </CalendarWrap>
      <TimeTable>
        <TimeWrap>
          <TimePicker
            selectedTime={selectedTime}
            onDayChange={handleDayPeriodChange}
            onHourChange={handleHourChange}
            onMinuteChange={handleMinuteChange}
          />
        </TimeWrap>
      </TimeTable>
      <ModalBtnWrap>
        <Button
          color="lightBlue"
          disabled={!hour.length || !minute.length || moment().add(-1, 'day').isAfter(moment(date))}
          size="mid"
          onClick={() => handleTimeChange()}
        >
          확인
        </Button>
      </ModalBtnWrap>
    </ChangeModalContainer>
  );
}

export default ChangeAppointmentModal;

const ChangeModalContainer = styled.form`
  padding: 3rem 4rem;
  border-radius: 5px;
  background-color: white;
  @media ${device.mobileMax} {
    width: 95%;
  }
`;

const CloseBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  img {
    cursor: pointer;
    width: 15px;
    height: 15px;
  }
`;

const CalendarWrap = styled.section`
  justify-content: center;
  padding: 1.5rem;
  background-color: white;
  flex: 1;
  display: flex;
`;

const TimeTable = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 51px;
  margin-bottom: 3.2rem;
`;

const TimeWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  button:nth-of-type(3n) {
    margin-right: 0;
  }
`;

const ModalBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
