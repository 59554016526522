/* eslint-disable @typescript-eslint/no-unused-vars */
import { takeLatest, call, put, fork, delay, select } from 'redux-saga/effects';
import { createActionTypes } from 'redux/lib/createSaga';

import * as chattingAPI from 'redux/lib/api/chatting';

import { arraySnakeToCamel, snakeToCamel } from 'utils';

import { IChattingState, IRootState } from 'types/payloadTypes';

const INITIALIZE_STATE = 'chatting/INITIALIZE_STATE';

const [POST_NEW_CHAT_ROOM, POST_NEW_CHAT_ROOM_SUCCESS] = createActionTypes('chatting/POST_NEW_CHAT_ROOM');

const [GET_CHAT_LIST, GET_CHAT_LIST_SUCCESS] = createActionTypes('chatting/GET_CHAT_LIST');

const [GET_NEXT_CHAT_LIST, GET_NEXT_CHAT_LIST_SUCCESS] = createActionTypes('chatting/GET_NEXT_CHAT_LIST');

const [POST_CHAT_MESSAGE] = createActionTypes('chatting/POST_CHAT_MESSAGE');

const [GET_CHAT_MESSAGE_LIST, GET_CHAT_MESSAGE_LIST_SUCCESS] = createActionTypes('chatting/GET_CHAT_MESSAGE_LIST');

const [GET_NEXT_CHAT_MESSAGE, GET_NEXT_CHAT_MESSAGE_SUCCESS] = createActionTypes('chatting/GET_NEXT_CHAT_MESSAGE');

const [GET_SEARCH_COLLEAGUE_LIST] = createActionTypes('chatting/GET_SEARCH_COLLEAGUE_LIST');

const [GET_CHAT_COLLEAGUE_LIST, GET_CHAT_COLLEAGUE_LIST_SUCCESS] = createActionTypes(
  'chatting/GET_CHAT_COLLEAGUE_LIST',
);

const [GET_NEW_MESSAGE_NOTIFICATION, GET_NEW_MESSAGE_NOTIFICATION_SUCCESS] = createActionTypes(
  'chatting/GET_NEW_MESSAGE_NOTIFICATION',
);

export const initializeChattingState = () => ({
  type: INITIALIZE_STATE, // saga 에서 call 안함. fetch가 아님
});

export const getNewMessageNotification = () => ({
  type: GET_NEW_MESSAGE_NOTIFICATION,
});

const getNewMessageNotificationSuccess = (payload) => ({
  type: GET_NEW_MESSAGE_NOTIFICATION_SUCCESS,
  payload,
});

// 파트너 목록 리스트 Get 요청
export const getChatColleagueList = () => ({
  type: GET_CHAT_COLLEAGUE_LIST,
});

// 파트너 목록 리스트 Get 성공시 state 저장
const getChatColleagueListSuccess = (payload) => ({
  type: GET_CHAT_COLLEAGUE_LIST_SUCCESS,
  payload,
});

// 채팅방 메세지 infinity scroll Get 요청
export const getNextChatMessage = () => ({
  type: GET_NEXT_CHAT_MESSAGE,
});

// 채팅방 메세지 infinity scroll Get 성공시 state 저장
const getNextChatMessageSuccess = (payload) => ({
  type: GET_NEXT_CHAT_MESSAGE_SUCCESS,
  payload,
});

// 채팅방 리스트 infinity scroll Get 요청
export const getNextChatList = () => ({
  type: GET_NEXT_CHAT_LIST,
});

// 채팅방 리스트 infinity scroll Get 성공시 state 저장
const getNextChatListSuccess = (payload) => ({
  type: GET_NEXT_CHAT_LIST_SUCCESS,
  payload,
});

// 의사 검색 Get 요청
export const getSearchColleagueList = (payload) => ({
  type: GET_SEARCH_COLLEAGUE_LIST,
  payload,
});

// 채팅 메세지 생성 요청
export const postChatMessage = (payload) => ({
  type: POST_CHAT_MESSAGE,
  payload,
});

// 채팅방 메세지 리스트 Get 요청
export const getChatMessageList = (payload) => ({
  type: GET_CHAT_MESSAGE_LIST,
  payload,
});

// 채팅방 메세지 리스트 Get 성공시 state 저장
const getChatMessageListSuccess = (payload) => ({
  type: GET_CHAT_MESSAGE_LIST_SUCCESS,
  payload,
});

// 채팅방 생성
export const postNewChatRoom = (payload) => ({
  type: POST_NEW_CHAT_ROOM,
  payload,
});

const postNewChatRoomSuccess = (payload) => ({
  type: POST_NEW_CHAT_ROOM_SUCCESS,
  payload,
});

// 채팅 리스트 Get 요청
export const getChatList = () => ({
  type: GET_CHAT_LIST,
});

// 채팅방 리스트 Get 성공시 state 저장
const getChatListSuccess = (payload) => ({
  type: GET_CHAT_LIST_SUCCESS,
  payload,
});

export const chattingSagas = [
  takeLatest(GET_SEARCH_COLLEAGUE_LIST, debounceFetchSearchColleagueList),
  takeLatest(POST_CHAT_MESSAGE, fetchChatMessage),
  takeLatest(GET_CHAT_MESSAGE_LIST, fetchChatMessageList),
  takeLatest(POST_NEW_CHAT_ROOM, fetchNewChatRoom),
  takeLatest(GET_CHAT_LIST, fetchChatList),
  takeLatest(GET_NEXT_CHAT_MESSAGE, fetchNextChatMessageList),
  takeLatest(GET_NEXT_CHAT_LIST, fetchNextChatList),
  takeLatest(GET_CHAT_COLLEAGUE_LIST, fetchChatColleagueList),
  takeLatest(GET_NEW_MESSAGE_NOTIFICATION, fetchNewMessageNotification),
];

const initialState = {
  chatList: {
    count: 0,
    next: '',
    previous: '',
    chatArray: [],
  },
  messageList: {
    count: 0,
    next: '',
    previous: '',
    messageArray: [],
  },
  colleagueList: {
    count: 0,
    next: '',
    previous: '',
    colleagueArray: [],
  },
  newColleague: {
    id: 0,
    colleagueDepartment: '',
    colleagueHospital: '',
    colleagueName: '',
    colleaguePhoto: '',
  },
  hasNewMessage: false,
};

interface IChatingAction {
  type: string;
  payload: IChattingState;
}

const chattingReducer = (state = initialState, action: IChatingAction) => {
  switch (action.type) {
    case INITIALIZE_STATE: {
      return {
        ...state,
        hasNewMessage: false,
      };
    }
    case GET_NEW_MESSAGE_NOTIFICATION_SUCCESS: {
      const { hasNewMessage } = action.payload;
      return {
        ...state,
        hasNewMessage,
      };
    }
    case POST_NEW_CHAT_ROOM_SUCCESS: {
      const newColleague = action.payload;
      return {
        ...state,
        newColleague,
      };
    }
    case GET_CHAT_COLLEAGUE_LIST_SUCCESS: {
      const { count, next, previous, colleagueArray } = action.payload.colleagueList;
      return {
        ...state,
        colleagueList: {
          ...state.colleagueList,
          count,
          next,
          previous,
          colleagueArray,
        },
      };
    }

    case GET_NEXT_CHAT_LIST_SUCCESS: {
      const { count, next, previous, chatArray } = action.payload.chatList;
      return {
        ...state,
        chatList: {
          ...state.chatList,
          count,
          next,
          previous,
          chatArray: [...state.chatList.chatArray, ...chatArray],
        },
      };
    }

    case GET_NEXT_CHAT_MESSAGE_SUCCESS: {
      const { count, next, previous, messageArray } = action.payload.messageList;
      return {
        ...state,
        messageList: {
          ...state.messageList,
          count,
          next,
          previous,
          messageArray: [...state.messageList.messageArray, ...messageArray],
        },
      };
    }

    case GET_CHAT_LIST_SUCCESS: {
      const { count, next, previous, chatArray } = action.payload.chatList;
      return {
        ...state,
        chatList: {
          ...state.chatList,
          count,
          next,
          previous,
          chatArray,
        },
      };
    }
    case GET_CHAT_MESSAGE_LIST_SUCCESS: {
      const { count, next, previous, messageArray } = action.payload.messageList;
      return {
        ...state,
        messageList: {
          ...state.messageList,
          count,
          next,
          previous,
          messageArray,
        },
      };
    }
    default: {
      return state;
    }
  }
};

function* fetchNewMessageNotification({ type, payload }) {
  try {
    const { data } = yield call(chattingAPI.messageNotification);

    const hasNewMessage = snakeToCamel(data);

    yield put(getNewMessageNotificationSuccess(hasNewMessage));
  } catch (e) {
    console.error(e);
  }
}

function* fetchSearchColleagueList({ type, payload }) {
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.serchColleagueList, payload);

    const colleagueArray = arraySnakeToCamel(results);

    yield put(
      getChatColleagueListSuccess({
        colleagueList: { count, next, previous, colleagueArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* debounceFetchSearchColleagueList({ type, payload }) {
  yield delay(500);
  yield fork(fetchSearchColleagueList, getSearchColleagueList(payload));
}

function* fetchNewChatRoom({ type, payload }) {
  try {
    const { data, status } = yield call(chattingAPI.makeChatRoom, payload);

    const newColleague = snakeToCamel(data);

    if (status === 201) {
      yield put(postNewChatRoomSuccess(newColleague));
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchChatMessage({ type, payload }) {
  try {
    yield call(chattingAPI.sendChatMessage, payload);
  } catch (e) {
    console.error(e);
  }
}

function* fetchChatList({ type, payload }) {
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.getChatList);

    const chatArray = arraySnakeToCamel(results);

    yield put(
      getChatListSuccess({
        chatList: { count, next, previous, chatArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchChatMessageList({ type, payload }) {
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.chatMessageList, payload);

    const messageArray = arraySnakeToCamel(results);

    yield put(
      getChatMessageListSuccess({
        messageList: { count, next, previous, messageArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchChatColleagueList({ type, payload }) {
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.chatColleagueList);

    const colleagueArray = arraySnakeToCamel(results);

    yield put(
      getChatColleagueListSuccess({
        colleagueList: { count, next, previous, colleagueArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchNextChatMessageList({ type, payload }) {
  try {
    const nextPageUrl = yield select((state: IRootState) => state.chatting.messageList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.nextChatMessage, nextPageUrl);

    const messageArray = arraySnakeToCamel(results);

    yield put(
      getNextChatMessageSuccess({
        messageList: { count, next, previous, messageArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchNextChatList({ type, payload }) {
  try {
    const nextPageUrl = yield select((state: IRootState) => state.chatting.chatList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(chattingAPI.nextChatMessage, nextPageUrl);

    const chatArray = arraySnakeToCamel(results);

    yield put(
      getNextChatListSuccess({
        chatList: { count, next, previous, chatArray },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}
export default chattingReducer;
