import React from 'react';

import styled from '@emotion/styled';

import { device } from 'styles/global/device';
import * as mixins from 'styles/global/mixins';
import { ModalHeader, ModalExplanation, CancelBtn, ConfirmBtn } from 'styles/modal';

interface ApprovalModalProps {
  confirmBtnText?: string;
  onClose: () => void;
  onConfirm: () => void;
  modalExplanation: string;
  modalTitle: string;
}

function ApprovalModal({
  modalTitle,
  modalExplanation,
  onClose,
  onConfirm,
  confirmBtnText = '확인',
}: ApprovalModalProps) {
  return (
    <Container>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalExplanation>{modalExplanation}</ModalExplanation>
      <BtnWrap>
        <CancelBtn size="mid" onClick={() => onClose()}>
          취소
        </CancelBtn>
        <ConfirmBtn size="big" onClick={() => onConfirm()}>
          {confirmBtnText}
        </ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

export default ApprovalModal;

const Container = styled.div`
  padding: 40px 40px 30px 40px;
  border-radius: 5px;
  background-color: white;
  @media ${device.mobileMax} {
    width: 98%;
  }
  h2 {
    margin-top: 8px;
  }
`;

const BtnWrap = styled.section`
  ${mixins.flexRow}
  margin-top: 40px;
  margin-left: 10em;
  button {
    margin-left: 8px;
  }
`;
