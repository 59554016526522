import { all } from 'redux-saga/effects';
import { appointmentSagas } from 'redux/modules/appointment';
import { authenticationSagas } from 'redux/modules/authentication';
import { scheduleSagas } from 'redux/modules/schedule';
import { waitingListSagas } from 'redux/modules/waitingList';
import { chattingSagas } from 'redux/modules/chatting';

// ...

// Our worker Saga: will perform the async increment task

// Our watcher Saga: spawn a new incrementAsync task on each INCREMENT_ASYNC

export default function* rootSaga() {
  yield all([
    ...appointmentSagas,
    ...authenticationSagas,
    ...scheduleSagas,
    ...waitingListSagas,
    ...chattingSagas,
  ]);
}
