import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect, BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';

import {
  SignIn,
  Forbidden,
  Treatment,
  AppointmentList,
  History,
  Schedule,
  Recording,
  WaitingList,
  TreatmentMobile,
} from 'pages';
import { Header } from 'components/organisms';
import PrivateRoutes from './PrivateRoutes';

import { IRootState } from 'types/payloadTypes';

function CheckHeader() {
  const { isLogin } = useSelector((state: IRootState) => state.authentication);
  const location = useLocation();

  if (
    location.pathname.match(/signin/) ||
    location.pathname.match(/forbidden/) ||
    location.pathname.match(/treatment/) ||
    location.pathname.match(/record/) ||
    location.pathname.match(/mobile/) ||
    !isLogin
  ) {
    return <div />;
  }
  return <Header currentPath={location.pathname} />;
}
const ConditionalHeader = CheckHeader;

function Routes() {
  return (
    <Router>
      <ConditionalHeader />
      <Switch>
        <Route exact component={SignIn} path="/signin" />
        <Route exact component={Forbidden} path="/forbidden" />
        <PrivateRoutes exact component={AppointmentList} path="/home" />
        <PrivateRoutes exact component={WaitingList} path="/waiting" />
        <PrivateRoutes exact component={Treatment} path="/treatment" />
        <PrivateRoutes exact component={History} path="/history" />
        <PrivateRoutes exact component={Schedule} path="/schedule" />
        <PrivateRoutes exact component={Recording} path="/record" />
        <PrivateRoutes exact component={TreatmentMobile} path="/mobile" />
        <Redirect to="/home" />
      </Switch>
    </Router>
  );
}

export default Routes;
