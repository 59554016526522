import { combineReducers } from 'redux';
import appointment from './appointment';
import authentication from './authentication';
import schedule from './schedule';
import loading from './loading';
import waitingList from './waitingList';
import chatting from './chatting';
import modal from './modal';

export default combineReducers({
  appointment,
  authentication,
  schedule,
  loading,
  waitingList,
  chatting,
  modal,
});
