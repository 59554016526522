import axiosClient from './axiosClient';

export const messageNotification = () => axiosClient.get('chat/message/new/');

export const serchColleagueList = (serchName: string) => axiosClient.get(`chat/colleague/?name=${serchName}`);

export const makeChatRoom = (participant: number) =>
  axiosClient.post('chat/room/', {
    participant,
  });

export const sendChatMessage = ({ message, room }: { message: string; room: number }) =>
  axiosClient.post(`chat/${room}/message/`, {
    message,
  });

export const getChatList = () => axiosClient.get('chat/room/');

export const chatMessageList = (roomId: number) => axiosClient.get(`chat/room/${roomId}/message/`);

export const chatColleagueList = () => axiosClient.get('chat/colleague/');

export const nextChatMessage = (nextPageUrl: string) => axiosClient.get(nextPageUrl);

export const nextChatList = (nextPageUrl: string) => axiosClient.get(nextPageUrl);
