import styled from '@emotion/styled';
import { FontProps, MarginProps } from 'types/styleTypes';
// import { showBtn, hideBtn } from "assets/images";
import {
  tableColors,
  signInColors,
  // signUpColors,
} from 'styles/global/color';

export interface ITextInput extends FontProps, MarginProps {
  width?: number;
  defaultValue?: string;
  placeholder?: string;
}

export const PerscriptionTextInput = styled.input<ITextInput>`
  outline: none;
  border: none;
  background-color: transparent;
  placeholder: ${(props) => props.placeholder};
  text-align: center;
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
  caret-color: #6785a3;
  width: ${(props) => props.width}vw;
  color: #6785a3;

  ::placeholder {
    color: ${tableColors.bodyPlaceholder};
  }
  ::-webkit-input-placeholder {
    color: ${tableColors.bodyPlaceholder};
  }
  :-ms-input-placeholder {
    color: ${tableColors.bodyPlaceholder};
  }
  ::-moz-placeholder {
    color: ${tableColors.bodyPlaceholder};
    opacity: 1;
  }
  :-moz-placeholder {
    color: ${tableColors.bodyPlaceholder};
    opacity: 1;
  }
`;

export const NormalInput = styled.input<ITextInput>`
  /* display: block; */
  font-family: NotoSansRegular;
  width: 100%;
  height: 60px;
  padding: 18px 15px;
  margin: 8px 0 18px;
  border: solid 1px ${signInColors.inputBorder};
  border-radius: 5px;

  ::placeholder {
    opacity: 30%;
    font-size: 16px;
    line-height: 24px;
    color: ${signInColors.placeholder};
  }
`;
