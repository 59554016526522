import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import 'moment/locale/ko';

import { Spinner } from 'components/atoms';
import { TreatmentHistory, AppointmentInfo, MedicalChart } from 'components/organisms';

import {
  getPatientDiagnosis,
  getPatientQuestionnaire,
  patchMedicalRecord,
  postMedicalRecord,
} from 'redux/modules/appointment';

import { formatAge } from 'utils';

import { H1, H2 } from 'styles/text';
import { device } from 'styles/global/device';
import { flexColumn } from 'styles/global/mixins';

import { IRootState } from 'types/payloadTypes';

interface CompleteAppointmentDetailProps {
  patientId: number;
}

function CompleteAppointmentDetail({ patientId }: CompleteAppointmentDetailProps) {
  moment.locale('ko');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [targetHistory, setTargetHistory] = useState(-1);

  const { medicalRecord, appointmentDetail } = useSelector((state: IRootState) => state.appointment);

  const { appointment, diagnosis, questionnaire } = appointmentDetail;
  const {
    departmentName,
    doctorName,
    dateOfBirth,
    filePath,
    patientGender,
    hospitalName,
    imagePath,
    patientName,
    symptomDesc,
    doctorPhoto,
    subDoctors,
  } = appointment;
  const { drinking, familyHistory, height, medication, pastHistory, smoking, weight } = questionnaire;
  const { assessment, objective, opinion, plan, subjective } = medicalRecord;

  const handleMedicalChartChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const record = {
      ...medicalRecord,
      [name]: value,
    };
    dispatch(patchMedicalRecord({ medicalRecord: record }));
  };

  const handleMedicalChartSave = () => {
    dispatch(postMedicalRecord({ medicalRecord }));
  };

  const handleHistoryDetailShow = (index: number) => {
    if (index === targetHistory) {
      setTargetHistory(-1);
      return;
    }

    setTargetHistory(index);
  };

  useEffect(() => {
    const loadingTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => clearTimeout(loadingTimeout);
  }, []);

  useEffect(() => {
    if (patientId) {
      dispatch(getPatientQuestionnaire(patientId));
      dispatch(getPatientDiagnosis(patientId));
    }
    // eslint-disable-next-line
  }, [patientId]);

  return (
    <CompleteAppointmentDetailContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PatientNameSection>
            <PatientName>{patientName}</PatientName>
            <GenderAge fontSize={20} margin="0 0 0 10px">
              {formatAge(dateOfBirth)}
              {`(${patientGender === 'M' ? '남' : '여'})`}
            </GenderAge>
          </PatientNameSection>
          <AppointmentInfoSection>
            <AppointmentInfo
              showDoctorInfo
              departmentName={departmentName}
              doctorName={doctorName}
              doctorPhoto={doctorPhoto}
              drinking={drinking}
              familyHistory={familyHistory}
              filePath={filePath}
              height={height}
              hospitalName={hospitalName}
              imagePath={imagePath}
              medication={medication}
              pastHistory={pastHistory}
              smoking={smoking}
              subDoctors={subDoctors}
              symptomDesc={symptomDesc}
              weight={weight}
            />
            <MedicalChart
              assessment={assessment}
              objective={objective}
              opinion={opinion}
              plan={plan}
              subjective={subjective}
              onChartChange={handleMedicalChartChange}
              onChartSave={handleMedicalChartSave}
            />
            <TreatmentHistory
              showHeader
              diagnosisHistory={diagnosis}
              handleHistoryDetailShow={handleHistoryDetailShow}
              parent="AppointmentDetailRow"
              pasteMedicalRecord={() => {}}
              targetHistory={targetHistory}
            />
          </AppointmentInfoSection>
        </>
      )}
    </CompleteAppointmentDetailContainer>
  );
}

export default CompleteAppointmentDetail;

const PatientName = styled(H1)``;

const GenderAge = styled(H2)`
  margin-left: 10px;
`;

const CompleteAppointmentDetailContainer = styled.div`
  ${flexColumn};
  background-color: white;
  width: 538px;
  height: 100%;
  overflow: auto;
  position: relative;
  @media ${device.laptopMax} {
    width: 100%;
    min-height: 590px;
    /* padding: 32px 50px 42px 54px; */
  }
`;

const PatientNameSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 50px 43px;
  @media ${device.laptopMax} {
    padding: 32px 50px 0 54px;
  }
`;

const AppointmentInfoSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  padding: 0 50px;
  height: 100%;
  @media ${device.wideScreen} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
