const headerColors = {
  active: '#477eb5',
  inactive: '#909090',
  doctor: '#3e3e3e',
  alarm: '#6bc7d3',
  border: '#e0e0e0',
  department: '#a3a3a3',
};

const callingColors = {
  endCall: '#ef2957',
  black: '#121212',
  btnDeactivated: '#2685fd',
  muteText: '#ffffff',
  muteBackground: 'rgba(0, 0, 0, 0.1)',
  btnBackground: 'rgba(0, 0, 0, 0.15)',
  networkBackground: '#ff9300',
  statusBarActive: '#ffffff',
  statusBarInactive: 'rgba(255, 255, 255, 0.4)',
};

const treatmentColors = {
  inactive: '#dedede',
  active: '#036ad1',
  background: '#f0f0f0',
  text: '#777777',
  finishBtnBackground: '#ffe0e0',
  finishBtnBorder: '#fcbaba',
  finishBtnText: '#e12327',
  date: '#3e3e3e',
  doctor: '#a3a3a3',
};

const globalColors = {
  name: '#3e3e3e',
  detail: '#909090',
  sub: '#777777',
  main: '#036ad1',
  placeholder: 'rgba(119, 119, 119, 0.5)', // #777777 에 opacity 50% 적용된 값
  borderBottom: '#f0f0f0',
  submitBorder: '#6bd7d3',
  submitBackground: '#d4f3f7',
  submitText: '#399ba7',
  messageBorder: '#6bc7d3',
  messageBackground: '#d4f3f7',
  messageText: '#43a8b5',
  inactiveBorder: '#e5e5e5',
  inactiveText: 'rgba(119, 119, 119, 0.5)', // #777777 에 opacity 50% 적용된 값
  cancelBtnText: '#909090',
  cancelBtnBackground: '#ededed',
  cancelBtnBorder: '#d8d8d8',
  modalBackground: 'rgba(0, 0, 0, 0.15)',
  dateSelectedBackground: '#ddeeff',
  sectionLabel: '#4d86bf',
  editBtnText: '#477eb5',
  editBtnBackground: '#ddeeff',
  editBtnBorder: '#a3d1ff',
  h3Text: '#a3a3a3',
  h3Background: '#ededed',
};

const tableColors = {
  headBackground: '#eeeeee',
  headText: '#909090',
  headBorder: 'rgba(112, 112, 112, 0.2)',
  bodyBackground: '#eaf4ff',
  bodyText: '#6785a3',
  bodyBorder: 'rgba(3, 106, 209, 0.2)',
  bodyPlaceholder: 'rgba(103, 133, 163, 0.5)',
  tableHeadText: '#a3a3a3',
  tableHeadBackground: '#eaeaea',
  tableBodyBorder: 'rgba(144,144,144,0.3)',
  tableBodyText: '#3e3e3e',
  selectedBorder: 'rgba(255,255,255,0.3)',
};

const appointmentListColors = {
  tableHeadText: '#909090',
  tableHeadBackground: '#eaeaea',
  tableBodyBorder: 'rgba(144,144,144,0.3)',
  tableBodyText: '#3e3e3e',
  secondTimeBackground: '#d9ecff',
  secondTimeBorder: '#7cbdff',
  secondTimeText: '#3a72aa',
  firstTimeBackground: '#d4f3f7',
  firstTimeBorder: '#6bc7d3',
  firstTimeText: '#399ba7',
  status: '#777777',
  statusBorder: '#d8d8d8',
  selectedAppointment: '#036ad1',
  selectedAppointmentBorder: 'rgba(255,255,255,0.3)',
  nextAppointmentBorder: '#399ba7',
  nextAppointmentRowBorder: '#d4f3f7',
  dateFilterWrap: '#e5e5e5',
  inviteBtnBorder: '#7cbdff',
  inviteBtnBackground: '#477eb5',
  inviteBtnInactiveBorder: '#d8d8d8',
  inviteBtnInactiveBackground: '#ededed',
  requestApprovedBtnBackground: '#ddeeff',
  requestApprovedBtnBorder: '#a3d1ff',
  noticeText: '#bdbdbd',
  online: '#2685fd',
  offline: '#e0e0e0',
  date: '#a3a3a3',
  btnText: '#4d86bf',
  btnBackground: '#eeeeee',
  activeBtnText: '#42a8b5',
  inactiveBtnText: '#a3a3a3',
  inactiveborder: '#d5d5d5',
  inactiveBackground: '#ffffff',
  emergencyText: '#e13237',
  emergencyBackground: '#ffe0e0',
};

const chatColors = {
  textInputBorder: '#d8d8d8',
  textInputText: '#777777',
  textInputPlaceholder: 'rgba(119, 119, 119, 0.5)',
  myChatBackground: '#036ad1',
  othersChatBackground: '#f2f2f2',
  chatTime: '#909090',
};

const signInColors = {
  title: '#036ad1',
  subtitle: '#717171',
  loginBackground: '#f0f0f0',
  inputLabel: '#909090',
  inputBorder: '#d8d8d8',
  placeholder: '#3e3e3e',
  signInText: '#ffff',
  pwAlertText: '#e13237',
  pwAlertBackground: '#ffe0e0',
  text: '#777777',
  inactive: '#e5e5e5',
  active: '#3881dc',
  header: '#4d86bf',
  findInfo: '#bdbdbd',
};

const signUpColors = {
  activeBackground: '#d4f3f7',
  activeBorder: '#6bc7d3',
  activeText: '#399ba7',
  inactiveBackground: '#f2f2f2',
  inactiveBorder: '#dddddd',
  inactiveText: '#909090',
  useTermsTitle: '#3e3e3e',
  useTermsContent: '#7c7c7c',
  checkboxUnchecked: '#f2f2f2',
  checkboxChecked: '#ddeeff',
  useTermsAgreement: '#909090',
  nextBtnBackground: '#ededed',
  nextBtnBorder: '#d8d8d8',
  inactiveAuthBtnBackground: '#ddeeff',
  activeAuthBtnBackground: '#3881dc',
  authBtnBorder: '#a3d1ff',
  inactiveAuthBtnText: '#477eb5',
  activeAuthBtnText: '#ffffff',
  dept: '#777777',
  focus: '#036ad1',
  inputAdvice: '#b7b7b7',
  pwAlertText: '#e13237',
  pwAlertBackground: '#ffe0e0',
  photoUploaderBackground: '#ededed',
  photoUploaderBorder: '#d8d8d8',
};

const appointmentHistoryColors = {
  inactiveText: '#909090',
  activeText: '#777777',
  selected: '#036ad1',
  placeholder: '#3e3e3e',
  inactiveBorder: '#e5e5e5',
  categorySelected: '#3a72aa',
  categorySelectedBackground: '#d9ecff',
  categorySelectedBorder: '#7cbdff',
  completeBorder: '#a3d1ff',
  completeText: '#477eb5',
  onWaitBorder: '#d8d8d8',
  calendarBorder: '#f0f0f0',
};

const paginationColors = {
  activeText: '#399ba7',
  activeBorder: '#6bc7d3',
  inactiveText: '#909090',
  inactiveBorder: '#d8d8d8',
};

const dropdownListColors = {
  border: '#e5e5e5',
  optionText: '#7c7c7c',
  active: '#036ad1',
  inactiveText: 'rgba(119, 119, 119, 0.5)',
  inactiveBorder: '#e5e5e5',
  searchBarBackground: '#ddeeff',
  searchBarText: '#477eb5',
};

const searchBarColors = {
  active: '#036ad1',
  inactiveText: 'rgba(119, 119, 119, 0.5)',
  inactiveBorder: '#e5e5e5',
};

const calendarColors = {
  day: '#a8a8a8',
  selectedDate: '#42a8b5',
  selectedBackground: '#d4f3f7',
  unavailableDate: 'rgba(0,0,0,0.3)',
  availableDate: 'rgb(0,0,0)',
  monthAndYear: '#3e3e3e',
  border: '#e8e8e8',
  inactiveText: '#777777',
  activeText: '#036ad1',
  activeBackground: '#ddeeff',
  appointmentIndicator: '#6bc7d3',
};

const scheduleColors = {
  dailyAppointmentText: '#909090',
  dailyAppointmentTime: '#3e3e3e',
  dailyAppointmentBorder: '#ededed',
  btnActiveBackground: '#d4f3f7',
  btnActiveBorder: '#6bd7d3',
  btnActiveText: '#399ba7',
  btnInactiveBackground: '#ededed',
  btnInactiveBorder: '#d8d8d8',
  inactiveText: '#777777',
  tableHeaderBackground: '#f2f2f2',
  tableBorder: '#f0f0f0',
  activeTimeBackground: '#ddeeff',
  activeTimeBorder: '#a3d1ff',
  activeTimeText: '#477eb5',
  tableRowBorder: '#f0f0f0',
};

const scheduleModalColors = {
  normalText: '#909090',
  cancelBtnBackground: '#ededed',
  cancelBtnBorder: '#d8d8d8',
  confirmBtnText: '#399ba7',
  confirmBtnBackground: '#d4f3f7',
  confirmBtnBorder: '#6bc7d3',
  cancelReasonInputBackground: '#f0f0f0',
  cancelReasonText: '#777777',
};

const changePasswordModalColors = {
  titleText: '#a3a3a3',
  subtitleText: '#777777',
  lableText: '#7c7c7c',
  inputPlaceholderText: '#3e3e3e',
  inactiveInputBorder: '#d8d8d8',
  inactiveBtnBackground: '#ededed',
  inactiveBtnText: '#909090',
  activeBtnBorder: '#a3d1ff',
  activeBtnBackground: '#ddeeff',
  activeBtnText: '#477eb5',
  pwAlertText: '#e13237',
  pwAlertBackground: '#ffe0e0',
  focus: '#036ad1',
  confirmBtnBorder: '#abe2ea',
  confirmBtnBackground: '#d4f3f7',
  confirmBtnText: '#399ba7',
};

const recordingColor = {
  overlayBackground: '#f0f0f0',
  recordWrapperBackground: '#FFFFFF',
  titleText: '#036AD1',
  adviseText: '#717171',
  recordBtnBorder: '#A3D1FF',
  recordBtnBackground: '#DDEEFF',
  recordBtnText: '#477EB5',
  watermarkHeader: '#7c7c7c',
  recordedPhraseText: '#477eb5',
  recordedPhraseBackground: '#ddeeff',
  recordingPhraseText: '#036ad1',
  recordingPhraseBorder: '#a3d1ff',
  notRecordedText: '#777777',
  notRecordedBackground: '#f2f2f2',
  phraseViewerBorder: '#eceff1',
  audioBtnActive: '#2685fd',
  audioBtnInactive: '#909090',
  audioBtnInactiveText: '#bcbcbc',
  nextPhraseInactiveBackground: 'rgba(3, 106, 209, 0.5)',
  nextPhraseActiveBackground: '#036AD1',
  phraseCountBorder: 'rgba(255, 255, 255, 0.3)',
  recordingAnimation: 'rgba(3, 106, 209, 0.4)',
  testExplanationText: '#bcbcbc',
  instructionBackground: '#d4f3f7',
  instructionText: '#42a8b5',
  policyTitle: '#3e3e3e',
  policyScript: '#7c7c7c',
  privacyText: '#777777',
  privacyBackground: '#ededed',
  privacyBorder: '#d8d8d8',
  agreeInactiveText: '#909090',
  agreeInactiveBackground: '#f2f2f2',
  agreeActiveText: '#477eb5',
  agreeActiveBackground: '#ddeeff',
};

const forbiddenColor = {
  background: '#f0f0f0',
  titleText: '#036AD1',
  messageText: '#717171',
  btnBackground: '#DDEEFF',
  btnBorder: '#A3D1FF',
  btnText: '#477EB5',
};

const findInfoModalColor = {
  active: '#036ad1',
  inactive: '#a3a3a3',
  label: '#7c7c7c',
  textinput: '#3e3e3e',
  border: '#d8d8d8',
  inactiveBtnBackground: '#ededed',
  inactiveBtnText: '#909090',
  activeBtnBackground: '#ddeeff',
  activeBtnBorder: '#a3d1ff',
  activeBtnText: '#477eb5',
  sentBtnBackground: '#3881dc',
  notificationText: '#399ba7',
  notificationBackground: '#d4f3f7',
};

const mypageColor = {
  name: '#3e3e3e',
  changeProfileBtn: '#ededed',
  specialtyInativeText: '#909090',
  specialtyInactiveBorder: '#dbdbdb',
  specialtyInactiveBackground: '#ebebeb',
  specialtyActiveText: '#399ba7',
  specialtyActiveBorder: '#6bc7d3',
  specialtyActiveBackground: '#d4f3f7',
  editSpecialtyBackground: '#f2f2f2',
  input: '#777777',
  inputBorder: 'rgba(0,0,0,0.15)',
  menuTabText: '#717171',
  labelText: '#7c7c7c',
  textareaBorder: '#d8d8d8',
  activeBtnText: '#477eb5',
  activeBtnBackground: '#ddeeff',
  activeBtnBorder: '#a3d1ff',
  pwAlertText: '#e13237',
  pwAlertBackground: '#ffe0e0',
  careerContainerBackground: '#f5f5f5',
  scrollbar: '#d8d8d8',
  subtitleText: '#a3a3a3',
};

const myListColor = {
  background: '#ffffff',
  nameText: '#3e3e3e',
  hospitalText: '#909090',
  profileBorder: '#ededed',
  dashBoardText: '#477eb5',
};

const modalColors = {
  normalText: '#909090',
  cancelBtnBackground: '#ededed',
  cancelBtnBorder: '#d8d8d8',
  confirmBtnText: '#399ba7',
  confirmBtnBackground: '#d4f3f7',
  confirmBtnBorder: '#6bc7d3',
  cancelReasonInputBackground: '#f0f0f0',
  cancelReasonText: '#777777',
};

const confirmModalColors = {
  background: '#ffffff',
  titleText: '#3e3e3e',
  infoText: '#777777',
  clickAdviseText: '#bdbdbd',
  cancelBtnBorder: '#d8d8d8',
  cancelBtnBackground: '#ededed',
  cancelBtnText: '#909090',
  confirmBtnBorder: '#6bc7d3',
  confirmBtnBackground: '#d4f3f7',
  confirmBtnText: '#399ba7',
};

const treatmentHistoryColors = {
  inactiveText: '#777777',
  inactiveBackground: '#ededed',
  activeText: '#4d86bf',
  activeBackground: '#ddeeff',
  activeBorder: '#a3d1ff',
};

export {
  headerColors,
  callingColors,
  treatmentColors,
  globalColors,
  tableColors,
  appointmentListColors,
  chatColors,
  signInColors,
  signUpColors,
  appointmentHistoryColors,
  paginationColors,
  dropdownListColors,
  searchBarColors,
  calendarColors,
  scheduleColors,
  scheduleModalColors,
  changePasswordModalColors,
  recordingColor,
  forbiddenColor,
  findInfoModalColor,
  mypageColor,
  myListColor,
  modalColors,
  confirmModalColors,
  treatmentHistoryColors,
};
