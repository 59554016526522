import React from 'react';

import styled from '@emotion/styled';

import { device } from 'styles/global/device';
import { ModalHeader, ModalExplanation, ConfirmBtn } from 'styles/modal';

interface BasicModalProps {
  onConfirm: () => void;
  modalExplanation: string;
  modalTitle: string;
}

function BasicModal({ onConfirm, modalExplanation, modalTitle }: BasicModalProps) {
  return (
    <Container>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalExplanation>{modalExplanation}</ModalExplanation>
      <ConfirmBtn size="big" onClick={() => onConfirm()}>
        확인
      </ConfirmBtn>
    </Container>
  );
}

export default BasicModal;

const Container = styled.div`
  padding: 40px 40px 30px 40px;
  border-radius: 5px;
  background-color: white;
  @media ${device.mobileMax} {
    width: 98%;
  }
  h2 {
    margin-top: 8px;
  }

  button {
    margin-left: 14em;
    margin-top: 24px;
  }
`;
