export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;

const AUTH_API_ADDRESS = 'http://gateway.voidoc.io/user/send_verification_code/ ';

export const APPOINTMENT_URL = `https://${API_ADDRESS}/appointment`;
export const USER_URL = `https://${API_ADDRESS}/user`;
export const CHAT_URL = `https://${API_ADDRESS}/chat`;
export const MEDIA_URL = 'https://voidocstorage.blob.core.windows.net/media';
export const SOCKET_URL = 'wss://signaling.voidoc.io:8080/';

export const AUTH_URL = `${AUTH_API_ADDRESS}`;
