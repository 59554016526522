import React from 'react';
import styled from '@emotion/styled';

import { BasicBtn } from 'styles/button';
import { flexCenter } from 'styles/global/mixins';
import { CallIcon } from 'components/atoms';

interface CallButtonProps {
  muted?: boolean;
  onClick: () => void;
  category: 'audio' | 'video' | 'sharing';
}

function CallButton({ muted, onClick, category }: CallButtonProps) {
  return (
    <BtnWrapper muted={muted} onClick={() => onClick()}>
      <CallIcon active={!muted} category={category} muted={muted} />
    </BtnWrapper>
  );
}

export default CallButton;

const BtnWrapper = styled(BasicBtn)<{ muted?: boolean }>`
  ${flexCenter};
  background-color: ${({ muted }) => (muted ? 'var(--point-blue-06)' : 'var(--grayscale-06)')};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin: 0 12px;
`;
