import { call, put } from 'redux-saga/effects';

import { startLoading, finishLoading } from 'redux/modules/loading';

export const createActionTypes = (type) => {
  const REQUEST = `${type}_REQUEST`;
  const SUCCESS = `${type}_SUCCESS`;
  const FAIL = `${type}_FAIL`;
  return [REQUEST, SUCCESS, FAIL];
};

export const createSaga = (originalType, request) => {
  const type = originalType.slice(0, -8);
  const [SUCCESS, FAIL] = [`${type}_SUCCESS`, `${type}_FAIL`];

  return function* saga(action) {
    yield put(startLoading(type));
    try {
      const response = yield call(request, action.payload);

      yield put({
        type: SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      yield put({
        type: FAIL,
        payload: e,
      });
    }

    yield put(finishLoading(type));
  };
};
