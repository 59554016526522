import styled from '@emotion/styled';

import { signUpColors, globalColors } from 'styles/global/color';
import { showBtn, hideBtn } from 'assets/images';

export const BasicBtn = styled.button`
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
`;

export const SubmitBtn = styled(BasicBtn)`
  width: 100%;
  border-radius: 5px;
  border: solid 1px #6bd7d3;
  background-color: #d4f3f7;
  margin-top: 15px;
  font-family: NotoSansRegular;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #399ba7;
  height: 50px;
`;

interface IVisibilityBtn {
  active: boolean;
}

export const VisibilityBtn = styled.button<IVisibilityBtn>`
  position: absolute;
  top: 12.5px;
  right: 16px;
  height: 30px;
  width: 30px;
  border: none;
  background-color: transparent;
  background-image: ${(props) => (props.active ? `url(${showBtn})` : `url(${hideBtn})`)};
  background-size: 30px;
`;

export const SignUpBtn = styled(BasicBtn)`
  margin-top: 8px;
  margin-left: 16px;
  border: solid 1px ${signUpColors.authBtnBorder};
  border-radius: 5px;
  width: 132px;
  height: 40px;
  color: ${signUpColors.inactiveAuthBtnText};
  background-color: ${signUpColors.inactiveAuthBtnBackground};
  font-size: 14px;
  font-weight: 500;
  text-align: center;
`;

interface NextBtnProps {
  active: boolean;
}

export const NextBtn = styled(BasicBtn)<NextBtnProps>`
  width: 122px;
  height: 40px;
  ${(props) => ` border: 1px solid
    ${props.active ? signUpColors.activeBorder : signUpColors.nextBtnBorder};`}
  border-radius: 3px;
  background-color: ${(props) => (props.active ? signUpColors.activeBackground : signUpColors.nextBtnBackground)};
  margin-top: 58px;
  margin-bottom: 70px;
  color: ${(props) => (props.active ? signUpColors.activeText : signUpColors.inactiveText)};
  position: absolute;
  right: 0;
`;

export const PrevBtn = styled(BasicBtn)`
  width: 122px;
  height: 40px;
  border: 1px solid ${signUpColors.nextBtnBorder};
  border-radius: 3px;
  background-color: ${signUpColors.nextBtnBackground};
  margin-top: 58px;
  margin-bottom: 70px;
  color: ${signUpColors.inactiveText};
  position: absolute;
  left: 0;
`;

export const CancelBtn = styled(BasicBtn)`
  font-family: NotoSansRegular;
  font-weight: normal;
  color: ${globalColors.cancelBtnText};
  background-color: ${globalColors.cancelBtnBackground};
  border: 1px solid ${globalColors.cancelBtnBorder};
  &:hover {
    color: ${globalColors.messageText};
    background-color: ${globalColors.messageBackground};
    border: 1px solid ${globalColors.messageBorder};
  }
`;

export const EditBtn = styled(BasicBtn)`
  font-family: NotoSansRegular;
  font-size: 13px;
  color: ${globalColors.editBtnText};
  background-color: ${globalColors.editBtnBackground};
  border: 1px solid ${globalColors.editBtnBorder};
  padding: 5px 0;
  border-radius: 3px;
  text-align: center;
`;

export const CompleteButton = styled(BasicBtn)`
  font-family: NotoSansRegular;
  font-size: 13px;
  color: white;
  background-color: ${globalColors.main};
  padding: 5px 0;
  border-radius: 3px;
  text-align: center;
`;

export const Icon = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
`;

export const EndCallIcon = styled(Icon)`
  width: 22.5px;
  height: 10.7px;
  background-image: url(/icons/ic-end-call.svg);
`;
