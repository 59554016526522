import React, { useState } from 'react';

import styled from '@emotion/styled';

import { useModal } from 'hooks';

import { signUpColors } from 'styles/global/color';
import { SignUpBtn } from 'styles/button';
import { NormalInput } from 'styles/textinput';
import { addBtn } from 'assets/images';

function DoctorInfo() {
  const { openModal } = useModal();

  const [imgBase64, setImgBase64] = useState('');

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result;
      if (base64) {
        setImgBase64(base64.toString());
      }
    };
    if (e.target.files[0]) {
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSelectDeptModal = () => {
    openModal({
      modalType: 'SelectDeptModal',
      modalProps: {},
    });
  };

  return (
    <DoctorInfoContainer>
      <p>사진등록</p>
      <UploadPhotoWrapper>
        {imgBase64 && (
          <Preview>
            <img alt="imagepreview" src={imgBase64} />
          </Preview>
        )}
        <Uploader
          accept="image/bmp,image/gif,image/jpeg,image/png,image/tiff,image/webp"
          type="file"
          onChange={(e) => handleFileChange(e)}
        />
      </UploadPhotoWrapper>
      <p>소속 병원</p>
      <DoctorInfoInput id="hospital" placeholder="소속 병원 이름을 입력해주세요" />
      <p>진료 과</p>
      <InputWrapper>
        <DoctorInfoInput id="department" placeholder="진료 과를 선택해주세요" />
        <SelectDepartment onClick={() => handleSelectDeptModal()}>진료과 선택</SelectDepartment>
      </InputWrapper>
      <p>의사 면허번호</p>
      <DoctorInfoInput id="certificationNo" placeholder="의사 면허번호를 입력해주세요" />
      {/* <PrevBtn>이전</PrevBtn>
        <NextBtn>다음</NextBtn> */}
    </DoctorInfoContainer>
  );
}

export default DoctorInfo;

const DoctorInfoContainer = styled.div`
  margin-top: 80px;
  p {
    display: inline-block;
    margin-bottom: 3px;
    font-size: 15px;
    line-height: 22px;
    color: ${signUpColors.useTermsContent};
  }
`;

const UploadPhotoWrapper = styled.div`
  position: relative;
  width: 90px;
  height: 90px;
  background-image: url(${addBtn});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  background-color: ${signUpColors.photoUploaderBackground};
  border: 1px solid ${signUpColors.photoUploaderBorder};
  border-radius: 3px;
  margin-bottom: 30px;
  margin-top: 8px;
`;

const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 3px;
  }
`;

const Uploader = styled.input`
  opacity: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  display: flex;
`;

const SelectDepartment = styled(SignUpBtn)``;

const DoctorInfoInput = styled(NormalInput)`
  height: 40px;
  width: 100%;
  &::placeholder {
    font-size: 14px;
  }
`;
