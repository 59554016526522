import React from 'react';

import styled from '@emotion/styled';

import { tableColors } from 'styles/global/color';
import { Table } from 'styles/table';

function ConcisePerscriptionRecordTable() {
  return (
    <PerscriptionTable>
      <thead>
        <tr>
          <th>처방코드</th>
          <th>
            <HeadColumn>명칭</HeadColumn>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>272B</td>
          <td>
            <BodyColumn>아토르바정 10mg(유한양행)</BodyColumn>
          </td>
        </tr>
        <tr>
          <td>JTASTR</td>
          <td>
            <BodyColumn>아토르바정 10mg(유한양행)</BodyColumn>
          </td>
        </tr>
      </tbody>
    </PerscriptionTable>
  );
}

export default ConcisePerscriptionRecordTable;

const PerscriptionTable = styled(Table)`
  margin-top: 24px;
  border-spacing: 0 5px;

  thead tr th {
    background-color: ${tableColors.headBackground};
    color: ${tableColors.headText};
  }

  tr th {
    padding: 9px 0 9px;
  }

  tbody tr td {
    padding: 12px 0 12px;
    font-family: NotoSansRegular;
    font-size: 15px;
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0px;
    text-indent: -99999px;
    width: 100%;
  }

  tbody tr {
    background-color: ${tableColors.bodyBackground};
    color: ${tableColors.bodyText};
  }
`;

const BodyColumn = styled.div`
  border-left: 1px solid ${tableColors.bodyBorder};
`;

const HeadColumn = styled.div`
  border-left: 1px solid ${tableColors.headBorder};
`;
