import React, { useState, useEffect, useRef } from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Spinner, Image } from 'components/atoms';

import { postLogin, getUserInfo } from 'redux/modules/authentication';

import { useForm, useModal } from 'hooks';

import { signInColors } from 'styles/global/color';
import { BasicBtn, VisibilityBtn } from 'styles/button';
import { device } from 'styles/global/device';
import { alertIcon, wave } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IRootState } from 'types/payloadTypes';

const userInput = {
  username: '',
  password: '',
  is_doctor: true,
};

interface LoginProps extends RouteComponentProps {}

function SignIn({ history }: LoginProps) {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const [form, onFormChange] = useForm(userInput);

  const [pwVisibility, setPwvisibility] = useState(false);
  const [resetInputfield, setResetInputfield] = useState(false);
  const requestSent = useRef(false);
  const [loading, setLoading] = useState(true);

  const {
    signInStatus,
    userInfo: { isInitialLogin },
    signInRequest,
    changePasswordPeriodStatus,
    signInMsg,
    passwordChanged,
  } = useSelector((state: IRootState) => state.authentication);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      handleSignin();
    }
  };

  const handleSignin = () => {
    setLoading(false);
    const loadingTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      dispatch(
        postLogin({
          username: form.username,
          password: form.password,
        }),
      );
      requestSent.current = true;
      setLoading(true);
    }, 1300);
    return () => clearTimeout(loadingTimeout);
  };

  const handleInputReset = () => {
    if (signInStatus === 400) {
      setResetInputfield(false);
    }
  };

  const routeToHome = () => {
    history.push('./home');
  };

  const handleFindInfoModal = () => {
    openModal({
      modalType: 'FindInfoModal',
      modalProps: {},
    });
  };

  const toggleVisibility = () => {
    setPwvisibility(!pwVisibility);
  };

  useEffect(
    () => {
      if (requestSent.current) {
        const checkAuthentication = () => {
          if (signInStatus === 200) {
            setResetInputfield(false);
            if (changePasswordPeriodStatus) {
              openModal({
                modalType: 'ChangePasswordRequestModal',
                modalProps: {
                  currentPassword: form.password,
                  title: `3개월 이상 변경되지 않은 비밀번호는 도용 위험에 노출될 수 있습니다.
                    개인정보 도용을 사전에 예방할 수 있도록 빠른 시일 내에 비밀번호를 변경해주세요.`,
                },
              });
            } else if (isInitialLogin) {
              openModal({
                modalType: 'ChangePasswordRequestModal',
                modalProps: {
                  currentPassword: form.password,
                  title: '최초 로그인 시 비밀번호 변경이 필요합니다',
                },
              });
            } else {
              dispatch(getUserInfo());
              routeToHome();
            }
          } else {
            setResetInputfield(true);
          }
        };
        checkAuthentication();
      }
    },
    // eslint-disable-next-line
    [signInRequest],
  );

  useEffect(() => {
    if (passwordChanged) {
      routeToHome();
      dispatch(getUserInfo());
    }
    // eslint-disable-next-line
  }, [passwordChanged]);

  return (
    <SignInContainer>
      <Logo>
        <Image alt="logo" src="icons/ic-logo.svg" />
      </Logo>
      <InputSection onKeyUp={(e) => handleKeyPress(e)}>
        <Label error={requestSent.current && resetInputfield} htmlFor="emailInput">
          아이디
          <InfoInput
            error={requestSent.current && resetInputfield}
            id="emailInput"
            name="username"
            placeholder="아이디를 입력해주세요"
            type="text"
            value={form.username}
            onChange={onFormChange}
            onClick={() => handleInputReset()}
          />
        </Label>
        <Label error={requestSent.current && resetInputfield} htmlFor="pwInput">
          비밀번호
          <PwInputWrapper>
            <InfoInput
              error={requestSent.current && resetInputfield}
              id="pwInput"
              name="password"
              placeholder="비밀번호를 입력해주세요"
              type={pwVisibility ? 'text' : 'password'}
              value={form.password}
              onChange={onFormChange}
              onClick={() => handleInputReset()}
            />
            <SetVisibilityBtn active={pwVisibility} name="pwVisibility" onClick={() => toggleVisibility()} />
          </PwInputWrapper>
        </Label>
        {requestSent.current && resetInputfield && (
          <PwAlertWrapper>
            <AlertIcon src={alertIcon} />
            <PwAlert>{signInMsg}</PwAlert>
          </PwAlertWrapper>
        )}
        <BtnWrapper>
          <Button color="darkBlue" size="full" onClick={() => handleSignin()}>
            {loading ? '로그인' : <Spinner isCircle />}
          </Button>
          {/* <SignInBtn>{loading ? "로그인" : <Spinner isCircle/>}</SignInBtn> */}
          {/* <SignUpBtn disabled>회원가입</SignUpBtn> */}
        </BtnWrapper>
        <FindInfoBtn onClick={() => handleFindInfoModal()}>아이디/비밀번호 찾기</FindInfoBtn>
      </InputSection>
    </SignInContainer>
  );
}

export default SignIn;

interface IAlertError {
  error: boolean;
}

const SignInContainer = styled.div`
  ${flexColumn};
  justify-content: center;
  align-items: center;
  background-color: ${signInColors.loginBackground};
  height: 100vh;
  font-family: NotoSansRegular;
  background-image: url(${wave});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  @media ${device.mobileMax} {
    flex-direction: column;
  }
`;

const Logo = styled.div`
  ${flexCenter};
  width: 170px;
  height: 34.5px;
`;

const InputSection = styled.div`
  background-color: white;
  width: 510px;
  min-height: 404px;
  margin-top: 40px;
  border-radius: 5px;
  box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.05);
  padding: 80px 55px 50px;

  a {
    text-decoration: none;
    display: block;
    color: ${signInColors.title};
    font-size: 17px;
    font-weight: 500;
    text-align: right;
    margin-top: 15px;
  }
  @media ${device.mobileMax} {
    padding: 56px 25px;
    width: 100%;
  }
`;

const Label = styled.label<IAlertError>`
  display: block;
  color: ${(props) => (props.error ? signInColors.pwAlertText : signInColors.header)};
  font-size: 13px;
  line-height: 19px;
  text-align: left;
  font-weight: 700;
  margin-bottom: 4px;
  &:nth-of-type(2) {
    margin-top: 24px;
  }
`;

const PwInputWrapper = styled.div`
  position: relative;
`;

const InfoInput = styled.input<IAlertError>`
  color: ${signInColors.text};
  font-family: NotoSansRegular;
  font-size: 16px;
  width: 100%;
  padding-bottom: 8px;
  padding-left: 0;
  outline: none;
  border: none;
  border-bottom: 1px solid ${(props) => (props.error ? signInColors.pwAlertText : signInColors.inactive)};
  ::placeholder {
    opacity: 0.5;
  }
  &:focus {
    border-bottom: 1px solid ${signInColors.active};
  }
`;

const SetVisibilityBtn = styled(VisibilityBtn)`
  top: -7px;
  right: 0;
`;

const PwAlertWrapper = styled.div`
  display: flex;
  background-color: ${signInColors.pwAlertBackground};
  align-items: center;
  margin-bottom: 30px;
`;

const AlertIcon = styled.img`
  width: 19px;
  height: 19px;
  margin: 0 3px;
`;

const PwAlert = styled.div`
  color: ${signInColors.pwAlertText};
  padding-left: 6px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 70px;
  @media ${device.mobileMax} {
    margin-top: 40px;
  }
`;

// const SignUpBtn = styled(SignInBtn)`
//   margin-left: 10px;
//   border: solid 1px ${signInColors.title};
//   background-color: ${signInColors.signInText};
//   color: ${signInColors.title};
//   @media ${device.mobileMax} {
//     margin-left: 6px;
//   }
// `;

const FindInfoBtn = styled(BasicBtn)`
  font-family: NotoSansMedium;
  font-size: 14px;
  color: ${signInColors.findInfo};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
  background: none;
  @media ${device.mobileMax} {
    font-size: 14px;
  }
`;
