import React from 'react';

import styled from '@emotion/styled';

import { DateFilter, SearchBar, Calendar, DropdownList, Checkbox } from 'components/molecules';

import { CenterWrap } from 'styles/container';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';
import { IDoctor } from 'types/payloadTypes';

type Clicked = 'initial' | 'department' | 'doctor' | 'patientName' | 'date' | 'status' | 'district' | 'isEmergency';

interface FilterOptions {
  date: string;
  patientName: string;
  doctor: number | string;
  department: string;
  clicked: Clicked;
  status: number | string;
  offset: number | string;
  currentPage: number;
}

interface SearchFilterProps {
  filterOption: FilterOptions;
  mineOnly: boolean;
  onOptionChange: (e, target: Clicked, option: string | number) => void;
  onOptionClick: (option: string) => void;
  onMine: () => void;
  departmentArray: Array<string>;
  doctorList: Array<IDoctor>;
  doctorArray: Array<string>;
}

function SearchFilter({
  filterOption,
  mineOnly,
  onOptionChange,
  onOptionClick,
  onMine,
  departmentArray,
  doctorList,
  doctorArray,
}: SearchFilterProps) {
  const getDoctorName = () => {
    const targetDoctor = doctorList.find((doctor) => doctor.doctorId === filterOption.doctor);
    if (targetDoctor) {
      return targetDoctor.fullName;
    }
  };

  return (
    <>
      <FilterWrap>
        <DropdownList
          active={filterOption.clicked === 'department' || filterOption.department !== ''}
          disabled={mineOnly}
          height={344}
          optionArray={departmentArray}
          selected={filterOption.department !== ''}
          showOptions={filterOption.clicked === 'department'}
          title={filterOption.department === '' ? '전체 상담 과' : filterOption.department}
          width={200}
          onActivate={() => onOptionClick('department')}
          onCancel={(e) => onOptionChange(e, 'department', '')}
          onSelect={(e, option: string) => onOptionChange(e, 'department', option)}
        />
        <DropdownList
          active={filterOption.clicked === 'doctor' || (!!filterOption.doctor && filterOption.doctor !== '')}
          disabled={mineOnly}
          optionArray={doctorArray}
          selected={!!filterOption.doctor && filterOption.doctor !== ''}
          showOptions={filterOption.clicked === 'doctor'}
          title={!filterOption.doctor || filterOption.doctor === '' || mineOnly ? '의료진' : getDoctorName()}
          width={190}
          onActivate={() => onOptionClick('doctor')}
          onCancel={(e) => onOptionChange(e, 'doctor', '')}
          onSelect={(e, option: string) => onOptionChange(e, 'doctor', option)}
        />
        <DateFilterWrap>
          <DateFilter
            date={filterOption.date}
            status={filterOption.date === ''}
            onCancel={(e) => onOptionChange(e, 'date', '')}
            onFilterClick={() => onOptionClick('date')}
          />
          {filterOption.clicked === 'date' && (
            <CalendarWrap>
              <Calendar handleDateChange={(e, value) => onOptionChange(value, 'date', e)} parent="AppointmentHistory" />
            </CalendarWrap>
          )}
        </DateFilterWrap>
        <SearchInputWrap active={filterOption.clicked === 'patientName' || filterOption.patientName !== ''}>
          <SearchBar
            active={filterOption.clicked === 'patientName' || filterOption.patientName !== ''}
            name="patientName"
            placeholder="환자 이름을 입력하세요"
            onInputChange={(e) => onOptionChange(e, 'patientName', e.target.value)}
            onSearch={() => onOptionClick('patientName')}
          />
        </SearchInputWrap>
        <CheckboxWrap active={mineOnly}>
          <Checkbox checked={mineOnly} onCheck={onMine} />
          <MineOnlyText active={mineOnly}>내 예약 리스트만 보기</MineOnlyText>
        </CheckboxWrap>
      </FilterWrap>
      <StatusBtnContainer>
        <StatusBtnWrap>
          <StatusBtn active={filterOption.status === ''} onClick={(e) => onOptionChange(e, 'status', '')}>
            전체상담
          </StatusBtn>
          <StatusBtn active={filterOption.status === 2} onClick={(e) => onOptionChange(e, 'status', 2)}>
            상담완료
          </StatusBtn>
          <StatusBtn active={filterOption.status === 0} onClick={(e) => onOptionChange(e, 'status', 0)}>
            상담대기
          </StatusBtn>
        </StatusBtnWrap>
      </StatusBtnContainer>
    </>
  );
}

export default SearchFilter;

interface SearchButtonProps {
  active?: boolean;
  marginRight?: number;
  status?: number;
  width?: number;
}

const colors = {
  tableHeadText: '#bdbdbd',
  btnText: '#4d86bf',
  requestApprovedBtnBorder: '#a3d1ff',
  inactiveText: '#909090',
  activeText: '#777777',
  selected: '#036ad1',
  placeholder: '#3e3e3e',
  inactiveBorder: '#e5e5e5',
  completeBorder: '#a3d1ff',
  completeText: '#477eb5',
  onWaitBorder: '#d8d8d8',
  calendarBorder: '#f0f0f0',
};

const CalendarWrap = styled.div`
  position: absolute;
  flex: 1;
  display: flex;
  top: 60px;
  left: 0;
  padding: 50px 20px;
  border: 1px solid ${colors.calendarBorder};
  width: 372px;
  background-color: white;
  z-index: 1;
`;

const StatusBtnContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  margin-top: 35px;
`;

const FilterWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StatusBtnWrap = styled.div`
  display: flex;
`;

const DateFilterWrap = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 3px;
  font-family: NotoSansMedium;
  border: 1px solid ${colors.inactiveBorder};
  margin-right: 10px;
`;

const SearchOptionWrap = styled.span<SearchButtonProps>`
  ${(props) => `
    height: 42px;
    color: ${props.active ? colors.selected : colors.inactiveText};
    font-family: NotoSansMedium;
    font-size: 13px;
    background-color: white;
    border-radius: 3px;
    padding: 13px 20px;
    border: 1px solid ${props.active ? colors.selected : colors.inactiveBorder};
    outline: none;
    cursor: pointer;

    `}
`;

const SearchInputWrap = styled(SearchOptionWrap.withComponent('div'))<SearchButtonProps>`
  display: flex;
  font-family: NotoSansMedium;
  font-size: 13px;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  margin-right: 10px;
  // width: 21%;
  min-width: 300px;
  max-width: 408px;
`;

const StatusBtn = styled(BasicBtn)<IActive>`
  height: 41px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  padding: 11px 16px;
  color: ${(props) => (props.active ? colors.btnText : colors.tableHeadText)};
  border: none;
  border-bottom: 1px solid ${(props) => (props.active ? colors.requestApprovedBtnBorder : 'none')};
  background-color: #f4f4f4;
  z-index: ${(props) => (props.active ? 2 : 1)};
`;

const CheckboxWrap = styled(CenterWrap)<IActive>`
  width: 173px;
  height: 42px;
  margin-right: 10px;
  background-color: white;
  border-radius: 3px;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 13px;
  border: 1px solid ${(props) => (props.active ? colors.completeBorder : colors.onWaitBorder)};
`;

const MineOnlyText = styled.span<IActive>`
  margin-top: -1px;
  font-family: NotoSansMedium;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => (props.active ? colors.completeText : colors.activeText)};
`;
