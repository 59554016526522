import React, { useEffect, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AgoraRTC, { IAgoraRTCClient, IRemoteAudioTrack } from 'agora-rtc-sdk-ng';
import styled from '@emotion/styled';
import moment from 'moment';

import TreatmentChart from 'pages/Treatment/MedicalRecord';
import Calling from './Calling';
import { MediaPlayer } from 'components/atoms';
import { PatientInformation } from 'components/organisms';

import {
  getAppointmentDetail,
  patchFinishTreatment,
  getDiagnosis,
  getPrescriptionInfo,
  getDistrictList,
  patchDoctorEntered,
  patchInvitePatient,
  initializeAppointmentState,
  postAgoraRTCToken,
} from 'redux/modules/appointment';

import { getParameter } from 'utils';
import { useAgora } from 'hooks';

import * as mixins from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IRootState } from 'types/payloadTypes';

const agoraClient: IAgoraRTCClient = AgoraRTC.createClient({
  codec: 'vp8',
  mode: 'rtc',
});

AgoraRTC.setLogLevel(4);

function TreatmentMobile() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    localAudioTrack,
    localScreenTrack,
    localVideoTrack,
    leave,
    join,
    joinState,
    shareScreen,
    stopScreenShare,
    remoteUsers,
  } = useAgora(agoraClient);

  const appointmentIdRef = useRef(0);

  const [selectedTab, setSelectedTab] = useState(1);
  const [patientAudio, setPatientAudio] = useState<IRemoteAudioTrack | undefined>(undefined);

  const { doctorId, fullname } = useSelector((state: IRootState) => state.authentication.userInfo);
  const { appointment, roomToken } = useSelector((state: IRootState) => state.appointment.appointmentDetail);
  const { finishTreatmentSuccess, invitePatientSuccess, medicalRecord, medicalRecordSaved } = useSelector(
    (state: IRootState) => state.appointment,
  );

  const { id, subDoctors, departmentName } = appointment;

  const endCall = () => {
    leave();
    dispatch(patchFinishTreatment({ appointmentId: appointmentIdRef.current }));
    history.push('/home');
  };
  const handleTabSelect = (tab: number) => {
    if (tab === selectedTab) return;
    setSelectedTab(tab);
  };

  const invitePatient = () => {
    dispatch(patchInvitePatient({ appointmentId: appointmentIdRef.current }));
  };

  useEffect(() => {
    if (invitePatientSuccess) {
      alert('환자에게 입장 요청을 보냈습니다');
      dispatch(initializeAppointmentState());
    }
    // eslint-disable-next-line
  }, [invitePatientSuccess]);

  useEffect(() => {
    if (medicalRecordSaved) {
      alert('진료기록이 저장되었습니다');
      dispatch(initializeAppointmentState());
    }
    // eslint-disable-next-line
  }, [medicalRecordSaved]);

  useEffect(() => {
    if (finishTreatmentSuccess) {
      dispatch(initializeAppointmentState());
      window.close();
    }
    // eslint-disable-next-line
  }, [finishTreatmentSuccess]);

  useEffect(() => {
    const appointmentId = getParameter('appointmentId');
    appointmentIdRef.current = parseInt(appointmentId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getDistrictList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAppointmentDetail({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getPrescriptionInfo({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getDiagnosis({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(postAgoraRTCToken(appointmentIdRef.current));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      patchDoctorEntered({
        appointmentId: appointmentIdRef.current,
        hasDoctorEntered: true,
        startedAt: moment().format('YYYY-MM-DD hh:mm'),
      }),
    );

    return () => {
      dispatch(
        patchDoctorEntered({
          appointmentId: appointmentIdRef.current,
          hasDoctorEntered: false,
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onbeforeunload = async () => {
      await dispatch(
        patchDoctorEntered({
          appointmentId: appointmentIdRef.current,
          hasDoctorEntered: false,
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const patient = remoteUsers?.find((user) => user.uid === 9999);
    if (!patient) return;
    setPatientAudio(patient.audioTrack);
  }, [remoteUsers]);

  return (
    <TreatmentContainer>
      <TreatmentSection>
        {selectedTab === 1 && (
          <Calling
            appointmentId={id}
            departmentName={departmentName}
            doctorId={doctorId}
            doctorName={fullname}
            join={join}
            joinState={joinState}
            leave={leave}
            localAudioTrack={localAudioTrack}
            localScreenTrack={localScreenTrack}
            localVideoTrack={localVideoTrack}
            remoteUsers={remoteUsers}
            roomToken={roomToken}
            subDoctorArray={subDoctors}
            onInvite={invitePatient}
            onStart={shareScreen}
            onStop={stopScreenShare}
          />
        )}
        {selectedTab === 2 && (
          <PatientInfoWrap>
            <PatientInformation appointmentId={id} parent="Treatment" />
          </PatientInfoWrap>
        )}
        {selectedTab === 3 && <TreatmentChart medicalRecord={medicalRecord} onEnd={endCall} />}
        {selectedTab !== 1 && (
          <PatientAudioWrap>
            <MediaPlayer audioTrack={patientAudio} id="patientAudio" videoTrack={undefined} />
          </PatientAudioWrap>
        )}
      </TreatmentSection>
      <BottomTab>
        <TabBtn clicked={selectedTab === 1} onClick={() => handleTabSelect(1)}>
          영상
        </TabBtn>
        <TabBtn clicked={selectedTab === 2} onClick={() => handleTabSelect(2)}>
          문진표
        </TabBtn>
        <TabBtn clicked={selectedTab === 3} onClick={() => handleTabSelect(3)}>
          차트
        </TabBtn>
      </BottomTab>
    </TreatmentContainer>
  );
}

export default TreatmentMobile;

const PatientAudioWrap = styled.span`
  visibility: hidden;
  z-index: -3;
`;

const PatientInfoWrap = styled.div`
  padding-top: 40px;
`;

const TreatmentContainer = styled.div`
  ${mixins.flexColumn}
  justify-content: space-between;
  overflow: hidden;
`;

const TreatmentSection = styled.section`
  height: calc(100vh - 40px);
  background-color: white;
`;

const BottomTab = styled.section`
  ${mixins.flexRow}
  width: 100%;
  border-top: 1px solid #036ad1;
  margin: 0;
  > button:nth-of-type(2) {
    border-left: 1px solid #036ad1;
    border-right: 1px solid #036ad1;
  }
`;

const TabBtn = styled(BasicBtn)<{ clicked: boolean }>`
  width: 100%;
  height: 40px;
  font-size: 24px;
  background-color: ${({ clicked }) => (clicked ? '#036ad1' : 'white')};
  color: ${({ clicked }) => (clicked ? 'white' : '#036ad1')};
`;
