import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { patchChangePassword } from 'redux/modules/authentication';

import { Image } from 'components/atoms';

import { useForm } from 'hooks';

import { BasicBtn, EditBtn } from 'styles/button';
import { mypageColor, globalColors } from 'styles/global/color';
import { SectionLabel } from 'styles/text';
import { flexCenter } from 'styles/global/mixins';
import { showBtn, hideBtn, alertIcon } from 'assets/images';

import { IRootState } from 'types/payloadTypes';
import { IActive } from 'types/styleTypes';

interface PasswordProps {
  onConfirm: () => void;
}

function Password({ onConfirm }: PasswordProps) {
  const dispatch = useDispatch();

  const [form, onFormChange] = useForm({
    currentPassword: '',
    password: '',
    confirmPassword: '',
  });

  const [coincidePw, setCoincidePw] = useState(true);
  const [pwValidation, setPwValidation] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState('');
  const [formFullfilled, setFormFullfilled] = useState(false);

  const { error } = useSelector((state: IRootState) => state.authentication);

  const handlePasswordChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(patchChangePassword(form));
    onConfirm();
  };

  const formCheck = () => {
    if (form.currentPassword && form.password && form.confirmPassword) {
      setFormFullfilled(true);
    } else {
      setFormFullfilled(false);
    }
  };

  const handlePwValidation = () => {
    const special = /\w\d/;
    if (!form.password) {
      setPwValidation(true);
    } else if (!special.test(form.password) || form.password.length <= 7) {
      setPwValidation(false);
    } else {
      setPwValidation(true);
    }
  };

  const handlePwCheck = () => {
    if (!form.password || !form.confirmPassword) {
      setCoincidePw(true);
    }
    if (form.password !== form.confirmPassword) {
      setCoincidePw(false);
    } else {
      setCoincidePw(true);
    }
  };

  const togglePasswordVisible = (target: string) => {
    if (!passwordVisible || passwordVisible !== target) {
      setPasswordVisible(target);
      return;
    }
    setPasswordVisible('');
  };

  useEffect(() => {
    handlePwCheck();
    formCheck();
    // eslint-disable-next-line
  }, [form.password, form.confirmPassword, form.currentPassword]);

  return (
    <InputSection>
      <SectionLabel htmlFor="currentPassword">기존 비밀번호</SectionLabel>
      <MypageInputWrap>
        <MypageInput
          id="currentPassword"
          name="currentPassword"
          placeholder="현재 비밀번호를 입력해주세요"
          type={passwordVisible === 'currentPassword' ? 'text' : 'password'}
          onChange={onFormChange}
        />
        <ToggleVisibilityBtn type="button" onClick={() => togglePasswordVisible('currentPassword')}>
          <Image alt="visible-password" src={passwordVisible === 'currentPassword' ? hideBtn : showBtn} />
        </ToggleVisibilityBtn>
      </MypageInputWrap>
      {error && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>기존 비밀번호가 일치하지 않습니다</PwAlert>
        </PwAlertWrapper>
      )}
      <SectionLabel htmlFor="password">비밀번호</SectionLabel>
      <MypageInputWrap>
        <MypageInput
          id="password"
          name="password"
          placeholder="비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다"
          type={passwordVisible === 'password' ? 'text' : 'password'}
          onChange={onFormChange}
        />
        <ToggleVisibilityBtn type="button" onClick={() => togglePasswordVisible('password')}>
          <Image alt="visible-password" src={passwordVisible === 'password' ? hideBtn : showBtn} />
        </ToggleVisibilityBtn>
      </MypageInputWrap>
      {!pwValidation && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다</PwAlert>
        </PwAlertWrapper>
      )}
      <SectionLabel htmlFor="confirmPassword">비밀번호 확인</SectionLabel>
      <MypageInputWrap>
        <MypageInput
          id="confirmPassword"
          name="confirmPassword"
          placeholder="비밀번호를 다시 입력하세요"
          type={passwordVisible === 'confirmPassword' ? 'text' : 'password'}
          onChange={onFormChange}
          onKeyUp={handlePwValidation}
        />
        <ToggleVisibilityBtn type="button" onClick={() => togglePasswordVisible('confirmPassword')}>
          <Image alt="visible-password" src={passwordVisible === 'confirmPassword' ? hideBtn : showBtn} />
        </ToggleVisibilityBtn>
      </MypageInputWrap>
      {!coincidePw && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>비밀번호가 일치하지 않습니다</PwAlert>
        </PwAlertWrapper>
      )}
      <ModifyPasswordBtn
        active={formFullfilled && pwValidation && coincidePw}
        disabled={!formFullfilled || !pwValidation || !coincidePw}
        onClick={(e) => handlePasswordChange(e)}
      >
        비밀번호 수정하기
      </ModifyPasswordBtn>
    </InputSection>
  );
}

export default Password;

const InputSection = styled.form`
  padding: 58px 32px 56px 32px;
  height: 100%;
`;

const MypageInputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const MypageInput = styled.input`
  border: 1px solid ${mypageColor.textareaBorder};
  border-radius: 5px;
  height: 40px;
  padding: 0 16px;
  margin-top: -6px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 16px;
  color: ${mypageColor.input};
  width: 100%;
  background: none;
  ::placeholder {
    opacity: 0.5;
    font-size: 12px;
  }
  :focus {
    border: 1px solid ${globalColors.main};
    background-color: transparent;
  }
`;

const ToggleVisibilityBtn = styled(BasicBtn)`
  ${flexCenter};
  position: absolute;
  top: -1px;
  right: 8px;
  width: 30px;
  height: 30px;
`;

const PwAlertWrapper = styled.div`
  height: 24px;
  display: flex;
  background-color: ${mypageColor.pwAlertBackground};
  align-items: center;
  margin-top: -22px;
  margin-bottom: 30px;
`;

const AlertIcon = styled.img`
  width: 19px;
  height: 19px;
  margin: 0 3px;
`;

const PwAlert = styled.div`
  color: ${mypageColor.pwAlertText};
  padding-left: 6px;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

const ModifyPasswordBtn = styled(EditBtn)<IActive>`
  width: 100%;
  height: 56px;
  font-size: 16px;
  margin-top: 72px;
  ${(props) =>
    `color : ${props.active ? mypageColor.activeBtnText : mypageColor.specialtyInativeText};
    background-color : ${props.active ? mypageColor.activeBtnBackground : mypageColor.changeProfileBtn};
    border : 1px solid ${props.active ? mypageColor.activeBtnBorder : mypageColor.textareaBorder}`}
`;
