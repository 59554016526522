import styled from '@emotion/styled';
import { globalColors, mypageColor } from 'styles/global/color';
import { MarginProps, PaddingProps, FontProps } from 'types/styleTypes';
import { device } from 'styles/global/device';

interface IText extends MarginProps, PaddingProps, FontProps {}

export const MainPageTitle = styled.h1<IText>`
  font-family: NotoSansRegular;
  font-size: 25px;
  @media ${device.mobileMax} {
    font-size: 18px;
  }
  font-weight: normal;
  color: ${globalColors.name};
  text-align: left;
  margin: 0;
  display: inline;
  margin: ${(props) => props.margin};
`;

export const H1 = styled.h1<IText>`
  font-family: NotoSansMedium;
  font-size: 20px;
  font-weight: 500;
  margin: ${(props) => props.margin || 0};
  color: ${globalColors.sub};
  @media ${device.laptopMax} {
    font-size: 16px;
  }
`;

export const H2 = styled.h2<IText>`
  font-family: NotoSansRegular;
  font-size: ${(props) => props.fontSize || 15}px;
  font-weight: normal;
  margin: ${(props) => props.margin || 0};
  color: ${globalColors.detail};
  @media ${device.laptopMax} {
    font-size: 16px;
  }
`;

export const H3 = styled.h3<IText>`
  font-family: NotoSansMedium;
  font-size: 18px;
  font-weight: 500;
  color: #777777;
  padding-bottom: 8.5px;
  margin: 0;
  @media ${device.laptopMax} {
    font-size: 14px;
    color: ${globalColors.h3Text};
    background-color: ${globalColors.h3Background};
    width: 100%;
    line-height: 28px;
    padding-bottom: 0;
    padding-left: 4px;
  }
  @media ${device.tabletMax} {
    background-color: transparent;
  }
`;

export const H4 = styled.h4<IText>`
  padding: 6.5px 0 6.5px 10px;
  margin: 30.5px 0px -6px;
  font-family: NotoSansMedium;
  font-size: 18px;
  font-weight: 500;
  color: #777777;
  background-color: #ededed;
  z-index: 3;
  @media ${device.laptopMax} {
    font-size: 14px;
    font-weight: normal;
    line-height: 16px;
    margin: 10px 0 0;
  }
`;

export const H5 = styled.h5<IText>`
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  color: #4d86bf;
  margin: 30px 0 0;
  @media ${device.laptopMax} {
    margin: 16px 0 5px;
    line-height: 24px;
    font-size: 14px;
  }
`;

export const H6 = styled.h6<IText>`
  font-family: NotoSansRegular;
  font-size: 18px;
  font-weight: normal;
  color: #777777;
  margin: 10px 0 0;
  //white-space: initial;
  width: 100%;
  word-wrap: break-word;
  @media ${device.laptopMax} {
    font-size: 14px;
    line-height: 16px;
    margin: 0;
  }
`;

export const SectionLabel = styled.label`
  font-family: NotoSansMedium;
  display: block;
  text-align: left;
  font-weight: 500;
  color: ${mypageColor.labelText};
  margin-bottom: 16px;
`;
