import React from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { globalColors } from 'styles/global/color';
import { calendarIcon, calendarIconInactive, resetFilterBtn } from 'assets/images';
import { flexCenter } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

interface DateFilterProps {
  date: string;
  status: boolean;
  onFilterClick?: () => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
}

function DateFilter({ date, status, onFilterClick, onCancel }: DateFilterProps) {
  return (
    <DateFilterContainer status={status} onClick={() => onFilterClick()}>
      <CalendarImage>
        <Image alt="calendar" src={status ? calendarIconInactive : calendarIcon} />
      </CalendarImage>
      <Date active={date !== ''} status={status}>
        {date === '' ? '날짜를 선택하세요' : date}
      </Date>
      {!status && (
        <CancelDateBtn onClick={(e) => onCancel(e)}>
          <Image alt="cancel" src={resetFilterBtn} />
        </CancelDateBtn>
      )}
    </DateFilterContainer>
  );
}

export default DateFilter;

interface DateProps {
  status: boolean;
  active?: boolean;
}

const DateFilterContainer = styled.div<DateProps>`
  display: flex;
  align-items: center;
  padding: 5.5px;
  cursor: pointer;
  background-color: ${(props) => (props.status ? 'white' : globalColors.dateSelectedBackground)};
`;

const CalendarImage = styled.div`
  ${flexCenter};
  width: 14.9px;
  height: 15.1px;
  margin-right: 8.4px;
`;

const Date = styled.span<DateProps>`
  margin-top: -1px;
  font-family: NotoSansMedium;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
  background-color: transparent;
  color: ${({ active, status }) => {
    if (active) return globalColors.inactiveText;
    if (status) return globalColors.sub;

    return globalColors.main;
  }};
`;

const CancelDateBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 15px;
  height: 15px;
  margin-top: -1px;
  margin-left: 7.5px;
`;
