const formatPhone = (phoneNumber: string) => {
  const phoneFound = phoneNumber.replace('82-', '0');
  const doctorPhone = `${phoneFound.slice(0, 3)}-${phoneFound.slice(
    3,
    7,
  )}-${phoneFound.slice(7)}
      `;
  return doctorPhone;
};

export default formatPhone;
