import React, { useEffect, useRef, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';

import { Image } from 'components/atoms';
import { MedicalChart } from 'components/organisms';

import { postMedicalRecord, patchMedicalRecord } from 'redux/modules/appointment';

import { useSTT } from 'hooks';

import { BasicBtn, SubmitBtn } from 'styles/button';
import { treatmentColors } from 'styles/global/color';
import { IDiagnosis, IRootState } from 'types/payloadTypes';
import { device } from 'styles/global/device';
import * as mixins from 'styles/global/mixins';

type Chart = 'subjective' | 'objective' | 'assessment' | 'plan' | 'opinion';

interface MedicalRecordProps {
  onEnd: () => void;
  medicalRecord: IDiagnosis;
}

function MedicalRecord({ onEnd, medicalRecord }: MedicalRecordProps) {
  const dispatch = useDispatch();

  const { connect, socketState, reset, stop, text } = useSTT();

  const chartRef = useRef(medicalRecord);

  const [chartType, setChartType] = useState<Chart>('subjective');

  const { appointmentDetail } = useSelector((state: IRootState) => state.appointment);

  const { doctorName, hospitalName, departmentName } = appointmentDetail.appointment;
  const { assessment, objective, opinion, plan, subjective } = medicalRecord;

  const handleMedicalRecordSave = () => {
    dispatch(postMedicalRecord({ medicalRecord }));
  };

  const onRecordClick = (header: Chart) => {
    setChartType(header);
    if (socketState === 'closed') {
      connect();
      return;
    }
    stop();
    chartRef.current[chartType] += '\n';
  };

  const onRecordChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    const record = { ...medicalRecord, [name]: value };
    dispatch(patchMedicalRecord({ medicalRecord: record }));
  };

  useEffect(() => {
    if (!text) return;

    const record = {
      ...medicalRecord,
      [chartType]: chartRef.current[chartType] + text,
    };
    dispatch(patchMedicalRecord({ medicalRecord: record }));

    // eslint-disable-next-line
  }, [chartType, text]);

  useEffect(() => {
    if (!reset) return;
    chartRef.current = medicalRecord;
    // eslint-disable-next-line
  }, [reset]);

  return (
    <MedicalRecordContainer>
      <section style={{ marginBottom: 17 }}>
        <Today>
          상담기록
          {moment().format('YYYY-MM-DD(dd)')}
        </Today>
        <DoctorName>
          {`${hospitalName} `}
          {`${departmentName} `}
          {doctorName}
        </DoctorName>
      </section>
      <InputSection>
        <MedicalChart
          active={socketState === 'open' ? chartType : undefined}
          assessment={assessment}
          objective={objective}
          opinion={opinion}
          plan={plan}
          showSaveBtn={false}
          subjective={subjective}
          onChartChange={onRecordChange}
          onChartClick={onRecordClick}
          onChartSave={handleMedicalRecordSave}
        />
      </InputSection>
      <BtnSection>
        <SaveBtn onClick={() => handleMedicalRecordSave()}>
          차트 저장
          <Image alt="save-chart" src="icons/ic-save.svg" />
        </SaveBtn>
        <FinishTreatmentWrap to="#">
          <FinishTreatmentBtn onClick={() => onEnd()}>
            상담 종료
            <Image alt="treatment-finish" src="icons/ic-treatment-finish.svg" />
          </FinishTreatmentBtn>
        </FinishTreatmentWrap>
      </BtnSection>
    </MedicalRecordContainer>
  );
}

export default MedicalRecord;

const InputSection = styled.section`
  ${mixins.flexColumn};
  justify-content: space-between;
  flex-grow: 1;
`;

const SaveBtn = styled(SubmitBtn)`
  width: 120px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  margin-top: 0;
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  @media ${device.laptopMax} {
    font-size: 13px;
  }

  img {
    width: 15.454px;
    height: 15.322px;
  }
`;

const Today = styled.h1`
  font-family: NotoSansMedium;
  font-size: 18px;
  font-weight: 500;
  color: ${treatmentColors.date};
  line-height: 27px;
  @media ${device.laptopMax} {
    font-size: 15px;
  }
`;

const DoctorName = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  line-height: 22px;
  color: ${treatmentColors.doctor};
  @media ${device.laptopMax} {
    font-size: 14px;
  }
  @media ${device.mobileMax} {
    display: none;
  }
`;

const FinishTreatmentBtn = styled(BasicBtn)`
  width: 120px;
  height: 40px;
  border-radius: 5px;
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid ${treatmentColors.finishBtnBorder};
  color: ${treatmentColors.finishBtnText};
  background-color: ${treatmentColors.finishBtnBackground};
  align-items: center;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  text-decoration: none;
  margin-left: 12px;
  @media ${device.laptopMax} {
    font-size: 13px;
  }

  img {
    width: 15.454px;
    height: 15.322px;
  }
`;

const BtnSection = styled.section`
  ${mixins.flexRow};
  margin: 16px 0 32px;
`;

const MedicalRecordContainer = styled.div`
  ${mixins.flexColumn};
  padding: 40px 32px;
  position: relative;
  height: 100%;
`;

const FinishTreatmentWrap = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 3;
`;
