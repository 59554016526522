import { useEffect } from 'react';

import firebase from 'firebase/app';

// eslint-disable-next-line import/no-duplicates
import 'firebase/firestore';
// eslint-disable-next-line import/no-duplicates
import 'firebase/database';

export const firebaseConfig = {
  apiKey: 'AIzaSyAxXJQVDwk_fR6KYV2Ik0R7lIc4oWWmHVI',
  authDomain: 'puzzle-voidoc.firebaseapp.com',
  databaseURL: 'https://puzzle-voidoc.firebaseio.com',
  projectId: 'puzzle-voidoc',
  storageBucket: 'puzzle-voidoc.appspot.com',
  messagingSenderId: '742602116103',
  appId: '1:742602116103:web:f3bc8f7c1dd2d10cf42ea2',
  measurementId: 'G-M127KKBWKP',
};

const useFirestore = (uid: string) => {
  try {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    } else {
      firebase.app();
    }
  } catch (e) {
    console.error(e);
  }

  const userStatusFirestoreRef = firebase.firestore().doc(`/doctor_status/'${uid}`);

  const isOfflineForFirestore = {
    active: false,
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  const isOnlineForFirestore = {
    active: true,
    last_changed: firebase.firestore.FieldValue.serverTimestamp(),
  };

  const sendOfflineMessage = () => {
    userStatusFirestoreRef.set(isOfflineForFirestore);
  };

  const sendOnlineMessage = () => {
    userStatusFirestoreRef.set(isOnlineForFirestore);
  };

  firebase
    .database()
    .ref('.info/connected')
    .on('value', (snapshot) => {
      if (!snapshot.val()) {
        sendOfflineMessage();
        return;
      }
      sendOnlineMessage();
    });

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      sendOfflineMessage();
    });
    return () => {
      window.removeEventListener('beforeunload', () => {});
    };
  });

  return { sendOfflineMessage };
};

export default useFirestore;
