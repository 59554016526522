import { useEffect, useRef, useState } from 'react';

import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
  ILocalVideoTrack,
  ILocalAudioTrack,
  NetworkQuality,
} from 'agora-rtc-sdk-ng';

export default function useAgora(client: IAgoraRTCClient | undefined): {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  localScreenTrack: ILocalVideoTrack | undefined;
  joinState: boolean;
  leave: Function;
  join: Function;
  shareScreen: () => void;
  stopScreenShare: () => void;
  remoteUsers: IAgoraRTCRemoteUser[];
  screenError: boolean;
} {
  const alertRef = useRef(false);
  const [joinState, setJoinState] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);
  const [localVideoTrack, setLocalVideoTrack] = useState<ICameraVideoTrack | undefined>(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [localScreenTrack, setLocalScreenTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [screenError, setScreenError] = useState(false);

  async function createLocalTracks(): Promise<[IMicrophoneAudioTrack, ICameraVideoTrack]> {
    const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack({
      AEC: true,
      ANS: true,
    });
    const cameraTrack = await AgoraRTC.createCameraVideoTrack({
      encoderConfig: '1080p_1',
      optimizationMode: 'detail',
    });
    setLocalAudioTrack(microphoneTrack);
    setLocalVideoTrack(cameraTrack);
    return [microphoneTrack, cameraTrack];
  }

  async function join(channel: string, token?: string, uid?: string | number | null) {
    if (!client) return;
    const [microphoneTrack, cameraTrack] = await createLocalTracks();
    try {
      await client.join(process.env.REACT_APP_AGORA_APP_ID, channel, token || null, uid);
      await client.enableDualStream();
      client.setLowStreamParameter({
        height: 400,
        width: 640,
        framerate: 5,
      });
    } catch (e) {
      console.error('join error; ', e);
    }

    await client.publish([microphoneTrack, cameraTrack]);

    (window as any).client = client;
    (window as any).videoTrack = cameraTrack;

    setJoinState(true);
  }

  async function leave() {
    if (localAudioTrack) {
      localAudioTrack.stop();
      localAudioTrack.close();
    }
    if (localVideoTrack) {
      localVideoTrack.stop();
      localVideoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(false);
    await client?.leave();
  }

  async function stopScreenShare() {
    await client.unpublish(localScreenTrack);
    await client.publish([localAudioTrack, localVideoTrack]);
  }

  async function shareScreen() {
    const isMacOS = () => navigator.userAgent.toLocaleLowerCase().indexOf('mac') > 0;

    const handleConfirmAlert = () => {
      const confirm = window.confirm('화면공유를 사용할 수 없습니다. \n시스템 권한을 허용하시겠습니까?');

      if (confirm) {
        window.open('x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture');
      }
    };

    try {
      setScreenError(false);
      await client.unpublish(localVideoTrack);

      const screenTrack = await AgoraRTC.createScreenVideoTrack({
        encoderConfig: '1080p_1',
        optimizationMode: 'detail',
      });
      if (!Array.isArray(screenTrack)) {
        await client.publish(screenTrack);
        setLocalScreenTrack(screenTrack);
      }
    } catch (error) {
      const { code, message } = error;
      if (code === 'PERMISSION_DENIED' && message.indexOf('by system') > 0) {
        if (isMacOS) {
          handleConfirmAlert();
        } else {
          alert('화면공유를 사용할 수 없습니다. \n시스템 권한을 허용해주세요.');
        }
      } else {
        console.error('screen share error: ', error);
      }
      setScreenError(true);
      stopScreenShare();
    }
  }

  useEffect(() => {
    if (!client) return;
    setRemoteUsers(client.remoteUsers);
    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await client.subscribe(user, mediaType);
      // toggle rerender while state of remoteUsers changed.
      // eslint-disable-next-line
      client.setStreamFallbackOption(user.uid, 1);
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser) => {
      // eslint-disable-next-line
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line
    const handleUserJoined = (user: IAgoraRTCRemoteUser) => {
      // eslint-disable-next-line
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    // eslint-disable-next-line
    const handleUserLeft = (user: IAgoraRTCRemoteUser) => {
      // eslint-disable-next-line
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    const handleNetworkQuality = ({ downlinkNetworkQuality, uplinkNetworkQuality }: NetworkQuality) => {
      console.log('downlnk: ', downlinkNetworkQuality);
      if (!localVideoTrack?.enabled) return;
      if (downlinkNetworkQuality >= 3 || uplinkNetworkQuality >= 3) {
        localVideoTrack.setEncoderConfiguration('120p_1');
        if (alertRef.current) return;
        alert('연결상태가 원활하지 않습니다');
        alertRef.current = true;
      } else {
        localVideoTrack.setEncoderConfiguration('1080p_1');
      }
    };

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);
    client.on('network-quality', handleNetworkQuality);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
      client.off('network-quality', handleNetworkQuality);
    };
  }, [client, localVideoTrack]);

  return {
    join,
    joinState,
    leave,
    localAudioTrack,
    localScreenTrack,
    localVideoTrack,
    shareScreen,
    stopScreenShare,
    remoteUsers,
    screenError,
  };
}
