import React from 'react';

import styled from '@emotion/styled';
import { IAgoraRTCRemoteUser, ILocalVideoTrack } from 'agora-rtc-sdk-ng';

import { MediaPlayer } from 'components/atoms';

import {
  Avatar,
  ParticipantInfo,
  ParticipantMutedIcon,
  ParticipantOverlay,
  ParticipantView,
} from 'styles/callWrappers';
import * as mixins from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

interface PatientScreenProps {
  onInvite: () => void;
  patient?: IAgoraRTCRemoteUser;
  screenTrack?: ILocalVideoTrack;
}

function PatientScreen({ onInvite, patient, screenTrack }: PatientScreenProps) {
  const handleRender = () => {
    if (screenTrack) return <MediaPlayer audioTrack={undefined} id="doctorScreen" videoTrack={screenTrack} />;
    if (patient) {
      return (
        <>
          {patient?.hasVideo ? (
            <MediaPlayer audioTrack={patient?.audioTrack} id="patientVideo" videoTrack={patient?.videoTrack} />
          ) : (
            <ParticipantOverlay>
              <Avatar />
            </ParticipantOverlay>
          )}
          <ParticipantInfo>
            환자
            {!patient?.hasAudio && <ParticipantMutedIcon />}
          </ParticipantInfo>
        </>
      );
    }

    return (
      <NoPatientWrap>
        <NoParticipantIcon />
        환자가 오프라인 상태입니다
        <SendMessageBtn onClick={() => onInvite()}>
          <SendMessageIcon />
          입장 요청 메세지 전송
        </SendMessageBtn>
      </NoPatientWrap>
    );
  };

  return (
    <PatientScreenWrap count={1} hasScreen={!!screenTrack}>
      {handleRender()}
    </PatientScreenWrap>
  );
}

export default PatientScreen;

const PatientScreenWrap = styled(ParticipantView)<{ hasScreen: boolean }>`
  height: 100%;
  overflow: none;
  ${mixins.sticky}
  background-color: ${({ hasScreen }) => (hasScreen ? 'white' : 'transparent')};
  > video {
    width: 100%;
    height: 100%;
    object-fit: ${({ hasScreen }) => (hasScreen ? 'contain' : 'cover')};
  }
`;

const NoPatientWrap = styled.div`
  ${mixins.flexColumn}
  ${mixins.flexCenter}
  background-color: var(--black);
  font-family: NotoSansRegular;
  font-size: 16px;
  line-height: 27px;
  color: var(--white);
  border: 1px solid black;
  width: 100%;
  height: 100%;
`;

const NoParticipantIcon = styled.div`
  width: 22.2px;
  height: 26.8px;
  background-image: url(/icons/ic-no-participant.svg);
  margin-bottom: 8px;
  background-repeat: no-repeat;
`;

const SendMessageIcon = styled.div`
  width: 14.1px;
  height: 10.8px;
  margin-right: 9.4px;
  background-image: url(/icons/ic-send-msg.svg);
  background-repeat: no-repeat;
`;

const SendMessageBtn = styled(BasicBtn)`
  font-size: 15px;
  line-height: 19px;
  color: var(--white);
  background-color: var(--point-blue-05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  border-radius: 3px;
  margin-top: 16px;
`;
