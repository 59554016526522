import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import { ChatColleagueList, ChatRoomList, ChatRoom } from 'components/molecules';

import {
  getChatList,
  getChatMessageList,
  getChatColleagueList,
  postNewChatRoom,
  getSearchColleagueList,
} from 'redux/modules/chatting';

import { useChatSocket } from 'hooks';

import { activeBackArrowBtn, activeCloseBtn, colleagueListIcon, chatIcon, searchIconActive } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IChat, IColleague, IRootState } from 'types/payloadTypes';
import { IActive } from 'types/styleTypes';
import { BasicBtn } from 'styles/button';

interface ChatMessengerProps {
  handleCloseBtnClick: () => void;
}

const initialColleague = {
  id: 0,
  colleagueDepartment: '',
  colleagueHospital: '',
  colleagueName: '',
  colleaguePhoto: '',
  recentMessage: '',
  unreadCount: 0,
};

type InitialRoute = 'ChatColleagueListTab' | 'ChatRoomListTab';

function ChatMessenger({ handleCloseBtnClick }: ChatMessengerProps) {
  const dispatch = useDispatch();
  const chatSocket = useChatSocket();

  const scrollbarRef = useRef(null);

  const [selectedChat, setSelectedChat] = useState<IChat>(initialColleague);
  const [chatMessage, setChatMessage] = useState('');
  const [searchName, setSearchName] = useState('');
  const [selectedTab, setSelectedTab] = useState<InitialRoute>('ChatColleagueListTab');

  const { chatList, messageList, colleagueList, newColleague } = useSelector((state: IRootState) => state.chatting);

  const { colleagueDepartment, colleagueHospital, colleagueName } = selectedChat;
  const { chatArray } = chatList;
  const { messageArray } = messageList;
  const { colleagueArray } = colleagueList;

  const handleGoBack = () => {
    setSelectedChat({
      ...selectedChat,
      id: 0,
    });
    dispatch(getChatList());
  };

  const handleChatMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatMessage(e.target.value);
  };

  const handleChatRoom = () => {
    if (selectedChat.id) {
      return (
        <ChatRoom
          chatMessage={chatMessage}
          messageArray={messageArray}
          scrollbarRef={scrollbarRef}
          onChat={handleChatMessage}
          onSend={sendMessage}
        />
      );
    }

    if (selectedTab === 'ChatColleagueListTab') {
      return (
        <ChatColleagueList colleagueArray={colleagueArray} scrollbarRef={scrollbarRef} onSelect={selectColleague} />
      );
    }

    return <ChatRoomList chatArray={chatArray} scrollbarRef={scrollbarRef} onSelect={selectChat} />;
  };

  const handleSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(e.target.value);
  };

  const selectChat = (chat: IChat) => {
    setSelectedChat({
      ...chat,
    });
  };

  const selectColleague = (selectedColleague: IColleague) => {
    const { userId, roomId, departmentName, colleagueName: colleague, hospitalName } = selectedColleague;
    if (roomId) {
      setSelectedChat({
        ...selectedChat,
        id: roomId,
        colleagueName: colleague,
        colleagueDepartment: departmentName,
        colleagueHospital: hospitalName,
      });
    } else {
      dispatch(postNewChatRoom(userId));
    }
  };

  const sendMessage = () => {
    chatSocket.emit('sendMessage', {
      room: selectedChat.id,
      message: chatMessage,
    });
    setChatMessage('');
    scrollbarRef.current.scrollIntoView({ block: 'start' });
  };

  useEffect(() => {
    if (!selectedChat.id) return;

    dispatch(getChatMessageList(selectedChat.id));

    chatSocket.on('connect', () => {
      chatSocket.emit('joinRoom', {
        room: selectedChat.id,
      });
      chatSocket.on('receiveMessage', (data) => {
        const { room } = data;
        dispatch(getChatMessageList(room));
      });
    });

    // eslint-disable-next-line
  }, [selectedChat.id]);

  useEffect(() => {
    dispatch(getChatList());
    dispatch(getChatColleagueList());
    // eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() => {
    const {
      id,
      colleagueDepartment: newColleagueDepartment,
      colleagueHospital: newColleagueHospital,
      colleagueName: newColleagueName,
    } = newColleague;
    if (newColleague) {
      setSelectedChat({
        ...selectedChat,
        id,
        colleagueDepartment: newColleagueDepartment,
        colleagueHospital: newColleagueHospital,
        colleagueName: newColleagueName,
      });
    }
    // eslint-disable-next-line
  }, [newColleague]);

  useEffect(() => {
    dispatch(getSearchColleagueList(searchName));
    // eslint-disable-next-line
  }, [searchName]);

  return (
    <ChatContainer>
      <ChatHeader>
        {selectedChat.id ? (
          <>
            <BackBtnIcon onClick={() => handleGoBack()}>
              <Image alt="back-button" src={activeBackArrowBtn} />
            </BackBtnIcon>
            <HeaderDoctorInfoWrap>
              <HeaderDoctorName>{colleagueName}</HeaderDoctorName>
              <HeaderHospital>
                {colleagueDepartment ? `${colleagueHospital} / ${colleagueDepartment}` : colleagueHospital}
              </HeaderHospital>
            </HeaderDoctorInfoWrap>
          </>
        ) : (
          <>
            <HeaderBtnSection>
              <HeaderBtnContainer>
                <HeaderBtnWrap>
                  <HeaderBtn
                    active={selectedTab === 'ChatColleagueListTab'}
                    onClick={() => setSelectedTab('ChatColleagueListTab')}
                  >
                    <ChatListIcon src={chatIcon} />
                    친구목록
                  </HeaderBtn>
                  <HeaderBtn
                    active={selectedTab === 'ChatRoomListTab'}
                    onClick={() => setSelectedTab('ChatRoomListTab')}
                  >
                    <FriendListIcon src={colleagueListIcon} />
                    채팅목록
                  </HeaderBtn>
                </HeaderBtnWrap>
              </HeaderBtnContainer>
              <CloseBtnIcon onClick={() => handleCloseBtnClick()}>
                <Image alt="close-button" src={activeCloseBtn} />
              </CloseBtnIcon>
            </HeaderBtnSection>
            {selectedTab === 'ChatColleagueListTab' && (
              <SearchBarWrap>
                <SearchBar
                  name="searchName"
                  placeholder="의료진을 검색해주세요."
                  value={searchName}
                  onChange={(e) => handleSearchName(e)}
                />
                <SearchBarIcon src={searchIconActive} />
              </SearchBarWrap>
            )}
          </>
        )}
      </ChatHeader>
      {handleChatRoom()}
    </ChatContainer>
  );
}

export default ChatMessenger;

const color = {
  chatBackground: '#2685fd',
  hospitalName: '#7cbdff',
};

const ChatContainer = styled.div`
  ${flexColumn};
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 1;
  width: 410px;
  height: 640px;
  border-radius: 8px;
  background-color: white;
  font-family: NotoSansCJKkr-Regular;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  animation: zoomIn 0.12s both;
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
      opacity: 1;
    }
  }
`;

const HeaderBtnSection = styled.section`
  display: flex;
  position: relative;
  justify-content: space-between;
`;

const HeaderBtnContainer = styled.div`
  cursor: pointer;
  ${flexColumn}; ;
`;

const HeaderBtnWrap = styled.div`
  display: flex;
  font-family: NotoSansCJKkr;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  position: relative;
`;

const HeaderBtn = styled.div<IActive>`
  display: flex;
  color: white;
  margin-right: 14px;
  ${({ active }) => `
  font-weight: ${active ? 'bold' : ''};
  opacity: ${active ? '1' : '0.4'};
  `}
`;

const ChatListIcon = styled.img`
  width: 15px;
  height: 15px;
  background-size: contain;
  margin-right: 3px;
  margin-bottom: 4px;
`;

const FriendListIcon = styled(ChatListIcon)`
  width: 12.5px;
  height: 12.5px;
`;

const ChatHeader = styled.div`
  ${flexColumn};
  background-color: ${color.chatBackground};
  padding: 24px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const CloseBtnIcon = styled(BasicBtn)`
  ${flexCenter};
  position: absolute;
  right: -5px;
  bottom: 5px;
  width: 25px;
  height: 25px;
  background-size: contain;
  :hover {
    opacity: 0.7;
  }
`;

const SearchBarWrap = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBar = styled.input`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border: none;
  height: 35px;
  margin-top: 24px;
  padding-left: 40px;
  font-size: 13px;
  font-family: NotoSansRegular;
  ::placeholder {
    font-size: 13px;
    color: ${color.chatBackground};
  }
`;

const SearchBarIcon = styled.img`
  width: 18px;
  height: 18px;
  position: absolute;
  margin-top: 22px;
  margin-left: 10px;
`;

const BackBtnIcon = styled(BasicBtn)`
  ${flexCenter};
  width: 32px;
  height: 32px;
  :hover {
    opacity: 0.7;
  }
`;

const HeaderDoctorInfoWrap = styled.div`
  ${flexColumn};
  margin-top: 10px;
`;

const HeaderDoctorName = styled.div`
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin-bottom: 8px;
`;

const HeaderHospital = styled.div`
  font-size: 16px;
  color: ${color.hospitalName};
`;
