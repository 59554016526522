import React from 'react';

import styled from '@emotion/styled';

import { Button } from 'components/atoms';

import * as text from 'styles/text';

interface ConfirmModalProps {
  modalTitle: string;
  modalExplanation: string;
  onConfirm?: () => void;
}

function ConfirmModal({ modalTitle, modalExplanation, onConfirm }: ConfirmModalProps) {
  return (
    <ModalContaier>
      <Title>{modalTitle}</Title>
      <Info>{modalExplanation}</Info>
      <ButtonWrap>
        <Button color="lightBlue" size="mid" onClick={() => onConfirm()}>
          확인
        </Button>
      </ButtonWrap>
    </ModalContaier>
  );
}

export default ConfirmModal;

const ModalContaier = styled.div`
  width: 518px;
  height: 209px;
  padding: 40px 40px 32px;
  border-radius: 5px;
  background-color: white;
`;

const Title = styled.h1`
  color: var(--grayscale-06);
  font-size: 18px;
  line-height: 1.61;
  font-weight: 500;
  margin-bottom: 8px;
`;

const Info = styled(text.H5)`
  margin-top: 8px;
  font-size: 15px;
  color: var(--grayscale-05);
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;
