import axiosClient from './axiosClient';

export const appointmentDate = ({ doctorId, month, year }: { doctorId: number; month: string; year: string }) =>
  axiosClient.get(`user/doctor/${doctorId}/monthly/?month=${month}&year=${year}`);

export const doctorSchedule = () => axiosClient.get('user/timetable/');

export const scheduleTimeOption = () => axiosClient.get('user/available_times/');

export const createDoctorSchedule = (payload) => axiosClient.post('user/timetable/', payload);

export const unavailabledate = (payload) => axiosClient.post('user/unavailabledate/', payload);

export const updateDoctorSchedule = (payload) => axiosClient.put('user/timetable/', payload);
