import React from 'react';

import styled from '@emotion/styled';

import { checkboxActive, checkboxInActive } from 'assets/images';

import { IActive } from 'types/styleTypes';

interface CheckboxProps {
  checked: boolean;
  onCheck: () => void;
}

function Checkbox({ checked, onCheck }: CheckboxProps) {
  return (
    <Check active={checked} onClick={() => onCheck()}>
      <img alt="check" src={checked ? checkboxActive : checkboxInActive} />
    </Check>
  );
}

export default Checkbox;

const Check = styled.button<IActive>`
  width: 17px;
  height: 17px;
  padding: 0;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 3px;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
