import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Questionnaire, MedicalDoctor, Symptoms } from 'components/molecules';
import { TreatmentHistory } from 'components/organisms';

import {
  getPatientDiagnosis,
  patchMedicalRecord,
  getPatientQuestionnaire,
  removeDoctor,
  getAppointmentDetail,
} from 'redux/modules/appointment';

import { useModal } from 'hooks';
import { formatAge } from 'utils';

import { H1, H2, H3 } from 'styles/text';
import { globalColors } from 'styles/global/color';
import { device } from 'styles/global/device';
import { showDetailIcon, hideDetailIcon, cooperationIcon } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';
import { IDiagnosis, IRootState } from 'types/payloadTypes';

interface PatientInformationProps {
  parent: string;
  appointmentId?: number;
  onCooperate?: () => void;
}

function PatientInformation({ parent, appointmentId, onCooperate }: PatientInformationProps) {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const [detail, setDetail] = useState(1);
  const [targetHistory, setTargetHistory] = useState(-1);

  const { questionnaire, diagnosis, appointment } = useSelector(
    (state: IRootState) => state.appointment.appointmentDetail,
  );
  const { cooperationRequestSuccess, removeDoctorSuccess } = useSelector((state: IRootState) => state.appointment);

  const {
    dateOfBirth,
    filePath,
    patientGender,
    imagePath,
    patientDistrict,
    patientName,
    symptomDesc,
    patient,
    doctorName,
    doctorPhoto,
    hospitalName,
    departmentName,
    subDoctors,
  } = appointment;

  const handleSymptomsImageModal = () => {
    openModal({
      modalType: 'SymptomsImageModal',
      modalProps: {
        imageArray: imagePath,
      },
    });
  };

  const handleDetailShow = (value: number): void => {
    if (detail === value) {
      setDetail(0);
      return;
    }
    setDetail(value);
  };

  const handleHistoryDetailShow = (index: number) => {
    if (index === targetHistory) {
      setTargetHistory(-1);
      return;
    }

    setTargetHistory(index);
  };

  const pasteMedicalRecord = (diagnosisItem: IDiagnosis) => {
    dispatch(patchMedicalRecord({ medicalRecord: diagnosisItem }));
  };

  const removeSubDoctor = (subDoctorId: number) => {
    dispatch(
      removeDoctor({
        appointmentId,
        subDoctorId,
      }),
    );
  };

  useEffect(() => {
    if (patient) {
      dispatch(getPatientQuestionnaire(patient));
      dispatch(getPatientDiagnosis(patient));
    }
    // eslint-disable-next-line
  }, [patient]);

  useEffect(() => {
    if (!!removeDoctorSuccess && !!cooperationRequestSuccess) return;
    if (parent === 'Treatment') return;
    if (!appointmentId) return;

    dispatch(getAppointmentDetail({ appointmentId }));
    // eslint-disable-next-line
  }, [cooperationRequestSuccess, removeDoctorSuccess, appointmentId]);

  return (
    <PatientInformationContainer>
      {parent === 'Treatment' && (
        <section style={{ display: 'flex' }}>
          <H1>{patientName}</H1>
          <H2 fontSize={20} margin="0 0 50px 10px">
            {`${formatAge(dateOfBirth)}(${patientGender === 'M' ? '남' : '여'}) / ${patientDistrict}`}
          </H2>
        </section>
      )}
      <OptionSection active={parent === 'AppointmentDetailRow'}>
        <PatientSection active={parent === 'AppointmentDetailRow'}>
          {parent !== 'Treatment' && (
            <>
              <PatientSectionTitle onClick={() => handleDetailShow(1)}>
                <H3>진료 의사</H3>
                {parent !== 'AppointmentDetailRow' && (
                  <ShowDetailImage src={detail === 1 ? hideDetailIcon : showDetailIcon} />
                )}
              </PatientSectionTitle>
              {detail === 1 && (
                <>
                  <MedicalDoctor
                    showDelete
                    departmentName={departmentName}
                    doctorName={doctorName}
                    doctorPhoto={doctorPhoto}
                    hospitalName={hospitalName}
                    subDoctorArray={subDoctors}
                    onRemove={removeSubDoctor}
                  />
                  {false && (
                    <CooperationBtn onClick={() => onCooperate()}>
                      <CooperationIcon src={cooperationIcon} />
                      협진 요청
                    </CooperationBtn>
                  )}
                </>
              )}
            </>
          )}
          <PatientSectionTitle onClick={() => handleDetailShow(2)}>
            <H3>환자 문진표</H3>
            {parent !== 'AppointmentDetailRow' && (
              <ShowDetailImage src={detail === 2 ? hideDetailIcon : showDetailIcon} />
            )}
          </PatientSectionTitle>
          {((parent !== 'AppointmentDetailRow' && detail === 2) || parent === 'AppointmentDetailRow') && (
            <Questionnaire questionnaire={questionnaire} />
          )}
        </PatientSection>
        <PatientSection active={parent === 'AppointmentDetailRow'}>
          <PatientSectionTitle onClick={() => handleDetailShow(3)}>
            <H3>환자 증상</H3>
            {parent !== 'AppointmentDetailRow' && (
              <ShowDetailImage src={detail === 3 ? hideDetailIcon : showDetailIcon} />
            )}
          </PatientSectionTitle>
          {((parent !== 'AppointmentDetailRow' && detail === 3) || parent === 'AppointmentDetailRow') && (
            <Symptoms
              fileArray={filePath}
              imageArray={imagePath}
              parent={parent}
              symptomDescription={symptomDesc}
              onModalOpen={handleSymptomsImageModal}
            />
          )}
        </PatientSection>
        <PatientSection active={parent === 'AppointmentDetailRow'}>
          <PatientSectionTitle onClick={() => handleDetailShow(4)}>
            <H3>이전 진료 기록</H3>
            {parent !== 'AppointmentDetailRow' && (
              <ShowDetailImage src={detail === 4 ? hideDetailIcon : showDetailIcon} />
            )}
          </PatientSectionTitle>
          {((parent !== 'AppointmentDetailRow' && detail === 4) || parent === 'AppointmentDetailRow') && (
            <TreatmentHistory
              diagnosisHistory={diagnosis}
              handleHistoryDetailShow={handleHistoryDetailShow}
              parent={parent}
              pasteMedicalRecord={pasteMedicalRecord}
              targetHistory={targetHistory}
            />
          )}
        </PatientSection>
      </OptionSection>
    </PatientInformationContainer>
  );
}

export default PatientInformation;

const PatientSection = styled.div<IActive>`
  ${flexColumn};
  position: relative;
  text-align: left;
  min-width: ${(props) => (props.active ? '33%' : '100%')};
  > div {
    margin-bottom: 24px;
  }
  @media ${device.laptopMax} {
    margin-right: 27px;
    margin-top: 0;
  }
`;

const PatientSectionTitle = styled(BasicBtn)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${globalColors.borderBottom};
  margin: 0 0 24px;
  text-align: left;
`;

const PatientInformationContainer = styled.div`
  padding: 40px;
  width: 100%;
  background-color: white;
  height: calc(100vh - 105px);
  @media ${device.laptopMax} {
    padding: 0 40px 0px 40px;
  }
`;

const OptionSection = styled.section<IActive>`
  position: relative;
  padding: 0;
  display: ${(props) => (props.active ? 'flex' : 'block')};
  @media ${device.laptopMax} {
    div:last-of-type {
      margin-right: 0;
    }
  }
`;

const ShowDetailImage = styled.img`
  height: 25px;
  width: 25px;
  margin-bottom: 4px;
`;

const CooperationBtn = styled(BasicBtn)`
  ${flexCenter};
  border-radius: 4px;
  color: white;
  font-family: NotoSansCJKkr;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  background-color: white;
  border: 1px solid #a3d1ff;
  color: #3e8bd8;
  margin: 15px 0 30px 0;
`;

const CooperationIcon = styled.img`
  width: 19px;
  height: 19px;
  margin-right: 16px;
  object-fit: contain;
`;
