import { useDispatch, useSelector } from 'react-redux';

import { hideModal, showModal } from 'redux/modules/modal';

import { IModalType, IRootState } from 'types/payloadTypes';

const useModal = () => {
  const dispatch = useDispatch();

  const { modalList } = useSelector((state: IRootState) => state.modal);

  const openModal = (targetModal: IModalType) => {
    dispatch(showModal(targetModal));
  };

  const closeModal = (targetModal: IModalType) => {
    const filteredModal = modalList.filter((modal) => modal.modalType !== targetModal.modalType);
    dispatch(hideModal(filteredModal));
  };

  return { openModal, closeModal };
};

export default useModal;
