import React from 'react';

import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Forbidden, Recording, SignIn } from 'pages';

import { IRootState } from 'types/payloadTypes';

interface PriveateRoutes extends RouteProps {
  component: React.ComponentType;
}

function PrivateRoutes({ component: Component, ...rest }: PriveateRoutes) {
  const token = localStorage.getItem('token');
  const { isLogin, isLoading, userInfo } = useSelector((state: IRootState) => state.authentication);

  const { hasRecording } = userInfo;

  if (!token) {
    return <Route {...rest} component={SignIn} />;
  }
  if (!isLoading) {
    return <div></div>;
  }
  if (!isLogin) {
    return <Route {...rest} component={Forbidden} />;
  }

  if (!hasRecording) {
    return <Route {...rest} component={Recording} />;
  }

  if (hasRecording) {
    return <Route {...rest} component={Component} />;
  }
}

export default PrivateRoutes;
