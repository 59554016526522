import axiosClient from './axiosClient';

export const patientQuestionnaire = (patientId: number) => axiosClient.get(`user/patient/${patientId}/questionnaire/`);

export const patientDiagnosis = (patientId: number) => axiosClient.get(`user/patient/${patientId}/diagnosis/`);

export const doctorEnterTreatmentRoom = ({
  appointmentId,
  hasDoctorEntered,
  startedAt,
}: {
  appointmentId: number;
  hasDoctorEntered: boolean;
  startedAt?: string;
}) =>
  axiosClient.patch(`appointment/appointments/${appointmentId}/`, {
    has_doctor_entered: hasDoctorEntered,
    has_doctor_requested: true,
    started_at: hasDoctorEntered ? startedAt : '',
  });

export const waitingPatientList = ({
  doctor,
  offset,
  startDate,
}: {
  doctor: number;
  offset: number;
  startDate: string;
}) => axiosClient.get(`appointment/list/?doctor=${doctor}&status=1&offset=${offset}&startDate=${startDate}`);

export const rejectAppointment = ({ appointmentId, reason }: { appointmentId: number; reason: string }) =>
  axiosClient.patch(`appointment/${appointmentId}/status/`, {
    status: 6,
    reject_reason: reason,
  });

export const approveAppointment = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.patch(`appointment/${appointmentId}/status/`, {
    status: 0,
  });

export const retrieveDiagnosis = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.get(`appointment/appointments/${appointmentId}/diagnosis/`);

export const patchStartTreatment = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.patch(`appointment/${appointmentId}/duration/`, {
    status: 5,
  });

export const getPrescriptionInfo = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.get(`appointment/${appointmentId}/prescription/`);

export const getDistrictList = () => axiosClient.get('user/city/1/district/');

export const postWebRTCOption = ({ appointmentId, audioOnly }: { appointmentId: number; audioOnly: boolean }) =>
  axiosClient.post(`appointment/${appointmentId}/webrtc/`, {
    audio_only: audioOnly,
  });

export const patchFinishTreatment = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.patch(`appointment/${appointmentId}/duration/`, {
    status: 2,
  });

export const patchAppointmentDuration = ({ appointmentId, duration }) =>
  axiosClient.patch(`appointment/appointments/${appointmentId}/duration/`, {
    duration,
  });

export const getAgoraToken = () => axiosClient.get('appointment/token');

export const postAgoraRTCToken = (appointmentId: number) =>
  axiosClient.post('appointment/rtc_token/', {
    room_id: `appointment_${appointmentId}`,
  });

export const postResourceId = (cname: string) =>
  axiosClient.post('appointment/agora/record/acquire/', {
    cname,
  });

export const postRecordStart = ({ cname, resourceId }: { cname: string; resourceId: string }) =>
  axiosClient.post('appointment/agora/record/start/', {
    cname,
    resource_id: resourceId,
  });

export const postRecordStop = ({
  cname,
  resourceId,
  sid,
  appointmentId,
}: {
  cname: string;
  resourceId: string;
  sid: string;
  appointmentId: number;
}) =>
  axiosClient.post('appointment/agora/record/stop/', {
    cname,
    resource_id: resourceId,
    sid,
    appointment_id: appointmentId,
  });

export const writePrescription = ({
  appointmentId,
  prescriptionData,
}: {
  appointmentId: number;
  prescriptionData: any;
}) =>
  axiosClient.post(`appointment/${appointmentId}/prescription/`, {
    prescriptionData,
  });

export const getDepartmentList = () => axiosClient.get('user/department/');

export const getDoctorList = (departmentId: number) => axiosClient.get(`user/department/${departmentId}/doctor_list/`);

export const appointmentNextPage = (nextPageUrl: string) => axiosClient.get(nextPageUrl);

export const appointmentPrevPage = (prevPageUrl: string) => axiosClient.get(prevPageUrl);

export const patchMedicalRecord = ({
  diagnosisId,
  appointmentId,
  assessment,
  objective,
  opinion,
  plan,
  subjective,
}: {
  diagnosisId: number;
  appointmentId: number;
  assessment: string;
  objective: string;
  opinion: string;
  plan: string;
  subjective: string;
}) =>
  axiosClient.patch(`appointment/diagnosis/${diagnosisId}/`, {
    appointment: appointmentId,
    assessment,
    objective,
    opinion,
    plan,
    subjective,
  });

export const postMedicalRecord = ({
  appointmentId,
  assessment,
  doctorId,
  objective,
  opinion,
  plan,
  subjective,
}: {
  assessment: string;
  appointmentId: number;
  doctorId: number;
  objective: string;
  opinion: string;
  plan: string;
  subjective: string;
}) =>
  axiosClient.post('appointment/diagnosis/', {
    assessment,
    appointment: appointmentId,
    doctor: doctorId,
    opinion,
    objective,
    plan,
    subjective,
  });

export const callPatient = ({
  patientId,
  appointmentId,
  type,
}: {
  patientId: number;
  appointmentId: number;
  type: string;
}) =>
  axiosClient.post('appointment/push/', {
    patient: patientId,
    appointment: appointmentId,
    type,
  });

export const appointmentDetail = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.get(`appointment/appointments/${appointmentId}`);

export const appointmentTargetPage = ({
  date,
  queryText,
  doctor,
  department,
  startDate,
  endDate,
  status,
  offset,
  page,
}: {
  date: string;
  queryText: string;
  doctor: number;
  department: number;
  startDate: string;
  endDate: string;
  status: number;
  offset: number;
  page: number;
}) =>
  axiosClient.get(
    // eslint-disable-next-line max-len
    `appointment/appointments/?date=${date}&query=${queryText}&doctor=${doctor}&department=${department}&startDate=${startDate}&endDate=${endDate}&status=${status}&offset=${offset}&page=${page}`,
  );

export const appointmentList = (queryParams: string[]) => axiosClient.get(`appointment/list/?${queryParams.join('')}`);

export const cancelAppointment = ({ appointmentId, reason }: { appointmentId: number; reason: string }) =>
  axiosClient.post('appointment/request/', {
    appointment: appointmentId,
    reason,
  });

export const diseaseList = () => axiosClient.get('user/disease/');

export const patientNoShow = (appointmentId: number) =>
  axiosClient.patch(`appointment/appointments/${appointmentId}`, {
    status: 4,
  });

export const invitePatient = ({ appointmentId }: { appointmentId: number }) =>
  axiosClient.patch(`appointment/appointments/${appointmentId}/invite/`);

export const waitingAppointmentList = () => axiosClient.get('appointment/today/waiting/');

export const waitingNextPage = (nextPageUrl: string) => {
  axiosClient.get(nextPageUrl);
};

export const waitingPrevPage = (prevPageUrl: string) => {
  axiosClient.get(prevPageUrl);
};

export const completeAppointmentList = () => axiosClient.get('appointment/today/done/');

export const completeNextPage = (nextPageUrl: string) => {
  axiosClient.get(nextPageUrl);
};

export const completePrevPage = (prevPageUrl: string) => {
  axiosClient.get(prevPageUrl);
};

export const requestCooperation = ({ doctors, appointmentId }: { doctors: number[]; appointmentId: number }) =>
  axiosClient.patch(`appointment/${appointmentId}/sub_doctor/`, {
    sub_doctors: [...doctors],
  });
export const cooperationDoctorList = ({
  appointmentId,
  departmentId,
}: {
  appointmentId: number;
  departmentId: number;
}) =>
  departmentId
    ? axiosClient.get(`appointment/${appointmentId}/sub_doctor/?department=${departmentId}`)
    : axiosClient.get(`appointment/${appointmentId}/sub_doctor/`);

export const deleteCooperationDoctor = ({
  appointmentId,
  subDoctorId,
}: {
  appointmentId: number;
  subDoctorId: number;
}) =>
  axiosClient.delete(`appointment/${appointmentId}/sub_doctor/`, {
    data: {
      sub_doctor: subDoctorId,
    },
  });

export const changeAppointment = ({
  appointmentId,
  date,
  selectedTime,
  status,
}: {
  appointmentId: number;
  date: string;
  selectedTime: string;
  status: number;
}) =>
  axiosClient.patch(`appointment/${appointmentId}/`, {
    appointment_date: `${date} ${selectedTime}`,
    status,
  });

export const pendingListNextPage = (nextPageUrl: string) => axiosClient.get(nextPageUrl);

export const pendingListPrevPage = (prevPageUrl: string) => axiosClient.get(prevPageUrl);
