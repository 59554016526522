import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
  timeout: 8000,
});

axiosClient.interceptors.request.use(
  (config) => {
    const voidocToken = localStorage.getItem('token');
    if (voidocToken) {
      config.headers.Authorization = `Token ${voidocToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default axiosClient;
