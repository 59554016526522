import React, { ReactNode } from 'react';

import styled from '@emotion/styled';

interface SubHeaderProps {
  children: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
}

function SubHeader({ children, onClick }: SubHeaderProps) {
  return <Header onClick={(e) => onClick(e)}>{children}</Header>;
}

export default SubHeader;

const color = {
  headerText: '#3e8bd8',
};

const Header = styled.h3`
  font-family: NotoSansMedium;
  font-size: 12px;
  font-weight: 500;
  color: ${color.headerText};
  text-align: left;
  line-height: 1.5;
  margin-bottom: 4px;
`;
