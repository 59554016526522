import React from 'react';

import styled from '@emotion/styled';

import { emptyColleague } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IChat } from 'types/payloadTypes';

interface ChatRoomListProps {
  chatArray: Array<IChat>;
  onSelect: (selectChat: IChat) => void;
  scrollbarRef: React.RefObject<any>;
}

function ChatRoomList({ chatArray, onSelect, scrollbarRef }: ChatRoomListProps) {
  return (
    <ChatRoomListContainer ref={scrollbarRef}>
      {chatArray.map((chat) => {
        const { colleagueDepartment, colleagueHospital, colleagueName, colleaguePhoto, recentMessage, unreadCount } =
          chat;
        return (
          <ColleagueProfile onClick={() => onSelect(chat)}>
            <ColleaguePhoto alt="colleague-profile" src={colleaguePhoto || emptyColleague}></ColleaguePhoto>
            <ColleagueSection>
              <ColleagueInfo>
                <ColleagueInfoWrap>
                  <ColleagueName>{colleagueName}</ColleagueName>
                  <Hospital>
                    {colleagueDepartment ? ` ${colleagueHospital} / ${colleagueDepartment}` : colleagueHospital}
                  </Hospital>
                </ColleagueInfoWrap>
                {unreadCount > 1 && <MessageCount>{unreadCount}</MessageCount>}
              </ColleagueInfo>
              <LatestMessage>
                {recentMessage.length > 20 ? `${recentMessage.substr(0, 20)}...` : recentMessage}
              </LatestMessage>
            </ColleagueSection>
          </ColleagueProfile>
        );
      })}
    </ChatRoomListContainer>
  );
}

export default ChatRoomList;

const color = {
  colleagueName: '#424242',
  hospitalName: '#777777',
  unreadMessage: '#76ccd8',
  latestMessageText: '#a3a3a3',
};

const ChatRoomListContainer = styled.div`
  overflow-y: auto;
  padding: 0 24px;
`;

const Hospital = styled.div`
  font-size: 13px;
  color: ${color.hospitalName};
  margin-left: 5px;
`;

const ColleaguePhoto = styled.img`
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const ColleagueProfile = styled.div`
  display: flex;
  margin: 40px 0;
  align-items: center;
  cursor: pointer;
`;

const ColleagueName = styled.div`
  font-size: 13px;
  color: ${color.colleagueName};
`;

const ColleagueInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ColleagueInfoWrap = styled.div`
  display: flex;
`;

const LatestMessage = styled.span`
  color: ${color.latestMessageText};
  margin-top: 8px;
`;

const MessageCount = styled.div`
  ${flexCenter};
  background-color: ${color.unreadMessage};
  border-radius: 50%;
  width: 21px;
  height: 21px;
  margin-bottom: 3px;
  font-family: NotoSansCJKkr;
  font-weight: bold;
  color: white;
`;

const ColleagueSection = styled.section`
  ${flexColumn};
  width: 100%;
  font-size: 14px;
  margin-left: 16px;
`;
