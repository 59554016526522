import React, { useState } from 'react';

import styled from '@emotion/styled';

import { useMobile } from 'hooks';

import { device } from 'styles/global/device';
import { flexCenter } from 'styles/global/mixins';
import { dropdownActive, dropdownInactive } from 'assets/images';

import { IActive } from 'types/styleTypes';

interface ISelectedTime {
  dayPeriod: string;
  hour: string;
  minute: string;
}

interface TimePickerProps {
  onDayChange: (option: string) => void;
  onHourChange: (option: string) => void;
  onMinuteChange: (option: string) => void;
  selectedTime: ISelectedTime;
}

const hoursData = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

const minuteData = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

function TimePicker({ onDayChange, onHourChange, onMinuteChange, selectedTime }: TimePickerProps) {
  const isMobile = useMobile();

  const [showDayPeriodList, setShowDayPeriodList] = useState(false);
  const [showHourList, setShowHourList] = useState(false);
  const [showMinuteList, setShowMinuteList] = useState(false);

  const { dayPeriod, hour, minute } = selectedTime;
  return (
    <>
      <TimeDropdown
        active={showDayPeriodList}
        selected={dayPeriod.length > 0}
        onClick={() => setShowDayPeriodList(!showDayPeriodList)}
      >
        {dayPeriod}
        <DropdownIcon active={showDayPeriodList} src={showDayPeriodList ? dropdownActive : dropdownInactive} />
        {showDayPeriodList && (
          <TimeOptionList active={showDayPeriodList}>
            <Option onClick={() => onDayChange('AM')}>AM</Option>
            <Option onClick={() => onDayChange('PM')}>PM</Option>
          </TimeOptionList>
        )}
      </TimeDropdown>
      <HoursDropdown active={showHourList} selected={hour.length > 0} onClick={() => setShowHourList(!showHourList)}>
        {!hour ? (
          <>
            {isMobile ? '시간' : '시간 선택'}
            <DropdownIcon active={showHourList} src={showHourList ? dropdownActive : dropdownInactive} />
          </>
        ) : (
          `${hour} 시`
        )}
        {showHourList && (
          <HourOptionList active={showHourList}>
            {hoursData.map((hourOption: string, index: number) => (
              <Option key={`hourOption-${index}`} onClick={() => onHourChange(hourOption)}>
                {hourOption}
              </Option>
            ))}
          </HourOptionList>
        )}
      </HoursDropdown>
      <MinuteDropdown
        active={showMinuteList}
        selected={minute.length > 0}
        onClick={() => setShowMinuteList(!showMinuteList)}
      >
        {!minute ? (
          <>
            {isMobile ? '분' : '분 선택'}
            <DropdownIcon active={showMinuteList} src={showMinuteList ? dropdownActive : dropdownInactive} />
          </>
        ) : (
          `${minute} 분`
        )}
        {showMinuteList && (
          <MinuteOptionList active={showMinuteList}>
            {minuteData.map((minuteOption: string, index: number) => (
              <Option key={`minuteOption-${index}`} onClick={() => onMinuteChange(minuteOption)}>
                {minuteOption}
              </Option>
            ))}
          </MinuteOptionList>
        )}
      </MinuteDropdown>
    </>
  );
}

export default TimePicker;

const color = {
  activeText: '#036ad1',
  inactiveText: 'rgba(119, 119, 119, 0.5)',
  selectedText: '#777777',
  activeBorder: '#036ad1',
  inActiveBorder: '#e5e5e5',
  optionActiveText: '#3881dc',
};

const TimeDropdown = styled.div<IActive>`
  ${flexCenter};
  position: relative;
  width: 116px;
  height: 51px;
  margin-right: 8px;
  border-radius: 4px;
  font-family: NotoSansMedium;
  font-size: 15px;
  cursor: pointer;
  background-color: #ffffff;
  color: ${({ active, selected }) => {
    if (active) return color.activeText;
    if (selected) return color.selectedText;

    return color.inactiveText;
  }};
  border: solid 1px ${(props) => (props.active ? color.activeText : color.inActiveBorder)};
  @media ${device.mobileMax} {
    width: 80px;
  }
`;

const HoursDropdown = styled(TimeDropdown)``;

const MinuteDropdown = styled(TimeDropdown)``;

const DropdownIcon = styled.img<IActive>`
  width: 21px;
  height: 21px;
  resize-mode: contain;
  margin-left: 8px;
  transition: all ease 0.4s;
  transform: ${(props) => (props.active ? 'rotate(-180deg)' : '')};
`;

const TimeOptionList = styled.section<IActive>`
  position: absolute;
  text-align: center;
  top: 51px;
  z-index: 15;
  background-color: white;
  border-radius: 3px;
  width: 116px;
  padding: 10px 20px;
  border: 1px solid ${color.inActiveBorder};
  option:last-of-type {
    margin: 0;
  }
  height: 100px;
  max-height: 500px;
  animation: drop-down 0.5s;

  @keyframes drop-down {
    0% {
      max-height: 0;
      opacity: 0;
    }
    100% {
      max-height: auto;
      opacity: 1;
    }
  }
  @media ${device.mobileMax} {
    width: 80px;
  }
`;

const HourOptionList = styled(TimeOptionList)`
  height: 150px;
  overflow: auto;
`;

const MinuteOptionList = styled(TimeOptionList)`
  height: 250px;
  overflow: auto;
  @media ${device.mobileMax} {
    height: 170px;
  }
`;

const Option = styled.option`
  ${flexCenter};
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  height: 40px;
  color: #777777;
  font-family: NotoSansRegular;
  font-size: 16px;
  :hover {
    color: ${color.optionActiveText};
  }
`;
