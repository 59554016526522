import React, { useEffect, useState, useRef } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import 'moment/locale/ko';

import WaitingAppointmentDetail from './WaitingTable/WaitingAppointmentDetail';
import CompleteAppointmentDetail from './CompleteTable/CompleteAppointmentDetail';
import WaitingTable from './WaitingTable';
import CompleteTable from './CompleteTable';
import EmptyList from './EmptyList';
import { Spinner } from 'components/atoms';

import {
  patchInvitePatient,
  getAppointmentDetail,
  getTodayAppointment,
  getDiagnosis,
  getCooperationDoctorList,
  postMedicalRecordSuccess,
} from 'redux/modules/appointment';

import { useMobile, useModal } from 'hooks';

import { MainContainer, AppointmentListContainer } from 'styles/container';
import { MainPageTitle } from 'styles/text';
import { device } from 'styles/global/device';
import { flexColumn } from 'styles/global/mixins';

import { IActive } from 'types/styleTypes';
import { IRootState } from 'types/payloadTypes';

function AppointmentList() {
  moment.locale('ko');

  const dispatch = useDispatch();
  const history = useHistory();
  const { openModal } = useModal();
  const isMobile = useMobile();

  const targetAppointmentRef = useRef(0);

  const [optionStatus, setOptionStatus] = useState(0);
  const [targetAppointment, setTargetAppointment] = useState({
    id: 0,
    patientName: '',
    date: '',
  });
  const [timeRerender, setTimeRereder] = useState(0);

  const { loading } = useSelector((state: IRootState) => state.loading);
  const { appointmentDetail, completeList, waitingList, medicalRecordSaved } = useSelector(
    (state: IRootState) => state.appointment,
  );

  const { patient } = appointmentDetail.appointment;

  const enterRoom = async (appointmentId: number) => {
    if (isMobile) {
      history.push(`/mobile?appointmentId=${appointmentId}&audioOnly=false`);
      return;
    }
    window.open(`/treatment?appointmentId=${appointmentId}`, '', '_blank');
  };

  const handleStatusFilter = (status: number) => {
    setOptionStatus(status);
  };

  const handlePatientInvite = () => {
    dispatch(patchInvitePatient({ appointmentId: targetAppointmentRef.current }));
  };

  const toggleAppointmentDetail = (
    e: React.MouseEvent<HTMLTableRowElement>,
    appointmentId: number,
    appointmentDate?: string,
    patientName?: string,
  ) => {
    const { type } = e.target as HTMLButtonElement;

    if (type === 'submit' || type === 'file') {
      return;
    }

    if (appointmentId === targetAppointment.id) {
      setTargetAppointment({ ...targetAppointment, id: 0 });
      return;
    }

    dispatch(getAppointmentDetail({ appointmentId }));
    dispatch(getCooperationDoctorList({ appointmentId, departmentId: 0 }));
    if (optionStatus === 2) {
      dispatch(getDiagnosis({ appointmentId }));
    }
    setTargetAppointment({
      ...targetAppointment,
      patientName,
      id: appointmentId,
      date: appointmentDate,
    });
  };

  const toggleCooperationModal = () => {
    openModal({
      modalType: 'CooperationModal',
      modalProps: {
        targetAppointment,
      },
    });
  };

  const handleInviteModal = (appointmentId: number) => {
    targetAppointmentRef.current = appointmentId;
    openModal({
      modalType: 'ApprovalModal',
      modalProps: {
        onConfirm: handlePatientInvite,
        modalTitle: '상담 시작',
        modalExplanation: '해당환자에게 바로 상담이 가능한 것을 알립니다',
        confirmBtnText: '알림 보내기',
      },
    });
  };

  useEffect(() => {
    const remainingTime = setInterval(() => {
      if (timeRerender === 0) {
        setTimeRereder(1);
      } else {
        setTimeRereder(0);
      }
    }, 60000);
    return () => clearInterval(remainingTime);
  }, [timeRerender]);

  useEffect(() => {
    dispatch(getTodayAppointment());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (medicalRecordSaved) {
      openModal({
        modalType: 'ConfirmModal',
        modalProps: {
          modalTitle: '차트 저장 완료',
          modalExplanation: '차트가 성공적으로 저장되었습니다.',
          onConfirm: dispatch(postMedicalRecordSuccess(false)),
        },
      });
    }
    // eslint-disable-next-line
  }, [medicalRecordSaved]);

  return (
    <MainContainer>
      {loading && <Spinner />}
      <ListContainer>
        <AppointmentListContainer>
          <TitleSection>
            <div>
              <MainPageTitle margin="8.5px 18px 0 0">오늘예약</MainPageTitle>
              <Date>{moment().format('YYYY-MM-DD dddd')}</Date>
            </div>
            <FilterWrap>
              <FilterBtn active={optionStatus === 0} onClick={() => handleStatusFilter(0)}>
                {'대기 중 '}
                {`(${waitingList.count}건)`}
              </FilterBtn>
              <FilterBtn active={optionStatus === 2} onClick={() => handleStatusFilter(2)}>
                {'완료 '}
                {`(${completeList.count}건)`}
              </FilterBtn>
            </FilterWrap>
          </TitleSection>
          <div style={{ overflow: 'auto' }}>
            {optionStatus === 0 ? (
              <WaitingTable
                isMobile={isMobile}
                openCooperationModal={toggleCooperationModal}
                targetAppointment={targetAppointment.id}
                waitingList={waitingList}
                onEnter={(appointmentId: number) => enterRoom(appointmentId)}
                onInvite={(appointmentId: number) => handleInviteModal(appointmentId)}
                onShow={toggleAppointmentDetail}
              />
            ) : (
              <CompleteTable
                completeList={completeList}
                targetAppointment={targetAppointment.id}
                toggleAppointmentDetail={toggleAppointmentDetail}
              />
            )}
            {!waitingList.count && optionStatus === 0 && <EmptyList title="오늘 대기중인 진료가 없습니다" />}
            {!completeList.count && optionStatus === 2 && <EmptyList title="오늘 완료된 진료가 없습니다" />}
          </div>
        </AppointmentListContainer>
      </ListContainer>
      {targetAppointment.id !== 0 && (
        <PCAppointmentDetailSection>
          {optionStatus === 0 && (
            <WaitingAppointmentDetail
              showConnection
              appointmentId={targetAppointment.id}
              openCooperationModal={toggleCooperationModal}
              parent="AppointmentDetailColumn"
              patientId={patient}
              onStart={enterRoom}
            />
          )}
          {optionStatus === 2 && <CompleteAppointmentDetail patientId={patient} />}
        </PCAppointmentDetailSection>
      )}
    </MainContainer>
  );
}

export default AppointmentList;

const colors = {
  tableHeadText: '#bdbdbd',
  btnText: '#4d86bf',
  requestApprovedBtnBorder: '#a3d1ff',
  date: '#a3a3a3',
  noticeText: '#bdbdbd',
};

const PCAppointmentDetailSection = styled.section`
  @media ${device.laptopMax} {
    display: none;
  }
  animation: 1s in-out forwards;
  margin-top: 1px;
  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
`;

const TitleSection = styled.section`
  ${flexColumn};
`;

const FilterWrap = styled.div`
  margin-top: 40px;
  border-bottom: 1px solid #e0e0e0;
  @media ${device.mobileMax} {
    display: none;
  }
`;

const FilterBtn = styled.button<IActive>`
  height: 41px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  padding: 11px 16px;
  color: ${(props) => (props.active ? colors.btnText : colors.tableHeadText)};
  border: none;
  border-bottom: 1px solid ${(props) => (props.active ? colors.requestApprovedBtnBorder : 'none')};
  background-color: #f4f4f4;
  z-index: ${(props) => (props.active ? 2 : 1)};
`;

const Date = styled.div`
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  margin-top: 12px;
  color: ${colors.date};
`;
