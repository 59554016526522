import React from 'react';

import styled from '@emotion/styled';

import SelectDate from './SelectDate';
import UpdateSchedule from './UpdateSchedule';

import { MainContainer } from 'styles/container';
import { MainPageTitle } from 'styles/text';

function Schedule() {
  return (
    <MainContainer>
      <ScheduleContainer>
        <MainPageTitle>일정관리</MainPageTitle>
        <ScheduleContentWrap>
          <SelectDate />
          <UpdateSchedule />
        </ScheduleContentWrap>
      </ScheduleContainer>
    </MainContainer>
  );
}

export default Schedule;

const ScheduleContainer = styled.div`
  padding: 60px 72px 58px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`;

const ScheduleContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 35px;
  padding-bottom: 58px;
`;
