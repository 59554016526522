import React from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { dropdownListColors } from 'styles/global/color';
import { dropdownActive, dropdownInactive, resetFilterBtn } from 'assets/images';
import { BasicBtn } from 'styles/button';
import { flexCenter } from 'styles/global/mixins';

interface DropdownListProps {
  title: string;
  active: boolean;
  onActivate: () => void;
  onSelect: (e: React.MouseEvent<HTMLOptionElement, MouseEvent>, option: string) => void;
  width: number;
  optionArray: Array<string>;
  showOptions: boolean;
  height?: number;
  selected: boolean;
  disabled: boolean;
  onCancel: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function DropdownList({
  title,
  active,
  onActivate,
  onSelect,
  width,
  optionArray,
  showOptions,
  height,
  onCancel,
  selected,
  disabled,
}: DropdownListProps) {
  return (
    <div>
      <TitleWrap active={active} disabled={disabled} width={width} onClick={() => onActivate()}>
        <DropdownOption active={active} disabled={disabled} selected={selected}>
          {title}
        </DropdownOption>
        {!disabled && selected ? (
          <CancelSelectionBtn onClick={(e) => onCancel(e)}>
            <Image alt="cancel" src={resetFilterBtn} />
          </CancelSelectionBtn>
        ) : (
          <DropdownIcon src={!disabled && active ? dropdownActive : dropdownInactive} />
        )}
      </TitleWrap>
      {showOptions && (
        <OptionContainer>
          <OptionList height={height} width={width}>
            {optionArray.map((option: string, index: number) => (
              <Option key={`dropdownOption-${index}`} onClick={(e) => onSelect(e, option)}>
                {option}
              </Option>
            ))}
          </OptionList>
        </OptionContainer>
      )}
    </div>
  );
}

export default DropdownList;

interface SearchButtonProps {
  active?: boolean;
  marginRight?: number;
  category?: number;
  width?: number;
  selected?: boolean;
  disabled?: boolean;
}

interface OptionProps {
  width?: number;
  height?: number;
}

const TitleWrap = styled.div<SearchButtonProps>`
  ${(props) => `
    display: flex;
    flex-direction: row;
    z-index: -1;
    height: 42px;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-family: NotoSansMedium;
    font-size: 15px;
    background-color: ${props.disabled ? '#eaeaea' : 'white'};
    border-radius: 3px;
    outline: none;
    cursor: ${props.disabled ? 'default' : 'pointer'};
    width: ${props.width}px;
    margin-right: 10px;
    `}

  border: 1px solid ${({ disabled, active }) => {
    if (disabled) return '#e5e5e5';
    if (active) return dropdownListColors.active;

    return dropdownListColors.inactiveBorder;
  }}
`;

const DropdownOption = styled.span<SearchButtonProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-family: NotoSansMedium;
  font-size: 13px;
  width: 100%;
  ${(props) => `
    color: ${!props.disabled && props.active ? dropdownListColors.active : dropdownListColors.inactiveText};
    opacity: ${props.selected ? 1 : 0.5};
    cursor: ${props.disabled ? 'default' : 'pointer'};
  `}
`;

const DropdownIcon = styled.img`
  width: 21px;
  height: 21px;
  resize-mode: contain;
  margin-top: 1px;
`;

const Option = styled.option`
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 23px;
  color: ${dropdownListColors.optionText};
  font-family: NotoSansRegular;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: ${dropdownListColors.active};
  }
`;

const OptionList = styled.section<OptionProps>`
  position: absolute;
  top: 0px;
  z-index: 15;
  background-color: white;
  border-radius: 3px;
  width: ${(props) => props.width}px;
  margin-top: 10px;
  padding: 30px 20px 40px 20px;
  border: 1px solid ${dropdownListColors.border};
  option:last-child {
    margin: 0;
  }
  overflow: auto;
  height: ${(props) => props.height}px;
`;

const OptionContainer = styled.div`
  position: relative;
`;

const CancelSelectionBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 21px;
  height: 21px;
  z-index: 3;
  background-color: transparent;
`;
