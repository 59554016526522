import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { postPrescription, patchFinishTreatment } from 'redux/modules/appointment';

import { getParameter } from 'utils';
import { useForm } from 'hooks';

import { Table } from 'styles/table';
import { device } from 'styles/global/device';
import { BasicBtn, SubmitBtn } from 'styles/button';
import { H1 } from 'styles/text';
import { PerscriptionTextInput } from 'styles/textinput';
import { globalColors, tableColors, treatmentColors } from 'styles/global/color';
import { flexColumn } from 'styles/global/mixins';

import { IRootState } from 'types/payloadTypes';

function Prescription() {
  const dispatch = useDispatch();

  const [form, onFormChange] = useForm({});

  const [rows, setRows] = useState([1]);

  const { prescriptionInfo, medicalRecord } = useSelector((state: IRootState) => state.appointment);

  const { doctorName, hospitalName } = prescriptionInfo;

  const handleRowAdd = (index: number): void => {
    if (index === rows.length - 1) {
      const tempRows = [...rows];
      tempRows.push(tempRows[tempRows.length - 1] + 1);
      setRows(tempRows);
    }
  };

  const handleSessionEnd = () => {
    const finishedAt = moment().format('YYYY-MM-DD HH:mm:ss');
    dispatch(patchFinishTreatment({ finishedAt }));
    window.close();
  };

  const handlePerscriptionWrite = (): void => {
    const appointmentId = getParameter('appointmentId');

    dispatch(
      postPrescription({
        appointmentId,
        assessment: medicalRecord.assessment,
        prescription: form,
      }),
    );
    alert('처방전 발행');
  };

  return (
    <>
      {/* <UnavailableContainer>서비스 준비중 입니다</UnavailableContainer> */}
      <PrescriptionContainer>
        <section>
          <Title>{moment().format('YYYY-MM-DD(dd)')}</Title>
          <DoctorInfo>{`${hospitalName} ${doctorName}`}</DoctorInfo>
        </section>
        <PatientInfoWrap>
          <TextInputWrap>
            <TargetEmail>받는사람</TargetEmail>
            <TargetEmailInput
              name="health_center_email"
              placeholder="받는 사람 이메일을 입력해주세요"
              type="text"
              onChange={onFormChange}
            />
          </TextInputWrap>
        </PatientInfoWrap>
        <PerscriptionTable>
          <thead>
            <tr>
              {/* <th>처방코드</th> */}
              <th>약 명칭</th>
              <th>
                <HeadColumn>투여용량/일</HeadColumn>
              </th>
              <th>투여횟수/일</th>
              <th>
                <HeadColumn>총투여일</HeadColumn>
              </th>
              <th>
                {/* <HeadColumn> */}
                용법
                {/* </HeadColumn> */}
              </th>
              {/* <th>급여</th> */}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: number, index: number) => (
              <tr key={`${row}-${index}`} onClick={() => handleRowAdd(index)}>
                {/* <td>
                    <PerscriptionTextInput
                      name={`medicationCode-${index}`}
                      placeholder="코드입력"
                      width={131.5}
                      onChange={onFormChange}
                      onKeyDown={() => handleRowAdd(index)}
                    />
                  </td> */}
                <td>
                  <PerscriptionTextInput
                    name={`dosage_name_${index + 1}`}
                    placeholder="약 명칭"
                    width={4.5}
                    onChange={onFormChange}
                    onFocus={() => handleRowAdd(index)}
                  />
                  {/* </BodyColumn> */}
                </td>
                <td>
                  <BodyColumn>
                    <PerscriptionTextInput
                      name={`dosage_amount_${index + 1}`}
                      placeholder="-"
                      width={3.5}
                      onChange={onFormChange}
                    />
                  </BodyColumn>
                </td>
                <td>
                  <PerscriptionTextInput
                    name={`dosage_daily_${index + 1}`}
                    placeholder="-"
                    width={3.5}
                    onChange={onFormChange}
                  />
                </td>
                <td>
                  <BodyColumn>
                    <PerscriptionTextInput
                      name={`dosage_days_${index + 1}`}
                      placeholder="-"
                      width={3.5}
                      onChange={onFormChange}
                    />
                  </BodyColumn>
                </td>
                <td>
                  {/* <BodyColumn> */}
                  <PerscriptionTextInput
                    name={`dosage_howto_${index + 1}`}
                    placeholder="-"
                    width={3.5}
                    onChange={onFormChange}
                  />
                  {/* </BodyColumn> */}
                </td>
                {/* <td>
                    <PerscriptionTextInput
                      name={`price-${index}`}
                      placeholder="-"
                      width={130}
                      onChange={onFormChange}
                    />
                  </td> */}
              </tr>
            ))}
          </tbody>
        </PerscriptionTable>
        {/* <section
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        > */}
        <BtnSection>
          <SaveBtn onClick={() => handlePerscriptionWrite()}>
            처방전 발행
            <Image alt="save-chart" src="icons/ic-save.svg" />
          </SaveBtn>
          <FinishTreatmentWrap to="/home">
            <FinishTreatmentBtn onClick={() => handleSessionEnd()}>
              상담기록 종료
              <Image alt="treatment-finish" src="icons/ic-treatment-finish.svg" />
            </FinishTreatmentBtn>
          </FinishTreatmentWrap>
        </BtnSection>
        {/* </section> */}
      </PrescriptionContainer>
    </>
  );
}

export default Prescription;

const Title = styled(H1)`
  font-size: 18px;
  font-weight: 500;
  color: ${globalColors.name};
`;

const DoctorInfo = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
  margin-top: 8px;
  color: ${globalColors.detail};
`;

const PrescriptionContainer = styled.div`
  ${flexColumn};
  padding: 40px;
  flex: 1;
`;

const PatientInfoWrap = styled.section`
  margin-top: 32px;
`;

const PerscriptionTable = styled(Table)`
  margin-top: 32px;
  border-spacing: 0 5px;
  border-collapse: separate;

  tbody:before {
    content: '@';
    display: block;
    line-height: 0px;
    text-indent: -99999px;
  }

  thead tr th {
    font-family: NotoSansRegular;
    font-size: 15px;
    font-weight: normal;
    padding: 9px 0 9px;
    background-color: ${tableColors.headBackground};
    color: ${tableColors.headText};
  }

  tbody tr td {
    padding: 12px 0 12px;
    font-size: 15px;
    background-color: ${tableColors.bodyBackground};
    color: ${tableColors.bodyText};
  }
`;

const BodyColumn = styled.div`
  border-left: 1px solid ${tableColors.bodyBorder};
  border-right: 1px solid ${tableColors.bodyBorder};
`;

const HeadColumn = styled.div`
  border-left: 1px solid ${tableColors.headBorder};
  border-right: 1px solid ${tableColors.headBorder};
`;

const TextInputWrap = styled.section`
  ${flexColumn};

  justify-content: flex-start;
`;

const TargetEmail = styled.div`
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 14px;
  margin-right: 8px;
  color: ${globalColors.sub};
  @media ${device.laptopMax} {
    font-size: 14px;
  }
`;
const TargetEmailInput = styled.input`
  padding: 8px;
  font-family: NotoSansRegular;
  font-size: 14px;
  margin-top: 6px;
  color: ${globalColors.cancelBtnBorder};
  font-family: NotoSansRegular;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #d8d8d8;
  outline: none;
`;

// const PerscriptionTextInput = styled.textarea`

//     font-family: NotoSansRegular;
//     font-size: 15px;
//     font-weight: normal;
//     border: 1px solid #d8d8d8;
//     outline: none;
//     height: 7.5vh;
//     padding: 6px 0 0 10px;
//     margin-top: 6px;
//     width: 100%;
//     color: ${globalColors.sub}
//     text-align: left;
//     ::placeholder {
//       opacity: 0.5;
//     }
//     ::-webkit-input-placeholder {
//         color: ${globalColors.placeholder};
//     }
//     :-ms-input-placeholder {
//         color: ${globalColors.placeholder};
//     }
//     ::-moz-placeholder {
//         color: ${globalColors.placeholder};
//         opacity: 1;
//     }
//     :-moz-placeholder {
//         color: ${globalColors.placeholder};
//         opacity: 1;
//     }

//   @media ${device.laptopMax} {
//     font-size: 13px;
//   }
// `;

export const MedicalRecordTextInput = styled.input`
  font-family: NotoSansRegular;
  font-size: 14px;
  font-weight: normal;
  border: 1px solid #d8d8d8;
  outline: none;
  width: 100%;
  margin-top: 16px;

  padding-left: 8px;
  color: ${globalColors.sub};
  text-align: left;
  ::placeholder {
    opacity: 0.5;
  }
  ::-webkit-input-placeholder {
    color: ${globalColors.placeholder};
  }
  :-ms-input-placeholder {
    color: ${globalColors.placeholder};
  }
  ::-moz-placeholder {
    color: ${globalColors.placeholder};
    opacity: 1;
  }
  :-moz-placeholder {
    color: ${globalColors.placeholder};
    opacity: 1;
  }

  @media ${device.laptopMax} {
    font-size: 13px;
  }
`;

const BtnSection = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 16px;
`;

const SaveBtn = styled(SubmitBtn)`
  width: 144px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
  margin-top: 0;
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  @media ${device.laptopMax} {
    font-size: 13px;
  }

  img {
    width: 15.454px;
    height: 15.322px;
  }
`;

const FinishTreatmentWrap = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 15;
`;

const FinishTreatmentBtn = styled(BasicBtn)`
  width: 144px;
  height: 40px;
  border-radius: 5px;
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
  border: 1px solid ${treatmentColors.finishBtnBorder};
  color: ${treatmentColors.finishBtnText};
  background-color: ${treatmentColors.finishBtnBackground};
  align-items: center;
  display: flex;
  padding: 0 16px;
  justify-content: space-between;
  text-decoration: none;
  margin-left: 12px;
  @media ${device.laptopMax} {
    font-size: 13px;
  }

  img {
    width: 15.454px;
    height: 15.322px;
  }
`;
