import React from 'react';

import styled from '@emotion/styled';

import { searchBarColors } from 'styles/global/color';
import { searchIcon, searchIconActive } from 'assets/images';

interface SearchBarProps {
  active: boolean;
  name: string;
  placeholder: string;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSearch?: () => void;
}

function SearchBar({ active, name, placeholder, onInputChange, onSearch }: SearchBarProps) {
  return (
    <>
      <SearchInput
        name={name}
        placeholder={placeholder}
        type="text"
        onChange={(e) => onInputChange(e)}
        onClick={() => onSearch()}
      />
      <SearchBtnImage src={active ? searchIconActive : searchIcon} />
    </>
  );
}

export default SearchBar;

const SearchBtnImage = styled.img`
  width: 14.8px;
  height: 14.8px;
`;

interface SearchButtonProps {
  active?: boolean;
  marginRight?: number;
  category?: number;
  width?: number;
  height?: number;
}

const SearchOptionText = styled.div<SearchButtonProps>`
  font-family: NotoSansMedium;
  font-size: 13px;
  ${(props) => `
    color: ${props.active ? searchBarColors.active : searchBarColors.inactiveText};
    border: none;
    background-color: transparent;
    padding: 0;
  `}
`;

const SearchInput = styled(SearchOptionText.withComponent('input'))<SearchButtonProps>`
  color: ${searchBarColors.active};
  width: 100%;
  border: none;
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 13px;
  padding: 0;
  caret-color: ${searchBarColors.active};
  ::placeholder {
    color: ${(props) => (props.active ? searchBarColors.active : searchBarColors.inactiveText)};
    font-size: 13px;
    opacity: 50%;
  }
`;
