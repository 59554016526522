import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Button } from 'components/atoms';

import { formatDate, formatDayPeriodTime } from 'utils';

import { modalColors } from 'styles/global/color';
import { BasicBtn, CancelBtn } from 'styles/button';
import { flexCenter } from 'styles/global/mixins';
import { calendarIcon, doctorIcon, inActiveCloseBtn } from 'assets/images';

import { ICooperationDoctor } from 'types/payloadTypes';

interface SelectedDoctorListProps {
  doctorArray: Array<ICooperationDoctor>;
  targetAppointment: { id: number; patientName: string; date: string };
  handleTabSelect: (selectedTab: string) => void;
  onRemove: (id: number) => void;
}

function SelectedDoctorList({ doctorArray, targetAppointment, handleTabSelect, onRemove }: SelectedDoctorListProps) {
  const [errorStatus, setErrorStatus] = useState(false);
  const [isHovering, setIsHovering] = useState({
    onHover: false,
    hoverID: 0,
  });

  return (
    <>
      <ModalTitle>요청확인 및 요청하기</ModalTitle>
      <ModalExplanation>예약의 내용을 확인하고 요청을 진행합니다</ModalExplanation>
      <ErrorMessageWrap>
        {!doctorArray.length && <ErrorMessage>협진 요청할 의료진을 선택해주세요</ErrorMessage>}
        {errorStatus && doctorArray.length >= 4 && <ErrorMessage>협진 요청 인원은 최대 4명입니다</ErrorMessage>}
      </ErrorMessageWrap>
      <ScheduleWrap>
        <CalendarIcon src={calendarIcon} />
        <ScheduleText>
          {`${formatDate(targetAppointment.date, 'YYYY-MM-DD (dd)')} ${formatDayPeriodTime(targetAppointment.date)} ${
            targetAppointment.patientName
          } 환자 진료`}
        </ScheduleText>
      </ScheduleWrap>
      <ScheduleTableSection>
        {doctorArray.map((doctor: ICooperationDoctor, index) => (
          <ScheduleTable
            key={`schedule-${index}`}
            onMouseEnter={() => setIsHovering({ onHover: true, hoverID: doctor.id })}
            onMouseLeave={() => setIsHovering({ onHover: false, hoverID: 0 })}
          >
            {isHovering && isHovering.hoverID === doctor.id && (
              <DeleteBtn onClick={() => onRemove(doctor.id)}>
                <DeleteIcon src={inActiveCloseBtn} />
              </DeleteBtn>
            )}
            <ScheduleInfoImg src={doctorIcon} />
            <DoctorName>{doctor.fullName}</DoctorName>
            <DepartmentName>
              {doctor.department}
              {' 전문의 '}
              &nbsp;
            </DepartmentName>
            <Hospital>{doctor.hospital}</Hospital>
          </ScheduleTable>
        ))}
        {doctorArray.length < 4 && (
          <AddScheduleTable onClick={() => handleTabSelect('DepartmentListTab')}>
            <AddScheduleIcon src={inActiveCloseBtn} />
          </AddScheduleTable>
        )}
      </ScheduleTableSection>
      <BtnWrap>
        <BackBtn
          type="button"
          onClick={doctorArray.length === 4 ? () => setErrorStatus(true) : () => handleTabSelect('DoctorListTab')}
        >
          이전
        </BackBtn>
        <Button
          active={!!doctorArray.length}
          color="lightBlue"
          disabled={!doctorArray.length}
          size="big"
          onClick={() => handleTabSelect('ConfirmRequestTab')}
        >
          요청하기
        </Button>
      </BtnWrap>
    </>
  );
}

export default SelectedDoctorList;

const colors = {
  sub: '#777777',
  text: '#717171',
  h3Text: '#a3a3a3',
  titleText: '#777777',
  backBtnActiveText: '#a3a3a3',
  backBtnActiveBackground: '#d8d8d8',
  scheduleTableBorder: '#dbdbdb',
  confirmActiveBorder: '#abe2ea',
  confirmActiveBackground: '#d4f3f7',
  confirmActiveText: '#399ba7',
  confirmInActiveText: '#909090',
  confirmInActiveBackground: '#ededed',
};

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 18px;
  color: ${modalColors.normalText};
  font-weight: 500;
  margin-top: 32px;
`;

const ModalText = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
`;

const ModalExplanation = styled(ModalText)`
  color: ${modalColors.normalText};
  margin-top: 9px;
  line-height: 30px;
`;

const DoctorName = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: ${colors.text};
`;

const DepartmentName = styled.div`
  font-size: 13px;
  color: ${colors.sub};
  margin: 10px 0;
`;

const Hospital = styled(DepartmentName)`
  color: #a3a3a3;
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;

const BackBtn = styled(CancelBtn)`
  width: 174px;
  padding: 8px 0;
  font-size: 16px;
  line-height: 24px;
  :hover {
    color: ${colors.backBtnActiveText};
    background-color: ${colors.backBtnActiveBackground};
    border: 1px solid ${colors.backBtnActiveBackground};
  }
`;

const ScheduleWrap = styled.div`
  display: flex;
  height: 22px;
  align-content: center;
  text-align: center;
  margin-top: 48px;
`;

const CalendarIcon = styled.img`
  width: 13.6px;
  height: 13.8px;
  margin-right: 12.7px;
  object-fit: contain;
`;

const ScheduleText = styled.h1`
  font-family: NotoSansCJKkr;
  font-size: 15px;
  letter-spacing: 0.08px;
  color: ${colors.titleText};
`;

const ScheduleTableSection = styled.div`
  display: flex;
  overflow: scroll;
  align-items: center;
  margin-top: 8px;
  height: 200px;
`;

const ScheduleTable = styled.div`
  position: relative;
  min-width: 162px;
  height: 136px;
  margin-right: 2px;
  margin-left: 10px;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px ${colors.scheduleTableBorder};
  background-color: white;
`;

const AddScheduleTable = styled(ScheduleTable)`
  ${flexCenter};
  cursor: pointer;
  border: dashed 1px ${colors.scheduleTableBorder};
`;

const AddScheduleIcon = styled.img`
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
`;

const ScheduleInfoImg = styled.img`
  width: 22px;
  height: 29px;
  margin-bottom: 12px;
  object-fit: contain;
  display: inline;
`;

const DeleteBtn = styled(BasicBtn)`
  ${flexCenter};
  position: absolute;
  width: 22px;
  height: 22px;
  background-color: white;
  border: 1px solid ${colors.scheduleTableBorder};
  border-radius: 50%;
  top: -10px;
  left: -10px;
  animation: zoomIn 0.2s alternate;
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
    50% {
      opacity: 1;
    }
  }
`;

const DeleteIcon = styled.img`
  width: 8px;
  height: 8px;
`;

const ErrorMessageWrap = styled.div`
  display: flex;
  position: relative;
`;

const ErrorMessage = styled(ModalExplanation)`
  color: red;
  margin-top: 0px;
  line-height: 0px;
  position: absolute;
  top: 15px;
  animation: zoomIn 0.4s alternate;
  @keyframes zoomIn {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
`;
