import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';

import { Image } from 'components/atoms';

import { getPolicy } from 'redux/modules/authentication';

import { recordingColor } from 'styles/global/color';
import { flexCenter, flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IRootState } from 'types/payloadTypes';

interface PrivacyPolicyProps {
  onShow: () => void;
}

function PrivacyPolicy({ onShow }: PrivacyPolicyProps) {
  const dispatch = useDispatch();

  const { title, script } = useSelector((state: IRootState) => state.authentication.policy);

  useEffect(() => {
    dispatch(getPolicy(3));
    // eslint-disable-next-line
  }, []);

  return (
    <PrivacyPolicyContainer>
      <Header>
        <ShowBtn onClick={() => onShow()}>
          <Image alt="go-back" src="icons/ic-back-arrow.svg" />
        </ShowBtn>
        <PolicyTitle>{title}</PolicyTitle>
      </Header>
      <PolicyScript>{script}</PolicyScript>
    </PrivacyPolicyContainer>
  );
}

export default PrivacyPolicy;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ShowBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 32px;
  height: 32px;
`;

const PolicyTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 15px;
  line-height: 1.47;
  color: ${recordingColor.policyTitle};
  font-weight: 500;
  margin-left: 56px;
  min-width: 200px;
  text-align: left;
`;

const PolicyScript = styled.div`
  font-size: 12px;
  color: ${recordingColor.policyScript};
  text-align: left;
  white-space: pre-line;
  margin: 0;
  padding: 0;
  margin: 77px 90px 0;
`;

const PrivacyPolicyContainer = styled.div`
  ${flexColumn};
  padding: 70px 100px;
  background-color: white;
`;
