import React, { useState, useRef, useEffect } from 'react';

import styled from '@emotion/styled';

import { flexColumn } from 'styles/global/mixins';
import { activePrevBtn, activeNextBtn, activeCloseBtn } from 'assets/images';

interface SymptomsImageModalProps {
  imageArray: Array<string>;
  onClose: () => void;
}
function SymptomsImageModal({ imageArray, onClose }: SymptomsImageModalProps) {
  const slideRef = useRef(null);

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextBtnClick = () => {
    setCurrentSlide(currentSlide >= imageArray.length - 1 ? 0 : currentSlide + 1);
  };

  const handlePrevBtnClick = () => {
    setCurrentSlide(currentSlide === 0 ? imageArray.length - 1 : currentSlide - 1);
  };

  useEffect(() => {
    slideRef.current.style.transition = 'all 0.5s ease-in-out';
    slideRef.current.style.transform = `translateX(-${currentSlide * 8}00px)`;
  }, [currentSlide]);

  return (
    <SymptomsModalContainer>
      <SliderHeader>
        <CurrentSlideText>{imageArray.length > 1 && `${currentSlide + 1} | ${imageArray.length}`}</CurrentSlideText>
        <CloseWrap onClick={() => onClose()}>
          <CloseIcon alt="close-button" src={activeCloseBtn} />
          <CloseModalText>닫기</CloseModalText>
        </CloseWrap>
      </SliderHeader>
      <SliderContainer>
        {imageArray.length > 1 && (
          <BtnIcon alt="prev-button" src={activePrevBtn} onClick={() => handlePrevBtnClick()} />
        )}
        <SliderContainerWrap>
          <Slider ref={slideRef}>
            {imageArray.map((image: string, index: number) => (
              <PatientUploadedImage key={`image-${index}`} alt={`injury-${index + 1}`} src={image} />
            ))}
          </Slider>
        </SliderContainerWrap>
        {imageArray.length > 1 && (
          <BtnIcon alt="next-button" src={activeNextBtn} onClick={() => handleNextBtnClick()} />
        )}
      </SliderContainer>
    </SymptomsModalContainer>
  );
}

export default SymptomsImageModal;

const SymptomsModalContainer = styled.div`
  ${flexColumn};
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SliderHeader = styled.div`
  width: 800px;
  margin: 5px auto;
  justify-content: space-between;
  display: flex;
`;

const CurrentSlideText = styled.span`
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-top: 10px;
`;

const CloseModalText = styled(CurrentSlideText)``;

const CloseWrap = styled.div`
  cursor: pointer;
  display: flex;
  :hover {
    text-decoration: underline;
    color: whitesmoke;
  }
`;

const CloseIcon = styled.img`
  display: inline;
  width: 35px;
  height: 35px;
`;

const SliderContainerWrap = styled.div`
  width: 800px;
  height: 800px;
  overflow: hidden;
  display: flex;
`;

const Slider = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

const PatientUploadedImage = styled.img`
  width: 800px;
  height: 800px;
  object-fit: fill;
  display: block;
`;

const BtnIcon = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
`;
