import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import { DoctorList, DepartmentList, ConfirmRequest } from 'components/molecules';
import { SelectedDoctorList } from 'components/organisms';

import { getCooperationDoctorList, getDepartmentList, patchCooperationDoctorRequest } from 'redux/modules/appointment';

import { inActiveCloseBtn } from 'assets/images';
import { BasicBtn } from 'styles/button';
import { flexCenter } from 'styles/global/mixins';

import { ICooperationDoctor, IDepartmentList, IRootState } from 'types/payloadTypes';

interface CooperationModalProps {
  targetAppointment: { id: number; patientName: string; date: string };
  onClose: () => void;
}

function CooperationModal({ targetAppointment, onClose }: CooperationModalProps) {
  const dispatch = useDispatch();

  const [departmentArray, setDepartmentArray] = useState<IDepartmentList[]>([]);
  const [doctorArray, setDoctorArray] = useState<ICooperationDoctor[]>([]);
  const [selected, setSelected] = useState({
    selectedTab: 'DepartmentListTab',
    selectedDepartment: 0,
    selectedDoctorArray: [],
  });

  const { departmentList, cooperationDoctorList } = useSelector((state: IRootState) => state.appointment);

  const { selectedTab, selectedDepartment, selectedDoctorArray } = selected;

  const confirmCooperation = () => {
    const doctors = new Set(selectedDoctorArray.map((doctor) => doctor.id));
    dispatch(
      patchCooperationDoctorRequest({
        doctors,
        appointmentId: targetAppointment.id,
      }),
    );
    onClose();
  };

  const getUniqueDoctorList = () => {
    const doctors = selectedDoctorArray.filter(
      (selectedDoctor, index, callback) => index === callback.findIndex((doctor) => doctor.id === selectedDoctor.id),
    );

    return doctors;
  };

  const handleDoctorRemove = (id: number) => {
    const removeDoctorList = selectedDoctorArray.filter((doctor) => doctor.id! !== id);

    setSelected({
      ...selected,
      selectedDoctorArray: removeDoctorList,
    });
  };

  const selectTab = (tab: string) => {
    setSelected({
      ...selected,
      selectedTab: tab,
    });
  };

  const selectDepartment = (department: number, tab: string) => {
    setSelected({
      ...selected,
      selectedTab: tab,
      selectedDepartment: department,
    });
  };

  const selectDoctor = (doctor: ICooperationDoctor, tab: string) => {
    setSelected({
      ...selected,
      selectedTab: tab,
      selectedDoctorArray: [...selectedDoctorArray, doctor],
    });
  };

  useEffect(() => {
    departmentList.forEach((department) => {
      department.name = department.name.replace(' ', '\n');
    });
    const sortedDepartmentList = departmentList.sort((a, b) => a.name.localeCompare(b.name));
    // eslint-disable-next-line
    setDepartmentArray(sortedDepartmentList);
  }, [departmentList]);

  useEffect(() => {
    const sortedDoctorList = cooperationDoctorList.sort((a, b) => a.fullName.localeCompare(b.fullName));
    // eslint-disable-next-line
    setDoctorArray(sortedDoctorList);
  }, [cooperationDoctorList]);

  useEffect(() => {
    if (selectedDepartment && selectedTab === 'DoctorListTab') {
      dispatch(
        getCooperationDoctorList({
          appointmentId: targetAppointment.id,
          departmentId: selectedDepartment,
        }),
      );
    }
    // eslint-disable-next-line
  }, [selectedDepartment, selectedTab]);

  useEffect(() => {
    dispatch(getDepartmentList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTab === 'DepartmentListTab') return setDoctorArray([]);
    // eslint-disable-next-line
  }, [selectedTab]);

  return (
    <ModalContaier>
      <CloseBtnWrap>
        <CloseBtn onClick={() => onClose()}>
          <Image alt="close-button" src={inActiveCloseBtn} />
        </CloseBtn>
      </CloseBtnWrap>
      {selectedTab === 'DepartmentListTab' && (
        <DepartmentList departmentArray={departmentArray} onSelect={selectDepartment} />
      )}
      {selectedTab === 'DoctorListTab' && (
        <DoctorList
          doctorArray={doctorArray}
          handleDepartmentSelect={selectDepartment}
          handleDoctorSelect={selectDoctor}
        />
      )}
      {selectedTab === 'SelectedDoctorListTab' && (
        <SelectedDoctorList
          doctorArray={getUniqueDoctorList()}
          handleTabSelect={selectTab}
          targetAppointment={targetAppointment}
          onRemove={handleDoctorRemove}
        />
      )}
      {selectedTab === 'ConfirmRequestTab' && <ConfirmRequest onConfirm={confirmCooperation} onSelect={selectTab} />}
    </ModalContaier>
  );
}

export default CooperationModal;

const ModalContaier = styled.form`
  width: 620px;
  padding: 40px 40px 30px;
  border-radius: 16px;
  background-color: white;
`;

const CloseBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 18px;
  height: 18px;
`;
