import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { flexCenter } from 'styles/global/mixins';

const subDoctorStyle = css`
  margin-left: 8px;
`;

const localVideoStyle = css`
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
`;
export const ParticipantView = styled.div<{
  count: number;
  isLocal?: boolean;
  isSubDoctor?: boolean;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  ${({ count }) => `
    width: ${count === 1 ? '100%' : '50%'}
  `}
  flex-grow: 1;
  ${({ isSubDoctor }) => isSubDoctor && subDoctorStyle};
  > video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    ${({ isLocal }) => isLocal && localVideoStyle}
  }
`;

export const ParticipantOverlay = styled.div`
  ${flexCenter};
  position: absolute;
  background-color: rgba(31, 31, 31, 0.5);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  background-image: url(/icons/ic-avatar.svg);
`;

export const ParticipantMutedIcon = styled.div`
  width: 10.2px;
  height: 19.5px;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(/icons/ic-audio-muted.svg);
  margin-left: 8px;
`;

export const ParticipantInfo = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 16px;
  bottom: 17px;
  height: 30px;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(31, 31, 31, 0.5);
  color: white;
`;
