import React from 'react';

import styled from '@emotion/styled';
import { scheduleModalColors } from 'styles/global/color';

interface CancelTreatmentModalProps {
  onConfirm: () => void;
}

function CancelTreatmentModal({ onConfirm }: CancelTreatmentModalProps) {
  return (
    <CancelTreatmentModalContainer>
      <ModalTitle>상담 취소</ModalTitle>
      <ModalExplanation>환자가 입장하지 않아 상담을 취소합니다</ModalExplanation>
      <ModalBtnWrap>
        <ConfirmBtn onClick={() => onConfirm()}>확인</ConfirmBtn>
      </ModalBtnWrap>
    </CancelTreatmentModalContainer>
  );
}

export default CancelTreatmentModal;

const CancelTreatmentModalContainer = styled.form`
  width: 620px;
  padding: 40px 40px 30px;
  border-radius: 5px;
  background-color: white;
`;

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 20px;
  color: ${scheduleModalColors.normalText};
  font-weight: 500;
  text-align: left;
`;

const ModalText = styled.h2`
  font-family: NotoSansRegular;
  font-size: 18px;
  font-weight: normal;
  text-align: left;
`;

const ModalExplanation = styled(ModalText)`
  color: ${scheduleModalColors.normalText};
  margin-top: 9px;
  line-height: 30px;
`;

const ConfirmBtn = styled(ModalText.withComponent('button'))`
  width: 230px;
  color: ${scheduleModalColors.confirmBtnText};
  background-color: ${scheduleModalColors.confirmBtnBackground};
  border: 1px solid ${scheduleModalColors.confirmBtnBorder};
  border-radius: 3px;
`;

const ModalBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 50px;
  height: 45px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button:last-child {
    margin-left: 11px;
  }
`;
