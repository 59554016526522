/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call, takeLatest, select, all } from 'redux-saga/effects';

import * as scheduleAPI from 'redux/lib/api/schedule';

import { IScheduleState, IRootState } from 'types/payloadTypes';
import { showFailModal, showSuccessModal } from './modal';

// eslint-disable-next-line @typescript-eslint/naming-convention
const GET_appointmentDateS = 'schedule/GET_appointmentDateS';
// eslint-disable-next-line @typescript-eslint/naming-convention
const GET_appointmentDateS_SUCCESS = 'schedule/GET_appointmentDateS_SUCCESS';
const GET_DOCTOR_SCEDULE = 'schedule/GET_DOCTOR_SCEDULE';
const GET_DOCTOR_SCEDULE_SUCCESS = 'schedule/GET_DOCTOR_SCEDULE_SUCCESS';
const POST_DOCTOR_SCHEDULE = 'schedule/SAVE_DOCTOR_SCHEDULE';
const PATCH_DOCTOR_SCHEDULE = 'schedule/PATCH_DOCTOR_SCHEDULE';
const POST_UNAVAILABLE_DATE = 'schedule/POST_UNAVAILABLE_DATE';

const GET_TIME_OPTIONS = 'schedule/GET_TIME_OPTIONS';
const GET_TIME_OPTIONS_SUCCESS = 'schedule/GET_TIME_OPTIONS_SUCCESS';

export const postUnavailableDate = (payload) => ({
  type: POST_UNAVAILABLE_DATE,
  payload,
});

export const postDoctorSchedule = (payload) => ({
  type: POST_DOCTOR_SCHEDULE,
  payload,
});

export const patchDotorSchedule = (payload) => ({
  type: PATCH_DOCTOR_SCHEDULE,
  payload,
});

export const getAppointmentDates = (payload) => ({
  type: GET_appointmentDateS,
  payload,
});

const getAppointmentDatesSuccess = (payload) => ({
  type: GET_appointmentDateS_SUCCESS,
  payload,
});

export const getDoctorSchedule = () => ({
  type: GET_DOCTOR_SCEDULE,
});

const getDoctorScheduleSuccess = (payload) => ({
  type: GET_DOCTOR_SCEDULE_SUCCESS,
  payload,
});

export const getTimeOptions = () => ({
  type: GET_TIME_OPTIONS,
});

const getTimeOptionsSuccess = (payload) => ({
  type: GET_TIME_OPTIONS_SUCCESS,
  payload,
});

const initialState: IScheduleState = {
  datesList: [],
  availableDayList: [],
  availableTimesList: {},
  unavailableDatesList: [],
  timeOptionList: [],
};

interface IScheduleAction {
  type: string;
  payload: IScheduleState;
}

const scheduleReducer = (state = initialState, action: IScheduleAction) => {
  switch (action.type) {
    case GET_TIME_OPTIONS_SUCCESS: {
      const { timeOptionList } = action.payload;
      return {
        ...state,
        timeOptionList,
      };
    }
    case GET_DOCTOR_SCEDULE_SUCCESS: {
      const { availableTimesList } = action.payload;
      return {
        ...state,
        availableTimesList,
      };
    }
    case GET_appointmentDateS_SUCCESS: {
      const { datesList, availableDayList, unavailableDatesList } = action.payload;

      return {
        ...state,
        datesList,
        availableDayList,
        unavailableDatesList,
      };
    }
    default:
      return state;
  }
};

export default scheduleReducer;

function* fetchAppointmentDates({ type, payload }) {
  try {
    const doctorId = yield select((state: IRootState) => state.authentication.userInfo.doctorId);

    const {
      data: { dates_list, available_days, unavailable_dates },
    } = yield call(scheduleAPI.appointmentDate, { doctorId, ...payload });

    yield put(
      getAppointmentDatesSuccess({
        datesList: dates_list,
        availableDayList: available_days,
        unavailableDatesList: unavailable_dates,
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchDoctorSchedule() {
  try {
    const {
      data: { time_tables },
    } = yield call(scheduleAPI.doctorSchedule);

    yield put(
      getDoctorScheduleSuccess({
        availableTimesList: time_tables,
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchCreateDoctorSchedule({ type, payload }) {
  try {
    const { status } = yield call(scheduleAPI.createDoctorSchedule, payload);

    if (status === 200) {
      yield all([
        yield put(
          showSuccessModal({
            modalInfo: {
              modalTitle: '저장 완료',
              modalExplanation: '일정을 성공적으로 저장하였습니다.',
            },
          }),
        ),
        yield call(fetchDoctorSchedule),
      ]);
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '저장 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchUpdateDotorSchedule({ type, payload }) {
  try {
    const response = yield call(scheduleAPI.updateDoctorSchedule, payload);
    if (response.status === 200) {
      yield all([
        yield put(
          showSuccessModal({
            modalInfo: {
              modalTitle: '저장 완료',
              modalExplanation: '일정을 성공적으로 저장하였습니다.',
            },
          }),
        ),
        yield call(fetchDoctorSchedule),
      ]);
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '저장 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchUnavailableDate({ type, payload }) {
  try {
    const response = yield call(scheduleAPI.unavailabledate, payload);
    if (response.status === 201) {
      yield all([
        yield put(
          showSuccessModal({
            modalInfo: {
              modalTitle: '저장 완료',
              modalExplanation: '일정을 성공적으로 저장하였습니다.',
            },
          }),
        ),
        yield call(fetchDoctorSchedule),
      ]);
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '저장 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchTimeOptions() {
  try {
    const { data, status } = yield call(scheduleAPI.scheduleTimeOption);

    if (status === 200) {
      yield put(getTimeOptionsSuccess({ timeOptionList: data }));
    }
  } catch (e) {
    console.error(e);
  }
}

export const scheduleSagas = [
  takeLatest(GET_appointmentDateS, fetchAppointmentDates),
  takeLatest(GET_DOCTOR_SCEDULE, fetchDoctorSchedule),
  takeLatest(POST_DOCTOR_SCHEDULE, fetchCreateDoctorSchedule),
  takeLatest(PATCH_DOCTOR_SCHEDULE, fetchUpdateDotorSchedule),
  takeLatest(POST_UNAVAILABLE_DATE, fetchUnavailableDate),
  takeLatest(GET_TIME_OPTIONS, fetchTimeOptions),
];
