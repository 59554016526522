import React, { useState } from 'react';

import { useStore } from 'react-redux';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import { Specialties } from 'components/molecules';
import { Experience, Phone, Password } from 'components/organisms';

import { mypageColor, globalColors } from 'styles/global/color';
import { BasicBtn } from 'styles/button';
import { specialtiesIcon, passwordIcon, careerIcon, phoneIcon } from 'assets/images';

interface MypageProps {
  onClose: () => void;
  onConfirm: () => void;
}

type SelectedTab = 'main' | 'specialties' | 'password' | 'experience' | 'phone';

const tabName: { title: string; enTitle: SelectedTab; icon: string }[] = [
  { title: '', enTitle: 'main', icon: '' },
  {
    title: '주 진료과목 수정',
    enTitle: 'specialties',
    icon: specialtiesIcon,
  },
  { title: '비밀번호 수정', enTitle: 'password', icon: passwordIcon },
  { title: '학력 및 경력 수정', enTitle: 'experience', icon: careerIcon },
  { title: '핸드폰번호 수정', enTitle: 'phone', icon: phoneIcon },
];

function Mypage({ onClose, onConfirm }: MypageProps) {
  const state = useStore().getState();

  const [selectedTab, setSelectedTab] = useState<SelectedTab>('main');

  const { departmentName, fullname, username, phoneNumber, hospital, doctorPhoto } = state.authentication.userInfo;

  return (
    <MypageContainer>
      <BtnWrap>
        <BackBtnWrap onClick={() => setSelectedTab('main')}>
          {selectedTab !== 'main' && <Image alt="back-arrow" src="icons/ic-back-arrow.svg" />}
        </BackBtnWrap>
        <MenuTabName>{tabName.find((tab) => tab.enTitle === selectedTab).title}</MenuTabName>
        <CloseBtnWrap onClick={() => onClose()}>
          <Image alt="close" src="icons/ic-close.svg" />
        </CloseBtnWrap>
      </BtnWrap>
      {selectedTab === 'main' && (
        <>
          <DoctorProfile>
            <DoctorPictureWrap>
              <DoctorPicture alt="doctor" src={doctorPhoto} />
              <ChangePictureBtn>
                <Image alt="profile-change" src="icons/ic-profile-setting.svg" />
              </ChangePictureBtn>
            </DoctorPictureWrap>
            <DoctorInfoWrap>
              <DoctorName>{`${fullname} 선생님`}</DoctorName>
              <Hospital>{hospital}</Hospital>
              <Department>{departmentName.join(' , ')}</Department>
              <DoctorId>{username}</DoctorId>
              <DoctorPhone>{phoneNumber}</DoctorPhone>
            </DoctorInfoWrap>
          </DoctorProfile>
          <MenuSection>
            {tabName.map((tab, index) => (
              <MenuTab key={`tabName-${index}`} onClick={() => setSelectedTab(tab.enTitle)}>
                <img alt={tab.enTitle} src={tab.icon} />
                <span>{tab.title}</span>
              </MenuTab>
            ))}
          </MenuSection>
        </>
      )}
      {selectedTab === 'specialties' && <Specialties />}
      {selectedTab === 'password' && <Password onConfirm={onConfirm} />}
      {selectedTab === 'experience' && <Experience />}
      {selectedTab === 'phone' && <Phone />}
    </MypageContainer>
  );
}

export default Mypage;

const MypageContainer = styled.div`
  font-family: NotoSansRegular;
  position: relative;
  background-color: white;
  padding: 32px 32px 45px 32px;
  width: 518px;
  min-height: 621px;
  overflow-y: auto;
  textarea {
    :focus {
      border: 1px solid ${globalColors.main};
      background-color: transparent;
    }
  }
  label {
    :focus-within {
      color: ${globalColors.main};
    }
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MenuTabName = styled.div`
  color: ${mypageColor.menuTabText};
  font-size: 18px;
  line-height: 27px;
`;

const DoctorInfoWrap = styled.div`
  margin-left: 41px;
`;

const Hospital = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${mypageColor.specialtyInativeText};
  margin-top: 3px;
`;

const Department = styled(Hospital)`
  word-break: keep-all;
`;

const DoctorId = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: ${mypageColor.input};
  margin-top: 3px;
`;

const DoctorPhone = styled(DoctorId)`
  margin-top: 3px;
`;

const MenuSection = styled.section`
  border-top: 1px solid ${mypageColor.inputBorder};
  padding-top: 77px;
  margin: 0 32px 0 32px;
`;
const MenuTab = styled.div`
  :first-of-type {
    display: none;
  }
  img {
    width: 28px;
    height: 34px;
  }
  span {
    color: ${mypageColor.menuTabText};
    font-size: 18px;
    line-height: 27px;
    margin-left: 16.5px;
  }
  margin-bottom: 35.2px;
  cursor: pointer;
`;

const CloseBtnWrap = styled(BasicBtn)`
  width: 32px;
  height: 32px;
  background-color: transparent;
`;

const BackBtnWrap = styled(CloseBtnWrap)``;

const DoctorPictureWrap = styled.div`
  position: relative;
`;

const ChangePictureBtn = styled(BasicBtn)`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${mypageColor.changeProfileBtn};

  img {
    width: 17.6px;
    height: 17.6;
  }
`;

const DoctorProfile = styled.section`
  display: flex;
  margin-bottom: 40px;
  padding: 0 32px 0 32px;
  align-items: center;
`;

const DoctorPicture = styled.img`
  width: 138px;
  height: 138px;
  border-radius: 50%;
`;

const DoctorName = styled.div`
  font-size: 18px;
  line-height: 27px;
  color: ${mypageColor.name};
`;
