import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/css';

import { SubHeader, SectionHeader } from 'components/atoms';

import * as mixins from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';

type Chart = 'subjective' | 'objective' | 'assessment' | 'plan' | 'opinion';

interface MedicalChartProps {
  active?: Chart | undefined;
  assessment: string;
  objective: string;
  onChartClick?: (header: Chart) => void;
  onChartChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  opinion: string;
  plan: string;
  onChartSave: () => void;
  showSaveBtn?: boolean;
  subjective: string;
}

function MedicalChart({
  active,
  assessment,
  objective,
  onChartClick,
  onChartChange,
  opinion,
  plan,
  onChartSave,
  showSaveBtn = true,
  subjective,
}: MedicalChartProps) {
  return (
    <MedicalChartContainer active={showSaveBtn}>
      {showSaveBtn && <SectionHeader>SOAP 기록</SectionHeader>}
      <MedicalChartWrap>
        <MedicalChartHeader onClick={() => onChartClick('subjective')}>Subjective</MedicalChartHeader>
        <MedicalRecordTextInput
          active={active === 'subjective'}
          defaultValue={subjective}
          name="subjective"
          placeholder="Subjective 내용을 입력해주세요"
          onChange={(e) => onChartChange(e)}
        />
        <MedicalChartHeader onClick={() => onChartClick('objective')}>Objective</MedicalChartHeader>
        <MedicalRecordTextInput
          active={active === 'objective'}
          defaultValue={objective}
          name="objective"
          placeholder="Objective 내용을 입력해주세요"
          onChange={(e) => onChartChange(e)}
        />
        <MedicalChartHeader onClick={() => onChartClick('assessment')}>Assessment</MedicalChartHeader>
        <MedicalRecordTextInput
          active={active === 'assessment'}
          defaultValue={assessment}
          name="assessment"
          placeholder="Assessment 내용을 입력해주세요"
          onChange={(e) => onChartChange(e)}
        />
        <MedicalChartHeader onClick={() => onChartClick('plan')}>Plan</MedicalChartHeader>
        <MedicalRecordTextInput
          active={active === 'plan'}
          defaultValue={plan}
          name="plan"
          placeholder="Plan 내용을 입력해주세요"
          onChange={(e) => onChartChange(e)}
        />
        <MedicalChartHeader onClick={() => onChartClick('opinion')}>Doctor&apos;s Opinion</MedicalChartHeader>
        <MedicalRecordTextInput
          active={active === 'opinion'}
          defaultValue={opinion}
          name="opinion"
          placeholder="Opinion 내용을 입력해주세요"
          onChange={(e) => onChartChange(e)}
        />
      </MedicalChartWrap>
      {showSaveBtn && <SaveBtn onClick={() => onChartSave()}>저장하기</SaveBtn>}
    </MedicalChartContainer>
  );
}

export default MedicalChart;

interface ITextArea {
  defaultValue: string;
  active: boolean;
}

const color = {
  textarea: '#777777',
  textareaBorder: '#dbdbdb',
  saveBtnBackground: '#eeeeee',
  saveBtnBorder: '#dbdbdb',
  saveBtnText: '#a3a3a3',
  saveBtnHoverBackground: '#ddeeff',
  saveBtnHoverBorder: '#a3d1ff',
  saveBtnHoverText: '#4d86bf',
};

const MedicalChartHeader = styled(SubHeader)`
  margin-top: 16px;
`;

const MedicalChartContainer = styled.div<IActive>`
  ${mixins.flexColumn};
  flex-grow: 1;
  width: ${(props) => (props.active ? '25vw' : '100%')};
  overflow: scroll;
`;

const MedicalChartWrap = styled.div`
  ${mixins.flexColumn};
  flex-grow: 1;
  > h3 {
    cursor: pointer;
  }

  > h3:not(:last-child) {
    margin-top: 8px;
  }

  & > textarea {
    flex-grow: 1;
    min-height: 7.5vh;
    width: 100%;
    padding: 6px 0 0 10px;
    line-height: 1.46;
    text-align: left;
    margin-top: 6px;
    overflow: scroll;
  }
`;

const blink = css`
  animation: blink 2s;
  animation-iteration-count: infinite;
`;

const MedicalRecordTextInput = styled.textarea<ITextArea>`
  font-family: NotoSansRegular;
  font-size: 13px;
  font-weight: normal;
  border: 1px solid ${color.textareaBorder};
  color: ${color.textarea};
  defaultvalue: ${({ defaultValue }) => defaultValue};
  outline: none;
  @keyframes blink {
    50% {
      border-color: #fff;
    }
  }

  ${({ active }) => (active ? blink : '')}

  ::placeholder {
    opacity: 0.5;
  }
`;

const SaveBtn = styled(BasicBtn)`
  display: flex;
  justify-content: flex-start;
  color: ${color.saveBtnText};
  background-color: ${color.saveBtnBackground};
  border: 1px solid ${color.saveBtnBorder};
  font-size: 13px;
  text-align: center;
  border-radius: 4px;
  padding: 13px;
  margin-top: 24px;
  &:hover {
    color: ${color.saveBtnHoverText};
    background-color: ${color.saveBtnHoverBackground};
    border: 1px solid ${color.saveBtnHoverBorder};
  }
`;
