const START_LOADING = 'loading/START_LOADING';
const FINISH_LOADING = 'loading/FINISH_LOADING';

export const startLoading = (payload) => ({
  type: START_LOADING,
  payload,
});

export const finishLoading = (payload) => ({
  type: FINISH_LOADING,
  payload,
});

const initialState = {
  loading: false,
};

interface ILoadingAction {
  type: string;
  payload: string;
}

const loadingReducer = (state = initialState, action: ILoadingAction) => {
  switch (action.type) {
    case START_LOADING: {
      return {
        loading: true,
      };
    }
    case FINISH_LOADING: {
      return {
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
};

export default loadingReducer;
