import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';

import { signUpColors } from 'styles/global/color';

interface TimerProps {
  condition: boolean;
}

function Timer({ condition }: TimerProps) {
  const [countDown, setCountDown] = useState(180);

  useEffect(() => {
    if (condition) {
      if (countDown === 0) {
        console.log(0);
      } else {
        const myInterval = setInterval(() => {
          setCountDown((prev) => prev - 1);
        }, 1000);

        return () => {
          clearInterval(myInterval);
        };
      }
    }
  }, [condition, countDown]);

  return (
    <CountDown>
      {`0${Math.floor(countDown / 60)}:${countDown % 60 < 10 ? `0${countDown % 60}` : countDown % 60}`}
    </CountDown>
  );
}

export default Timer;

const CountDown = styled.div`
  font-size: 14px;
  border: 1px solid ${signUpColors.authBtnBorder};
  border-radius: 3px;
  color: ${signUpColors.activeAuthBtnBackground};
  background-color: ${signUpColors.inactiveAuthBtnBackground};
  padding: 2.5px 11px;
  width: 56px;
  position: absolute;
  right: 16px;
  top: 13.5px;
`;
