import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { IActive } from 'types/styleTypes';

interface ModifyFormProps {
  selectedExperience: { index: number; value: string };
  value: string;
  active: boolean;
  modifyExperience: (CareerIndex: number, newValue: string) => void;
}

function ModifyForm({ selectedExperience, value, active, modifyExperience }: ModifyFormProps) {
  const [input, setInput] = useState(value);

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (selectedExperience.index >= 1000) {
      modifyExperience(selectedExperience.index - 1000, input);
    } else {
      modifyExperience(selectedExperience.index, input);
    }
  };

  return (
    <form>
      <ConditionalInput active={active} name="text" value={input} onChange={(e) => handleChange(e)} />
      <ConfirmEditBtn active={active && selectedExperience.index !== -1} onClick={(e) => handleSubmit(e)}>
        <Image alt="edit-complete" src="icons/ic-check-active.svg" />
      </ConfirmEditBtn>
    </form>
  );
}

export default ModifyForm;

const ConditionalInput = styled.input<IActive>`
  display: ${(props) => (props.active ? '' : 'none')};
  border: none;
  background-color: transparent;
  margin-left: 10px;
`;

const ConfirmEditBtn = styled.button<IActive>`
  position: absolute;
  top: 0px;
  right: 0;
  display: ${(props) => (props.active ? 'flex' : 'none')};
  justify-content: ${(props) => (props.active ? 'center' : '')};
  align-items: ${(props) => (props.active ? 'center' : '')};
  width: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  img {
    width: 13.1px;
    height: 10.6px;
  }
`;
