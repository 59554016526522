import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import { MyList } from 'components/organisms';

import { initializeState, postLogout } from 'redux/modules/authentication';

import { useFirestore, useModal } from 'hooks';

import { headerColors } from 'styles/global/color';
import { device } from 'styles/global/device';
import { logoutIcon } from 'assets/images';
import { flexCenter, flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IRootState } from 'types/payloadTypes';

interface HeaderProps {
  currentPath: string;
}

function Header({ currentPath }: HeaderProps) {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const history = useHistory();

  const [showMyList, setShowMyList] = useState(false);
  const [seletedTab, setSelectedTab] = useState('Home');

  const { firebaseUid, fullname, departmentName, hospital, idPhoto, username } = useSelector(
    (state: IRootState) => state.authentication.userInfo,
  );

  const { sendOfflineMessage } = useFirestore(firebaseUid);

  const closeMyList = () => {
    setShowMyList(false);
  };

  const handleLogout = () => {
    dispatch(postLogout());
    dispatch(initializeState());
    history.push('/signin');
  };

  const selectDashbord = (selectedTab) => {
    setSelectedTab(selectedTab);
    closeMyList();
  };

  const handleMypageModal = () => {
    if (username === 'doctor@gmail.com') {
      return;
    }
    openModal({
      modalType: 'Mypage',
      modalProps: {},
    });
  };

  useEffect(
    () => () => {
      sendOfflineMessage();
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <VoidocHeader>
      <PCHeader>
        <LogoWrap to="/home">
          <Logo>
            <Image alt="logo" src="icons/ic-logo.svg" />
          </Logo>
        </LogoWrap>
        <HeaderBtnWrap>
          <HeaderNav>
            <Link to="/home">
              <HeaderBtn focus={currentPath === '/home'}>오늘 예약</HeaderBtn>
            </Link>
            <Link to="/waiting">
              <HeaderBtn focus={currentPath === '/waiting'}>승인 대기</HeaderBtn>
            </Link>
            <Link to="/history">
              <HeaderBtn focus={currentPath === '/history'}>예약 관리</HeaderBtn>
            </Link>
            <Link to="/schedule">
              <HeaderBtn focus={currentPath === '/schedule'}>일정 관리</HeaderBtn>
            </Link>
          </HeaderNav>
          <DoctorInfo onClick={() => handleMypageModal()}>
            <HeaderText color={headerColors.doctor}>{fullname}</HeaderText>
            <DoctorText>
              {`${
                departmentName.length > 1 ? `${departmentName[0]} 외 ${departmentName.length - 1} ` : departmentName[0]
              } /${hospital}`}
            </DoctorText>
          </DoctorInfo>
          <DoctorPicture alt="doctor" src={idPhoto} />
          <LogoutBtn onClick={() => handleLogout()}>
            <Image alt="logout" src={logoutIcon} />
            <LogoutText fontSize={12}>로그아웃</LogoutText>
          </LogoutBtn>
        </HeaderBtnWrap>
      </PCHeader>
      <MobileHeader>
        <TabletLogoWrap to="home">
          <TabletLogo>
            <Image alt="logo" src="icons/ic-logo.svg" />
          </TabletLogo>
        </TabletLogoWrap>
        <MobileLogoWrap to="/home">
          <MobileLogo>
            <Image alt="logo" src="icons/ic-logo-small.svg" />
          </MobileLogo>
        </MobileLogoWrap>
        <ListBtnWrap onClick={() => setShowMyList(!showMyList)}>
          <Image alt="menu" src="icons/ic-hamburger.svg" />
        </ListBtnWrap>
      </MobileHeader>
      {showMyList && (
        <MyList
          departmentName={departmentName}
          doctorPhoto={idPhoto}
          fullname={fullname}
          hospital={hospital}
          seletedTab={seletedTab}
          onClose={closeMyList}
          onLogout={handleLogout}
          onSelect={selectDashbord}
        />
      )}
    </VoidocHeader>
  );
}

export default Header;

interface IHeaderText {
  color?: string;
  fontSize?: number;
  margin?: string;
}

interface IHeaderBtn {
  focus: boolean;
}

const VoidocHeader = styled.header`
  display: flex;
  position: relative;
  padding: 20px 72px 20px 72px;
  justify-content: space-between;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: white;
  @media ${device.tabletMax} {
    height: 90px;
    padding: 0 30px 0 40px;
  }
`;

const PCHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  @media ${device.tabletMax} {
    display: none;
  }
`;

const HeaderText = styled.div<IHeaderText>`
  ${(props) => `
    color: ${props.color || headerColors.inactive};
    text-align: right;
    font-size: ${props.fontSize || 15}px;
  `}
  font-family: NotoSansRegular;
  min-width: 50px;
`;

const LogoWrap = styled(Link)`
  padding: 11.3px 0;
`;

const Logo = styled.div`
  ${flexCenter};
  width: 110px;
  height: 22px;
`;

const MobileLogoWrap = styled(LogoWrap)`
  @media ${device.mobileMin} {
    display: none;
  }
`;

const MobileLogo = styled(Logo)`
  width: 22.9px;
  height: 26.3px;
`;

const TabletLogoWrap = styled(LogoWrap)`
  @media ${device.mobileMax} {
    display: none;
  }
`;

const TabletLogo = styled(Logo)`
  width: 134.7px;
  height: 27.3px;
`;

const HeaderBtnWrap = styled.section`
  display: flex;
  justify-content: flex-end;
`;

const HeaderNav = styled.nav`
  padding-right: 4px;
  display: flex;
`;

const HeaderBtn = styled(BasicBtn)<IHeaderBtn>`
  ${(props) => `
  color: ${props.focus ? headerColors.active : headerColors.inactive};
  font-family: ${props.focus ? 'NotoSansMedium' : 'NotoSansRegular'};
  font-weight: ${props.focus ? '500' : 'normal'};
  `};

  font-size: 16px;
  background-color: transparent;
  margin-right: 4.4rem;
  padding-top: 15px;
  width: 100%;
  white-space: nowrap;

  &:hover {
    color: ${headerColors.active};
  }
`;

const DoctorText = styled(HeaderText)`
  white-space: nowrap;
  font-size: 13px;
  color: ${headerColors.department};
`;

const DoctorInfo = styled.div`
  ${flexColumn};
  justify-content: space-around;
  cursor: pointer;
  padding-left: 5%;
  border-left: 1px solid ${headerColors.border};
  div {
    display: flex;
    flex-wrap: nowrap;
    min-width: 100px;
    width: 100%;
    justify-content: flex-end;
  }
`;

const DoctorPicture = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-left: 27px;
  margin-right: 3.6%;
`;

const LogoutBtn = styled(BasicBtn)`
  padding: 0.5px 0 0.5px 40px;
  padding-left: 32px;
  background-color: transparent;
  ${flexColumn};
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid ${headerColors.border};

  img {
    width: 20.7px;
    height: 26.9px;
  }
`;

const MobileHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media ${device.laptopMin} {
    display: none;
    width: 100%;
  }
`;

const LogoutText = styled(HeaderText)`
  font-size: 12px;
  text-align: center;
`;

const ListBtnWrap = styled(BasicBtn)`
  ${flexCenter};
  width: 42px;
  height: 42px;
  background-color: transparent;
`;
