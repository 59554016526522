import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { getDiseaseList } from 'redux/modules/appointment';

import { H5, H6 } from 'styles/text';

import { IQuestionniare, IRootState } from 'types/payloadTypes';

interface QuestionnaireProps {
  questionnaire: IQuestionniare;
}

const drinkingArray = [
  '음주 여부를 선택해주세요',
  '음주 안함',
  '음주를 거의 하지 않음',
  '일 주에 한 번',
  '일 주에 두 번 이상',
  '한 달에 한 번',
];

function Questionnaire({ questionnaire }: QuestionnaireProps) {
  const dispatch = useDispatch();

  const diseaseList = useSelector((state: IRootState) => state.appointment.diseaseList);

  const getDiseaseText = (array: Array<number>): string => {
    if (array.length === 0) {
      return '없음';
    }
    let diseaseText = '';
    for (let i = 0; i < array.length; i++) {
      const targetDisease = diseaseList.find((disease) => disease.id === array[i]);
      if (targetDisease) {
        diseaseText += `${targetDisease.name}, `;
      }
    }
    return diseaseText.slice(0, diseaseText.length - 2);
  };

  useEffect(() => {
    dispatch(getDiseaseList());
    // eslist-disable-next-line
  }, [dispatch]);

  return (
    <PatientSectionDetail>
      <H5>키 / 몸무게</H5>
      <H6>{`${questionnaire.height}cm / ${questionnaire.weight}kg`}</H6>
      <H5>흡연여부 / 음주여부</H5>
      <H6>{`${questionnaire.smoking ? '흡연자' : '비흡연자'} / ${drinkingArray[questionnaire.drinking]}`}</H6>
      <H5>과거력</H5>
      <H6>{getDiseaseText(questionnaire.pastHistory)}</H6>
      <H5>가족력</H5>
      <H6>{getDiseaseText(questionnaire.familyHistory)}</H6>
      <H5>복용 약물</H5>
      <H6>{questionnaire.medication === '' ? '없음' : questionnaire.medication}</H6>
    </PatientSectionDetail>
  );
}

export default Questionnaire;

const PatientSectionDetail = styled.div`
  padding: 0px 10px 10px;
  overflow: auto;
`;
