import React, { useEffect, useRef, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';

import EmergencyContact from './EmergencyContact';
import TreatmentChart from './MedicalRecord';
import Prescription from './Prescription';
import Calling from './Calling';
import { PatientInformation } from 'components/organisms';

import {
  getAppointmentDetail,
  getDiagnosis,
  getPrescriptionInfo,
  getDistrictList,
  patchDoctorEntered,
  initializeAppointmentState,
  patchInvitePatient,
  patchFinishTreatment,
  postAgoraRTCToken,
  postRecordStop,
  getAgoraToken,
} from 'redux/modules/appointment';

import { getParameter } from 'utils';

import { treatmentColors } from 'styles/global/color';
import { device } from 'styles/global/device';
import { BasicBtn } from 'styles/button';

import { IActive } from 'types/styleTypes';
import { IRootState } from 'types/payloadTypes';

function Treatment() {
  const dispatch = useDispatch();

  const appointmentIdRef = useRef(0);

  const [tab, setTab] = useState({ left: 1, right: 1 });

  const { departmentId, doctorId, fullname } = useSelector((state: IRootState) => state.authentication.userInfo);
  const {
    appointment,
    appointment: { id: appointmentId },
    roomToken,
    resourceId,
    sid,
  } = useSelector((state: IRootState) => state.appointment.appointmentDetail);
  const { finishTreatmentSuccess, invitePatientSuccess, medicalRecord, medicalRecordSaved } = useSelector(
    (state: IRootState) => state.appointment,
  );

  const { id, patient, subDoctors, departmentName } = appointment;

  const { right } = tab;

  const endCall = () => {
    dispatch(patchFinishTreatment({ appointmentId: appointmentIdRef.current }));
    dispatch(
      postRecordStop({
        cname: `appointment_${appointmentId}`,
        resourceId,
        sid,
        appointmentId,
      }),
    );
  };

  const handleTabClick = (value: number) => {
    setTab({
      left: 0,
      right: value,
    });
  };

  const invitePatient = () => {
    dispatch(patchInvitePatient({ appointmentId: appointmentIdRef.current }));
  };

  useEffect(() => {
    if (invitePatientSuccess) {
      alert('환자에게 입장 요청을 보냈습니다');
      dispatch(initializeAppointmentState());
    }
    // eslint-disable-next-line
  }, [invitePatientSuccess]);

  useEffect(() => {
    if (medicalRecordSaved) {
      alert('진료기록이 저장되었습니다');
      dispatch(initializeAppointmentState());
    }
    // eslint-disable-next-line
  }, [medicalRecordSaved]);

  useEffect(() => {
    if (finishTreatmentSuccess) {
      dispatch(initializeAppointmentState());
    }
    // eslint-disable-next-line
  }, [finishTreatmentSuccess]);

  useEffect(() => {
    const parameterId = getParameter('appointmentId');
    appointmentIdRef.current = parseInt(parameterId);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getDistrictList());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAppointmentDetail({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getPrescriptionInfo({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getDiagnosis({ appointmentId: appointmentIdRef.current }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(postAgoraRTCToken(appointmentIdRef.current));
    dispatch(getAgoraToken());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(
      patchDoctorEntered({
        appointmentId: appointmentIdRef.current,
        hasDoctorEntered: true,
        startedAt: moment().format('YYYY-MM-DD HH:mm'),
      }),
    );
    return () => {
      dispatch(
        patchDoctorEntered({
          appointmentId: appointmentIdRef.current,
          hasDoctorEntered: false,
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      dispatch(
        patchDoctorEntered({
          appointmentId: appointmentIdRef.current,
          hasDoctorEntered: false,
        }),
      );
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (finishTreatmentSuccess) window.close();
  }, [finishTreatmentSuccess]);

  return (
    <TreatmentContainer>
      <CallingSection>
        <Calling
          appointmentId={id}
          departmentName={departmentName}
          doctorId={doctorId}
          doctorName={fullname}
          patientId={patient}
          roomToken={roomToken}
          subDoctorArray={subDoctors}
          onInvite={() => invitePatient()}
        />
        <PatientInformation appointmentId={id} parent="Treatment" />
      </CallingSection>

      <TreatmentSection>
        <TreatmentTabWrap>
          <TreatmentTab active={right === 1} onClick={() => handleTabClick(1)}>
            차트
          </TreatmentTab>
          {departmentId.indexOf(19) !== -1 && (
            <>
              <TreatmentTab active={right === 2} onClick={() => handleTabClick(2)}>
                처방전
              </TreatmentTab>
              <TreatmentTab active={right === 3} onClick={() => handleTabClick(3)}>
                긴급연락망
              </TreatmentTab>
            </>
          )}
        </TreatmentTabWrap>

        <TreatmentInfoWrap>
          {right === 1 && <TreatmentChart medicalRecord={medicalRecord} onEnd={endCall} />}
          {right === 2 && <Prescription />}
          {right === 3 && <EmergencyContact />}
        </TreatmentInfoWrap>
      </TreatmentSection>
    </TreatmentContainer>
  );
}

export default withRouter(Treatment);

const TreatmentContainer = styled.div`
  position: relative;
  display: flex;
  padding: 40px;
  height: 100vh;
  overflow: hidden;
  background-color: ${treatmentColors.background};
`;

const TreatmentInfoWrap = styled.div`
  background-color: white;
  height: 100%;
`;

const TreatmentTabWrap = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${treatmentColors.background};
`;

const CallingSection = styled.div`
  width: 60vw;
  margin-top: 40px;

  display: flex;
  flex-direction: row;
  overflow: hidden;
  > div:last-child {
    width: 100%;
    margin-left: 8px;
    @media ${device.laptopMax} {
      padding-top: 40px;
    }
  }
`;

const TreatmentSection = styled.section`
  position: relative;
  width: 40%;
  margin-left: 36px;
  overflow: hidden;
`;

const TreatmentTab = styled(BasicBtn)<IActive>`
  width: 130px;
  height: 40px;
  border-radius: 10px 10px 0px 0px;
  margin-right: 5px;
  background-color: ${(props) => (props.active ? treatmentColors.active : treatmentColors.inactive)};
  color: ${(props) => (props.active ? 'white' : treatmentColors.text)};
  font-family: NotoSansMedium;
  font-size: 15px;
  font-weight: 500;
`;
