import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { SubHeader, SectionHeader, Image } from 'components/atoms';
import { ShowDetailHeader, MedicalDoctor } from 'components/molecules';

import { getDiseaseList } from 'redux/modules/appointment';

import { useModal } from 'hooks';

import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IDoctor, IRootState } from 'types/payloadTypes';
import { BasicBtn } from 'styles/button';

interface AppointmentInfoProps {
  departmentName: string;
  doctorName: string;
  drinking: number;
  familyHistory: Array<number>;
  filePath: Array<string>;
  height: number;
  hospitalName: string;
  imagePath: Array<string>;
  medication: string;
  pastHistory: Array<number>;
  showDoctorInfo: boolean;
  smoking: boolean;
  symptomDesc: string;
  weight: number;
  doctorPhoto: string;
  subDoctors: Array<IDoctor>;
}

const drinkingArray = [
  '음주 여부를 선택해주세요',
  '음주 안함',
  '음주를 거의 하지 않음',
  '일 주에 한 번',
  '일 주에 두 번 이상',
  '한 달에 한 번',
];

function AppointmentInfo({
  departmentName,
  doctorName,
  drinking,
  familyHistory,
  filePath,
  height,
  hospitalName,
  imagePath,
  medication,
  pastHistory,
  showDoctorInfo,
  smoking,
  symptomDesc,
  weight,
  doctorPhoto,
  subDoctors,
}: AppointmentInfoProps) {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const [detail, setDetail] = useState(showDoctorInfo ? 1 : 2);
  const [showSymptomsImageModal, setShowSymptomsImageModal] = useState(false);

  const diseaseList = useSelector((state: IRootState) => state.appointment.diseaseList);

  const getDiseaseText = (array: Array<number>): string => {
    if (array.length === 0) {
      return '없음';
    }
    let diseaseText = '';
    for (let i = 0; i < array.length; i++) {
      const targetDisease = diseaseList.find((disease) => disease.id === array[i]);
      if (targetDisease) {
        diseaseText += `${targetDisease.name}, `;
      }
    }
    return diseaseText.slice(0, diseaseText.length - 2);
  };

  const showDetail = (detailId: number) => {
    if (detailId === detail) {
      setDetail(0);
      return;
    }
    setDetail(detailId);
  };

  const toggleImageModal = () => {
    openModal({
      modalType: 'SymptomsImageModal',
      modalProps: {
        imageArray: imagePath,
      },
    });
    setShowSymptomsImageModal(!showSymptomsImageModal);
  };

  useEffect(() => {
    dispatch(getDiseaseList());
    // eslint-disable-next-line
  }, []);

  return (
    <AppointmentInfoContainer>
      <SectionHeader>예약정보</SectionHeader>
      <AppointmentInfoWrap>
        {showDoctorInfo && (
          <>
            <ShowDetailHeader detailDisplayed={detail === 1} detailId={1} onShow={showDetail}>
              의사정보
            </ShowDetailHeader>
            <AppointmentDetail detailDisplayed={detail === 1}>
              <MedicalDoctor
                departmentName={departmentName}
                doctorName={doctorName}
                doctorPhoto={doctorPhoto}
                hospitalName={hospitalName}
                showDelete={false}
                subDoctorArray={subDoctors}
              />
            </AppointmentDetail>
          </>
        )}
        <ShowDetailHeader detailDisplayed={detail === 2} detailId={2} onShow={showDetail}>
          문진표
        </ShowDetailHeader>
        <AppointmentDetail detailDisplayed={detail === 2}>
          <SubHeader>키 / 몸무게</SubHeader>
          <QuestionnaireContent>{`${height}cm / ${weight}kg`}</QuestionnaireContent>
          <SubHeader>흡연여부 / 음주여부</SubHeader>
          <QuestionnaireContent>
            {`${smoking ? '흡연자' : '비흡연자'} / ${drinkingArray[drinking]}`}
          </QuestionnaireContent>
          <SubHeader>과거병력</SubHeader>
          <QuestionnaireContent>{getDiseaseText(pastHistory)}</QuestionnaireContent>
          <SubHeader>가족력</SubHeader>
          <QuestionnaireContent>{getDiseaseText(familyHistory)}</QuestionnaireContent>
          <SubHeader>복용약물</SubHeader>
          <QuestionnaireContent>{medication || '없음'}</QuestionnaireContent>
        </AppointmentDetail>
        <ShowDetailHeader detailDisplayed={detail === 3} detailId={3} onShow={showDetail}>
          환자증상
        </ShowDetailHeader>
        <AppointmentDetail detailDisplayed={detail === 3}>
          <SubHeader>환자증상</SubHeader>
          <Sypmtoms>{symptomDesc}</Sypmtoms>
          <SubHeader>환부사진</SubHeader>

          {imagePath?.slice(0, 3).map((image: string, index: number) => (
            <ImageWrap key={`image-${index}`} onClick={() => toggleImageModal()}>
              <PatientUploadedImage alt={`injury-${index + 1}`} src={image} />
              {index === 2 && imagePath.length > 3 && (
                <MoreImageText>{`+${imagePath.length - 3}\n이미지 더보기`}</MoreImageText>
              )}
            </ImageWrap>
          ))}

          <SubHeader>첨부파일</SubHeader>
          {filePath?.map((file: string, index: number) => (
            <FileWrap
              key={`file-${index}`}
              download
              href={file}
              // 이미지 새 탭으로 열림
              rel="noopener noreferrer"
              target="_blank"
            >
              <FileIconWrap>
                <FileIcon>
                  <Image alt="file" src="icons/ic-file.svg" />
                </FileIcon>
                <FileName>{file.replace('injury/file/', '')}</FileName>
              </FileIconWrap>
              <FileIcon>
                <Image alt="download" src="icons/ic-download.svg" />
              </FileIcon>
            </FileWrap>
          ))}
        </AppointmentDetail>
      </AppointmentInfoWrap>
    </AppointmentInfoContainer>
  );
}

export default AppointmentInfo;

interface DetailDisplayed {
  detailDisplayed: boolean;
}

const color = {
  contentTitle: '#717171',
  content: '#777777',
  hospitalName: '#a3a3a3',
};

const AppointmentInfoContainer = styled.div`
  width: 25vw;
`;

const AppointmentInfoWrap = styled.div`
  margin-top: 24px;
`;

const AppointmentDetail = styled.div<DetailDisplayed>`
  ${flexColumn};
  margin-top: 16px;
  overflow: auto;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0;
  ${(props) =>
    `
    display: ${props.detailDisplayed ? 'block' : 'none'};
  `}
`;

const Content = styled.h5`
  font-family: NotoSansRegular;
  font-size: 13px;
  color: ${color.content};
  text-align: left;
  line-height: 2.08;
  white-space: pre-line;
`;

const Sypmtoms = styled(Content)`
  line-height: 1.69;
  margin-bottom: 11px;
`;

const QuestionnaireContent = styled(Content)`
  line-height: 1.92;
  margin-bottom: 16px;
`;

const MoreImageText = styled.div`
  font-size: 15px;
  color: white;
  white-space: pre-line;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  position: absolute;
  z-index: 3;
  font-weight: bold;
`;

const ImageWrap = styled(BasicBtn)`
  cursor: pointer;
  display: inline-block;
  background-color: white;
  position: relative;
  height: 8vw;
  width: 8vw;
`;

const PatientUploadedImage = styled.img`
  height: 8vw;
  width: 8vw;
  resize-mode: cover;
`;

const FileWrap = styled.a`
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  color: #777777;
`;

const FileIconWrap = styled.div`
  display: flex;
  align-items: center;
`;

const FileIcon = styled.div`
  ${flexCenter};
  width: 28px;
  height: 28px;
`;

const FileName = styled.span`
  margin-left: 4.8px;
  font-size: 13px;
`;
