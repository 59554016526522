import React, { Fragment, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import 'moment/locale/ko';

import { Spinner } from 'components/atoms';
import { Pagination, MultiButton } from 'components/molecules';

import {
  getPendingPatientListNextPage,
  getPendingPatientListPreviousPage,
  getPendingPatientList,
  patchApproveAppointment,
  patchRejectAppointment,
  patchChangeAppointment,
} from 'redux/modules/waitingList';

import { formatDate, formatAge, formatPageArray } from 'utils';
import { useMobile, useModal } from 'hooks';

import { device } from 'styles/global/device';
import { MainContainer, AppointmentListContainer } from 'styles/container';
import { MainPageTitle } from 'styles/text';
import { appointmentListColors } from 'styles/global/color';
import { Table, TableRow } from 'styles/table';

import { IRootState, IResults } from 'types/payloadTypes';

const initialState = {
  appointmentId: 0,
  patientName: '',
};

function WaitingList() {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const isMobile = useMobile();

  const { doctorId } = useStore().getState().authentication.userInfo;

  const targetAppointmentRef = useRef(0);

  const [targetWaiting, setTargetWaiting] = useState(initialState);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState<Array<number>>([]);

  const { loading } = useSelector((state: IRootState) => state.loading);
  const { modalList } = useSelector((state: IRootState) => state.modal);
  const { waitingApprovalList } = useSelector((state: IRootState) => state.waitingList);

  const { count, waitingApprovalArray } = waitingApprovalList;

  const approveAppointment = () => {
    dispatch(
      patchApproveAppointment({
        appointmentId: targetAppointmentRef.current,
      }),
    );
  };

  const changeAppointment = (selectedTime: string, date: string) => {
    if (selectedTime) {
      dispatch(
        patchChangeAppointment({
          selectedTime,
          date,
          appointmentId: targetAppointmentRef.current,
          status: 0,
        }),
      );
    } else {
      alert('변경할 시간이 선택되지 않았습니다');
    }
  };

  const handleGetPage = (page: number) => {
    if (currentPage === page) return;

    setCurrentPage(page);
  };

  const handleNextPage = () => {
    dispatch(getPendingPatientListNextPage());
  };

  const handlePreviousPage = () => {
    dispatch(getPendingPatientListPreviousPage());
  };

  const handleWaitingDetailOpen = (appointmentId: number) => {
    if (appointmentId === targetWaiting.appointmentId) {
      setTargetWaiting({ ...targetWaiting, appointmentId: 0 });
      return;
    }

    setTargetWaiting({
      ...targetWaiting,
      appointmentId,
    });
  };

  const handleApprovalModal = (appointmentId: number, patientName: string) => {
    targetAppointmentRef.current = appointmentId;
    openModal({
      modalType: 'ApprovalModal',
      modalProps: {
        onConfirm: approveAppointment,
        modalTitle: '예약 승인',
        modalExplanation: `${patientName} 환자의 예약을 승인합니다`,
        confirmBtnText: '확인',
      },
    });
  };

  const handleRejectModal = (appointmentId: number) => {
    targetAppointmentRef.current = appointmentId;
    openModal({
      modalType: 'InputModal',
      modalProps: {
        onConfirm: rejectAppointment,
        modalTitle: '거절 사유 입력',
        confirmBtnText: '요청거절',
        modalExplanation: '해당 요청의 거절 사유를 입력 후 요청 거절 버튼을 눌러주세요',
        placeholder: '거절 사유를 입력해주세요',
      },
    });
  };

  const handleChangeModal = (appointmentId: number) => {
    targetAppointmentRef.current = appointmentId;
    openModal({
      modalType: 'ChangeAppointmentModal',
      modalProps: {
        onConfirm: changeAppointment,
      },
    });
  };

  const rejectAppointment = (reason: string) => {
    dispatch(
      patchRejectAppointment({
        appointmentId: targetAppointmentRef.current,
        reason,
      }),
    );
  };

  useEffect(() => {
    const tempPageArray = formatPageArray(count);
    setPageArray(tempPageArray);
    // eslint-disable-next-line
  }, [count]);

  useEffect(
    () => {
      const today = moment().format('YYYY-MM-DD');
      dispatch(
        getPendingPatientList({
          doctor: doctorId,
          offset: `${currentPage - 1}0`,
          startDate: today,
        }),
      );
    },
    // eslint-disable-next-line
    [currentPage, modalList],
  );

  return (
    <MainContainer>
      {loading && <Spinner />}
      <ListContainer>
        <WaitingApprovalListContainer>
          <TitleSection>
            <MainPageTitle margin="8.5px 18px 0 0">
              {'승인 대기 상담 '}
              {`(${count}건)`}
            </MainPageTitle>
          </TitleSection>
          <WaitingApprovalTable>
            <thead>
              <tr>
                <th>예약시간</th>
                <th>환자이름</th>
                <th>나이</th>
                <th>성별</th>
                <th>CC</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {waitingApprovalArray.map((requestList: IResults) => {
                const {
                  appointmentDate,
                  patientName,
                  dateOfBirth,
                  patientGender,
                  id,
                  symptomDesc,
                  doctorId: requestDoctorId,
                } = requestList;
                return (
                  <Fragment key={`waitingApprovalArray-${id}`}>
                    <TableRow onClick={isMobile ? () => handleWaitingDetailOpen(id) : () => {}}>
                      <td>{formatDate(appointmentDate, 'YYYY-MM-DD (dd) / HH:mm')}</td>
                      <td>
                        <BodyColumn>{patientName}</BodyColumn>
                      </td>
                      <td>{formatAge(dateOfBirth)}</td>
                      <td>
                        <BodyColumn>{patientGender === 'M' ? '남' : '여'}</BodyColumn>
                      </td>
                      <td>{symptomDesc}</td>
                      <td>
                        <MultiButton
                          appointmentId={id}
                          doctorId={requestDoctorId}
                          patientName={patientName}
                          onApprove={handleApprovalModal}
                          onCancel={handleRejectModal}
                          onChangeModal={handleChangeModal}
                        />
                      </td>
                    </TableRow>
                    {targetWaiting.appointmentId === id && isMobile && (
                      <tr>
                        <td>
                          <LaptopAppointmentDetailSection>
                            <MultiBtnSection>
                              <MultiButton
                                appointmentId={id}
                                doctorId={requestDoctorId}
                                patientName={patientName}
                                onApprove={handleApprovalModal}
                                onCancel={handleRejectModal}
                                onChangeModal={handleChangeModal}
                              />
                            </MultiBtnSection>
                          </LaptopAppointmentDetailSection>
                        </td>
                      </tr>
                    )}
                  </Fragment>
                );
              })}
            </tbody>
          </WaitingApprovalTable>
          <PaginationSection>
            {count > 10 && (
              <Pagination
                count={count}
                currentPage={currentPage}
                paginationArray={pageArray}
                onGetPage={handleGetPage}
                onNext={handleNextPage}
                onPrevious={handlePreviousPage}
              />
            )}
          </PaginationSection>
        </WaitingApprovalListContainer>
      </ListContainer>
    </MainContainer>
  );
}

export default WaitingList;

interface ITableBody {
  active?: boolean;
  nextAppointment?: boolean;
}

const ListContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
`;

const TitleSection = styled.section`
  display: flex;
  flex-direction: row;
`;

const BodyColumn = styled.div<ITableBody>`
  border-left: 1px solid
    ${({ active, nextAppointment }) => {
      if (active) return appointmentListColors.selectedAppointmentBorder;
      if (nextAppointment) return appointmentListColors.nextAppointmentBorder;

      return appointmentListColors.tableBodyBorder;
    }};
  border-right: 1px solid
    ${({ active, nextAppointment }) => {
      if (active) return appointmentListColors.selectedAppointmentBorder;
      if (nextAppointment) return appointmentListColors.nextAppointmentBorder;

      return appointmentListColors.tableBodyBorder;
    }};
`;

const WaitingApprovalTable = styled(Table)`
  margin-top: 58px;
  border-spacing: 0 10px;
  @media ${device.mobileMax} {
    margin-top: 24px;
  }

  thead tr {
  }
  thead tr th:first-of-type {
    border-radius: 5px 0 0 5px;
    width: 19%;
  }

  th:nth-of-type(2) {
    width: 11%;
  }

  th:nth-of-type(3),
  th:nth-of-type(4) {
    width: 6%;
    @media ${device.mobileMax} {
      display: none;
    }
  }

  th:nth-of-type(5) {
    padding-left: 20px;
    text-align: left;
    @media ${device.mobileMax} {
      display: none;
    }
  }
  th:last-of-type {
    width: 37.5%;
    @media ${device.mobileMax} {
      display: none;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    margin-bottom: 8px;
    tr:last-of-type {
      margin-bottom: 0;
    }
  }

  tbody tr {
    td:first-of-type {
      width: 19%;
    }

    td:nth-of-type(2) {
      width: 11%;
      @media ${device.mobileMax} {
        div {
          border-right: none;
        }
      }
    }

    td:nth-of-type(3),
    td:nth-of-type(4) {
      width: 6%;
      @media ${device.mobileMax} {
        display: none;
      }
    }

    td:nth-of-type(5) {
      text-align: left;
      padding-left: 20px;
      max-width: 350px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      @media ${device.mobileMax} {
        display: none;
      }
    }
    td:nth-of-type(6) {
      @media ${device.mobileMax} {
        display: none;
      }
    }
    td:last-of-type {
      width: 20%;
      /* @media ${device.mobileMax} {
        display: none;
      } */
    }
  }
`;

const WaitingApprovalListContainer = styled(AppointmentListContainer)``;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const LaptopAppointmentDetailSection = styled.section`
  display: block;
  margin-bottom: 8px;
  @media ${device.wideScreen} {
    display: none;
  }
`;

const MultiBtnSection = styled.div`
  background-color: white;
  padding: 17px;
`;
