import styled from '@emotion/styled';

import { device } from 'styles/global/device';
import { flexCenter, flexColumn } from 'styles/global/mixins';

export const TreatmentInfoContainer = styled.div`
  ${flexColumn};
  position: relative;
  background-color: white;
  flex: 1;
  max-height: 85%;
  padding: 0;
  overflow: auto;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  /* flex: 1;
  position: relative; 
  overflow: auto;*/
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
`;

export const UnavailableContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  ${flexCenter};
  background-color: rgba(255, 255, 255, 0.4);
  font-family: NotoSansMedium;
  font-size: 20px;
  font-weight: 500;
  color: #3e3e3e;
`;

export const AppointmentListContainer = styled.div`
  flex: 1;
  position: relative;
  padding: 51px 72px 130px;
  overflow: auto;
  @media ${device.tabletMax} {
    padding: 51px 40px 130px;
  }

  @media ${device.mobileMax} {
    padding: 51px 20px 130px;
  }
`;

export const CenterWrap = styled.div`
  ${flexCenter};
`;
