import { useState } from 'react';

const useForm = (initialState: any) => {
  const [form, setForm] = useState(initialState);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return [form, onChange];
};

export default useForm;
