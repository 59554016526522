import React, { useState, useEffect } from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { formatPageArray } from 'utils';

import { BasicBtn } from 'styles/button';
import { paginationColors } from 'styles/global/color';
import { flexCenter } from 'styles/global/mixins';
import { prevPageBtn, nextPageBtn } from 'assets/images';

interface PaginationProps {
  currentPage: number;
  onGetPage: (value: number) => void;
  onNext: () => void;
  onPrevious: () => void;
  paginationArray: Array<number>;
  count: number;
}

function Pagination({ currentPage, onGetPage, onNext, onPrevious, paginationArray, count }: PaginationProps) {
  const [displayArray, setDisplayArray] = useState<Array<number>>([]);

  const handleQueryNextPage = () => {
    if (currentPage === paginationArray.length) {
      return;
    }
    onNext();
    onGetPage(currentPage + 1);
  };

  const handleQueryPreviousPage = () => {
    if (currentPage === 1) {
      return;
    }
    onPrevious();
    onGetPage(currentPage - 1);
  };

  useEffect(() => {
    const paginationArr = formatPageArray(count);
    const { length } = paginationArr;
    if (length <= 10) {
      setDisplayArray(paginationArr);
      return;
    }

    if (currentPage <= 5) {
      setDisplayArray(paginationArr.slice(0, 10));
      return;
    }

    if (length - currentPage <= 6) {
      setDisplayArray(paginationArray.slice(length - 10, length));
      return;
    }
    setDisplayArray(paginationArray.slice(currentPage - 5, currentPage + 5));

    // eslint-disable-next-line
  }, [currentPage, count]);

  return (
    <>
      <PaginationBtn isImage active={false} onClick={() => handleQueryPreviousPage()}>
        <PaginationIcon>
          <Image alt="prev" src={prevPageBtn} />
        </PaginationIcon>
      </PaginationBtn>
      {displayArray.map((page: number, index: number) => (
        <PaginationBtn
          // disabled
          key={`pagination-${index}`}
          active={currentPage === page}
          isImage={false}
          onClick={() => onGetPage(page)}
        >
          {page}
        </PaginationBtn>
      ))}
      <PaginationBtn isImage active={false} onClick={() => handleQueryNextPage()}>
        <PaginationIcon>
          <Image alt="next" src={nextPageBtn} />
        </PaginationIcon>
      </PaginationBtn>
    </>
  );
}

export default Pagination;

interface PaginationBtnProps {
  active: boolean;
  isImage?: boolean;
}

const PaginationBtn = styled(BasicBtn)<PaginationBtnProps>`
  ${(props) => `
    background-color: white;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 5px;
    color: ${props.active ? paginationColors.activeText : paginationColors.inactiveText};
    border: 1px solid ${props.active ? paginationColors.activeBorder : paginationColors.inactiveBorder};
    margin: ${props.isImage ? '0 20px' : '0 5px'};
    &:hover {
      color: ${paginationColors.activeText}
      border: 1px solid ${paginationColors.activeBorder}
    }
  `}
`;

const PaginationIcon = styled.div`
  ${flexCenter};
  width: 21.2px;
  height: 21.2px;
  resize-mode: contain;
`;
