import React from 'react';

import styled from '@emotion/styled';
import moment from 'moment';
import 'moment/locale/ko';

import { SectionHeader } from 'components/atoms';
import {
  ShowDetailHeader /* ,FullPerscriptionRecordTable,ConcisePerscriptionRecordTable */,
} from 'components/molecules';

import { formatDate } from 'utils';

import { H5, H6 } from 'styles/text';
import { device } from 'styles/global/device';
import { appointmentListColors } from 'styles/global/color';
import { clockIcon } from 'assets/images';

import { IDiagnosis } from 'types/payloadTypes';
import { IActive } from 'types/styleTypes';

interface TreatmentHistoryProps {
  parent: string;
  diagnosisHistory: Array<IDiagnosis>;
  targetHistory: number;
  showHeader?: boolean;
  handleHistoryDetailShow: (index: number) => void;
  pasteMedicalRecord: (diagnosis: IDiagnosis) => void;
}

function TreatmentHistory({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  parent,
  diagnosisHistory,
  showHeader = false,
  handleHistoryDetailShow,
  targetHistory,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pasteMedicalRecord,
}: TreatmentHistoryProps) {
  moment.locale('ko');

  return (
    <TreatmentHistoryContainer active={showHeader}>
      {showHeader && <SectionHeader>이전 진료 기록</SectionHeader>}
      {diagnosisHistory.map((diagnosis: IDiagnosis, index: number) => {
        const {
          assessment,
          date,
          department,
          doctorName,
          duration,
          finishedAt,
          startedAt,
          id,
          objective,
          opinion,
          plan,
          subjective,
        } = diagnosis;
        const startTime = startedAt.slice(-8);
        const finishTime = finishedAt.slice(-8);

        return (
          !!id && (
            <DiagnosisContainer key={`disgnosis-${index}`}>
              {/* <DateHeader> */}
              <ShowDetailHeader
                detailDisplayed={targetHistory === index}
                detailId={index}
                onShow={() => handleHistoryDetailShow(index)}
              >
                {`${formatDate(date, 'YYYY-MM-DD (dd)')} / ${department} ${doctorName}`}
              </ShowDetailHeader>
              {/* {index === 0 && parent === "Treatment" && (
                <DateHeader>
                  <ShowDetailHeader
                    subDetail
                    showDetail={() => handleHistoryDetailShow(index)}
                    detailId={index}
                    detailDisplayed={targetHistory === index}
                  >
                    {`${formatDate(
                      date,
                      "YYYY-MM-DD (dd)"
                    )} / ${department} ${doctorName}`}
                  </ShowDetailHeader>
                  {index === 0 && parent === "Treatment" && !isMobile && (
                    <PasteBtn onClick={() => pasteMedicalRecord(diagnosis)}>
                      진료기록에 붙여넣기
                    </PasteBtn>
                  )} */}
              {/* </DateHeader> */}
              {targetHistory === index && (
                <PatientSectionDetail>
                  <TimeWrap>
                    <img alt="time-icon" src={clockIcon} />
                    {`${startTime} ~${finishTime} (${duration}진료)`}
                  </TimeWrap>
                  <H5>Subjective</H5>
                  <TreatmentContent>{subjective}</TreatmentContent>
                  <H5>Objective</H5>
                  <TreatmentContent>{objective}</TreatmentContent>
                  <H5>Assessment</H5>
                  <TreatmentContent>{assessment}</TreatmentContent>
                  <H5>Plan</H5>
                  <TreatmentContent>{plan}</TreatmentContent>
                  <H5>Doctor&apos;s opinion</H5>
                  <TreatmentContent>{opinion}</TreatmentContent>
                </PatientSectionDetail>
              )}
            </DiagnosisContainer>
          )
        );
      })}

      {
        // to be added when giving a perscription online is legalized
        /* <H4>처방기록 2020-07-15(수)</H4>
      {parent === "appointmentDetail" ? (
        <ConcisePerscriptionRecordTable />
      ) : (
        <FullPerscriptionRecordTable />
      )} */
      }
    </TreatmentHistoryContainer>
  );
}

export default TreatmentHistory;

const TreatmentContent = styled(H6)`
  white-space: pre-line;
`;

const DiagnosisContainer = styled.div`
  margin-top: 15px;
  overflow: auto;
  > div:first-of-type {
    margin-bottom: 0;
  }
  @media ${device.laptopMax} {
    margin-top: 10px;
  }
`;

const PatientSectionDetail = styled.div`
  padding: 0px 10px 10px;
  overflow: inherit;
  height: 300px;
  text-align: left;
`;

const TreatmentHistoryContainer = styled.div<IActive>`
  width: ${(props) => (props.active ? '25vw' : '100%')};
  position: relative;
  height: 500px;
  overflow: auto;
`;

// const DateHeader = styled.div`
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin: 10px 0;
//   width: 100%;
//   @media ${device.laptopMax} {
//     margin: 0;
//   }
// `;

// const PasteBtn = styled(Button)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: ${treatmentHistoryColors.activeText};
//   background-color: ${treatmentHistoryColors.activeBackground};
//   border: 1px solid ${treatmentHistoryColors.activeBorder};
//   border-radius: 3px;
//   margin-left: 8px;
//   font-family: NotoSansRegular;
//   font-size: 1.5vh;
//   width: 16.7vw;
//   height: 32px;
//   @media ${device.laptopMax} {
//     font-size: 12px;
//     width: 18vw;
//   }
// `;

const TimeWrap = styled.div`
  img {
    height: 14.7px;
    width: 14.7px;
    margin-right: 10.1px;
  }
  line-height: 1.6;
  display: flex;
  align-items: center;
  color: ${appointmentListColors.inactiveBtnText};
  font-size: 14px;
  text-align: left;
`;
