import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { IAgoraRTCRemoteUser, ILocalAudioTrack, ILocalVideoTrack } from 'agora-rtc-sdk-ng';

import { MediaPlayer } from 'components/atoms';

import {
  Avatar,
  ParticipantInfo,
  ParticipantMutedIcon,
  ParticipantOverlay,
  ParticipantView,
} from 'styles/callWrappers';
import * as mixins from 'styles/global/mixins';

import { IDoctor } from 'types/payloadTypes';

interface DoctorScreenProps {
  departmentName: string;
  doctorName: string;
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  remoteDoctors: IAgoraRTCRemoteUser[];
  subDoctors: IDoctor[];
}

function DoctorScreen({
  departmentName,
  doctorName,
  localAudioTrack,
  localVideoTrack,
  remoteDoctors,
  subDoctors,
}: DoctorScreenProps) {
  const getDoctorName = (uid: number) => {
    const doctor = subDoctors.find((subDoctor) => subDoctor.doctorId === uid);

    return doctor ? `${doctor?.departmentName} ${doctor?.fullName}` : '';
  };

  return (
    <DoctorVideoWrap allowScroll={remoteDoctors.length > 1}>
      {localVideoTrack ? (
        <>
          <ParticipantView isLocal count={1 + remoteDoctors.length}>
            <MediaPlayer audioTrack={localAudioTrack} id="myVideo" videoTrack={localVideoTrack} />
            {!localVideoTrack.muted || (
              <ParticipantOverlay>
                <Avatar />
              </ParticipantOverlay>
            )}
            <ParticipantInfo>
              {`${departmentName} ${doctorName}`}
              {localAudioTrack.muted && <ParticipantMutedIcon />}
            </ParticipantInfo>
          </ParticipantView>
          <>
            {remoteDoctors.map((doctor, index) => (
              <ParticipantView key={`doctor-${index}`} isSubDoctor count={1 + remoteDoctors.length}>
                {doctor.hasVideo ? (
                  <MediaPlayer audioTrack={doctor.audioTrack} id={`doctor-${index}`} videoTrack={doctor.videoTrack} />
                ) : (
                  <ParticipantOverlay>
                    <Avatar />
                  </ParticipantOverlay>
                )}
                <ParticipantInfo>
                  {typeof doctor.uid === 'number' && getDoctorName(doctor.uid)}
                  {!doctor.hasAudio && <ParticipantMutedIcon />}
                </ParticipantInfo>
              </ParticipantView>
            ))}
          </>
        </>
      ) : (
        <NoDoctorMessage>
          <Avatar />
        </NoDoctorMessage>
      )}
    </DoctorVideoWrap>
  );
}

export default DoctorScreen;

interface IDoctorVideoWrap {
  allowScroll: boolean;
}

const subDocorStyle = css`
  width: 36.458vw;
`;

const soloDoctorStyle = css`
  width: 100%;
`;

const DoctorVideoWrap = styled.div<IDoctorVideoWrap>`
  ${mixins.sticky}
  ${mixins.flexRow}
  height: 50%;
  ${({ allowScroll }) => (allowScroll ? subDocorStyle : soloDoctorStyle)}
`;

const NoDoctorMessage = styled.div`
  ${mixins.flexCenter}
  background-color: var(--black);
  border: 1px solid black;
  width: 100%;
  height: 100%;
`;
