const size = {
  mobile: '559px',
  tabletMax: '999px',
  tabletMin: '600px',
  laptopMin: '1000px',
  laptopMax: '1599px',
  wideScreen: '1600px',
};

export const device = {
  mobileMax: `(max-width : ${size.mobile})`,
  mobileMin: `(min-width : ${size.mobile})`,
  tabletMin: `(min-width : ${size.tabletMin})`,
  tabletMax: `(max-width : ${size.tabletMax})`,
  laptopMin: `(min-width : ${size.laptopMin})`,
  laptopMax: `(max-width : ${size.laptopMax})`,
  wideScreen: `(min-width : ${size.wideScreen})`,
};
