import React from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';

import { Image } from 'components/atoms';

import { H5, H6 } from 'styles/text';
import { appointmentListColors } from 'styles/global/color';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IActive } from 'types/styleTypes';

interface SymptomsProps {
  onModalOpen?: () => void;
  parent: string;
  imageArray: Array<string>;
  symptomDescription?: string;
  fileArray: Array<string>;
}

function Symptoms({ parent, onModalOpen, imageArray, symptomDescription, fileArray }: SymptomsProps) {
  return (
    <PatientSectionDetail>
      <H5>환자 증상</H5>
      <PatientSymptoms>{symptomDescription}</PatientSymptoms>
      <H5>환부 사진</H5>
      {parent === 'AppointmentDetailRow' &&
        imageArray?.slice(0, 3).map((image: string, index: number) => (
          <ImageWrap
            key={`image-${index}`}
            active={parent === 'AppointmentDetailRow'}
            status={index === 2}
            onClick={() => onModalOpen()}
          >
            <PatientUploadedImage>
              <Image alt={`injury-${index + 1}`} src={image} />
            </PatientUploadedImage>
            {index === 2 && imageArray.length > 3 && (
              <ImageTextWrap>
                <ImageMoreText>{`+${imageArray.length - 3}`}</ImageMoreText>
                <ImageMoreText>이미지 더보기</ImageMoreText>
              </ImageTextWrap>
            )}
          </ImageWrap>
        ))}

      {(parent === 'AppointmentDetailColumn' || parent === 'Treatment') && (
        <div style={{ flex: 1, flexWrap: 'wrap' }}>
          {imageArray?.map((image: string, index: number) => (
            <ImageWrap
              key={`image-${index}`}
              active={parent !== 'AppointmentDetailColumn'}
              onClick={() => onModalOpen()}
            >
              <PatientUploadedImage>
                <img alt={`injury-${index + 1}`} src={image} />
              </PatientUploadedImage>
            </ImageWrap>
          ))}
        </div>
      )}
      <H5>첨부파일</H5>
      {(parent === 'AppointmentDetailRow' || parent === 'Treatment') &&
        fileArray?.map((file: string, index: number) => (
          <FileWrap key={`file-${index}`}>
            <FileIconWrap>
              <FileIcon>
                <Image alt="download" src="icons/ic-file.svg" />
              </FileIcon>
              <FileName>{`파일-${index + 1}`}</FileName>
            </FileIconWrap>
            <a
              download
              href={file}
              // 이미지 새 탭으로 열림
              rel="noopener noreferrer"
              target="_blank"
            >
              <FileIcon>
                <Image alt="download" src="icons/ic-download.svg" />
              </FileIcon>
            </a>
          </FileWrap>
        ))}
    </PatientSectionDetail>
  );
}

export default Symptoms;

const PatientSymptoms = styled(H6)`
  white-space: pre-line;
`;

const PatientSectionDetail = styled.div`
  padding: 0px 10px 10px;
  overflow: auto;
  ${flexColumn};
  align-items: flex-start;
`;

const PatientUploadedImage = styled.div`
  ${flexCenter};
  width: 110px;
  height: 110px;
  margin: 10px 13px 0 0;
  resize-mode: cover;
`;

const FileWrap = styled.div`
  margin-top: 13px;
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  color: ${appointmentListColors.status};
`;

const FileIconWrap = styled.div`
  display: flex;
  align-items: center;
`;

const FileIcon = styled.div`
  ${flexCenter};
  width: 28px;
  height: 28px;
`;

const FileName = styled.span`
  margin-left: 4.8px;
`;

const ImageTextWrap = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 100%;
  top: 10px;
  left: 0;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
`;

const ImageMoreText = styled.div`
  font-size: 15px;
  color: white;
  text-align: center;
`;

const ImageWrap = styled.div<IActive>`
  display: inline-block;
  position: relative;
  height: 130px;
  width: 130px;
  margin: 0 10px 10px 0;
  ${({ status }) =>
    status &&
    css`
      opacity: 0.8;
    `};
  ${({ active }) =>
    !active &&
    css`
      height: 180px;
      width: 180px;
    `};
`;
