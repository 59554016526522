import React, { useState } from 'react';

import styled from '@emotion/styled';

import PrivacyPolicy from './PrivacyPolicy';
import RecordingIntro from './RecordingIntro';
import RecordingWatermark from './RecordWatermark';

import { recordingColor } from 'styles/global/color';

function Recording() {
  const [currentPage, setCurrentPage] = useState(1);

  const handleRecordingStart = () => {
    setCurrentPage(2);
  };

  const handlePrivacyPolicyOpen = () => {
    setCurrentPage(3);
  };

  const showIntro = () => {
    setCurrentPage(1);
  };

  return (
    <RecordingContainer>
      {currentPage === 1 && <RecordingIntro onPolicyOpen={handlePrivacyPolicyOpen} onStart={handleRecordingStart} />}
      {currentPage === 2 && <RecordingWatermark />}
      {currentPage === 3 && <PrivacyPolicy onShow={showIntro} />}
    </RecordingContainer>
  );
}

export default Recording;

const RecordingContainer = styled.div`
  padding: 70px 415px;
  background-color: ${recordingColor.overlayBackground};
  justify-content: center;

  height: 100vh;
  overflow: auto;
`;
