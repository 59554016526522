import React, { Fragment } from 'react';

import styled from '@emotion/styled';

import AppointmentDetail from 'pages/AppointmentList/WaitingTable/WaitingAppointmentDetail';

import { formatDate, formatPhone, formatAge } from 'utils';

import { device } from 'styles/global/device';
import { Table, TableRow, BodyColumn } from 'styles/table';

import { IAppointment } from 'types/payloadTypes';

interface AppointmentListProps {
  patient: number;
  targetAppointment: number;
  displayArray: Array<IAppointment>;
  onShow: (appointmentId: number) => void;
}

const statusArray = ['예약완료', '승인대기', '상담완료', '예약취소', '미진료', '진료중', '예약거절'];

function AppointmentList({ patient, targetAppointment, displayArray, onShow }: AppointmentListProps) {
  return (
    <section style={{ overflow: 'auto' }}>
      <AppointmentTable>
        <thead>
          <tr>
            <th>예약 날짜</th>
            <th>진료과 / 상담의사</th>
            <th>거주 지역</th>
            <th>환자 이름</th>
            <th>전화 번호</th>
            <th>나이 / 성별</th>
            <th>진단명</th>
            <th>응급 상태</th>
            <th>상담 상태</th>
          </tr>
        </thead>
        <tbody>
          {displayArray.map((appointment: IAppointment, index: number) => {
            const {
              id,
              appointmentDate,
              departmentName,
              doctorName,
              patientName,
              dateOfBirth,
              status,
              patientGender,
              isEmergency,
              patientDistrict,
              patientPhone,
              diagnosis,
            } = appointment;
            return (
              <Fragment key={`appointment-${index}`}>
                <TableRow key={`appointment-${index}`} active={targetAppointment === id} onClick={() => onShow(id)}>
                  <td>{formatDate(appointmentDate, 'YYYY-MM-DD (dd) HH:mm')}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>
                      {departmentName}
                      {` /${doctorName}`}
                    </BodyColumn>
                  </td>
                  <td>{patientDistrict}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>{patientName}</BodyColumn>
                  </td>
                  <td>{formatPhone(patientPhone)}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>
                      {formatAge(dateOfBirth)}
                      {` / ${patientGender === 'M' ? '남' : '여'}`}
                    </BodyColumn>
                  </td>
                  <td>{diagnosis}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id} emergency={isEmergency}>
                      {isEmergency ? '응급' : '일반'}
                    </BodyColumn>
                  </td>
                  <td>{statusArray[status]}</td>
                </TableRow>
                {targetAppointment === id && (
                  <tr>
                    <td colSpan={9}>
                      <LaptopAppointmentDetailSection>
                        <AppointmentDetail parent="AppointmentDetailRow" patientId={patient} />
                      </LaptopAppointmentDetailSection>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </AppointmentTable>
    </section>
  );
}

export default AppointmentList;

const AppointmentTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 10px;

  tbody tr {
    td:nth-of-type(2) {
      word-break: keep-all;
    }
    td:nth-of-type(7) {
      max-width: 300px;
      text-align: left;
      padding-left: 33.9px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }
  }

  thead tr th:first-of-type {
    width: 15%;
  }

  th:nth-of-type(2) {
    width: 13%;
  }
  th:nth-of-type(3) {
    width: 8%;
  }
  th:nth-of-type(4) {
    width: 9%;
  }

  th:nth-of-type(5) {
    width: 12%;
  }
  th:nth-of-type(6) {
    width: 7%;
  }

  th:nth-of-type(7) {
    width: 22.6%;
    text-align: left;
    padding-left: 33.9px;
  }

  th:nth-of-type(8) {
    width: 7%;
  }

  th:last-child {
    width: 9.4%;
  }
`;

const LaptopAppointmentDetailSection = styled.section`
  display: block;
  margin-bottom: 8px;
  text-align: left;
  @media ${device.wideScreen} {
    display: none;
  }
`;
