import React from 'react';

import styled from '@emotion/styled';

import { globalColors } from 'styles/global/color';

interface InformMessageProps {
  message: string;
}

function InformMessage({ message }: InformMessageProps) {
  return (
    <MessageWrap>
      <MessageText>{message}</MessageText>
    </MessageWrap>
  );
}

export default InformMessage;

const MessageWrap = styled.div`
  background-color: ${globalColors.submitBackground};
  padding: 3px 0 3px 12px;
  width: 100%;
`;

const MessageText = styled.div`
  font-family: NotoSansRegular;
  font-size: 12px;
  color: ${globalColors.messageText};
  text-align: left;
`;
