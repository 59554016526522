import React, { useState } from 'react';

import { useStore, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import { ModifyForm } from 'components/molecules';

import { patchCareerChange } from 'redux/modules/authentication';

import { useForm } from 'hooks';

import { BasicBtn, EditBtn } from 'styles/button';
import { mypageColor } from 'styles/global/color';
import { SectionLabel } from 'styles/text';

import { IActive } from 'types/styleTypes';
import { flexCenter } from 'styles/global/mixins';

function Experience() {
  const dispatch = useDispatch();

  const state = useStore().getState();
  const [form, onFormChange] = useForm({
    education: '',
    career: '',
  });

  const { career, education } = state.authentication.userInfo;
  const [careerList, setCareerList] = useState<Array<string>>(career);
  const [educationList, setEducationList] = useState<Array<string>>(education);
  const [selectedExperience, setSelectedExperience] = useState({
    index: -1,
    value: '',
  });

  const handleCareerAdd = (newCareer) => {
    if (!newCareer) {
      return;
    }
    setCareerList([...careerList, newCareer]);
    form.career = '';
  };

  const handleEducationAdd = (newEducation) => {
    if (!newEducation) {
      return;
    }
    setEducationList([...educationList, newEducation]);
    form.education = '';
  };

  const handleModify = (index: number, newValue: string) => {
    setSelectedExperience({ index, value: newValue });
  };

  const handleExperience = () => {
    dispatch(patchCareerChange({ educationList, careerList }));
    window.location.reload();
  };

  const handleCareerRemove = (careerItem: string) => {
    setCareerList(careerList.filter((item) => item !== careerItem));
  };

  const handleEducationRemove = (educationItem: string) => {
    setEducationList(educationList.filter((item) => item !== educationItem));
  };

  const modifyCareer = (careerIndex, newValue) => {
    const clone = [...careerList];
    clone.splice(careerIndex, 1, newValue);
    setCareerList(clone);
    setSelectedExperience({ index: -1, value: '' });
  };

  const modifyEducation = (educationIndex, newValue) => {
    const clone = [...educationList];
    clone.splice(educationIndex, 1, newValue);
    setEducationList(clone);
    setSelectedExperience({ index: -1, value: '' });
  };

  return (
    <InputSection>
      <SectionLabel htmlFor="education">경력정보</SectionLabel>
      <ExperienceContainer>
        {careerList.map((careerItem, index) => (
          <ExperienceWrap key={`career-${index}`}>
            <DotImg>
              <Image alt="dot" src="icons/ic-list-dot.svg" />
            </DotImg>
            <ModifyForm
              active={index === selectedExperience.index}
              modifyExperience={modifyCareer}
              selectedExperience={selectedExperience}
              value={careerItem}
            />
            <EachExperience active={index === selectedExperience.index}>{careerItem}</EachExperience>
            <ModifyBtnWrap active={index === selectedExperience.index}>
              <ModifyBtn onClick={() => handleModify(index, careerItem)}>
                <Image alt="modify" src="icons/ic-modify.svg" />
              </ModifyBtn>
              <DeleteBtn onClick={() => handleCareerRemove(careerItem)}>
                <Image alt="delete" src="icons/ic-delete.svg" />
              </DeleteBtn>
            </ModifyBtnWrap>
          </ExperienceWrap>
        ))}
        <ExperienceInputWrap>
          <MypageExperienceInput name="career" placeholder="추가할 경력을 입력해주세요" onChange={onFormChange} />
          <AddBtnWrap>
            <AddBtn onClick={() => handleCareerAdd(form.career)}>
              <Image alt="add" src="icons/ic-plus-inActive.svg" />
            </AddBtn>
          </AddBtnWrap>
        </ExperienceInputWrap>
      </ExperienceContainer>
      <SectionLabel htmlFor="career">학력정보</SectionLabel>
      <ExperienceContainer>
        {educationList.map((educationItem, index) => (
          <ExperienceWrap key={`career-${index}`}>
            <DotImg>
              <Image alt="dot" src="icons/ic-list-dot.svg" />
            </DotImg>
            <ModifyForm
              active={index + 1000 === selectedExperience.index}
              modifyExperience={modifyEducation}
              selectedExperience={selectedExperience}
              value={educationItem}
            />
            <EachExperience active={index + 1000 === selectedExperience.index}>{educationItem}</EachExperience>
            <ModifyBtnWrap active={index + 1000 === selectedExperience.index}>
              <ModifyBtn onClick={() => handleModify(index + 1000, educationItem)}>
                <Image alt="modify" src="icons/ic-modify.svg" />
              </ModifyBtn>
              <DeleteBtn onClick={() => handleEducationRemove(educationItem)}>
                <Image alt="delete" src="icons/ic-delete.svg" />
              </DeleteBtn>
            </ModifyBtnWrap>
          </ExperienceWrap>
        ))}
        <ExperienceInputWrap>
          <MypageExperienceInput name="education" placeholder="추가할 학력을 입력해주세요" onChange={onFormChange} />
          <AddBtnWrap>
            <AddBtn onClick={() => handleEducationAdd(form.education)}>
              <Image alt="add" src="icons/ic-plus-inActive.svg" />
            </AddBtn>
          </AddBtnWrap>
        </ExperienceInputWrap>
      </ExperienceContainer>
      <ModifyExperienceBtn active onClick={() => handleExperience()}>
        학력 및 경력 수정하기
      </ModifyExperienceBtn>
    </InputSection>
  );
}

export default Experience;

const InputSection = styled.div`
  padding: 58px 32px 56px 32px;
  height: 100%;
  position: relative;
`;

const ExperienceContainer = styled.div`
  background-color: ${mypageColor.careerContainerBackground};
  margin: 0 -64px;
  padding: 0 64px;
  margin-bottom: 40px;
  max-height: 196px;
  overflow: auto;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${mypageColor.scrollbar};
    -webkit-box-shadow: 0 0 1px ${mypageColor.scrollbar};
  }
`;

const ExperienceWrap = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${mypageColor.textareaBorder};
`;

const DotImg = styled.div`
  ${flexCenter};
  width: 4px;
  height: 4px;
`;

const EachExperience = styled.div<IActive>`
  display: ${(props) => (props.active ? 'none' : 'inline-block')};
  font-size: 14px;
  line-height: 16px;
  color: ${mypageColor.name};
  margin-left: 10px;
  width: 317px;
  overflow: visible;
  height: 100%;
`;

const ModifyBtnWrap = styled.div<IActive>`
  display: ${(props) => (props.active ? 'none' : 'flex')};
  position: absolute;
  right: 0;
  justify-content: space-evenly;
  width: 60px;
  height: 30px;
  align-items: center;
`;

const ModifyBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 12px;
  height: 15px;
`;

const DeleteBtn = styled(ModifyBtn)`
  width: 10.9px;
  height: 16.6px;
`;

const ExperienceInputWrap = styled.div`
  display: flex;
  align-items: center;
`;
const MypageExperienceInput = styled.input`
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  ::placeholder {
    color: ${mypageColor.name};
    font-size: 14px;
    line-height: 16px;
    font-family: NotoSansRegular;
  }
`;

const AddBtnWrap = styled.div`
  width: 60px;
  padding-left: 30px;
`;

const AddBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 13.8px;
  height: 13.8px;
`;

const ModifyExperienceBtn = styled(EditBtn)<IActive>`
  width: 100%;
  height: 56px;
  border: 1px solid ${mypageColor.textareaBorder};
  background-color: ${mypageColor.changeProfileBtn};
  color: ${mypageColor.specialtyInativeText};
  ${(props) =>
    `color : ${props.active ? mypageColor.activeBtnText : mypageColor.specialtyInativeText};
    background-color : ${props.active ? mypageColor.activeBtnBackground : mypageColor.changeProfileBtn};
    border : 1px solid ${props.active ? mypageColor.activeBtnBorder : mypageColor.textareaBorder}`}
`;
