import React, { HTMLAttributes, ReactNode } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { flexCenter } from 'styles/global/mixins';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  disabled?: boolean;
  active?: boolean;
  color: 'gray' | 'blue' | 'lightBlue' | 'darkBlue';
  size: 'full' | 'big' | 'mid' | 'small';
}

function Button({ children, disabled = false, active, ...props }: ButtonProps) {
  return (
    <ButtonStyled active={active} disabled={disabled} type="button" {...props}>
      {children}
    </ButtonStyled>
  );
}

export default Button;

const darkBlue = css`
  background-color: var(--dark-blue);
  border: 1px solid var(--dark-blue);
  color: #fff;
`;

const blue = css`
  background-color: var(--main-blue-01);
  border: 1px solid var(--main-blue-02);
  color: var(--label-blue);
  :hover {
    background-color: var(--main-blue-02);
  }
`;

const lightBlue = css`
  background-color: var(--sub-blue-01);
  border: 1px solid var(--sub-blue-06);
  color: var(--sub-blue-03);
  :hover {
    background-color: var(--sub-blue-05);
  }
`;

const gray = css`
  background-color: var(--gray-background-01);
  border: 1px solid var(--gray-border-01);
  color: var(--grayscale-05);
  :hover {
    background-color: var(--grayscale-03);
  }
`;

const disableColor = css`
  background-color: var(--gray-background-01);
  border: 1px solid var(--gray-border-01);
  color: var(--grayscale-05);
`;

const full = css`
  width: 100%;
  height: 42px;
  padding: 12px 0;
  font-size: 16px;
`;

const big = css`
  width: 200px;
  height: 42px;
  font-size: 16px;
`;

const mid = css`
  width: 142px;
  height: 42px;
  font-size: 16px;
`;

const small = css`
  width: 80px;
  height: 35px;
  font-size: 14px;
`;

const colors = { gray, blue, lightBlue, darkBlue };
const sizes = { full, big, mid, small };

const ButtonStyled = styled.button<ButtonProps>`
  ${flexCenter}
  ${({ color, disabled }) => (disabled ? disableColor : color && colors[color])};
  ${({ size }) => size && sizes[size]};
  text-decoration: none;
  border-radius: ${({ color }) => (color === 'darkBlue' ? '2px' : '5px')};
  cursor: pointer;
`;
