import React, { ReactNode } from 'react';

import styled from '@emotion/styled';

interface SectionHeaderProps {
  children: ReactNode;
}

function SectionHeader({ children }: SectionHeaderProps) {
  return <H1>{children}</H1>;
}

export default SectionHeader;

const color = {
  headerText: '#a3a3a3',
  headerBackground: '#f7f7f7',
};

const H1 = styled.h1`
  font-family: NotoSansMedium;
  font-size: 13px;
  font-weight: 500;
  color: ${color.headerText};
  background-color: ${color.headerBackground};
  text-align: left;
  padding: 4px;
  width: 100%;
  line-height: 1.46;
`;
