import styled from '@emotion/styled';
import { tableColors, appointmentListColors } from 'styles/global/color';
import { device } from 'styles/global/device';

interface ITableBody {
  active?: boolean;
  nextAppointment?: boolean;
  waitingAppointment?: boolean;
  emergency?: boolean;
  borderLeft?: boolean;
  borderRight?: boolean;
}

export const EmptySpace = styled.tr`
  @media ${device.tabletMax} {
    height: 8px;
  }
  td {
    padding: 5px 0;
    @media ${device.mobileMax} {
      padding: 0;
    }
  }
`;

export const TableRow = styled.tr<ITableBody>`
  cursor: pointer;
  background-color: ${({ active, emergency, nextAppointment }) => {
    if (active) return appointmentListColors.selectedAppointment;
    if (emergency) return appointmentListColors.emergencyBackground;
    if (nextAppointment) return appointmentListColors.firstTimeBackground;

    return 'white';
  }};
  border: 1px solid
    ${({ active, nextAppointment }) => {
      if (active) return 'transparent';
      if (nextAppointment) return appointmentListColors.firstTimeBorder;

      return 'transparent';
    }};

  td {
    padding: 24.5px 0;
    color: ${({ active, emergency, nextAppointment }) => {
      if (active) return 'white';
      if (emergency) return appointmentListColors.emergencyText;
      if (nextAppointment) return appointmentListColors.nextAppointmentBorder;

      return appointmentListColors.status;
    }};

    @media ${device.tabletMax} {
      padding: 14.5px 0;
    }
  }
`;

export const BodyColumn = styled.div<ITableBody>`
  color: ${({ active, emergency, waitingAppointment, nextAppointment }) => {
    if (active) return 'white';
    if (emergency) return appointmentListColors.emergencyText;
    if (waitingAppointment) return appointmentListColors.selectedAppointment;
    if (nextAppointment) return appointmentListColors.activeBtnText;

    return appointmentListColors.status;
  }};
  border-left: 1px solid ${(props) => (props.active ? tableColors.selectedBorder : tableColors.tableBodyBorder)};
  border-right: 1px solid ${(props) => (props.active ? tableColors.selectedBorder : tableColors.tableBodyBorder)};
  ${({ borderLeft }) =>
    borderLeft &&
    `
      border-left: none;
    `}
  ${({ borderRight }) =>
    borderRight &&
    `
      border-right: none;
    `}
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 99.9%;

  thead tr {
    th {
      font-family: NotoSansMedium;
      font-size: 13px;
      color: ${tableColors.headText};
      background-color: ${tableColors.headBackground};
      padding: 11.5px 0 11.5px;
    }
    th:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    th:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  tbody tr {
    td {
      font-family: NotoSansRegular;
      text-align: center;
      font-size: 15px;
      line-height: 32px;
    }
    td:first-of-type {
      border-radius: 5px 0 0 5px;
    }
    td:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }
`;
