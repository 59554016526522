import React, { useState, useEffect, useRef } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Spinner } from 'components/atoms';
import { Pagination } from 'components/molecules';
import SearchFilter from './SearchFilter';
import AppointmentList from './AppointmentList';
import AppointmentDetail from 'pages/AppointmentList/WaitingTable/WaitingAppointmentDetail';

import {
  getDepartmentList,
  getDoctorList,
  getAppointmentList,
  getAppointmentDetail,
  getAppointmentPreviousPage,
  getAppointmentNextPage,
} from 'redux/modules/appointment';

import { formatPageArray } from 'utils';

import { MainContainer, AppointmentListContainer } from 'styles/container';
import { MainPageTitle } from 'styles/text';
import { device } from 'styles/global/device';

import { IActive } from 'types/styleTypes';
import { IRootState } from 'types/payloadTypes';

type Clicked = 'initial' | 'date' | 'department' | 'doctor' | 'patientName';

interface FilterOptions {
  date: string;
  patientName: string;
  doctor: number | string;
  department: string;
  clicked: Clicked;
  status: number | string;
  offset: number | string;
  currentPage: number;
}

const initialFilter: FilterOptions = {
  date: '',
  patientName: '',
  doctor: '',
  department: '',
  clicked: 'initial',
  status: '',
  offset: '',
  currentPage: 1,
};

function History() {
  const dispatch = useDispatch();

  const firstRender = useRef(true);

  const [departmentArray, setDepartmentArray] = useState<Array<string>>([]);
  const [doctorArray, setDoctorArray] = useState<Array<string>>([]);
  const [mineOnly, setMineOnly] = useState(true);
  const [filterOption, setFilterOption] = useState<FilterOptions>(initialFilter);
  const [targetAppointment, setTargetAppointment] = useState<number>(0);
  const [displayArray, setDisplayArray] = useState([]);
  const [pageArray, setPageArray] = useState<Array<number>>([]);

  const { appointmentList, departmentList, doctorList, appointmentDetail } = useSelector(
    (state: IRootState) => state.appointment,
  );
  const doctorId = useSelector((state: IRootState) => state.authentication.userInfo.doctorId);
  const { loading } = useSelector((state: IRootState) => state.loading);

  const { appointmentArray, count } = appointmentList;
  const { date, department, doctor, patientName, status, offset } = filterOption;

  const { patient } = appointmentDetail.appointment;

  const handleGetPage = (page: number) => {
    if (filterOption.offset === page) {
      return;
    }

    setFilterOption({
      ...filterOption,
      currentPage: page,
      offset: (page - 1) * 10,
    });
  };

  const handleNextPage = () => {
    dispatch(getAppointmentNextPage());
  };

  const handlePreviousPage = () => {
    dispatch(getAppointmentPreviousPage());
  };

  const handleFilterOptionClick = (target: Clicked) => {
    if (filterOption.clicked === target) {
      setFilterOption({ ...filterOption, clicked: 'initial' });
    } else {
      if ((target === 'department' || target === 'doctor') && mineOnly) {
        return;
      }
      setFilterOption({ ...filterOption, clicked: target });
    }
  };

  const handleSearchOptionChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>,
    name: string,
    value: string | number,
  ) => {
    let filterValue = value;
    if (value === '') {
      e.stopPropagation();
      if (name === 'department') {
        setFilterOption({
          ...filterOption,
          department: '',
          doctor: '',
          clicked: 'initial',
        });
        return;
      }
    } else if (value === '진료과목을 선택해주세요') {
      return;
    }

    if (name === 'doctor') {
      const targetDoctor = doctorList.find((doctorOfList) => doctorOfList.fullName === value);
      if (value) {
        filterValue = targetDoctor.doctorId;
      }
    }
    setFilterOption({ ...filterOption, [name]: filterValue, clicked: 'initial' });
  };

  const toggleAppointmentDetail = (appointmentId: number) => {
    if (appointmentId === targetAppointment) {
      setTargetAppointment(0);
    } else {
      dispatch(getAppointmentDetail({ appointmentId }));
      setTargetAppointment(appointmentId);
    }
  };

  const toggleMineOnly = () => {
    setMineOnly(!mineOnly);
  };

  useEffect(() => {
    if (mineOnly) {
      setFilterOption({ ...filterOption, department: '', doctor: doctorId });
    } else {
      setFilterOption({ ...filterOption, doctor: '' });
    }
    // eslint-disable-next-line
  }, [mineOnly]);

  useEffect(() => {
    setDisplayArray(appointmentArray);
  }, [appointmentArray]);

  useEffect(() => {
    const tempDepartmentArray = departmentList.map((el) => el.name).sort();
    setDepartmentArray(tempDepartmentArray);
  }, [departmentList]);

  useEffect(() => {
    if (doctorList.length > 0) {
      const tempDoctorArray = doctorList.map((el) => el.fullName).sort();
      setDoctorArray(tempDoctorArray);
    } else {
      setDoctorArray(['진료과목을 선택해주세요']);
    }
  }, [doctorList]);

  useEffect(() => {
    const tempPageArray = formatPageArray(count);
    setPageArray(tempPageArray);
    // eslint-disable-next-line
  }, [count]);

  useEffect(() => {
    if (firstRender.current) {
      return;
    }
    dispatch(getAppointmentList({ ...filterOption }));
    if (filterOption.department !== '') {
      const target = departmentList.find((departmentOfList) => departmentOfList.name === filterOption.department);

      dispatch(getDoctorList({ departmentId: target.id }));
    } else {
      setDoctorArray(['진료과목을 선택해주세요']);
    }
    setFilterOption({ ...filterOption, offset: '', currentPage: 1 });

    // eslint-disable-next-line
  }, [date, department, doctor, patientName, status]);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      dispatch(getAppointmentList({ ...filterOption }));
    }
    // eslint-disable-next-line
  }, [offset]);

  useEffect(() => {
    dispatch(
      getAppointmentList({
        date: '',
        patientName: '',
        doctor: doctorId,
        department: '',
        startDate: '',
        endDate: '',
        status: '',
        offset: '',
        month: '',
      }),
    );
    dispatch(getDepartmentList());
    // eslint-disable-next-line
  }, []);

  return (
    <MainContainer>
      {loading && <Spinner />}
      <AppointmentListContainer>
        <TitleSection>
          <MainPageTitle>예약 관리</MainPageTitle>
          <FilterContainer active={!!targetAppointment}>
            <SearchFilter
              departmentArray={departmentArray}
              doctorArray={doctorArray}
              doctorList={doctorList}
              filterOption={filterOption}
              mineOnly={mineOnly}
              onMine={toggleMineOnly}
              onOptionChange={handleSearchOptionChange}
              onOptionClick={(target: Clicked) => handleFilterOptionClick(target)}
            />
          </FilterContainer>
        </TitleSection>
        <AppointmentList
          displayArray={displayArray}
          patient={patient}
          targetAppointment={targetAppointment}
          onShow={toggleAppointmentDetail}
        />
        <PaginationSection>
          {appointmentList.count > 10 && (
            <Pagination
              count={count}
              currentPage={filterOption.currentPage}
              paginationArray={pageArray}
              onGetPage={handleGetPage}
              onNext={handleNextPage}
              onPrevious={handlePreviousPage}
            />
          )}
        </PaginationSection>
      </AppointmentListContainer>
      {targetAppointment !== 0 && (
        <PCAppointmentDetailSection>
          <AppointmentDetail parent="AppointmentDetailColumn" patientId={patient} />
        </PCAppointmentDetailSection>
      )}
    </MainContainer>
  );
}

export default History;

const PCAppointmentDetailSection = styled.section`
  animation: 1s in-out forwards;
  margin-top: 1px;
  @media ${device.laptopMax} {
    display: none;
  }

  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

const TitleSection = styled.section`
  margin-bottom: 30px;
`;

const FilterContainer = styled.div<IActive>`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 20px;
`;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;
