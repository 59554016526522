import React from 'react';

import styled from '@emotion/styled';

import { EndCallButton, CallButton } from 'components/molecules';

import * as mixins from 'styles/global/mixins';

// eslint-disable-next-line import/no-cycle
import { VideoSource } from 'pages/Treatment/Calling';

interface UtilityButtonsProps {
  audioMuted: boolean;
  leave: Function;
  onAudioMute: () => void;
  onVideoMute: () => void;
  onShare: () => void;
  videoMuted: boolean;
  videoSource: VideoSource;
}

function UtilityButtons({
  audioMuted,
  leave,
  onAudioMute,
  onVideoMute,
  onShare,
  videoMuted,
  videoSource,
}: UtilityButtonsProps) {
  return (
    <BtnWrap>
      <CallButton category="sharing" muted={videoSource === 'screen'} onClick={() => onShare()} />
      <CallButton category="video" muted={videoMuted} onClick={() => onVideoMute()} />
      <CallButton category="audio" muted={audioMuted} onClick={() => onAudioMute()} />
      <EndCallButton onCallEnd={() => leave()} />
    </BtnWrap>
  );
}

export default UtilityButtons;

const BtnWrap = styled.div`
  ${mixins.sticky}
  ${mixins.flexCenter}
  width: 100%;
  height: 100px;
  border-radius: 8px;
  background-color: var(--grayscale-09);
`;
