import React from 'react';

import styled from '@emotion/styled';

import { modalColors, globalColors } from 'styles/global/color';
import { H6 } from 'styles/text';
import { flexColumn } from 'styles/global/mixins';

import { IDepartmentList } from 'types/payloadTypes';

interface DepartmentListProps {
  departmentArray: Array<IDepartmentList>;
  onSelect: (id: number, selectedTab: string) => void;
}

function DepartmentList({ departmentArray, onSelect }: DepartmentListProps) {
  return (
    <>
      <ModalTitle>협진 요청 과 선택</ModalTitle>
      <ModalExplanation>협진 요청할 의사의 전공 과를 선택해주세요</ModalExplanation>
      <DepartmentTab>
        <DepartmentSection>
          {departmentArray.map((department, index) => {
            const { image, name, id } = department;
            return (
              <Department key={`department-${index}`} onClick={() => onSelect(id, 'DoctorListTab')}>
                <DepartmentPhoto alt="department-logo" src={image} />
                <DepartmentName>{name}</DepartmentName>
              </Department>
            );
          })}
        </DepartmentSection>
      </DepartmentTab>
    </>
  );
}

export default DepartmentList;

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 18px;
  color: ${modalColors.normalText};
  font-weight: 500;
  margin-top: 32px;
`;

const ModalText = styled.h2`
  font-family: NotoSansRegular;
  font-size: 15px;
  font-weight: normal;
`;

const ModalExplanation = styled(ModalText)`
  color: ${modalColors.normalText};
  margin-top: 9px;
  line-height: 30px;
`;

const DepartmentTab = styled.div``;

const DepartmentSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 525px;
  margin: 0 auto;
  margin-top: 56px;
  div:nth-of-type(4n) {
    margin-right: 0;
  }
`;

const Department = styled.div`
  cursor: pointer;
  ${flexColumn};
  justify-content: space-between;
  align-items: center;
  margin-right: 55px;
  margin-bottom: 24px;
  font-size: 13px;
  line-height: 19px;
  color: ${globalColors.sub};
`;

const DepartmentPhoto = styled.img`
  width: 70px;
  height: 70px;
`;

const DepartmentName = styled(H6)`
  text-align: center;
  width: 90px;
  white-space: pre-wrap;
  color: #a3a3a3;
  margin: 8px 0 0 0;
`;
