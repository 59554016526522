import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import moment, { Moment } from 'moment';
import 'moment/locale/ko';

import { calendarColors } from 'styles/global/color';
import { flexCenter, flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';
import { prevMonthBtn, nextMonthBtn, prevYearBtn, nextYearBtn } from 'assets/images';

interface CalendarProps {
  parent: 'AppointmentSchedule' | 'AppointmentHistory' | 'ChangeAppointmentModal';
  doctorId?: number;
  handleMonthChange?: (date: string) => void;
  handleDateChange?: (value: string, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  availableDays?: Array<number>;
  appointmentDateSet?: Set<number>;
  unavailableDates?: Array<string>;
}

const DAY_ARRAY = [
  { SUN: '일' },
  { MON: '월' },
  { TUE: '화' },
  { WED: '수' },
  { THURS: '목' },
  { FRI: '금' },
  { SAT: '토' },
];
const MONTH_ARRAY = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function Calendar({
  handleDateChange,
  handleMonthChange,
  availableDays,
  appointmentDateSet = new Set(),
  unavailableDates = [],
  doctorId,
  parent,
}: CalendarProps) {
  moment.locale('ko');
  const [date, setDate] = useState(moment());
  const [dateArray, setDateArray] = useState<Array<number>>([]);

  const createDateArray = () => {
    const monthArray: Array<number> = [];
    const startDay = moment(date).startOf('month').day();

    const endDate = moment(date).endOf('month').date();

    for (let i = 0; i < startDay; i++) {
      monthArray.push(0);
    }

    for (let j = 1; j <= endDate; j++) {
      monthArray.push(j);

      if (j === endDate) {
        while (monthArray.length % 7 !== 0) {
          monthArray.push(0);
        }
      }
    }
    setDateArray(monthArray);
  };

  const formatDate = (value: number) => {
    if (value < 10) {
      return `0${value}`;
    }
    return value;
  };

  const getColor = (value, index: number) => {
    const isSunday = index % 7 === 0;
    const isSaturday = index % 7 === 6;
    const hideDay = value === 0;

    if (hideDay) return 'transparent';
    if (isSunday) return '#e13237';
    if (isSaturday) return '#036ad1';
  };

  const handleActiveDate = (pastDisabled, fetchDate, index) => {
    if (pastDisabled) return false;
    if (!availableDays) return true;

    return availableDays?.includes(index % 7) && !unavailableDates.includes(fetchDate);
  };

  const handleDateBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: number) => {
    e.preventDefault();
    const dateFormat = `${date.format('YYYY-MM')}-${formatDate(value)}`;
    if (value === 0) {
      return;
    }
    setDate(moment(dateFormat));
    handleDateChange(dateFormat);
  };

  const handleCalendarBtnClick = (type: string, unit: 'month' | 'year') => {
    const tempDate = date.clone();
    let newDate: Moment;
    if (type === 'add') {
      newDate = tempDate.add(1, unit);
    } else {
      newDate = tempDate.subtract(1, unit);
    }
    setDate(newDate);
  };

  useEffect(() => {
    createDateArray();
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (parent === 'AppointmentSchedule') handleMonthChange(date.format('YYYY-MM-DD'));
    // eslint-disable-next-line
  }, [date.month(), date.year(), doctorId]);

  return (
    <CalendarContainer>
      <CalendarHeader>
        <CalendarBtn src={prevYearBtn} type="year" onClick={() => handleCalendarBtnClick('subtract', 'year')} />
        <CalendarBtn
          margin="0 18px"
          src={prevMonthBtn}
          type="month"
          onClick={() => handleCalendarBtnClick('subtract', 'month')}
        />
        <MonthAndYear>{`${MONTH_ARRAY[date.month()]} ${date.year()}`}</MonthAndYear>
        <CalendarBtn
          margin="0 18px"
          src={nextMonthBtn}
          type="month"
          onClick={() => handleCalendarBtnClick('add', 'month')}
        />
        <CalendarBtn src={nextYearBtn} type="year" onClick={() => handleCalendarBtnClick('add', 'year')} />
      </CalendarHeader>
      <DayContainer>
        {DAY_ARRAY.map((day, index: number) => (
          <Day key={`day-${index}`} color={getColor(day, index)}>
            {Object.values(day)[0]}
          </Day>
        ))}
      </DayContainer>
      <DateContainer>
        {dateArray.map((eachDate: number, index: number) => {
          const fetchDate = `${date.format('YYYY-MM')}-${formatDate(eachDate)}`;
          const pastDisabled =
            moment().add(-1, 'day').isAfter(moment(fetchDate)) && parent === 'ChangeAppointmentModal';
          return (
            <DateWrap key={`date-${index}`}>
              <Date
                active={handleActiveDate(pastDisabled, fetchDate, index)}
                color={getColor(eachDate, index)}
                disabled={(doctorId && !availableDays.includes(index % 7)) || pastDisabled}
                selected={date.date() === eachDate}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleDateBtnClick(e, eachDate)}
              >
                {eachDate}
              </Date>
              {appointmentDateSet.has(eachDate) && <AppointmentIndicator />}
            </DateWrap>
          );
        })}
      </DateContainer>
    </CalendarContainer>
  );
}

export default Calendar;

interface IDate {
  color: string;
  selected: boolean;
  active: boolean;
}

interface IDay {
  color: string;
}

interface ICalendarBtn {
  type: string;
  margin?: string;
}

const DateWrap = styled.div`
  ${flexColumn};
  position: relative;
  align-items: center;
`;

const AppointmentIndicator = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  bottom: 4px;
  background-color: ${calendarColors.appointmentIndicator};
`;

const CalendarBtn = styled.img<ICalendarBtn>`
  ${(props) => `
width: ${props.type === 'year' ? '10.8px' : '7.3px'};
height: ${props.type === 'year' ? '10px' : '10.1px'};
margin: ${props.margin || 0};
`};
  cursor: pointer;
`;

const CalendarContainer = styled.div`
  background-color: white;
  max-width: 365px;
  height: 360px;
`;

const CalendarHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const MonthAndYear = styled.span`
  margin: 0 11px;
  font-family: NotoSansMedium;
  font-size: 20px;
  color: ${calendarColors.monthAndYear};
`;

const DayContainer = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const Day = styled.span<IDay>`
  font-family: NotoSansRegular;
  font-size: 15px;
  width: 46px;
  height: 46px;
  padding: 12px 16px;
  color: ${({ color }) => color || calendarColors.day};
`;

const DateContainer = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Date = styled(BasicBtn)<IDate>`
  ${flexCenter};
  ${(props) => `
    background-color: ${props.selected ? calendarColors.selectedBackground : 'transparent'};
    font-family: ${props.selected ? 'NotoSansMedium' : 'NotoSansRegular'};
    `};
  color: ${({ selected, color, active }) => {
    if (selected) return calendarColors.selectedDate;
    if (color) return color;
    if (active) return calendarColors.availableDate;

    return calendarColors.availableDate;
  }};
  width: 36px;
  height: 36px;
  text-align: center;
  border-radius: 50%;
  margin: 5px;
  font-size: 15px;
`;
