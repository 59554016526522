import React from 'react';

import styled from '@emotion/styled';

import { Button } from 'components/atoms';

import { device } from 'styles/global/device';

interface MultiButtonProps {
  onApprove: (appointmentId: number, patientName: string) => void;
  onChangeModal: (appointmentId: number) => void;
  onCancel: (appointmentId: number, patientName: string) => void;
  patientName: string;
  appointmentId: number;
  // eslint-disable-next-line react/no-unused-prop-types
  doctorId: number;
}

function MultiButton({ onApprove, onChangeModal, onCancel, patientName, appointmentId }: MultiButtonProps) {
  return (
    <BtnWrap>
      <Button color="blue" size="small" onClick={() => onApprove(appointmentId, patientName)}>
        승인
      </Button>
      <Space />
      <Button color="blue" size="small" onClick={() => onChangeModal(appointmentId)}>
        변경
      </Button>
      <Space />
      <Button color="gray" size="small" onClick={() => onCancel(appointmentId, patientName)}>
        취소
      </Button>
    </BtnWrap>
  );
}

export default MultiButton;

const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 40px;
  @media ${device.mobileMax} {
    justify-content: center;
    margin-right: 0px;
  }
`;

const Space = styled.span`
  display: inline-block;
  width: 8px;
  height: 100%;
`;
