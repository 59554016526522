import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { getDepartmentList } from 'redux/modules/authentication';

import { signUpColors } from 'styles/global/color';
import { flexColumn } from 'styles/global/mixins';

import { IRootState, IDepartmentList } from 'types/payloadTypes';

interface SelectDeptModalProps {
  onClose: () => void;
}

function SelectDeptModal({ onClose }: SelectDeptModalProps) {
  const dispatch = useDispatch();

  const [allDept, setAllDept] = useState<Array<IDepartmentList>>([]);

  const departmentList = useSelector((state: IRootState) => state.authentication.departmentList);

  useEffect(() => {
    dispatch(getDepartmentList());
  }, [dispatch]);

  useEffect(() => {
    const sortedArray = departmentList.sort((a, b) => a.name.localeCompare(b.name));
    setAllDept(sortedArray);
  }, [departmentList, setAllDept]);

  return (
    <>
      <Overlay onClick={() => onClose()} />
      <SelectDeptModalContainer>
        <Title>진료과 선택</Title>
        <DeptWrapper>
          {allDept.map((el) => (
            <DeptWrapperInside key={el.id}>
              <img alt={el.name} src={el.image} />
              <DeptTitle>{el.name}</DeptTitle>
            </DeptWrapperInside>
          ))}
        </DeptWrapper>
      </SelectDeptModalContainer>
    </>
  );
}

export default SelectDeptModal;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000;
  z-index: 1;
  opacity: 0.15;
`;

const SelectDeptModalContainer = styled.div`
  z-index: 3;
  width: 812px;
  /* height: 616px; */
  background-color: #ffffff;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 65px;
  /* opacity: 1; */
`;

const Title = styled.h1`
  color: ${signUpColors.useTermsTitle};
  font-size: 25px;
  line-height: 37px;
  margin-left: 45px;
  margin-bottom: 20px;
`;

const DeptWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 40px;
  margin-bottom: 70px;
`;

const DeptWrapperInside = styled.div`
  ${flexColumn};
  align-items: center;
  margin-bottom: 40px;
  width: 98px;
  flex: 0 0 16.66%;
  img {
    width: 70px;
    height: 70px;
  }
`;
const DeptTitle = styled.div`
  font-size: 15px;
  line-height: 22px;
  margin-top: 7px;
  color: ${signUpColors.dept};
`;
