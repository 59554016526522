import snakeToCamel from './snakeToCamel';

interface Obj {
  [key: string]: string | number | boolean | Array<any>;
}

const arraySnakeToCamel = (array: Array<Obj>) => {
  const updated = array.map((obj) => snakeToCamel(obj));
  return updated;
};

export default arraySnakeToCamel;
