const getParameter = (name, url = window.location.href) => {
  const replaceName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${replaceName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export default getParameter;
