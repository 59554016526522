import React, { ReactNode } from 'react';

import styled from '@emotion/styled';

import * as mixins from 'styles/global/mixins';
import { hideDetailIcon, showDetailIcon } from 'assets/images';

import { IActive } from 'types/styleTypes';

interface ShowDetailHeaderProps {
  children: ReactNode;
  detailDisplayed: boolean;
  detailId: number;
  onShow: (id: number) => void;
  subDetail?: boolean;
}

function ShowDetailHeader({ children, detailDisplayed, detailId, onShow, subDetail = false }: ShowDetailHeaderProps) {
  return (
    <HeaderContainer active={subDetail} onClick={() => onShow(detailId)}>
      <H2>{children}</H2>
      <ShowDetailImage src={detailDisplayed ? hideDetailIcon : showDetailIcon} />
    </HeaderContainer>
  );
}

export default ShowDetailHeader;

const color = {
  headerText: '#777777',
  border: '#d8d8d8',
};

const HeaderContainer = styled.div<IActive>`
  ${mixins.sticky}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${color.border};
  padding: 4px;
  width: 100%;
  cursor: pointer;
  margin: ${(props) => (props.active ? '0' : '16px 0')};
`;

const H2 = styled.h2`
  font-family: NotoSansMedium;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: ${color.headerText};
`;

const ShowDetailImage = styled.img`
  height: 1.25vw;
  width: 1.25vw;
`;
