/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call, takeLatest, select, fork, all } from 'redux-saga/effects';

import { snakeToCamel } from 'utils';
import { resetInitializeAppointmentState } from './appointment';
import * as authenticationAPI from 'redux/lib/api/authentication';

import { IAuthenticationState, IRootState, INameList } from 'types/payloadTypes';
import { showFailModal, showSuccessModal } from './modal';

const PATCH_CAREER_CHANGE_SUCCESS = 'doctor/PATCH_CAREER_CHANGE_SUCCESS';
const PATCH_CAREER_CHANGE = 'doctor/PATCH_CAREER_CHANGE';

const INITIALIZE_STATE = 'doctor/INITIALIZE_STATE';

const GET_USER_INFO_SUCCESS = 'authentication/GET_USER_INFO_SUCCESS';
const GET_USER_INFO = 'authentication/GET_USER_INFO';

const PATCH_CHANGE_PASSWORD_FAIL = 'authentication/PATCH_CHANGE_PASSWORD_FAIL';
const PATCH_CHANGE_PASSWORD_SUCCESS = 'authentication/PATCH_CHANGE_PASSWORD_SUCCESS';
const PATCH_CHANGE_PASSWORD = 'authentication/PATCH_CHANGE_PASSWORD';

const POST_LOGIN = 'authentication/POST_LOGIN';
const POST_LOGIN_STATUS = 'authentication/POST_LOGIN_STATUS';
const POST_LOGOUT = 'authentication/POST_LOGOUT';
const RESET_USER_INFO = 'authentication/RESET_USERINFO';

const GET_DEPARTMENT_LIST = 'authentication/GET_DEPARTMENT_LIST';
const GET_DEPARTMENT_LIST_SUCCESS = 'authentication/GET_DEPARTMENT_LIST_SUCCESS';

const GET_VERIFICATION_CODE = 'authentication/GET_VERIFICATION_CODE';
const GET_VERIFICATION_CODE_SUCCESS = 'authentication/GET_VERIFICATION_CODE_SUCCESS';

const PUT_RECORDING = 'authentication/PUT_RECORDING';

const POST_FIND_USERNAME = 'authentication/POST_FIND_USERNAME';
const POST_FIND_PASSWORD = 'authentication/POST_FIND_PASSWORD';
const POST_FIND_USENAME_STATUS = 'authentication/POST_FIND_USENAME_STATUS';
const POST_FIND_PASSWORD_STATUS = 'authentication/POST_FIND_PASSWORD_STATUS';
const POST_VERIFY_PHONE = 'authentication/POST_VERIFY_PHONE';
const SET_POST_VERIFY_PHONE_STATUS = 'authentication/SET_POST_VERIFY_PHONE_STATUS';
const RESET_FOUND_USER_INFO = 'authentication/RESET_FOUND_USER_INFO';
const GET_SPECIALTY_LIST = 'authentication/GET_SPECIALTY_LIST';
const GET_SPECIALTY_LIST_SUCCESS = 'authentication/GET_SPECIALTY_LIST_SUCCESS';
const PATCH_SPECIALTY = 'authentication/PATCH_SPECIALTY';
const GET_POLICY = 'authentication/GET_POLICY';
const GET_POLICY_SUCCESS = 'authentication/GET_POLICY_SUCCESS';
const PATCH_FCM_TOKEN = 'authentication/PATCH_FCM_TOKEN';
const AUTO_LOGOUT_SUCCESS = 'authentication/AUTO_LOGOUT_SUCCESS';
const PATCH_CHANGE_PASSWORD_GRACE_PERIOD = 'authentication/PATCH_CHANGE_PASSWORD_GRACE_PERIOD';

const initialState: IAuthenticationState = {
  departmentList: [],
  signInRequest: 0,
  signInStatus: 0,
  signInMsg: '',
  oldPassword: '',
  isLogin: false,
  isLoading: false,
  foundUsername: '',
  postFindPasswordStatus: false,
  changePasswordPeriodStatus: true,
  codeSent: false,
  verified: false,
  passwordChanged: false,
  careerChanged: false,
  specialtyList: [],
  userInfo: {
    departmentName: [],
    departmentId: [],
    doctorId: 0,
    hasRecording: false,
    isInitialLogin: false,
    id: 0,
    username: '',
    fullname: '',
    hospital: '',
    idPhoto: '',
    specialty: [],
    phoneNumber: '',
    hasSchedule: false,
    career: [],
    education: [],
    firebaseUid: '',
  },
  policy: {
    title: '',
    script: '',
  },
  error: null,
  autoLogoutActive: false,
};

interface IAuthenticationAction {
  type: string;
  payload: IAuthenticationState;
}

export const patchChangePasswordGracePeriod = (payload) => ({
  type: PATCH_CHANGE_PASSWORD_GRACE_PERIOD,
  payload,
});

export const autoLogoutSuccess = (payload) => ({
  type: AUTO_LOGOUT_SUCCESS,
  payload,
});

export const patchFCMToken = () => ({
  type: PATCH_FCM_TOKEN,
});

const patchCareerChangeSuccess = (payload) => ({
  type: PATCH_CAREER_CHANGE_SUCCESS,
  payload,
});

export const patchCareerChange = (payload) => ({
  type: PATCH_CAREER_CHANGE,
  payload,
});

export const initializeState = () => ({
  type: INITIALIZE_STATE,
});

const patchChangePasswordFail = (payload) => ({
  type: PATCH_CHANGE_PASSWORD_FAIL,
  payload,
});

const patchChangePasswordSuccess = (payload) => ({
  type: PATCH_CHANGE_PASSWORD_SUCCESS,
  payload,
});

export const getPolicy = (payload) => ({
  type: GET_POLICY,
  payload,
});

export const getPolicySuccess = (payload) => ({
  type: GET_POLICY_SUCCESS,
  payload,
});

export const postLogout = () => ({
  type: POST_LOGOUT,
});

const resetUserInfo = () => ({
  type: RESET_USER_INFO,
});

export const patchSpecialty = (payload) => ({
  type: PATCH_SPECIALTY,
  payload,
});

export const getSpecialtyList = (payload) => ({
  type: GET_SPECIALTY_LIST,
  payload,
});

const getSpecialtyListSuccess = (payload) => ({
  type: GET_SPECIALTY_LIST_SUCCESS,
  payload,
});

export const postVerifyPhone = (payload) => ({
  type: POST_VERIFY_PHONE,
  payload,
});

export const resetFoundUserInfo = () => ({
  type: RESET_FOUND_USER_INFO,
});

const setPostVerifyPhoneStatus = (payload) => ({
  type: SET_POST_VERIFY_PHONE_STATUS,
  payload,
});

export const postFindUsername = (payload) => ({
  type: POST_FIND_USERNAME,
  payload,
});

const postFindUsernameStatus = (payload) => ({
  type: POST_FIND_USENAME_STATUS,
  payload,
});

export const postFindPassword = (payload) => ({
  type: POST_FIND_PASSWORD,
  payload,
});

const setPostFindPasswordStatus = (payload) => ({
  type: POST_FIND_PASSWORD_STATUS,
  payload,
});

const getUserInfoSuccess = (payload) => ({
  type: GET_USER_INFO_SUCCESS,
  payload,
});

export const getUserInfo = () => ({
  type: GET_USER_INFO,
});

export const putRecording = (payload) => ({
  type: PUT_RECORDING,
  payload,
});

export const patchChangePassword = (payload) => ({
  type: PATCH_CHANGE_PASSWORD,
  payload,
});

export const postLogin = (payload) => ({
  type: POST_LOGIN,
  payload,
});

const postLoginStatus = (payload) => ({
  type: POST_LOGIN_STATUS,
  payload,
});

export const getVerificationCode = (payload) => ({
  type: GET_VERIFICATION_CODE,
  payload,
});

const getVerificationCodeSuccess = (payload) => ({
  type: GET_VERIFICATION_CODE_SUCCESS,
  payload,
});

export const getDepartmentList = () => ({
  type: GET_DEPARTMENT_LIST,
});

const getDepartmentListSuccess = (payload) => ({
  type: GET_DEPARTMENT_LIST_SUCCESS,
  payload,
});

const authenticationReducer = (state = initialState, action: IAuthenticationAction) => {
  switch (action.type) {
    case INITIALIZE_STATE: {
      return {
        ...state,
        error: null,
        codeSent: false,
        verified: false,
        passwordChanged: false,
        careerChanged: false,
      };
    }
    case AUTO_LOGOUT_SUCCESS: {
      const autoLogoutActive = action.payload;
      return {
        ...state,
        autoLogoutActive,
      };
    }
    case PATCH_CAREER_CHANGE_SUCCESS: {
      const { careerChanged } = action.payload;
      return {
        ...state,
        careerChanged,
        error: null,
      };
    }
    case PATCH_CHANGE_PASSWORD_FAIL: {
      const { error } = action.payload;
      return {
        ...state,
        error,
      };
    }
    case PATCH_CHANGE_PASSWORD_SUCCESS: {
      const { passwordChanged } = action.payload;
      return {
        ...state,
        passwordChanged,
        error: null,
      };
    }
    case GET_POLICY_SUCCESS: {
      const { policy } = action.payload;
      return {
        ...state,
        policy,
      };
    }
    case RESET_USER_INFO: {
      return {
        ...state,
        userInfo: {
          ...initialState.userInfo,
        },
      };
    }
    case GET_SPECIALTY_LIST_SUCCESS: {
      const { specialtyList } = action.payload;
      return {
        ...state,
        specialtyList: [...new Set([...state.specialtyList, ...specialtyList])],
      };
    }
    case RESET_FOUND_USER_INFO: {
      return {
        ...state,
        foundUsername: '',
        postFindPasswordStatus: false,
      };
    }

    case POST_FIND_PASSWORD_STATUS: {
      const { postFindPasswordStatus } = action.payload;
      return {
        ...state,
        postFindPasswordStatus,
      };
    }

    case POST_FIND_USENAME_STATUS: {
      const { foundUsername } = action.payload;

      return {
        ...state,
        foundUsername,
        verified: false,
        codeSent: false,
      };
    }
    case SET_POST_VERIFY_PHONE_STATUS: {
      const { verified } = action.payload;

      return {
        ...state,
        verified,
      };
    }

    case GET_USER_INFO_SUCCESS: {
      const { isLogin, isLoading, userInfo } = action.payload;

      return {
        ...state,
        isLogin,
        isLoading,
        userInfo,
      };
    }

    case POST_LOGIN_STATUS: {
      const { signInStatus, oldPassword, userInfo, changePasswordPeriodStatus, signInMsg } = action.payload;
      if (signInStatus === 200) {
        return {
          ...state,
          signInStatus: 200,
          oldPassword,
          signInRequest: state.signInRequest + 1,
          userInfo,
          changePasswordPeriodStatus,
        };
      }
      return {
        ...state,
        signInStatus: 400,
        signInRequest: state.signInRequest + 1,
        signInMsg,
      };
    }
    case GET_VERIFICATION_CODE_SUCCESS: {
      const { codeSent } = action.payload;

      return {
        ...state,
        codeSent,
      };
    }
    case GET_DEPARTMENT_LIST_SUCCESS: {
      const { departmentList } = action.payload;
      return {
        ...state,
        departmentList,
      };
    }
    default: {
      return state;
    }
  }
};

function* fetchCareerChange({ type, payload }) {
  try {
    const { doctorId } = yield select((state) => state.authentication.userInfo);

    yield call(authenticationAPI.changeDoctorCareer, { payload, doctorId });

    yield put(patchCareerChangeSuccess({ careerChanged: true }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchUserInfo() {
  try {
    const {
      status,
      data: { user_info },
    } = yield call(authenticationAPI.getUserInfo);

    if (status === 200) {
      const userInfo = snakeToCamel(user_info);

      yield all([
        yield put(
          getUserInfoSuccess({
            isLogin: true,
            isLoading: true,
            userInfo,
          }),
        ),
        yield put(initializeState()),
        yield put(patchFCMToken()),
      ]);
    } else {
      yield put(resetUserInfo());
    }
  } catch (e) {
    yield put(resetUserInfo());
    yield call([localStorage, 'removeItem'], 'token');
  }
}

function* fetchRecording({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.audioRecording, payload);
    if (status === 200) {
      yield fork(fetchUserInfo);
    }
  } catch (e) {
    console.error(e);
  }
}

function* fetchChangePassword({ type, payload }) {
  try {
    const {
      data: { token },
      status,
    } = yield call(authenticationAPI.changePassword, payload);

    if (status === 200) {
      yield localStorage.setItem('token', token);
      yield put(patchChangePasswordSuccess({ passwordChanged: true }));
      yield put(
        showSuccessModal({
          modalInfo: {
            modalTitle: '비밀번호 변경',
            modalExplanation: '비밀번호를 성공적으로 변경하였습니다.',
          },
        }),
      );
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '비밀번호 변경 실패',
          modalExplanation: '최근 사용한 비밀번호 입니다. 다른 비밀번호를 사용해주세요.',
        },
      }),
    );
    yield put(patchChangePasswordFail({ e }));
  }
}

function* fetchLogin({ type, payload }) {
  try {
    const { password } = payload;
    const fcmToken = yield call([localStorage, 'getItem'], 'fcmToken');

    const {
      data: { user_info, token, change_password },
      status,
    } = yield call(authenticationAPI.login, { ...payload, fcmToken });

    const userInfo = snakeToCamel(user_info);

    yield put(
      postLoginStatus({
        signInStatus: status,
        oldPassword: password,
        userInfo,
        changePasswordPeriodStatus: change_password,
      }),
    );
    yield localStorage.setItem('token', token);
  } catch (e) {
    const {
      response: { data },
    } = e;
    yield put(
      postLoginStatus({
        signInStatus: 400,
        signInMsg: data.msg,
      }),
    );
  }
}

function* fetchVerificationCode({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.phoneVerificationCode, payload);

    if (status === 200) {
      yield put(getVerificationCodeSuccess({ codeSent: true }));
    } else {
      yield put(getVerificationCodeSuccess({ codeSent: false }));
    }
  } catch (e) {
    yield put(getVerificationCodeSuccess({ codeSent: false }));
  }
}

function* fetchDepartmentList() {
  try {
    const { data } = yield call(authenticationAPI.departmentList);

    yield put(getDepartmentListSuccess({ departmentList: data }));
  } catch (e) {
    console.error(e);
  }
}

function* fetchFindPassword({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.findPassword, payload);

    if (status === 200) {
      yield put(setPostFindPasswordStatus({ postFindPasswordStatus: true }));
    } else {
      yield put(setPostFindPasswordStatus({ postFindPasswordStatus: false }));
    }
  } catch (e) {
    yield put(setPostFindPasswordStatus({ postFindPasswordStatus: false }));
  }
}

function* fetchFindUsername({ type, payload }) {
  try {
    const {
      status,
      data: { username },
    } = yield call(authenticationAPI.findUsername, payload);

    if (status === 200) {
      yield put(postFindUsernameStatus({ foundUsername: username }));
    } else {
      yield put(postFindUsernameStatus({ foundUsername: 'ERROR_NO_SUCH_USER' }));
    }
  } catch (e) {
    postFindUsernameStatus({ foundUsername: 'ERROR_NO_SUCH_USER' });
  }
}

function* fetchVerityPhone({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.verityPhone, payload);

    if (status === 200) {
      yield put(setPostVerifyPhoneStatus({ verified: true }));
    } else {
      yield put(setPostVerifyPhoneStatus({ verified: false }));
    }
  } catch (e) {
    yield put(setPostVerifyPhoneStatus({ verified: false }));
  }
}

function* fetchSpecialtyList({ type, payload }) {
  try {
    const {
      status,
      data: { specialty_list },
    } = yield call(authenticationAPI.getSpecialtyList, payload);

    if (status === 200) {
      const specialtyList = specialty_list.map((specialty: INameList) => specialty.name);

      yield put(getSpecialtyListSuccess({ specialtyList }));
    } else {
      yield put(getSpecialtyListSuccess({ specialtyList: [] }));
    }
  } catch (e) {
    yield put(getSpecialtyListSuccess({ specialtyList: [] }));
  }
}

function* fetchUpdateSpecialty({ type, payload }) {
  try {
    yield call(authenticationAPI.updateSpecialty, payload);

    yield fork(fetchUserInfo);
  } catch (e) {
    yield fork(fetchUserInfo);
  }
}

function* fetchLogout({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.logout);

    if (status === 204) {
      yield call([localStorage, 'removeItem'], 'token');
      yield put(resetInitializeAppointmentState());
    }
    yield put(resetUserInfo());
  } catch (e) {
    yield put(resetUserInfo());
  }
}

function* fetchPolicy({ type, payload }) {
  try {
    const {
      data: { title, script },
    } = yield call(authenticationAPI.getPolicy, payload);

    yield put(
      getPolicySuccess({
        policy: { title, script },
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchFCMToken({ type, payload }) {
  try {
    const fcmToken = yield call([localStorage, 'getItem'], 'fcmToken');
    const userId = yield select((state: IRootState) => state.authentication.userInfo.id);

    yield call(authenticationAPI.getFcmToken, { userId, fcmToken });
  } catch (e) {
    console.error(e);
  }
}

function* fetchChangePasswordGracePeriod({ type, payload }) {
  try {
    const { status } = yield call(authenticationAPI.passwordGracePeriod, payload);

    if (status === 200) {
      yield put(patchChangePasswordSuccess({ passwordChanged: true }));
    }
  } catch (e) {
    yield fork(fetchUserInfo);
  }
}

export const authenticationSagas = [
  takeLatest(GET_DEPARTMENT_LIST, fetchDepartmentList),
  takeLatest(GET_VERIFICATION_CODE, fetchVerificationCode),
  takeLatest(POST_LOGIN, fetchLogin),
  takeLatest(PATCH_CHANGE_PASSWORD, fetchChangePassword),
  takeLatest(GET_USER_INFO, fetchUserInfo),
  takeLatest(PUT_RECORDING, fetchRecording),
  takeLatest(POST_FIND_USERNAME, fetchFindUsername),
  takeLatest(POST_FIND_PASSWORD, fetchFindPassword),
  takeLatest(POST_VERIFY_PHONE, fetchVerityPhone),
  takeLatest(GET_SPECIALTY_LIST, fetchSpecialtyList),
  takeLatest(PATCH_SPECIALTY, fetchUpdateSpecialty),
  takeLatest(POST_LOGOUT, fetchLogout),
  takeLatest(GET_POLICY, fetchPolicy),
  takeLatest(PATCH_CAREER_CHANGE, fetchCareerChange),
  takeLatest(PATCH_FCM_TOKEN, fetchFCMToken),
  takeLatest(PATCH_CHANGE_PASSWORD_GRACE_PERIOD, fetchChangePasswordGracePeriod),
];

export default authenticationReducer;
