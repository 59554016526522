import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import moment from 'moment';
import 'moment/locale/ko';

import { Spinner } from 'components/atoms';
import { PatientInformation } from 'components/organisms';

import { getPatientDiagnosis, getPatientQuestionnaire } from 'redux/modules/appointment';

import { formatAge } from 'utils';

import { H1, H2 } from 'styles/text';
import { device } from 'styles/global/device';
import { globalColors, appointmentListColors } from 'styles/global/color';
import { flexCenter, flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';
import { videoCallIcon, voiceCallIcon } from 'assets/images';

import { IRootState } from 'types/payloadTypes';
import { IActive } from 'types/styleTypes';

interface WaitingAppointmentDetailProps {
  parent: 'AppointmentDetailColumn' | 'AppointmentDetailRow' | 'History';
  isMobile?: boolean;
  onStart?: (appointmentId: number) => void;
  patientId: number;
  appointmentId?: number;
  showConnection?: boolean;
  openCooperationModal?: () => void;
}

function WaitingAppointmentDetail({
  parent,
  onStart,
  isMobile,
  patientId,
  appointmentId,
  showConnection,
  openCooperationModal,
}: WaitingAppointmentDetailProps) {
  moment.locale('ko');

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const { patientName, dateOfBirth, patientGender, id, hasPatientEntered, status } = useSelector(
    (state: IRootState) => state.appointment.appointmentDetail.appointment,
  );

  useEffect(() => {
    if (patientId) {
      dispatch(getPatientQuestionnaire(patientId));
      dispatch(getPatientDiagnosis(patientId));
    }
    // eslint-disable-next-line
  }, [patientId]);

  useEffect(() => {
    const loadingTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => clearTimeout(loadingTimeout);
  }, []);

  return (
    <WaitingAppointmentDetailContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <PatientNameSection>
            {parent === 'AppointmentDetailColumn' && !isMobile && showConnection && (
              <ConnectionStatus active={hasPatientEntered}></ConnectionStatus>
            )}
            <H1>{patientName}</H1>
            <H2 fontSize={20} margin="0 0 0 10px">
              {formatAge(dateOfBirth)}
              {`(${patientGender === 'M' ? '남' : '여'})`}
            </H2>
          </PatientNameSection>
          {false && status === 0 && (
            <CallingSection>
              <MakeCallBtn onClick={() => onStart(id)}>
                <MakeCallIcon src={videoCallIcon} />
                영상 진료 시작
              </MakeCallBtn>
              <CooperationBtn>
                <CooperationIcon src={voiceCallIcon} />
                음성 진료 시작
              </CooperationBtn>
            </CallingSection>
          )}
          <PatientInfoSection>
            <PatientInformation appointmentId={appointmentId} parent={parent} onCooperate={openCooperationModal} />
          </PatientInfoSection>
        </>
      )}
    </WaitingAppointmentDetailContainer>
  );
}

export default WaitingAppointmentDetail;

const WaitingAppointmentDetailContainer = styled.div`
  ${flexColumn};
  background-color: white;
  width: 538px;
  height: 100%;
  overflow: auto;
  position: relative;
  @media ${device.laptopMax} {
    width: 100%;
    min-height: 590px;
  }
`;

const ConnectionStatus = styled.div<IActive>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  //접속시 컬러 변경코드
  background-color: ${(props) => (props.active ? appointmentListColors.online : appointmentListColors.offline)};
  margin-right: 10px;
`;

const PatientNameSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 60px 50px 43px;
  @media ${device.laptopMax} {
    padding: 32px 50px 32px 54px;
  }
`;

const CallingSection = styled.div`
  display: flex;
  padding: 0 50px;
  margin-bottom: 56px;
  @media ${device.laptopMax} {
    margin-top: 10px;
    width: 40%;
    order: 2;
  }
`;

const PatientInfoSection = styled.section`
  padding: 0 10px;
  height: 100%;
`;

const MakeCallBtn = styled(BasicBtn)`
  display: flex;
  justify-content: center;
  border-radius: 4px;
  padding: 10px 45px;
  margin-right: 8px;
  font-family: NotoSansMedium;
  font-size: 20px;
  font-weight: 500;
  color: white;
  background-color: ${globalColors.main};
`;

const MakeCallIcon = styled.img`
  width: 30px;
  height: 17px;
  margin-right: 14px;
  margin-top: 5px;
`;

const CooperationBtn = styled(BasicBtn)`
  ${flexCenter};
  border-radius: 4px;
  color: white;
  font-family: NotoSansCJKkr;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 14px;
  background-color: #ddeeff;
  border: 1px solid #a3d1ff;
  color: #3e8bd8;
`;

const CooperationIcon = styled.img`
  width: 18.1px;
  height: 19.2px;
  margin-right: 16px;
  object-fit: contain;
`;
