import React from 'react';

import { css } from '@emotion/core';

import PulseLoader from 'react-spinners/PulseLoader';
import { ClipLoader } from 'react-spinners';

interface SpinnerProps {
  isCircle?: boolean;
}

function Spinner({ isCircle }: SpinnerProps) {
  return (
    <>
      {isCircle && <ClipLoader color="#fafafa" css={clipOverride} size={23} />}
      {!isCircle && <PulseLoader color="#036AD1" css={pulseOverride} size={25} />}
    </>
  );
}

export default Spinner;

const clipOverride = css`
  display: block;
  margin: 0 auto;
`;

const pulseOverride = css`
  display: block;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
`;
