import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Image } from 'components/atoms';
import UseTerms from './UseTerms';
import UserInfo from './UserInfo';
import DoctorInfo from './DoctorInfo';

import { signUpColors } from 'styles/global/color';
import { flexCenter } from 'styles/global/mixins';

function SignUp() {
  const [step, setStep] = useState(1);

  const handleNextPage = () => {
    setStep(step + 1);
  };

  return (
    <SignUpContainer>
      <LogoWrapper>
        <Image alt="logo" src="icons/ic-logo.svg" />
      </LogoWrapper>
      <SignUpFlow>
        <FlowStep active={step === 1}>약관동의</FlowStep>
        <FlowStep active={step === 2}>가입정보</FlowStep>
        <FlowStep active={step === 3}>의사정보</FlowStep>
        <FlowStep active={step === 4}>가입완료</FlowStep>
      </SignUpFlow>
      <section>{step === 1 && <UseTerms onNext={handleNextPage} />}</section>
      <section>{step === 2 && <UserInfo />}</section>
      <section>{step === 3 && <DoctorInfo />}</section>
    </SignUpContainer>
  );
}

export default SignUp;
interface IFlowStep {
  active: boolean;
}
const SignUpContainer = styled.div`
  margin: 0 auto;
  font-family: NotoSansRegular;
  width: 794px;
  position: relative;
`;

const LogoWrapper = styled.div`
  ${flexCenter};
  width: 134.7px;
  height: 27.3px;
  margin: 70px auto;
`;

const SignUpFlow = styled.ol`
  display: flex;
  justify-content: space-between;
`;

const FlowStep = styled.li<IFlowStep>`
  position: relative;
  width: 156px;
  height: 40px;
  border: solid 1px ${(props) => (props.active ? signUpColors.activeBorder : signUpColors.inactiveBorder)};
  background-color: ${(props) => (props.active ? signUpColors.activeBackground : signUpColors.inactiveBackground)};
  color: ${(props) => (props.active ? signUpColors.activeText : signUpColors.inactiveText)};
  border-radius: 22px;
  font-size: 15px;
  line-height: 35px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -150px;
    background-color: ${signUpColors.inactiveBorder};
    height: 2px;
    width: 100%;
    z-index: -1;
  }
  &:first-of-type:before {
    display: none;
  }
`;
