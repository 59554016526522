const formatPageArray = (count: number) => {
  const pageNumbers = [];
  const math = Math.ceil(count / 10);
  for (let i = 1; i <= math; i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};

export default formatPageArray;
