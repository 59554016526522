import React from 'react';

import styled from '@emotion/styled';

import { Button } from 'components/atoms';

import { modalColors } from 'styles/global/color';
import { device } from 'styles/global/device';

interface AutoLogoutInfoModalProps {
  onConfirm: () => void;
}

function AutoLogoutInfoModal({ onConfirm }: AutoLogoutInfoModalProps) {
  return (
    <ModalContainer>
      <ModalTitle>자동 로그아웃 안내</ModalTitle>
      <LogoutReason>장시간 활동이 없어 자동 로그아웃 되었습니다.</LogoutReason>
      <ModalBtnWrap>
        <Button color="lightBlue" size="big" onClick={onConfirm}>
          확인
        </Button>
      </ModalBtnWrap>
    </ModalContainer>
  );
}

export default AutoLogoutInfoModal;

const ModalContainer = styled.form`
  width: 620px;
  padding: 40px 40px 30px;
  border-radius: 5px;
  background-color: white;
`;

const LogoutReason = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${modalColors.cancelReasonInputBackground};
  color: ${modalColors.cancelReasonText};
  font-family: NotoSansRegular;
  font-size: 17px;
  text-align: left;
  padding: 30px 0;
  margin-top: 30px;
  border: none;
  width: 100%;
`;

const ModalTitle = styled.h1`
  font-family: NotoSansMedium;
  font-size: 21px;
  color: ${modalColors.normalText};
  font-weight: 500;
  text-align: left;
  padding-bottom: 15px;
  border-bottom: 1px solid ${modalColors.cancelReasonInputBackground};
  @media ${device.mobileMax} {
    font-size: 14px;
  }
`;

const ModalBtnWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
  height: 45px;
`;
