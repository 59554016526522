/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call, takeLatest, select } from 'redux-saga/effects';

import * as appointmentAPI from 'redux/lib/api/appointment';
import { createActionTypes } from 'redux/lib/createSaga';
import { startLoading, finishLoading } from 'redux/modules/loading';

import { showFailModal, showSuccessModal } from './modal';

import { arraySnakeToCamel } from 'utils';

import { IWaitingList, IRootState } from 'types/payloadTypes';

const GET_PENDING_PATIENT_LIST_PREVIOUS_PAGE = 'waitingList/GET_PENDING_PATIENT_LIST_PREVIOUS_PAGE';
const GET_PENDING_PATIENT_LIST_NEXT_PAGE = 'waitingList/GET_PENDING_PATIENT_LIST_NEXT_PAGE';

const PATCH_REJECT_APPOINTMENT = 'appointment/PATCH_REJECT_APPOINTMENT';

const PATCH_APPROVE_APPOINTMENT = 'request/PATCH_APPROVE_APPOINTMENT,';

const [GET_PENDING_PATIENT_LIST, GET_PENDING_PATIENT_LIST_SUCCESS] = createActionTypes(
  'appoinetment/GET_PENDING_PATIENT_LIST',
);

const PATCH_CHANGE_APPOINTMENT = 'appointment/CHANGE_APPOINTMENT';

const initialState = {
  waitingApprovalList: {
    count: 0,
    waitingApprovalArray: [],
    next: '',
    previous: '',
  },
};

interface IWaitingListAction {
  type: string;
  payload: IWaitingList;
}

export const patchChangeAppointment = (payload) => ({
  type: PATCH_CHANGE_APPOINTMENT,
  payload,
});

export const patchRejectAppointment = (payload) => ({
  type: PATCH_REJECT_APPOINTMENT,
  payload,
});

export const getPendingPatientListNextPage = () => ({
  type: GET_PENDING_PATIENT_LIST_NEXT_PAGE,
});

export const getPendingPatientListPreviousPage = () => ({
  type: GET_PENDING_PATIENT_LIST_PREVIOUS_PAGE,
});

export const patchApproveAppointment = (payload) => ({
  payload,
  type: PATCH_APPROVE_APPOINTMENT,
});

export const getPendingPatientListSuccess = (payload) => ({
  payload,
  type: GET_PENDING_PATIENT_LIST_SUCCESS,
});

export const getPendingPatientList = (payload) => ({
  payload,
  type: GET_PENDING_PATIENT_LIST,
});

const waitingListReducer = (state = initialState, action: IWaitingListAction) => {
  switch (action.type) {
    case GET_PENDING_PATIENT_LIST_SUCCESS: {
      const waitingApprovalList = action.payload;
      return {
        ...state,
        waitingApprovalList,
      };
    }

    default: {
      return state;
    }
  }
};

function* fetchChangeAppointment({ type, payload }) {
  try {
    const { status } = yield call(appointmentAPI.changeAppointment, payload);

    if (status === 200) {
      yield put(
        showSuccessModal({
          modalInfo: {
            modalTitle: '변경 완료',
            modalExplanation: '예약이 성공적으로 변경되었습니다.',
          },
        }),
      );
    }
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '변경 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
}

function* fetchRejectAppointment({ type, payload }) {
  yield put(startLoading(type));
  try {
    yield call(appointmentAPI.rejectAppointment, payload);
    yield put(
      showSuccessModal({
        modalInfo: {
          modalTitle: '거절 완료',
          modalExplanation: '예약이 성공적으로 거절되었습니다.',
        },
      }),
    );
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '거절 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
  yield put(startLoading(type));
}

function* fetchApproveAppointment({ type, payload }) {
  yield put(startLoading(type));

  try {
    yield call(appointmentAPI.approveAppointment, payload);
    yield put(
      showSuccessModal({
        modalInfo: {
          modalTitle: '승인 완료',
          modalExplanation: '예약이 성공적으로 승인되었습니다.',
        },
      }),
    );
  } catch (e) {
    yield put(
      showFailModal({
        modalInfo: {
          modalTitle: '승인 실패',
          modalExplanation: e.response.data.msg,
        },
      }),
    );
  }
  yield put(finishLoading(type));
}

function* fetchPendingPatientList({ type, payload }) {
  yield put(startLoading(type));
  try {
    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.waitingPatientList, payload);

    const waitingApprovalArray = arraySnakeToCamel(results);

    yield put(
      getPendingPatientListSuccess({
        count,
        next,
        previous,
        waitingApprovalArray,
      }),
    );
  } catch (e) {
    console.error(e);
  }
  yield put(finishLoading(type));
}

function* fetchPendingListNextPage({ type, payload }) {
  try {
    const nextPageUrl = yield select((state: IRootState) => state.waitingList.waitingApprovalList.next);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.pendingListNextPage, nextPageUrl);

    const waitingApprovalArray = arraySnakeToCamel(results);

    yield put(
      getPendingPatientListSuccess({
        count,
        next,
        previous,
        waitingApprovalArray,
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

function* fetchPendingListPreviousPage({ type, payload }) {
  try {
    const prevPageUrl = yield select((state: IRootState) => state.waitingList.waitingApprovalList.previous);

    const {
      data: { count, next, previous, results },
    } = yield call(appointmentAPI.pendingListPrevPage, prevPageUrl);

    const waitingApprovalArray = arraySnakeToCamel(results);

    yield put(
      getPendingPatientListSuccess({
        count,
        next,
        previous,
        waitingApprovalArray,
      }),
    );
  } catch (e) {
    console.error(e);
  }
}

export const waitingListSagas = [
  takeLatest(PATCH_CHANGE_APPOINTMENT, fetchChangeAppointment),
  takeLatest(GET_PENDING_PATIENT_LIST, fetchPendingPatientList),
  takeLatest(PATCH_APPROVE_APPOINTMENT, fetchApproveAppointment),
  takeLatest(GET_PENDING_PATIENT_LIST_NEXT_PAGE, fetchPendingListNextPage),
  takeLatest(GET_PENDING_PATIENT_LIST_PREVIOUS_PAGE, fetchPendingListPreviousPage),
  takeLatest(PATCH_REJECT_APPOINTMENT, fetchRejectAppointment),
];

export default waitingListReducer;
