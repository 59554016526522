import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Image, Timer } from 'components/atoms';

import {
  postFindUsername,
  postFindPassword,
  getVerificationCode,
  postVerifyPhone,
  resetFoundUserInfo,
} from 'redux/modules/authentication';

import { useForm } from 'hooks';

import { findInfoModalColor } from 'styles/global/color';
import { NormalInput } from 'styles/textinput';
import { SectionLabel } from 'styles/text';
import { BasicBtn } from 'styles/button';

import { IRootState } from 'types/payloadTypes';

interface FindInfoModalProps {
  onClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const initialForm = {
  firstName: '',
  lastName: '',
  username: '',
  phoneNumber: '',
  code: '',
};

function FindInfoModal({ onClose }: FindInfoModalProps) {
  const dispatch = useDispatch();

  const [form, onFormChange] = useForm(initialForm);

  const [selected, setSelected] = useState('username');

  const { foundUsername, postFindPasswordStatus, codeSent, verified } = useSelector(
    (state: IRootState) => state.authentication,
  );

  const getFoundUserInfoText = () => {
    if (foundUsername !== '') {
      return (
        <FoundUserInfo>
          가입된 아이디는
          <FoundUsername>{foundUsername}</FoundUsername>
          입니다
        </FoundUserInfo>
      );
    }
    if (postFindPasswordStatus) {
      return <FoundUserInfo>카카오톡으로 임시 비밀번호를 발송하였습니다</FoundUserInfo>;
    }
  };

  const handleInfoOptionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    e.preventDefault();
    if (foundUsername !== '' || postFindPasswordStatus) {
      dispatch(resetFoundUserInfo());
    }
    setSelected(value);
  };

  const handleVerificationCodeRetrieve = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    dispatch(
      getVerificationCode({
        ...form,
        phoneNumber: form.phoneNumber.replace(/-/g, ''),
      }),
    );
  };

  const handlePhoneVerificationRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    dispatch(
      postVerifyPhone({
        ...form,
        phoneNumber: form.phoneNumber.replace(/-/g, ''),
      }),
    );
  };

  const handleFindUsernameRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(postFindUsername(form));
  };

  const handleFindPasswordRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(postFindPassword(form));
  };

  const handleRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (foundUsername !== '' || postFindPasswordStatus) {
      return onClose(e);
    }

    if (selected === 'username') {
      return handleFindUsernameRequest(e);
    }

    return handleFindPasswordRequest(e);
  };

  const handleBtnText = () => {
    if (foundUsername !== '' || postFindPasswordStatus) {
      return '로그인하기';
    }

    if (selected === 'username') {
      return '아이디 찾기';
    }

    return '비밀번호 찾기';
  };

  return (
    <FindInfoModalContainer>
      <CloseBtnWrap onClick={(e) => onClose(e)}>
        <Image alt="close" src="icons/ic-close.svg" />
      </CloseBtnWrap>
      <FindInfoModalWrap>
        <InfoOptionSection>
          <OptionInfoBtn active={selected === 'username'} onClick={(e) => handleInfoOptionClick(e, 'username')}>
            아이디 찾기
          </OptionInfoBtn>
          <OptionInfoBtn active={selected === 'password'} onClick={(e) => handleInfoOptionClick(e, 'password')}>
            비밀번호 찾기
          </OptionInfoBtn>
        </InfoOptionSection>

        {foundUsername === '' && !postFindPasswordStatus ? (
          <InputSection>
            {selected === 'username' ? (
              <InputWrap>
                <span>
                  <FindInfoLabel>성</FindInfoLabel>
                  <FindInfoInput name="lastName" placeholder="성을 입력해주세요" onChange={onFormChange} />
                </span>
                <span>
                  <FindInfoLabel>이름</FindInfoLabel>
                  <FindInfoInput name="firstName" placeholder="이름을 입력해주세요" onChange={onFormChange} />
                </span>
              </InputWrap>
            ) : (
              <>
                <FindInfoLabel>이메일</FindInfoLabel>
                <InputWrap>
                  <FindInfoInput name="username" placeholder="이메일을 입력해주세요" onChange={onFormChange} />
                </InputWrap>
              </>
            )}
            <FindInfoLabel>핸드폰 번호</FindInfoLabel>
            <InputWrap>
              <FindInfoInput name="phoneNumber" placeholder="핸드폰 번호를 입력해주세요" onChange={onFormChange} />
              <VerifyBtn sent={codeSent} onClick={(e) => handleVerificationCodeRetrieve(e)}>
                인증번호 발송
              </VerifyBtn>
            </InputWrap>
            {codeSent && <Notification>인증 문자를 입력한 핸드폰 번호로 발송하였습니다</Notification>}
            <FindInfoLabel>인증번호</FindInfoLabel>
            <InputWrap>
              <FindInfoInput name="code" placeholder="인증번호를 입력해주세요" onChange={onFormChange} />
              {codeSent && (
                <TimerWrapper>
                  <Timer condition={codeSent} />
                </TimerWrapper>
              )}
              <VerifyBtn sent={verified} onClick={(e) => handlePhoneVerificationRequest(e)}>
                인증하기
              </VerifyBtn>
            </InputWrap>
            {verified && <Notification>인증이 완료되었습니다</Notification>}
          </InputSection>
        ) : (
          getFoundUserInfoText()
        )}
        <SendRequestBtn
          active={verified || foundUsername !== '' || postFindPasswordStatus}
          disabled={!verified && foundUsername === '' && !postFindPasswordStatus}
          onClick={(e) => handleRequest(e)}
        >
          {handleBtnText()}
        </SendRequestBtn>
      </FindInfoModalWrap>
    </FindInfoModalContainer>
  );
}

export default FindInfoModal;

interface Active {
  active: boolean;
}

interface IVerifyBtn {
  sent: boolean;
}

const FindInfoModalContainer = styled.div`
  background-color: white;
  width: 676px;
`;

const FindInfoModalWrap = styled.form`
  padding: 0 80px 56px;
`;

const InputWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 8px;
  padding: 0;
  > span:first-of-type {
    margin-right: 8px;
  }
  > span {
    width: 100%;
  }

  > input:first-of-type {
    margin-right: 8px;
    flex: 1;
  }

  > button {
    width: 104px;
    padding: 10px 11px;
  }
`;

const CloseBtnWrap = styled(BasicBtn)`
  display: flex;
  width: 100%;
  padding: 40px 40px 0 0;
  justify-content: flex-end;
  background-color: transparent;

  img {
    width: 32px;
    height: 32px;
  }
`;

const InfoOptionSection = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  > button:first-of-type {
    margin-right: 16px;
  }
`;

const OptionInfoBtn = styled(BasicBtn)<Active>`
  font-size: 18px;
  background: transparent;
  ${(props) => `
    color: ${props.active ? findInfoModalColor.active : findInfoModalColor.inactive};
    font-family: ${props.active ? 'NotoSansBold' : 'NotoSansMedium'};
    font-weight: ${props.active ? 'bold' : '500'};
  `}
`;

const InputSection = styled.div`
  margin: 26px 0 0;
`;

const FindInfoInput = styled(NormalInput)`
  height: 40px;
  margin: 0;
`;

const FindInfoLabel = styled(SectionLabel)`
  font-size: 15px;
  margin-top: 30px;
`;

const VerifyBtn = styled(BasicBtn)<IVerifyBtn>`
  border-radius: 5px;
  width: 104px;
  height: 40px;
  display: flex;
  font-size: 13.5px;
  font-family: NotoSansMedium;
  font-weight: 500;
  justify-content: center;
  align-items: center;

  ${(props) => `
    color: ${props.sent ? 'white' : findInfoModalColor.activeBtnText};
    border: 1px solid ${props.sent ? 'transparent' : findInfoModalColor.activeBtnBorder};
    background-color ${props.sent ? findInfoModalColor.sentBtnBackground : findInfoModalColor.activeBtnBackground};

  `};
`;

const Notification = styled.div`
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
  color: ${findInfoModalColor.notificationText};
  background-color: ${findInfoModalColor.notificationBackground};
`;

const TimerWrapper = styled.div`
  position: absolute;
  right: 110px;
  bottom: 44px;
`;

const SendRequestBtn = styled(BasicBtn)<Active>`
  font-family: NotoSansRegular;
  font-size: 16px;
  border-radius: 3px;
  width: 100%;
  height: 56px;
  margin-top: 77px;
  ${(props) => `
    color: ${props.active ? findInfoModalColor.activeBtnText : findInfoModalColor.inactiveBtnText};
    background-color: ${
      props.active ? findInfoModalColor.activeBtnBackground : findInfoModalColor.inactiveBtnBackground
    };
    border: ${props.active ? findInfoModalColor.activeBtnBorder : findInfoModalColor.border}
  `}
`;

const FoundUserInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 450px;
  font-family: NotoSansRegular;
  font-size: 18px;
  color: ${findInfoModalColor.textinput};
`;

const FoundUsername = styled.span`
  font-family: NotoSansRegular;
  font-size: 18px;
  color: ${findInfoModalColor.textinput};
  font-weight: bold;
  margin: 0 3px;
`;
