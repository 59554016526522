import React from 'react';

import styled from '@emotion/styled';

import { flexCenter } from 'styles/global/mixins';
import { emptyList } from 'assets/images';

interface EmptyListProps {
  title: string;
}

function EmptyList({ title }: EmptyListProps) {
  return (
    <EmptyListImageContainer>
      <EmptyListImage src={emptyList} />
      <EmptyListText>{title}</EmptyListText>
    </EmptyListImageContainer>
  );
}

export default EmptyList;

const EmptyListImageContainer = styled.div`
  ${flexCenter};
  flex-direction: column;
  height: 677px;
`;

const EmptyListImage = styled.img`
  width: 193px;
  height: 220px;
  margin: 24px;
`;

const EmptyListText = styled.span`
  font-family: NotoSansCJKkr;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: normal;
  text-align: center;
  color: #a3a3a3;
`;
