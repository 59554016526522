import styled from '@emotion/styled';

import { device } from 'styles/global/device';

const TextInput = styled.input`
  width: 100%;
  padding: 8px;
  font-size: 1.5em;
  text-align: left;
  background-color: var(--gray-background-02);
  color: var(--grayscale-06);
  border: none;
  ::placeholder {
    opacity: 0.5;
  }
  @media ${device.mobileMax} {
    font-size: 1.1em;
    padding: 5px 0 5px 8px;
  }
`;

export default TextInput;
