import React, { useState } from 'react';

import { useStore, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { getSpecialtyList, patchSpecialty } from 'redux/modules/authentication';

import { EditBtn, CompleteButton, BasicBtn } from 'styles/button';
import { mypageColor } from 'styles/global/color';
import { SectionLabel } from 'styles/text';

import { IRootState } from 'types/payloadTypes';

function Specialties() {
  const dispatch = useDispatch();

  const state = useStore().getState();

  const [updateSpecialty, setUpdateSpecialty] = useState(false);
  const [selectedSpecialty, setSelectedSpecialty] = useState<Array<string>>([]);

  const { departmentId } = useSelector((rootState: IRootState) => rootState.authentication.userInfo);
  const specialtyList = useSelector((rootState: IRootState) => rootState.authentication.specialtyList);

  const { specialty } = state.authentication.userInfo;

  const handleSpecialtyChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    e.preventDefault();
    if (selectedSpecialty.includes(value)) {
      const filteredArray = selectedSpecialty.filter((specialtyName) => specialtyName !== value);
      setSelectedSpecialty(filteredArray);
    } else {
      setSelectedSpecialty((prev) => [...prev, value]);
    }
  };

  const getSpecialtyDisplay = () => {
    if (updateSpecialty) {
      return (
        <ModifySpecialtyWrap>
          {specialtyList.map((specialtyName: string, index: number) => {
            if (selectedSpecialty.includes(specialtyName)) {
              return (
                <SelectedSpecialty key={`specialty-${index}`} onClick={(e) => handleSpecialtyChange(e, specialtyName)}>
                  {specialtyName}
                </SelectedSpecialty>
              );
            }

            return (
              <Specialty key={`specialty-${index}`} onClick={(e) => handleSpecialtyChange(e, specialtyName)}>
                {specialtyName}
              </Specialty>
            );
          })}
        </ModifySpecialtyWrap>
      );
    }
    return specialty.map((specialtyName: string, index: number) => (
      <Specialty key={`specialty-${index}`} disabled>
        {specialtyName}
      </Specialty>
    ));
  };

  const handleUpdateBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    setSelectedSpecialty(specialty);

    departmentId.forEach((id) => dispatch(getSpecialtyList(id)));

    setUpdateSpecialty(true);
  };

  const handleConfirmBtnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    dispatch(patchSpecialty(selectedSpecialty));
    setUpdateSpecialty(false);
  };

  return (
    <SpecialtyWrap>
      <SectionLabel>주요 진료과목</SectionLabel>
      {getSpecialtyDisplay()}
      {updateSpecialty ? (
        <UpdateSpecialtyBtn onClick={(e) => handleConfirmBtnClick(e)}>진료과목 수정완료</UpdateSpecialtyBtn>
      ) : (
        <ModifySpecialtyBtn onClick={(e) => handleUpdateBtnClick(e)}>진료과목 수정하기</ModifySpecialtyBtn>
      )}
    </SpecialtyWrap>
  );
}

export default Specialties;

const SpecialtyWrap = styled.form`
  position: relative;
  padding: 58px 32px 0 32px;
  height: 100%;
`;

const ModifySpecialtyWrap = styled.div`
  margin: -16px -32px 0 -64px;
  padding: 16px 64px;
`;

const Specialty = styled(BasicBtn)`
  margin-right: 6px;
  margin-bottom: 6px;
  padding: 1px 15px;
  border: 1px solid ${mypageColor.specialtyInactiveBorder};
  background-color: ${mypageColor.specialtyInactiveBackground};
  border-radius: 1px;
  font-size: 15px;
  color: ${mypageColor.specialtyInativeText};
  text-align: center;
  line-height: 22px;
`;

const SelectedSpecialty = styled(Specialty)`
  color: ${mypageColor.specialtyActiveText};
  border: 1px solid ${mypageColor.specialtyActiveBorder};
  background-color: ${mypageColor.specialtyActiveBackground};
`;

const ModifySpecialtyBtn = styled(EditBtn)`
  width: 86%;
  margin: 0 32px;
  height: 56px;
  font-size: 16px;
  position: absolute;
  bottom: -330px;
  left: 0;
`;

const UpdateSpecialtyBtn = styled(CompleteButton)`
  width: 86%;
  height: 56px;
  font-size: 16px;
  position: absolute;
  bottom: -200px;
  right: 33px;
`;
