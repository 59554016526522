import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useStore } from 'react-redux';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { putRecording } from 'redux/modules/authentication';

import { flexCenter, flexColumn } from 'styles/global/mixins';
import { recordingColor } from 'styles/global/color';
import { BasicBtn } from 'styles/button';

const phraseArray = [
  '보이닥',
  '이홍주',
  '헛파문',
  '역대수',
  '매운요리',
  '백신제',
  '해라시스템',
  '업소',
  '벨정보',
  '제거자',
  '윤여복',
  '당뇨',
  '의류과',
  '뉴스와',
  '최규엽',
  '야드',
  '외교',
  '열',
  '워드',
  '교외',
  '폐위',
  '과외',
  '예',
  '무궁화 꽃이 피었습니다',
];

const initialRecording = [
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
  [new Uint8Array()],
];

type RecordingStatus = 'ready' | 'recording' | 'stopped';
// type AudioBtn = 'record' | 'play';

interface IRecording {
  index: number;
  status: RecordingStatus;
  playing: boolean;
  audioFile: string;
  tested: boolean;
}

function RecordingWatermark() {
  const dispatch = useDispatch();

  const state = useStore().getState();

  const bytearrayRef = useRef(initialRecording);
  const bytearrayIndexRef = useRef(0);
  const audioRecorderRef = useRef(null);
  const audioFileRef = useRef(null);
  const phraseRef = useRef(null);

  const [recording, setRecording] = useState<IRecording>({
    index: 0,
    status: 'ready',
    playing: false,
    audioFile: '',
    tested: false,
  });

  const { id, fullname } = state.authentication.userInfo;

  const getInstructionText = (): string => {
    if (recording.status === 'ready') {
      return '녹음 버튼을 눌러서 녹음을 진행해 주세요';
    }
    if (recording.status === 'recording') {
      return '녹음이 완료되면 버튼을 한 번 더 눌러서 저장해 주세요';
    }
    if (bytearrayIndexRef.current === 0) {
      if (!recording.tested) {
        return '재생 버튼을 눌러서 녹음된 음성을 확인해 주세요';
      }
      return '테스트가 완료되었습니다 다음 단계를 진행해주세요 ';
    }
    if (bytearrayIndexRef.current < phraseArray.length - 1) {
      return '녹음이 완료되었습니다 다음 단어를 녹음해주세요';
    }
    return '녹음이 완료되었습니다.';
  };

  const handleDataSend = async () => {
    const merged = bytearrayRef.current.flat();
    const blobbed = new Blob(merged, { type: 'audio/wav' });
    const data = new FormData();
    data.append('recording', blobbed, `${id}_${fullname}.wav`);
    dispatch(putRecording(data));
  };

  const handleNextPhrase = () => {
    bytearrayIndexRef.current += 1;
    setRecording({
      ...recording,
      playing: false,
      status: 'ready',
    });
  };

  const handlePhrase = (idx: number) => {
    if (idx === bytearrayIndexRef.current) return 'recording';
    if (idx > bytearrayIndexRef.current) return 'notRecorded';

    return 'recorded';
  };

  const handlePhraseBtn = () => {
    if (bytearrayIndexRef.current === 0) return '테스트 녹음 완료';
    if (bytearrayIndexRef.current === phraseArray.length - 1) return '녹음완료';

    return `다음단어 (${bytearrayIndexRef.current}/${phraseArray.length - 1})`;
  };

  const handleRecordingStart = async () => {
    const microphonePermission = await navigator.permissions.query({
      name: 'microphone',
    });

    if (microphonePermission.state !== 'granted') {
      requestMicrophoneAccess();
      return;
    }

    if (bytearrayIndexRef.current > 0 && bytearrayRef.current[bytearrayIndexRef.current - 1].length > 0) {
      const tempArray = [...bytearrayRef.current];
      tempArray[bytearrayIndexRef.current - 1] = [];

      bytearrayRef.current = tempArray;
    }
    if (!audioRecorderRef.current) {
      await requestMicrophoneAccess();
    }

    audioRecorderRef.current.start();

    setTimeout(() => {
      setRecording({ ...recording, status: 'recording' });
    }, 800);
  };

  const handleRecordingStop = async () => {
    audioRecorderRef.current.stop();

    setRecording({
      ...recording,
      status: 'stopped',
    });
  };

  const handleRecordingPlay = async () => {
    if (recording.status !== 'stopped') {
      return;
    }

    const audio = new Audio(audioFileRef.current);
    audio.addEventListener('ended', () => {
      audio.currentTime = 0;

      setRecording({
        ...recording,
        tested: true,
        playing: false,
      });
    });
    audio.play();

    setRecording({ ...recording, playing: true });
  };

  const requestMicrophoneAccess = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const audioRecorder = new MediaRecorder(stream, {});

        audioRecorder.addEventListener('dataavailable', async (event) => {
          const audioBlob = new Blob([event.data]);
          audioFileRef.current = URL.createObjectURL(audioBlob);

          if (bytearrayIndexRef.current !== 0) {
            const arrayBuffer = await event.data.arrayBuffer();
            const uint8Array = new Uint8Array(arrayBuffer);
            bytearrayRef.current[bytearrayIndexRef.current - 1].push(uint8Array, new Uint8Array(255));
          }
        });
        audioRecorderRef.current = audioRecorder;
      })
      .catch((e) => {
        alert('마이크 액세스를 승인해주세요');
        console.error(e);
      });
  };

  useEffect(() => {
    if (bytearrayIndexRef.current === 13) {
      phraseRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [recording]);

  useEffect(() => {
    requestMicrophoneAccess();
    // eslint-disable-next-line
  }, []);

  return (
    <RecordingWaterMarkContainer>
      <RecordingPhraseListContainer>
        <RecordingWatermarkHeader>녹음 단어 리스트</RecordingWatermarkHeader>
        <PhraseWrap>
          {phraseArray.map((phrase: string, index: number) => (
            <Phrase
              key={`phrase-${index}`}
              ref={index === phraseArray.length - 1 ? phraseRef : null}
              status={handlePhrase(index)}
            >
              {phrase}
            </Phrase>
          ))}
        </PhraseWrap>
      </RecordingPhraseListContainer>
      <RecordingActionContainer>
        <RecordingWatermarkHeader>표시되는 단어를 녹음해주세요</RecordingWatermarkHeader>
        <PhraseViewer>
          {phraseArray[bytearrayIndexRef.current]}
          {bytearrayIndexRef.current === 0 && <TestExplanation>처음 단어는 테스트 입니다.</TestExplanation>}
          <RecordingInstruction>{getInstructionText()}</RecordingInstruction>
        </PhraseViewer>
        <AudioBtnWrap>
          <div>
            <RecordingBtn
              disabled={recording.playing}
              onClick={recording.status === 'recording' ? () => handleRecordingStop() : () => handleRecordingStart()}
            >
              {recording.status === 'recording' && <RecordingBtnAnimation />}
              {recording.status === 'recording' ? (
                <StopRecording>
                  <Image alt="stop-recoding" src="icons/ic-stop-recoding.svg" />
                </StopRecording>
              ) : (
                <PhraseRecording>
                  <Image alt="phrase-recoding" src="icons/ic-mic.svg" />
                </PhraseRecording>
              )}
            </RecordingBtn>
            <AudioText color={recordingColor.audioBtnActive}>
              {recording.status === 'stopped' ? '다시 녹음' : '녹음'}
            </AudioText>
          </div>
          <div>
            <PlayRecordingBtn
              disabled={recording.status !== 'stopped'}
              status={recording.status}
              onClick={() => handleRecordingPlay()}
            >
              {recording.playing ? (
                <StopPlaying>
                  <Image alt="stop-playing" src="icons/ic-stop-play.svg" />
                </StopPlaying>
              ) : (
                <PlayRecording>
                  <Image alt="play-recoding" src="icons/ic-play-recoding.svg" />
                </PlayRecording>
              )}
            </PlayRecordingBtn>
            <AudioText
              color={
                recording.status === 'stopped' ? recordingColor.audioBtnActive : recordingColor.audioBtnInactiveText
              }
            >
              {recording.playing ? '정지' : '재생'}
            </AudioText>
          </div>
        </AudioBtnWrap>
        <NextPhraseBtn
          active={recording.status === 'stopped' && recording.tested && !recording.playing}
          disabled={recording.playing || !recording.tested || recording.status !== 'stopped'}
          onClick={
            bytearrayIndexRef.current === phraseArray.length - 1 ? () => handleDataSend() : () => handleNextPhrase()
          }
        >
          {handlePhraseBtn()}
        </NextPhraseBtn>
      </RecordingActionContainer>
    </RecordingWaterMarkContainer>
  );
}

export default RecordingWatermark;

interface IPhrase {
  status: 'recorded' | 'recording' | 'notRecorded';
}

interface IAudioBtn {
  status: 'ready' | 'recording' | 'stopped';
}

interface IAudioText {
  color: string;
}

interface INextPhraseBtn {
  active: boolean;
}

const NextPhraseBtn = styled(BasicBtn)<INextPhraseBtn>`
  border-radius: 3px;
  background-color: ${(props) => (props.active ? recordingColor.recordingPhraseText : recordingColor.audioBtnInactive)};
  font-family: NotoSansRegular;
  font-size: 15px;
  color: white;
  height: 56px;
  justify-content: center;
  width: 100%;
  align-items: center;
`;

const PhraseViewer = styled.div`
  ${flexCenter};
  position: relative;
  border: 1px solid ${recordingColor.phraseViewerBorder};
  align-self: stretch;
  flex: 1;
  width: 578px;
  color: ${recordingColor.notRecordedText};
  font-size: 25px;
`;

const AudioBtnWrap = styled.div`
  display: flex;
  margin: 40px 209px 32px;
  justify-content: space-between;
`;

const RecordingWaterMarkContainer = styled.div`
  font-family: NotoSansRegular;
  display: flex;
  width: 1089px;
  background-color: white;
  padding: 85px 100px 70px;
`;

const RecordingPhraseListContainer = styled.section`
  ${flexColumn};
  margin-right: 32px;
`;

const PhraseWrap = styled.div`
  height: 650px;
  overflow-y: scroll; /* Hide vertical scrollbar */

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const RecordingWatermarkHeader = styled.h1`
  font-family: NotoSansMedium;
  font-size: 15px;
  color: ${recordingColor.watermarkHeader};
  font-weight: 500;
  margin: 0 0 24px;
`;

const RecordingActionContainer = styled.section`
  ${flexColumn};
`;

const AudioBtn = styled(BasicBtn)`
  ${flexCenter};
  width: 64px;
  height: 64px;
  border-radius: 50%;
`;

const Phrase = styled.h2<IPhrase>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: NotoSansRegular;
  width: 279px;
  height: 40px;
  padding: 8px 20px;
  margin-bottom: 8px;

  color: ${({ status }) => {
    if (status === 'recorded') return recordingColor.recordedPhraseText;
    if (status === 'recording') return recordingColor.recordingPhraseText;

    return recordingColor.notRecordedText;
  }};
  background-color: ${({ status }) => {
    if (status === 'recorded') return recordingColor.recordedPhraseBackground;
    if (status === 'notRecorded') return recordingColor.notRecordedBackground;

    return 'white';
  }};
  border: 1px solid ${({ status }) => (status === 'recording' ? recordingColor.recordingPhraseBorder : 'transparent')};
`;

const TestExplanation = styled.h3`
  font-family: NotoSansMedium;
  color: ${recordingColor.testExplanationText};
  font-size: 13px;
  font-weight: 500;
  top: 241px;
  position: absolute;
`;
const RecordingBtn = styled(AudioBtn)`
  background-color: ${recordingColor.audioBtnActive};
  position: relative;
  resize-content: contain;
`;

const StopRecording = styled.div`
  ${flexCenter};
  width: 24px;
  height: 24px;
`;

const PhraseRecording = styled(StopRecording)`
  width: 19.682px;
  height: 28.826px;
`;

const StopPlaying = styled(StopRecording)`
  width: 19px;
  height: 27px;
`;
const PlayRecording = styled(StopRecording)`
  width: 21.685px;
  height: 27.161px;
`;
const RecordingBtnAnimation = styled.span`
  position: absolute;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  animation: pulse 2s infinite;
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
    50% {
      transform: scale(1);
      box-shadow: 0 0 0 0 ${recordingColor.recordingAnimation};
    }
    100% {
      transform: scale(1.7);
      box-shadow: 0 0 0 0 ${recordingColor.recordingAnimation};
    }
  }
`;

const PlayRecordingBtn = styled(AudioBtn)<IAudioBtn>`
  background-color: ${(props) =>
    props.status === 'stopped' ? recordingColor.audioBtnActive : recordingColor.audioBtnInactive};
`;

const RecordingInstruction = styled.h4`
  background-color: ${recordingColor.instructionBackground};
  text-align: center;
  font-family: NotoSansMedium;
  font-size: 13px;
  font-weight: 500;
  color: ${recordingColor.instructionText};
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 538px;
  padding: 5px 0;
`;

const AudioText = styled.h5<IAudioText>`
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 13px;
  color: ${(props) => props.color};
  text-align: center;
  margin-top: 15px;
`;
