import React, { Fragment, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import AppointmentDetail from './CompleteAppointmentDetail';
import { Pagination } from 'components/molecules';

import { getNextCompletePage, getPreviousCompletePage } from 'redux/modules/appointment';

import { formatAge, formatDayPeriodTime, formatPageArray } from 'utils';

import { device } from 'styles/global/device';
import { BodyColumn, Table, TableRow } from 'styles/table';

import { IAppointment, IAppointmentList } from 'types/payloadTypes';

interface CompleteTableProps {
  completeList: IAppointmentList;
  toggleAppointmentDetail: (e: React.MouseEvent<Element, MouseEvent>, appointmentId: number) => void;
  targetAppointment: number;
}

function CompleteTable({ completeList, toggleAppointmentDetail, targetAppointment }: CompleteTableProps) {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageArray, setPageArray] = useState<Array<number>>([]);

  const { appointmentArray, count } = completeList;

  const handleGetPage = (page: number) => {
    if (currentPage === page) {
      return null;
    }
    setCurrentPage(page);
    if (page > currentPage) {
      dispatch(getNextCompletePage());
    } else {
      dispatch(getPreviousCompletePage());
    }
  };

  const handleNextPage = () => {
    dispatch(getNextCompletePage());
  };

  const handlePreviousPage = () => {
    dispatch(getPreviousCompletePage());
  };

  useEffect(() => {
    const tempPageArray = formatPageArray(count);
    setPageArray(tempPageArray);
    // eslint-disable-next-line
  }, [count]);

  return (
    <>
      <AppointmentTable>
        <thead>
          <tr>
            <th>예약 시각</th>
            <th>환자 이름 (나이/성별)</th>
            <th>거주 지역</th>
            <th>시작 시각</th>
            <th>종료 시각</th>
            <th>총 진료시간</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {appointmentArray.map((appointment: IAppointment, index: number) => {
            const {
              id,
              appointmentDate,
              patientGender,
              dateOfBirth,
              patientDistrict,
              patientName,
              status,
              isEmergency,
              patient,
              startedAt,
              finishedAt,
              duration,
            } = appointment;
            return (
              <Fragment key={`appointment-${index}`}>
                <TableRow
                  active={targetAppointment === id}
                  emergency={isEmergency && status === 0}
                  onClick={(e) => toggleAppointmentDetail(e, id)}
                >
                  <td>{formatDayPeriodTime(appointmentDate)}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>
                      {`${patientName} (${formatAge(dateOfBirth)}/${patientGender === 'M' ? '남' : '여'})`}
                    </BodyColumn>
                  </td>
                  <td>{patientDistrict}</td>
                  <td>
                    <BodyColumn active={targetAppointment === id}>{formatDayPeriodTime(startedAt)}</BodyColumn>
                  </td>
                  <td>{formatDayPeriodTime(finishedAt)}</td>
                  <td>
                    <BodyColumn borderRight active={targetAppointment === id}>
                      {duration}
                    </BodyColumn>
                  </td>
                  <td></td>
                </TableRow>
                {targetAppointment === id && (
                  <tr>
                    <td style={{ padding: 0 }}>
                      <LaptopAppointmentDetailSection>
                        <AppointmentDetail patientId={patient} />
                      </LaptopAppointmentDetailSection>
                    </td>
                  </tr>
                )}
                {targetAppointment === id && (
                  <tr>
                    <td>
                      <MobileAppointmentDetailSection>
                        <AppointmentDetail patientId={patient} />
                      </MobileAppointmentDetailSection>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </AppointmentTable>
      <PaginationSection>
        {count > 10 && (
          <Pagination
            count={count}
            currentPage={currentPage}
            paginationArray={pageArray}
            onGetPage={handleGetPage}
            onNext={handleNextPage}
            onPrevious={handlePreviousPage}
          />
        )}
      </PaginationSection>
    </>
  );
}

export default CompleteTable;

const PaginationSection = styled.section`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`;

const LaptopAppointmentDetailSection = styled.section`
  @media ${device.wideScreen} {
    display: none;
  }
  @media ${device.tabletMax} {
    display: none;
  }
`;

const MobileAppointmentDetailSection = styled.section`
  @media ${device.wideScreen} {
    display: none;
  }
  @media ${device.laptopMin} {
    display: none;
  }

  background-color: white;
`;

const AppointmentTable = styled(Table)`
  width: 100%;
  margin-top: 32px;
  border-spacing: 0 10px;
  @media ${device.mobileMax} {
    margin-top: 24px;
  }

  thead tr {
    th {
      padding: 15px 0 15px;
      margin-bottom: 15px;
      width: 15%;
      @media ${device.mobileMax} {
        font-size: 12px;
      }
    }

    th:first-of-type {
      @media ${device.tabletMax} {
        display: none;
      }
    }

    th:nth-of-type(2) {
      @media ${device.tabletMax} {
        width: 7%;
      }
      @media ${device.mobileMax} {
        display: none;
      }
    }

    th:nth-of-type(3) {
      @media ${device.tabletMax} {
        width: 12%;
      }
      @media ${device.mobileMax} {
        width: 37%;
      }
    }

    th:nth-of-type(4) {
      @media ${device.mobileMax} {
        width: 26%;
      }
    }
    th:nth-of-type(5) {
      @media ${device.tabletMax} {
        width: 7%;
      }
      @media ${device.mobileMax} {
        display: none;
      }
    }

    th:nth-of-type(6) {
      @media ${device.tabletMax} {
        padding: 0;
      }
      @media ${device.mobileMax} {
        display: none;
      }
    }
    th:nth-of-type(7) {
      @media ${device.mobileMax} {
        width: 37%;
      }
    }
    th:nth-of-type(8) {
      @media ${device.tabletMax} {
        display: none;
      }
    }
    th:last-of-type {
      width: 30%;
    }
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
    margin-bottom: 8px;
    tr:last-of-type {
      margin-bottom: 0;
    }
  }

  tbody {
    display: block;
    tr {
      td {
        width: 15%;

        @media ${device.mobileMax} {
          font-size: 14px;
        }
      }
      td:first-of-type {
        @media ${device.tabletMax} {
          width: 0;
          color: transparent;
          div {
            background-color: transparent;
          }
        }
      }

      td:nth-of-type(2) {
        @media ${device.tabletMax} {
          width: 7%;
          div {
            border-left: none;
          }
        }
        @media ${device.mobileMax} {
          display: none;
        }
      }

      td:nth-of-type(3) {
        @media ${device.tabletMax} {
          width: 12%;
        }
        @media ${device.mobileMax} {
          width: 37%;
        }
      }

      td:nth-of-type(4) {
        @media ${device.mobileMax} {
          width: 26%;
        }
      }
      td:nth-of-type(5) {
        @media ${device.tabletMax} {
          width: 7%;
        }
        @media ${device.mobileMax} {
          display: none;
        }
      }

      td:nth-of-type(6) {
        @media ${device.mobileMax} {
          display: none;
        }
      }
      td:nth-of-type(7) {
        @media ${device.mobileMax} {
          width: 37%;
        }
      }

      td:last-of-type {
        width: 30%;
      }
    }
  }
`;
