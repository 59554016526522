import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { patchChangePassword, getUserInfo, patchChangePasswordGracePeriod } from 'redux/modules/authentication';

import { useForm } from 'hooks';

import { NormalInput } from 'styles/textinput';
import { showBtn, hideBtn, alertIcon } from 'assets/images';
import { changePasswordModalColors } from 'styles/global/color';
import { BasicBtn } from 'styles/button';

import { IRootState } from 'types/payloadTypes';

interface ChangePasswordRequestModalProps {
  currentPassword: string;
  title: string;
  onConfirm: () => void;
}

const pwVisibleStatus = {
  pwVisibility: false,
  rePwVisibility: false,
};

const newPassword = {
  password: '',
  rePassword: '',
};

function ChangePasswordRequestModal({ currentPassword, title, onConfirm }: ChangePasswordRequestModalProps) {
  const dispatch = useDispatch();

  const [form, onFormChange] = useForm(newPassword);

  const [pwVisibility, setPwVisibility] = useState(pwVisibleStatus);
  const [matchPw, setMatchPw] = useState(true);
  const [pwValidation, setPwValidation] = useState(true);

  const { userInfo } = useSelector((state: IRootState) => state.authentication);

  const { doctorId } = userInfo;

  const handlePwValidation = () => {
    const special = /\w\d/;
    if (!form.password) {
      setPwValidation(true);
    } else if (!special.test(form.password) || form.password.length < 8) {
      setPwValidation(false);
    } else {
      setPwValidation(true);
    }
  };

  const handlePasswordChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(patchChangePassword({ currentPassword, password: form.password }));
    dispatch(getUserInfo());
    onConfirm();
  };

  const handleGracePeriodPasswordChange = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    dispatch(patchChangePasswordGracePeriod(doctorId));
    dispatch(getUserInfo());
    onConfirm();
  };

  const handlePwCheck = () => {
    if (!form.password || !form.repassword) {
      setMatchPw(true);
    } else if (form.password !== form.repassword) {
      setMatchPw(false);
    } else {
      setMatchPw(true);
    }
  };

  const toggleVisibility = (e: any): void => {
    setPwVisibility({
      ...pwVisibility,
      [e.target.name]: !pwVisibility[e.target.name],
    });
  };

  useEffect(() => {
    handlePwCheck();
    // eslint-disable-next-line
  }, [form]);

  return (
    <ModalContainer>
      <Title>비밀번호 변경</Title>
      <Advise>
        {title.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </Advise>
      <Label htmlFor="passwordInput">
        비밀번호
        <PwInputWrapper>
          <PwInput
            id="passwordInput"
            name="password"
            placeholder="비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다"
            type={pwVisibility.pwVisibility ? 'text' : 'password'}
            value={form.password}
            onChange={onFormChange}
            onKeyUp={handlePwValidation}
          />
          <VisibilityBtn active={pwVisibility.pwVisibility} name="pwVisibility" onClick={(e) => toggleVisibility(e)} />
        </PwInputWrapper>
      </Label>
      {!pwValidation && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>비밀번호는 숫자 영문자 조합 8자 이상이어야 합니다</PwAlert>
        </PwAlertWrapper>
      )}

      <Label htmlFor="rePasswordInput">
        비밀번호 확인
        <PwInputWrapper>
          <RePwInput
            active={matchPw}
            id="rePasswordInput"
            name="rePassword"
            placeholder="비밀번호를 한번 더 입력해주세요"
            type={pwVisibility.rePwVisibility ? 'text' : 'password'}
            value={form.rePassword}
            onChange={onFormChange}
            onKeyUp={handlePwCheck}
          />
          <VisibilityBtn
            active={pwVisibility.rePwVisibility}
            name="rePwVisibility"
            onClick={(e) => toggleVisibility(e)}
          />
        </PwInputWrapper>
      </Label>
      {!matchPw && (
        <PwAlertWrapper>
          <AlertIcon src={alertIcon} />
          <PwAlert>비밀번호가 일치하지 않습니다</PwAlert>
        </PwAlertWrapper>
      )}

      <BtnWrap>
        <ChangeBtn
          active={matchPw && pwValidation && !Object.values(form).includes('')}
          onClick={(e) => handlePasswordChange(e)}
        >
          비밀번호 변경
        </ChangeBtn>
        {title !== '최초 로그인 시 비밀번호 변경이 필요합니다' && (
          <ChangeToBtn onClick={(e) => handleGracePeriodPasswordChange(e)}>다음에 변경하기</ChangeToBtn>
        )}
      </BtnWrap>
    </ModalContainer>
  );
}

export default ChangePasswordRequestModal;

interface IVisibilityBtn {
  active: boolean;
}

interface IChangeBtn {
  active: boolean;
}

const ModalContainer = styled.div`
  z-index: 3;
  width: 676px;
  padding: 72px 80px 56px;
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  line-height: 22px;
  color: ${changePasswordModalColors.lableText};
  &:focus-within {
    color: ${changePasswordModalColors.focus};
  }
`;

const Title = styled.h1`
  color: ${changePasswordModalColors.titleText};
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const Advise = styled.h3`
  font-size: 15px;
  font-weight: normal;
  line-height: 27px;
  color: ${changePasswordModalColors.subtitleText};
  margin-bottom: 56px;
`;

const PwInputWrapper = styled.div`
  position: relative;
`;

const PwInput = styled(NormalInput)`
  height: 40px;
  width: 100%;
  &::placeholder {
    font-size: 14px;
    color: ${changePasswordModalColors.inputPlaceholderText};
  }
  &:focus {
    border: solid 1px ${changePasswordModalColors.focus};
  }
`;

interface IRePwInput {
  active: boolean;
}

const RePwInput = styled(PwInput)<IRePwInput>`
  border: ${(props) =>
    props.active
      ? `1px solid ${changePasswordModalColors.inactiveInputBorder}}`
      : `1px solid ${changePasswordModalColors.pwAlertText}`};
`;

const VisibilityBtn = styled.button<IVisibilityBtn>`
  position: absolute;
  top: 12px;
  right: 20px;
  height: 30px;
  width: 30px;
  border: none;
  background-color: transparent;
  background-image: ${(props) => (props.active ? `url(${showBtn})` : `url(${hideBtn})`)};
  background-size: 30px;
`;

const PwAlertWrapper = styled.div`
  display: flex;
  background-color: ${changePasswordModalColors.pwAlertBackground};
  align-items: center;
  margin-top: -23px;
  margin-bottom: 30px;
`;

const AlertIcon = styled.img`
  width: 19px;
  height: 19px;
  margin: 0 3px;
`;

const PwAlert = styled.div`
  color: ${changePasswordModalColors.pwAlertText};
  padding-left: 5px;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
  display: flex;
  align-items: center;
`;

const BtnWrap = styled.div`
  display: flex;
  margin-top: 125px;
`;

const ChangeBtn = styled(BasicBtn)<IChangeBtn>`
  width: 100%;
  height: 56px;
  font-size: 16px;
  color: ${(props) =>
    props.active ? changePasswordModalColors.activeBtnText : changePasswordModalColors.inactiveBtnText};
  background-color: ${(props) =>
    props.active ? changePasswordModalColors.activeBtnBackground : changePasswordModalColors.inactiveBtnBackground};
  border-radius: 3px;
  ${(props) => ` border: 1px solid
    ${props.active ? changePasswordModalColors.activeBtnBorder : changePasswordModalColors.inactiveInputBorder};`}
`;

const ChangeToBtn = styled(BasicBtn)`
  width: 100%;
  height: 56px;
  margin-left: 10px;
  font-size: 16px;
  color: ${changePasswordModalColors.activeBtnText};
  background-color: ${changePasswordModalColors.activeBtnBackground};
  border-radius: 3px;
  border: 1px solid ${changePasswordModalColors.activeBtnBorder};
`;
