import styled from '@emotion/styled';
import { css } from '@emotion/core';

interface ConfirmBtnProps {
  size: 'big' | 'mid';
  disabled?: boolean;
}

const big = css`
  padding: 8px 3.75vw;
`;

const mid = css`
  padding: 8px 2.92vw;
`;

const sizes = { big, mid };
const btnDisabled = css`
  cursor: not-allowed;
`;

const btnEnabled = css`
  cursor: pointer;
`;

export const ConfirmBtn = styled.button<ConfirmBtnProps>`
  font-size: NotoSansRegular;
  font-size: 1.3rem;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  background-color: var(--sub-blue-01);
  border: 1px solid var(--sub-blue-05);
  color: var(--sub-blue-03);
  :hover {
    background-color: var(--sub-blue-05);
  }
  ${(props) => sizes[props.size]};
  ${({ disabled }) => (disabled ? btnDisabled : btnEnabled)}
`;

export const CancelBtn = styled(ConfirmBtn)`
  background-color: var(--gray-background-01);
  border: 1px solid var(--gray-border-01);
  color: var(--grayscale-05);
  :hover {
    background-color: var(--gray-border-01);
  }
`;

export const ModalHeader = styled.h1`
  font-family: NotoSansMedium;
  font-size: 1.8em;
  text-align: left;
  color: var(--grayscale-06);
  margin: 0;
  line-height: 26px;
`;

export const ModalExplanation = styled.h2`
  font-family: NotoSansRegular;
  font-size: 1.5em;
  text-align: left;
  color: var(--grayscale-05);
  margin: 0;
  line-height: 21px;
`;
