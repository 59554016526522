import React from 'react';

import styled from '@emotion/styled';

import { globalColors, treatmentColors } from 'styles/global/color';

import { device } from 'styles/global/device';
import { useSelector } from 'react-redux';
import { IRootState } from 'types/payloadTypes';

function EmergencyContact() {
  const districtList = useSelector((state: IRootState) => state.appointment.districtList);

  return (
    <EmergencyContactContainer>
      {districtList.map((district, index) => {
        const { name, phone } = district;
        return (
          <ContactWrap key={`district-${index}`}>
            <DetailWrap>
              <District>{name}</District>
            </DetailWrap>
            <DetailWrap>
              <PhoneNumber>{phone}</PhoneNumber>
            </DetailWrap>
          </ContactWrap>
        );
      })}
    </EmergencyContactContainer>
  );
}

export default EmergencyContact;

const DetailWrap = styled.div`
  margin-right: 16px;
`;

const ContactWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
`;

const District = styled.h2`
  font-family: NotoSansMedium;
  font-weight: 500;
  font-size: 15px;
  width: 80px;
  color: ${globalColors.sub};
  @media ${device.laptopMax} {
    font-size: 14px;
  }
`;

const EmergencyContactContainer = styled.div`
  padding: 40px;
  overflow: auto;
  position: relative;
`;

const PhoneNumber = styled.h3`
  font-family: NotoSansRegular;
  font-size: 15px;
  margin: 0;
  color: ${treatmentColors.doctor};
  @media ${device.laptopMax} {
    font-size: 14px;
  }
`;
