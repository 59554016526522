import React from 'react';

import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

import { Image } from 'components/atoms';

import { myListColor } from 'styles/global/color';
import { device } from 'styles/global/device';

import { IActive } from 'types/styleTypes';
import { BasicBtn } from 'styles/button';

interface MyListProps {
  fullname: string;
  departmentName: Array<string>;
  hospital: string;
  doctorPhoto: string;
  seletedTab: string;
  onClose: () => void;
  onSelect: (selectedTab: string) => void;
  onLogout: () => void;
}

function MyList({
  fullname,
  departmentName,
  hospital,
  doctorPhoto,
  seletedTab,
  onClose,
  onSelect,
  onLogout,
}: MyListProps) {
  return (
    <MyListContainer>
      <CloseBtnWrap onClick={() => onClose()}>
        <CloseBtn>
          <Image alt="close" src="icons/ic-close.svg" />
        </CloseBtn>
      </CloseBtnWrap>
      <ProfileSection>
        <ProfilePicture alt="doctorPhoto" src={doctorPhoto}></ProfilePicture>
        <DoctorName>{`${fullname} 선생님`}</DoctorName>
        <Hostpital>{hospital}</Hostpital>
        <Department>{departmentName.join(', ')}</Department>
      </ProfileSection>
      <ListSection>
        <DashBoard active={seletedTab === 'Home'} to="/home" onClick={() => onSelect('Home')}>
          오늘 예약
        </DashBoard>
        <DashBoard active={seletedTab === 'Waiting'} to="/waiting" onClick={() => onSelect('Waiting')}>
          승인 대기
        </DashBoard>
        {/* <List to="">예약기록</List>
        <List to="">진료일정 관리</List> */}
        <Logout onClick={() => onLogout()}>로그아웃</Logout>
      </ListSection>
    </MyListContainer>
  );
}

export default MyList;

const MyListContainer = styled.div`
  height: 100vh;
  width: 351px;
  background-color: ${myListColor.background};
  position: absolute;
  right: 0;
  padding: 24px 50px 0 50px;
  z-index: 1;
  @media ${device.mobileMax} {
    width: 285px;
  }
  animation: 0.7s in-out forwards;
  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0);
    }
  }
`;

const CloseBtnWrap = styled.div`
  width: 42px;
  height: 42px;
  position: absolute;
  right: 29px;
`;

const CloseBtn = styled(BasicBtn)`
  width: 28.3px;
  heigth: 28.3px;
`;

const ProfileSection = styled.section`
  margin-top: 65px;
  border-bottom: 1px solid ${myListColor.profileBorder};
  padding-bottom: 40px;
`;

const ProfilePicture = styled.img`
  border-radius: 50%;
  width: 91px;
  height: 91px;
  margin-bottom: 16px;
`;

const DoctorName = styled.div`
  font-size: 18px;
  line-height: 27px;
  color: ${myListColor.nameText};
`;

const Hostpital = styled.div`
  font-size: 15px;
  line-height: 22px;
  color: ${myListColor.hospitalText};
`;

const Department = styled(Hostpital)`
  word-break: keep-all;
`;

const ListSection = styled.div`
  padding-top: 40px;
`;

const DashBoard = styled(Link)<IActive>`
  display: block;
  color: ${(props) => (props.active ? myListColor.dashBoardText : myListColor.hospitalText)};
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
`;

const Logout = styled.div`
  color: ${myListColor.hospitalText};
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 20px;
  cursor: pointer;
`;
