import React from 'react';

import styled from '@emotion/styled';

import { TextInput } from 'components/atoms';

import { useForm } from 'hooks';

import { device } from 'styles/global/device';
import * as mixins from 'styles/global/mixins';
import { ModalHeader, ModalExplanation, CancelBtn, ConfirmBtn } from 'styles/modal';

interface InputModalProps {
  confirmBtnText: string;
  onClose: () => void;
  onConfirm: (text: string) => void;
  placeholder: string;
  modalExplanation: string;
  modalTitle: string;
}

function InputModal({
  confirmBtnText,
  onClose,
  onConfirm,
  placeholder,
  modalExplanation,
  modalTitle,
}: InputModalProps) {
  const [form, onFormChange] = useForm({ text: '' });

  return (
    <Container>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalExplanation>{modalExplanation}</ModalExplanation>
      <TextInput name="text" placeholder={placeholder} onChange={onFormChange} />
      <BtnWrap>
        <CancelBtn size="mid" onClick={() => onClose()}>
          취소
        </CancelBtn>
        <ConfirmBtn disabled={!form.text} size="big" onClick={() => onConfirm(form.text)}>
          {confirmBtnText}
        </ConfirmBtn>
      </BtnWrap>
    </Container>
  );
}

export default InputModal;

const Container = styled.div`
  padding: 40px 40px 30px 40px;
  border-radius: 5px;
  min-width: 31.1%;
  background-color: white;
  @media ${device.mobileMax} {
    width: 98%;
  }
  h2 {
    margin-top: 8px;
  }

  input {
    margin-top: 24px;
  }
`;

const BtnWrap = styled.section`
  ${mixins.flexRow}
  justify-content: flex-end;
  margin-top: 24px;
  margin-left: 10em;
  button {
    margin-left: 8px;
  }
`;
