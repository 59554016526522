import React, { useEffect } from 'react';

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { getNextChatMessage } from 'redux/modules/chatting';

import { formatDate } from 'utils';
import { useInfiniteScroll } from 'hooks';

import { chatSendIcon } from 'assets/images';
import { flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IChatMessage } from 'types/payloadTypes';

interface ChatRoomProps {
  messageArray: Array<IChatMessage>;
  chatMessage: string;
  onSend: () => void;
  onChat: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  scrollbarRef: React.RefObject<any>;
}

function ChatRoom({ messageArray, chatMessage, onSend, scrollbarRef, onChat }: ChatRoomProps) {
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [isFetching, setIsFetching] = useInfiniteScroll(fetchNextList, scrollbarRef);

  function fetchNextList() {
    dispatch(getNextChatMessage());
    setIsFetching(false);
  }

  useEffect(() => {
    scrollbarRef.current.scrollIntoView();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ChatRoomContainer ref={scrollbarRef}>
        <ChatRoomSection>
          {messageArray.map((messages) => {
            const { createdAt, message, isMine } = messages;
            return isMine ? (
              <MyMessageWrap>
                <ChatTime>{formatDate(createdAt, 'HH:mm')}</ChatTime>
                <MyChat>
                  {message.split('\n').map((line, index) => (
                    <MessageLine key={`myMessageLine-${index}`}>{line}</MessageLine>
                  ))}
                </MyChat>
              </MyMessageWrap>
            ) : (
              <OthersMessageWrap>
                <OthersChat>
                  {message.split('\n').map((line, index) => (
                    <MessageLine key={`othersMessageLine-${index}`}>{line}</MessageLine>
                  ))}
                </OthersChat>
                <ChatTime>{formatDate(createdAt, 'HH:mm')}</ChatTime>
              </OthersMessageWrap>
            );
          })}
        </ChatRoomSection>
      </ChatRoomContainer>
      <ChatInputWrap>
        <ChatTextarea
          name="chatMessage"
          placeholder="메세지를 입력해주세요"
          value={chatMessage}
          onChange={(e) => onChat(e)}
        />
        <ChatSendBtn onClick={() => onSend()} />
      </ChatInputWrap>
    </>
  );
}

export default ChatRoom;

const color = {
  chatBackground: '#2685fd',
  chatInputBorder: '#e0e0e0',
  othersChatBackground: '#EEEEEE',
  textInputText: '#777777',
  chatTime: '#909090',
};

const ChatRoomContainer = styled.div`
  ${flexColumn};
  position: relative;
  overflow-y: auto;
  min-height: 395px;
  animation: 0.25s in-out forwards;
  @keyframes in-out {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const ChatRoomSection = styled.section`
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
`;

const MyMessageWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const OthersMessageWrap = styled(MyMessageWrap)`
  justify-content: flex-start;
`;

const MessageLine = styled.div`
  margin-bottom: 3px;
  :last-child {
    margin-bottom: 0;
  }
`;

const OthersChat = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  max-width: 334px;
  margin: 20px 3px 0 0;
  padding: 8px 15px;
  background-color: ${color.othersChatBackground};
  border-radius: 0 20px 20px 20px;
  color: ${color.textInputText};
`;

const MyChat = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  max-width: 334px;
  padding: 8px 15px;
  margin: 20px 0 0 3px;
  border-radius: 20px 0 20px 20px;
  color: white;
  background-color: ${color.chatBackground};
`;

const ChatInputWrap = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
`;

const ChatTextarea = styled.textarea`
  width: 85%;
  font-size: 15px;
  padding: 10px 40px 10px 16px;
  border: 1px solid ${color.chatInputBorder};
  border-radius: 25px;
  margin: 40px 0;
  background: url(chatSendIcon);
  background-repeat: no-repeat;
  resize: none;
  height: 40px;
  max-height: 85px;
  ::placeholder {
    opacity: 0.5;
  }
`;

const ChatSendBtn = styled(BasicBtn)`
  position: absolute;
  background: url(${chatSendIcon});
  background-size: contain;
  width: 17px;
  height: 17px;
  cursor: pointer;
  border: none;
  top: 43%;
  right: 13%;
`;

const ChatTime = styled.span`
  font-family: NotoSansRegular;
  font-size: 11px;
  color: ${color.chatTime};
`;
