import React, { useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';
import moment from 'moment';
import styled from '@emotion/styled';

import { Calendar, InformMessage } from 'components/molecules';

import { getAppointmentList, patchCancelAppointment } from 'redux/modules/appointment';
import { getAppointmentDates, postUnavailableDate } from 'redux/modules/schedule';

import { useModal } from 'hooks';

import { scheduleColors } from 'styles/global/color';
import { BasicBtn, CancelBtn } from 'styles/button';
import { flexCenter, flexColumn } from 'styles/global/mixins';

import { IRootState, IAppointment } from 'types/payloadTypes';

function SelectDate() {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const { doctorId } = useStore().getState().authentication.userInfo;

  const selectedDateRef = useRef('');
  const patchCancelAppointmentRef = useRef(0);

  const [datePassed, setDatePassed] = useState(false);

  const appointmentArray = useSelector((state: IRootState) => state.appointment.appointmentList.appointmentArray);
  const { datesList, availableDayList, unavailableDatesList } = useSelector((state: IRootState) => state.schedule);

  const addUnavailableDate = () => {
    dispatch(postUnavailableDate({ date: selectedDateRef.current }));
  };

  const compareTime = (appointmentTime: string) => {
    const thisMoment = moment();
    const isAppointmentPassed = thisMoment.isBefore(appointmentTime);

    return isAppointmentPassed;
  };

  const handlefetchAppointmentList = (date) => {
    dispatch(
      getAppointmentList({
        date,
        queryText: '',
        doctor: doctorId,
        department: '',
        startDate: '',
        endDate: '',
        status: 0,
        offset: '',
        month: '',
        patientName: '',
      }),
    );

    dispatch(
      getAppointmentDates({
        month: date.slice(5, 7),
        year: date.slice(0, 4),
      }),
    );

    const today = new Date();
    const selectedDate = new Date(date);
    setDatePassed(today >= selectedDate);

    selectedDateRef.current = date;
  };

  const getAppointmentStatus = (index: number) => {
    const { requestStatus } = appointmentArray[index];
    switch (requestStatus) {
      case 0: {
        return (
          <CancelAppointmentBtn onClick={() => handlePatchCancelAppointmentModal(appointmentArray[index].id)}>
            예약취소
          </CancelAppointmentBtn>
        );
      }

      case 1: {
        return <RequestPending>취소 진행중</RequestPending>;
      }

      case 2: {
        return <RequestGranted>취소완료</RequestGranted>;
      }

      default:
        return null;
    }
  };

  const getInformMessage = (index: number) => {
    const { requestStatus } = appointmentArray[index];
    if (requestStatus === 1) {
      return (
        <div style={{ marginTop: 5 }}>
          <InformMessage message="취소요청이 접수되어 관리자 승인 대기중입니다" />
        </div>
      );
    }
  };

  const getDisabledStatus = () => {
    if (appointmentArray.length === 0) {
      return false;
    }
    const requestStatus = appointmentArray.find((appointment: IAppointment) => appointment.requestStatus === 0);
    if (requestStatus) {
      return true;
    }
    return false;
  };

  const getDatesSet = () => new Set(datesList);

  const handleMonthChange = (date: string) => {
    const fetchMonth = date.slice(5, -3);
    const fetchYear = date.slice(0, 4);
    dispatch(
      getAppointmentDates({
        month: fetchMonth,
        year: fetchYear,
      }),
    );
  };

  const handleUnavailableDateAddModal = () => {
    openModal({
      modalType: 'ApprovalModal',
      modalProps: {
        onConfirm: addUnavailableDate,
        modalTitle: '예약 불가 일정 추가',
        modalExplanation: '해당 날짜에 상담 예약을 받지 않으시겠습니까?',
      },
    });
  };

  const handlePatchCancelAppointmentModal = (appointmentId: number) => {
    patchCancelAppointmentRef.current = appointmentId;
    openModal({
      modalType: 'InputModal',
      modalProps: {
        onConfirm: requestpatchCancelAppointment,
        modalTitle: '예약 취소',
        modalExplanation: '해당 예약을 취소하시겠습니까?',
        placeholder: '취소 사유를 입력해주세요',
        confirmBtnText: '확인',
      },
    });
  };

  const requestpatchCancelAppointment = (reason: string) => {
    dispatch(
      patchCancelAppointment({
        appointmentId: patchCancelAppointmentRef.current,
        reason,
      }),
    );
  };

  const showAddUnavailableBtn = () => {
    if (datePassed) {
      return false;
    }
    if (appointmentArray.length === 0) {
      return true;
    }

    const activeAppointment = appointmentArray.filter((appointment) => appointment.requestStatus !== 2);

    if (activeAppointment.length === 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    handlefetchAppointmentList(moment().format('YYYY-MM-DD'));
    // eslint-disable-next-line
  }, []);

  return (
    <SelectDateContainer>
      <CalendarWrap>
        <Calendar
          appointmentDateSet={getDatesSet()}
          availableDays={availableDayList}
          doctorId={doctorId}
          handleDateChange={handlefetchAppointmentList}
          handleMonthChange={handleMonthChange}
          parent="AppointmentSchedule"
          unavailableDates={unavailableDatesList}
        />
      </CalendarWrap>
      <AppointmentInfoSection hasAppointment={appointmentArray.length !== 0}>
        {appointmentArray.length === 0 ? (
          <NoAppointmentText>해당 날짜에 예약이 없습니다</NoAppointmentText>
        ) : (
          appointmentArray.map(
            (appointment, index) =>
              appointment.status !== 3 && (
                <AppointmentWrap key={`appointment-${index}`}>
                  <AppointmentDetail>
                    <AppointmentTime>{appointment.appointmentDate.slice(11, 16)}</AppointmentTime>
                    <CancelAppointmentWrap>
                      <PatientName>
                        {appointment.patientName}
                        {' 환자 예약'}
                      </PatientName>
                      {compareTime(appointment.appointmentDate) && getAppointmentStatus(index)}
                    </CancelAppointmentWrap>
                  </AppointmentDetail>
                  {getInformMessage(index)}
                </AppointmentWrap>
              ),
          )
        )}
      </AppointmentInfoSection>
      {showAddUnavailableBtn() && (
        <AddUnavailableBtn disabled={getDisabledStatus()} onClick={() => handleUnavailableDateAddModal()}>
          예약 불가 일정 추가
        </AddUnavailableBtn>
      )}
    </SelectDateContainer>
  );
}

export default SelectDate;

interface IAppointmentInfoSection {
  hasAppointment: boolean;
}

interface IAddUnavailableBtn {
  disabled: boolean;
}

const AddUnavailableBtn = styled(BasicBtn)<IAddUnavailableBtn>`
  padding: 13px 0;
  border-radius: 5px;
  font-family: NotoSansRegular;
  font-size: 16px;
  width: 100%;
  ${(props) => `
    color: ${!props.disabled ? scheduleColors.btnActiveText : scheduleColors.inactiveText};
    border: 1px solid ${!props.disabled ? scheduleColors.btnActiveBorder : scheduleColors.btnInactiveBorder};
    background-color: ${!props.disabled ? scheduleColors.btnActiveBackground : scheduleColors.btnInactiveBackground}
  `}
`;

const AppointmentWrap = styled.div`
  border-bottom: 1px solid ${scheduleColors.dailyAppointmentBorder};
  width: 100%;
  padding: 15px 0;
`;

const AppointmentDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  time:first-of-type {
    margin-right: 15px;
  }
`;

const AppointmentTime = styled.time`
  font-family: NotoSansRegular;
  font-size: 15px;
  color: ${scheduleColors.dailyAppointmentTime};
`;
const PatientName = styled.span`
  font-family: NotoSansRegular;
  font-size: 15px;
  color: ${scheduleColors.dailyAppointmentText};
`;

const CalendarWrap = styled.section`
  display: flex;
  justify-content: center;
  padding: 50px 20px 0;
`;

const SelectDateContainer = styled.div`
  ${flexColumn};
  flex: 1;
  margin-right: 20px;
  width: 405px;
  background-color: white;
  height: 100%;
  padding: 0 41.5px 50px;
`;

const AppointmentInfoSection = styled.section<IAppointmentInfoSection>`
  ${flexColumn};
  flex: 1;
  justify-content: flex-start;
  overflow: auto;
  min-height: 250px;
  div:last-child {
    border: none;
  }
  margin-top: ${(props) => (props.hasAppointment ? 40 : 0)}px;
`;

const NoAppointmentText = styled.div`
  margin: auto;
  font-family: NotoSansRegular;
  font-size: 15px;
  margin: 120px 0;
  color: ${scheduleColors.dailyAppointmentText};
  text-align: center;
`;

const CancelAppointmentBtn = styled(CancelBtn)`
  font-size: 13px;
  border-radius: 5px;
  width: 68px;
`;

const CancelAppointmentWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  align-items: center;
`;

const RequestPending = styled.span`
  ${flexCenter};
  border-radius: 5px;
  border: 1px solid ${scheduleColors.btnActiveBorder};
  color: ${scheduleColors.btnActiveText};
  font-family: NotoSansRegular;
  font-size: 13px;
  width: 82px;
  height: 25px;
`;

const RequestGranted = styled.span`
  ${flexCenter};
  border-radius: 5px;
  border: 1px solid ${scheduleColors.btnActiveBorder};
  color: ${scheduleColors.btnActiveText};
  background-color: ${scheduleColors.btnActiveBackground};
  font-family: NotoSansRegular;
  font-size: 13px;
  width: 68px;
  height: 25px;
`;
