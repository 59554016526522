import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { getDoctorSchedule, postDoctorSchedule, patchDotorSchedule, getTimeOptions } from 'redux/modules/schedule';

import { useModal } from 'hooks';

import { Table } from 'styles/table';
import { scheduleColors } from 'styles/global/color';
import { flexColumn } from 'styles/global/mixins';
import { BasicBtn } from 'styles/button';

import { IRootState, INameList } from 'types/payloadTypes';

function UpdateSchedule() {
  const dispatch = useDispatch();
  const { openModal } = useModal();

  const availableTimesList = useSelector((state: IRootState) => state.schedule.availableTimesList);

  const timeOptionList = useSelector((state: IRootState) => state.schedule.timeOptionList);

  const [availableTimes, setAvailableTimes] = useState(availableTimesList);

  const getDailySchedule = (day: string) => {
    if (Object.keys(availableTimes).length === 0) {
      return <NoAppointmentText>*일정을 등록해주세요</NoAppointmentText>;
    }
    if (Object.keys(availableTimes).includes(day)) {
      return timeOptionList.map((time: INameList, index: number) => (
        <TimeOption
          key={`time-${index}`}
          active={availableTimes[day].includes(time.id)}
          onClick={() => handleScheduleChange(day, time.id)}
        >
          {time.name}
        </TimeOption>
      ));
    }
    return <NoAppointmentText>*상담이 없는 날입니다</NoAppointmentText>;
  };

  const handleScheduleChange = (day: string, id: number) => {
    if (availableTimes[day].includes(id)) {
      const targetIndex = availableTimes[day].indexOf(id);
      availableTimes[day].splice(targetIndex, 1);
      setAvailableTimes({ ...availableTimes });
    } else {
      setAvailableTimes({
        ...availableTimes,
        [day]: [...availableTimes[day], id],
      });
    }
  };

  const handleDayBtnClick = (day: string) => {
    if (Object.keys(availableTimes).includes(day)) {
      delete availableTimes[day];
      setAvailableTimes({ ...availableTimes });
    } else {
      setAvailableTimes({ ...availableTimes, [day]: [] });
    }
  };

  const saveDoctorSchedule = () => {
    if (!Object.keys(availableTimesList).length) {
      dispatch(postDoctorSchedule(availableTimes));
      return;
    }

    Object.keys(availableTimes).forEach((day) => {
      if (availableTimes[day].includes(null) || availableTimes[day].length === 0) {
        delete availableTimes[day];
      }
    });

    dispatch(patchDotorSchedule(availableTimes));
  };

  const toggleUpdateModal = () => {
    openModal({
      modalType: 'ApprovalModal',
      modalProps: {
        onConfirm: saveDoctorSchedule,
        modalTitle: '일정 저장',
        modalExplanation: '해당 일정을 저장하시겠습니까?',
      },
    });
  };

  useEffect(() => {
    dispatch(getDoctorSchedule());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAvailableTimes(availableTimesList);
    // eslint-disable-next-line
  }, [availableTimesList]);

  useEffect(() => {
    dispatch(getTimeOptions());
    // eslint-disable-next-line
  }, []);

  return (
    <DayTimeWrap>
      <UpdateScheduleForm>
        <ScheduleTable>
          <thead>
            <tr>
              <th>요일</th>
              <th>예약 가능 시간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('0')} onClick={() => handleDayBtnClick('0')}>
                  일요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('0')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('1')} onClick={() => handleDayBtnClick('1')}>
                  월요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('1')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('2')} onClick={() => handleDayBtnClick('2')}>
                  화요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('2')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('3')} onClick={() => handleDayBtnClick('3')}>
                  수요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('3')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('4')} onClick={() => handleDayBtnClick('4')}>
                  목요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('4')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('5')} onClick={() => handleDayBtnClick('5')}>
                  금요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('5')}</TimeOptionWrap>
              </td>
            </tr>
            <tr>
              <td>
                <DayBtn active={Object.keys(availableTimes).includes('6')} onClick={() => handleDayBtnClick('6')}>
                  토요일
                </DayBtn>
              </td>
              <td>
                <TimeOptionWrap>{getDailySchedule('6')}</TimeOptionWrap>
              </td>
            </tr>
          </tbody>
        </ScheduleTable>
        <SaveScheduleBtnWrap>
          <SaveScheduleBtn
            active={Object.keys(availableTimes).length !== 0 && Object.values(availableTimes)[0].length !== 0}
            onClick={() => toggleUpdateModal()}
          >
            저장
          </SaveScheduleBtn>
        </SaveScheduleBtnWrap>
      </UpdateScheduleForm>
    </DayTimeWrap>
  );
}

export default UpdateSchedule;

interface IUpdateSchedule {
  active: boolean;
}

const UpdateScheduleForm = styled.div`
  ${flexColumn};
  flex: 1;
  height: 100%;
  justify-content: space-between;
`;

const SaveScheduleBtnWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const TimeOptionWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 32px;
  flex-wrap: wrap;
`;

const UpdateScheduleBtn = styled(BasicBtn)<IUpdateSchedule>`
  ${(props) => `
  color: ${props.active ? scheduleColors.btnActiveText : scheduleColors.inactiveText};
  border: 1px solid ${props.active ? scheduleColors.btnActiveBorder : scheduleColors.btnInactiveBorder};
  background-color: ${props.active ? scheduleColors.btnActiveBackground : scheduleColors.btnInactiveBackground};
  `}
  cursor: pointer;
  &:hover {
    color: ${scheduleColors.btnActiveText};
    border: 1px solid ${scheduleColors.btnActiveBorder};
    background-color: ${scheduleColors.btnActiveBackground};
  }
`;

const DayBtn = styled(UpdateScheduleBtn)<IUpdateSchedule>`
  font-family: NotoSansMedium;
  font-size: 15px;
  width: 121px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

const TimeOption = styled.time<IUpdateSchedule>`
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 0;
  font-size: 15px;
  font-weight: normal;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 40px;
  width: 53px;
  margin: 3px;
  cursor: pointer;
  ${(props) => `
    color: ${props.active ? scheduleColors.activeTimeText : scheduleColors.inactiveText};
    border: 1px solid ${props.active ? scheduleColors.activeTimeBorder : scheduleColors.btnInactiveBorder};
    background-color: ${props.active ? scheduleColors.activeTimeBackground : scheduleColors.btnInactiveBackground};
  `}
  &:hover {
    color: ${scheduleColors.activeTimeText};
    border: 1px solid ${scheduleColors.activeTimeBorder};
    background-color: ${scheduleColors.activeTimeBackground};
  }
`;

const DayTimeWrap = styled.section`
  background-color: white;
  width: 1351px;
  padding: 50px;
  overflow: auto;
  @media (min-width: 1350px) {
    width: 1351px;
  }
`;

const ScheduleTable = styled(Table)`
  border-spacing: 0 10px;
  thead tr th {
    font-family: NotoSansMedium;
    font-size: 16px;
    font-weight: 500;
    color: ${scheduleColors.dailyAppointmentText};
    background-color: ${scheduleColors.tableHeaderBackground};
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0px;
    text-indent: -99999px;
    width: 100%;
  }

  thead tr {
    th:first-of-type {
      border-radius: 5px 0 0 5px;
      padding: 6px 0;
      width: 121px;
    }

    th:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  tbody tr td {
    height: 81px;
    padding: 20.5px 0;
    margin: 0;
  }
  tbody tr:not(:last-child) td {
    border-bottom: 1px solid ${scheduleColors.tableRowBorder};
  }
`;

const NoAppointmentText = styled.div`
  font-family: NotoSansRegular;
  font-size: 15px;
  color: ${scheduleColors.inactiveText};
  opacity: 0.5;
  text-align: left;
`;

const SaveScheduleBtn = styled(UpdateScheduleBtn)<IUpdateSchedule>`
  font-family: NotoSansRegular;
  font-size: 16px;
  padding: 13px 0;
  width: 130px;
  border-radius: 3px;
`;
