import React from 'react';

import { useStore, useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';

import { Timer } from 'components/atoms';

import { getVerificationCode, postVerifyPhone } from 'redux/modules/authentication';

import { useForm } from 'hooks';

import { SectionLabel } from 'styles/text';
import { EditBtn } from 'styles/button';
import { mypageColor, globalColors, findInfoModalColor } from 'styles/global/color';

import { IRootState } from 'types/payloadTypes';

function Phone() {
  const dispatch = useDispatch();

  const state = useStore().getState();
  const [form, onFormChange] = useForm({
    phoneNumber: '',
    authenticationNumber: 0,
  });

  const { codeSent, verified } = useSelector((rootState: IRootState) => rootState.authentication);

  const { username } = state.authentication.userInfo;

  const handleVerificationCodeRetrieve = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!form.phoneNumber) return;
    e.preventDefault();

    dispatch(
      getVerificationCode({
        ...form,
        phoneNumber: `82-${form.phoneNumber.replace(/-/g, '')}`,
      }),
    );
  };

  const handlePhoneVerificationRequest = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!form.authenticationNumber) return;
    e.preventDefault();

    dispatch(
      postVerifyPhone({
        email: username,
        code: form.authenticationNumber,
        phoneNumber: `82-${form.phoneNumber.replace(/-/g, '')}`,
      }),
    );
  };

  return (
    <InputSection>
      <SectionLabel htmlFor="phoneNumber">휴대폰 번호</SectionLabel>
      <MypageInputWrap>
        <MypageInput name="phoneNumber" placeholder="변경할 휴대폰 번호를 입력해주세요" onChange={onFormChange} />
        <AuthenticationNoSendBtn sent={codeSent} onClick={(e) => handleVerificationCodeRetrieve(e)}>
          인증번호 발송
        </AuthenticationNoSendBtn>
      </MypageInputWrap>
      {codeSent && <Notification>인증 문자를 입력한 핸드폰 번호로 발송하였습니다</Notification>}
      <SectionLabel htmlFor="authenticationNumber">인증 코드</SectionLabel>
      <MypageInputWrap>
        <MypageInput name="authenticationNumber" placeholder="인증코드를 입력해주세요" onChange={onFormChange} />
        {codeSent && (
          <TimerWrapper>
            <Timer condition={codeSent} />
          </TimerWrapper>
        )}
        <AuthenticationBtn sent={verified} onClick={(e) => handlePhoneVerificationRequest(e)}>
          인증하기
        </AuthenticationBtn>
      </MypageInputWrap>
      {verified && <Notification>인증이 완료되었습니다</Notification>}
      <UpdatePersonalInfoBtn sent={verified}>휴대폰 번호 수정하기</UpdatePersonalInfoBtn>
    </InputSection>
  );
}

export default Phone;

interface IVerifyBtn {
  sent: boolean;
}

const InputSection = styled.form`
  padding: 58px 32px 56px 32px;
  height: 100%;
  position: relative;
`;

const MypageInputWrap = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
`;

const MypageInput = styled.input`
  border: 1px solid ${mypageColor.textareaBorder};
  border-radius: 5px;
  height: 40px;
  padding: 0 16px;
  margin-top: -6px;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 16px;
  color: ${mypageColor.input};
  width: 100%;
  background: none;
  ::placeholder {
    opacity: 0.5;
    font-size: 12px;
  }
  :focus {
    border: 1px solid ${globalColors.main};
    background-color: transparent;
  }
`;

const AuthenticationNoSendBtn = styled(EditBtn)<IVerifyBtn>`
  width: 114px;
  line-height: 19px;
  height: 40px;
  margin-left: 8px;
  margin-top: -6px;
`;

const AuthenticationBtn = styled(AuthenticationNoSendBtn)``;

const Notification = styled.div`
  display: flex;
  align-items: center;
  margin-top: -22px;
  margin-bottom: 33px;
  padding-left: 10px;
  font-size: 12px;
  line-height: 18px;
  height: 24px;
  color: ${findInfoModalColor.notificationText};
  background-color: ${findInfoModalColor.notificationBackground};
`;

const TimerWrapper = styled.div`
  position: absolute;
  right: 85px;
  bottom: 73px;
`;

const UpdatePersonalInfoBtn = styled(EditBtn)<IVerifyBtn>`
  height: 56px;
  position: absolute;
  bottom: -170px;
  width: 86%;
  ${(props) =>
    `color : ${props.sent ? mypageColor.activeBtnText : mypageColor.specialtyInativeText};
    background-color : ${props.sent ? mypageColor.activeBtnBackground : mypageColor.changeProfileBtn};
    border : 1px solid ${props.sent ? mypageColor.activeBtnBorder : mypageColor.textareaBorder}`}
`;
