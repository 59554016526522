import React, { useRef, useEffect } from 'react';

import styled from '@emotion/styled';
import { ILocalVideoTrack, IRemoteVideoTrack, ILocalAudioTrack, IRemoteAudioTrack } from 'agora-rtc-sdk-ng';

export interface VideoPlayerProps {
  audioTrack: ILocalAudioTrack | IRemoteAudioTrack | undefined;
  id: string;
  videoTrack: ILocalVideoTrack | IRemoteVideoTrack | undefined;
}

function MediaPlayer({ audioTrack, id, videoTrack }: VideoPlayerProps) {
  const container = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!container.current) return;
    if (!videoTrack) return;
    const mediaStream = new MediaStream();
    if (audioTrack) {
      const audioStreamTrack = audioTrack?.getMediaStreamTrack();
      mediaStream.addTrack(audioStreamTrack);
    }

    const videoStreamTrack = videoTrack.getMediaStreamTrack();
    mediaStream.addTrack(videoStreamTrack);
    container.current.srcObject = mediaStream;

    return () => {
      videoTrack?.stop();
    };
  }, [container, id, audioTrack, videoTrack]);

  useEffect(() => {
    if (id !== 'myVideo') {
      audioTrack?.play();
    }
    return () => {
      audioTrack?.stop();
    };
  }, [audioTrack, id]);

  return <VideoElement autoPlay playsInline id={id} muted={id !== 'patientAudio'} ref={container} />;
}

export default MediaPlayer;

const VideoElement = styled.video`
  background-image: url(/icons/ic-avatar.svg);
  background-repeat: no-repeat;
  background-size: 80px 80px;
  background-color: rgba(31, 31, 31, 0.5);
  background-position: center center;
  background-clip: padding-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
`;
